<template>
    <div class="row ma-4 mr-16 pr-16">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.name') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="name" id="name" name="name" maxlength="30" required hide-details
                    persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.name')"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgName">{{ msg.name }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.code') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="code" maxlength="20" id="code" name="code"
                    :placeholder="$t('suppliers.generalInformation.placeholder.code')" variant="outlined" hide-details
                    persistent-placeholder></v-text-field>
                <span class='warningHint' id="WarningMsgCode"> {{ msg.code }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.payment') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-autocomplete v-model="paymentCondition" :items="paymentConditionItems" :suffix="'Days'"
                    :placeholder="$t('suppliers.generalInformation.placeholder.payment')" variant="outlined"
                    v-model:search="manualPaymentCondition" hide-no-data hide-details
                    @update:search="manualPaymentCondition = manualPaymentCondition.replace(/[^0-9.]/g, '')">
                </v-autocomplete>
                <span class='warningHint' id="WarningMsgPaymentCondition"> {{ msg.paymentCondition }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.currencies') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-autocomplete id="Currency" name="Currency" variant="outlined" v-model="Devise" persistent-placeholder
                    :placeholder="$t('suppliers.generalInformation.placeholder.currencies')" :items="DeviseList"
                    item-title="name" item-value="symbol" hide-details>
                    <template v-slot:item="{ props, item, index }">
                        <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                            {{ item.name }}
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <span class="warningHint" id="WarningMsgDevise">{{ msg.Devise }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.defaultPhone') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="defaultPhone" id="defaultPhone" name="defaultPhone"
                    :placeholder="$t('suppliers.generalInformation.placeholder.defaultPhone')" variant="outlined"
                    maxlength="15" @update:model-value="defaultPhone = defaultPhone.replace(/[^0-9.]/g, '');" hide-details
                    persistent-placeholder></v-text-field>

                <span class='warningHint' id="WarningMsgDefaultPhone"> {{ msg.defaultPhone }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.defaultEmail') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="defaultEmail" id="defaultEmail" name="defaultEmail" maxlength="40"
                    :placeholder="$t('suppliers.generalInformation.placeholder.defaultEmail')" variant="outlined"
                    hide-details persistent-placeholder>
                </v-text-field>
                <span class='warningHint' id="WarningMsgDefaultEmail"> {{ msg.defaultEmail }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.delivery') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="delivery" maxlength="30" id="delivery" name="delivery"
                    :placeholder="$t('suppliers.generalInformation.placeholder.delivery')" variant="outlined" hide-details
                    persistent-placeholder></v-text-field>
                <span class='warningHint' id="WarningMsgDelivery"> {{ msg.delivery }} </span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.total') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="totalMinimumOrder" id="totalMinimumOrder" name="totalMinimumOrder" hide-details
                    maxlength="9" persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.total')"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgTotalMinimumOrder"> {{ msg.totalMinimumOrder }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.country') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <select-country :placeholder="$t('suppliers.generalInformation.placeholder.country')"
                    v-bind:countryProps="country" @getCountry="getCountryFromSelect"></select-country>
                <span class='warningHint' id="WarningMsgCountry"> {{ msg.country }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.postalCode') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field class="ml-1" v-model="postalCode" id="postalCode" name="postalCode" maxlength="9"
                    @update:model-value="postalCode = postalCode.replace(/[^0-9.]/g, '');" hide-details
                    persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.postalCode')"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgPostalCode"> {{ msg.postalCode }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.city') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="city" maxlength="20" id="city" name="city"
                    :placeholder="$t('suppliers.generalInformation.placeholder.city')" variant="outlined" hide-details
                    persistent-placeholder></v-text-field>
                <span class='warningHint' id="WarningMsgCity"> {{ msg.city }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.address') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="address" maxlength="50" id="address" name="address" hide-details
                    persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.address')"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgAddress"> {{ msg.address }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import deviseJson from "../../../data/DevisesList.json"
import SelectCountry from '../../SelectCountry/SelectCountry.vue';
export default {
    components: {
        SelectCountry,
    },
    data: () => ({
        msg: [],
        paymentConditionItems: ["10", "15", "20", "30"],
        manualPaymentCondition: '',
        postalCode: "",
        citiesItems: [],
        errorMessages: "",
        name: "",
        Devise: null,
        paymentCondition: null,
        defaultPhone: "",
        delivery: "",
        defaultEmail: "",
        country: null,
        city: "",
        code: "",
        currencies: "",
        totalMinimumOrder: "",
        address: "",
        supplierData: {},
        DeviseList: deviseJson,
    }),
    watch: {
        manualPaymentCondition(value) {
            let findValue = this.paymentConditionItems.find(v => v == value);
            if (value != '' && findValue == undefined) {
                this.paymentConditionItems[4] = value;
            } else {
                this.paymentConditionItems.splice(4)
            }
        },
        Devise(value) {
            this.Devise = value;
            this.supplierData.currencies = this.Devise;
            if (this.Devise == null) delete this.supplierData.currencies;
            this.$emit("getData", this.supplierData);
        },
        postalCode(value) {
            this.postalCode = value;
            this.supplierData.postalCode = this.postalCode
            if (this.postalCode == "") delete this.supplierData.postalCode;
            this.$emit("getData", this.supplierData);
        },
        defaultEmail(value) {
            this.defaultEmail = value;
            this.supplierData.defaultEmail = this.defaultEmail;
            if (this.defaultEmail == "") delete this.supplierData.defaultEmail;
            this.$emit("getData", this.supplierData);
        },
        defaultPhone(value) {
            this.defaultPhone = value;
            this.supplierData.defaultPhone = this.defaultPhone;
            if (this.defaultPhone == "") delete this.supplierData.defaultPhone;
            this.$emit("getData", this.supplierData);
        },
        name(value) {
            this.name = value;
            this.supplierData.name = this.name;
            if (this.name == "") delete this.supplierData.name;
            this.$emit("getData", this.supplierData);
        },
        paymentCondition(value) {
            this.paymentCondition = value;
            this.supplierData.paymentCondition = this.paymentCondition;
            if (this.paymentCondition == "")
                delete this.supplierData.paymentCondition;
            this.$emit("getData", this.supplierData);
        },
        delivery(value) {
            this.delivery = value;
            this.supplierData.delivery = this.delivery;
            if (this.delivery == "") delete this.supplierData.delivery;
            this.$emit("getData", this.supplierData);
        },
        country(value) {
            this.country = value;
            this.supplierData.country = this.country
            if (this.country == "") delete this.supplierData.country;
            this.$emit("getData", this.supplierData);
        },
        city(value) {
            this.city = value;
            this.supplierData.city = this.city;
            if (this.city == "") delete this.supplierData.city;
            this.$emit("getData", this.supplierData);
        },
        code(value) {
            this.code = value;
            this.supplierData.code = this.code;
            if (this.code == "") delete this.supplierData.code;
            this.$emit("getData", this.supplierData);
        },
        currencies(value) {
            this.currencies = value;
            this.supplierData.currencies = this.currencies;
            if (this.currencies == "")
                delete this.supplierData.currencies;
            this.$emit("getData", this.supplierData);
        },
        totalMinimumOrder(value) {
            this.totalMinimumOrder = value;
            this.supplierData.totalMinimumOrder = this.totalMinimumOrder;
            if (this.totalMinimumOrder == "")
                delete this.supplierData.totalMinimumOrder;
            this.$emit("getData", this.supplierData);
        },
        address(value) {
            this.address = value;
            this.supplierData.address = this.address
            this.$emit("getData", this.supplierData);
        },
    },
    methods: {
        getCountryFromSelect(value) {
            this.country = value;
        },
        ControleSaisir() {
            if (this.postalCode == "") {
                this.msg["postalCode"] = this.$t('suppliers.generalInformation.errors.postalCode');
            } else {
                this.msg["postalCode"] = "";
            }
            if (this.name == "") {
                this.msg["name"] = this.$t('suppliers.generalInformation.errors.name');
            } else {
                this.msg["name"] = "";
            }
            if (this.code == "") {
                this.msg["code"] = this.$t('suppliers.generalInformation.errors.code');
            } else {
                this.msg["code"] = "";
            }
            if (this.paymentCondition == null || this.paymentCondition == "Select") {
                this.msg["paymentCondition"] = this.$t('suppliers.generalInformation.errors.payment');
            } else {
                this.msg["paymentCondition"] = "";
            }

            if (!(this.defaultPhone) || (this.defaultPhone).length == 0) {
                this.msg['defaultPhone'] = this.$t('suppliers.generalInformation.errors.defaultPhone');
            } else if (((this.defaultPhone).length < 8) || ((this.defaultPhone).length > 15)) {
                this.msg['defaultPhone'] = this.$t('suppliers.generalInformation.errors.wrongNumber');
            } // eslint-disable-next-line
            else if (this.defaultPhone.match(/^[\+]?[0-9]{8,15}$/im)) {
                this.msg['defaultPhone'] = '';
            } else {
                this.msg['defaultPhone'] = this.$t('suppliers.generalInformation.errors.wrongNumber');
            }

            if (this.delivery == "") {
                this.msg["delivery"] = this.$t('suppliers.generalInformation.errors.delivery');
            } else {
                this.msg["delivery"] = "";
            }

            if (this.defaultEmail == "") {
                this.msg["defaultEmail"] = this.$t('suppliers.generalInformation.errors.defaultEmail');
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.defaultEmail)) {
                this.msg["defaultEmail"] = "";
            } else {
                this.msg["defaultEmail"] = this.$t('suppliers.generalInformation.errors.wrongEmail');
            }

            if (this.country == null || this.country == "") {
                this.msg["country"] = this.$t('suppliers.generalInformation.errors.country');
            } else {
                this.msg["country"] = "";
            }
            if (this.city == "" || this.city == "Select city") {
                this.msg["city"] = this.$t('suppliers.generalInformation.errors.city');
            } else {
                this.msg["city"] = "";
            }
            if (this.currencies == "" || this.currencies == "Select currency") {
                this.msg["currencies"] = this.$t('suppliers.generalInformation.errors.currencyks');
            } else {
                this.msg["currencies"] = "";
            }
            if (this.totalMinimumOrder == "") {
                this.msg["totalMinimumOrder"] = this.$t('suppliers.generalInformation.errors.total');
            } else {
                this.msg["totalMinimumOrder"] = "";
            }
            if (this.address == "") {
                this.msg["address"] = this.$t('suppliers.generalInformation.errors.address');
            } else {
                this.msg["address"] = "";
            }

            if (!this.Devise || this.Devise.length == 0) {
                this.msg["Devise"] = this.$t('suppliers.generalInformation.errors.currencyks');
            } else {
                this.msg["Devise"] = "";
            }

            if (
                this.msg["postalCode"] == "" &&
                this.msg["name"] == "" &&
                this.msg["paymentCondition"] == "" &&
                this.msg["defaultPhone"] == "" &&
                this.msg["delivery"] == "" &&
                this.msg["defaultEmail"] == "" &&
                this.msg["country"] == "" &&
                this.msg["city"] == "" &&
                this.msg["totalMinimumOrder"] == "" &&
                this.msg["address"] == "" &&
                this.msg["code"] == ""
                && this.msg["Devise"] == ""
            ) {
                return true;
            }
        },
    }
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
}

.logo-img {
    background-image: url("../../../assets/upload.png");
    width: 100px;
    height: 79px;
    background-size: contain;
}

.upload {
    background-color: #3bbaff;
    width: 147px;
    height: 37px;
    padding: 13px, 136px, 13px, 136px;
    border-radius: 9px;
    text-align: center;
    margin-left: 12px;
}

.load {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 34px;
    color: #fff;
}

.uploadText {
    width: 330px;
    height: 36px;
    margin-top: -4px;
    font-family: inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: darkgray;
}

.updateSuccess {
    width: 313px;
    height: 32px;
    background-color: hsl(142, 100%, 91%);
    border-radius: 6px;
    margin-top: 25px;
    font-family: Inter;
    font-size: 13px;
}

.updateSuccess .v-card-text {
    color: #24c581;
    font-style: 600, Bold;
    font-size: 12px;
}

.nxmlogo {
    width: 88px;
    height: 80px;
    display: flex;
}

.footer {
    margin-top: 30px;
    margin-bottom: 30px
}

.all {
    display: block;
    margin-left: 90px;
    margin-top: -100px;
}
</style>