<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('permissions.editRole') }}
                </v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :loader="buttonLoader" :ClickFun="Save" svg="2" :Title="$t('permissions.Save')" />
            </div>
        </div>
        <v-card elevation="0">
            <v-tabs v-model="tab" grow touchless>
                <v-tab class="slider" value="1" centered v-model="role"
                    :style="[parseInt(tab) == 1 ? 'color:blue' : NotActive]">
                    {{ $t('permissions.Roles') }}
                </v-tab>
                <v-tab class="slider" value="2" v-model="Permissions"
                    :style="[parseInt(tab) == 2 ? 'color:blue' : NotActive]">
                    {{ $t('permissions.Permissions') }}
                </v-tab>
                <v-tab class="slider" value="3" v-model="Users" :style="[parseInt(tab) == 3 ? 'color:blue' : NotActive]">
                    {{ $t('permissions.Users') }}
                </v-tab>
                <v-tab style="pointer-events: none;">
                </v-tab>
                <v-tab style="pointer-events: none;">
                </v-tab>
            </v-tabs>
            <v-divider class="ma-0"></v-divider>
            <v-window v-model="tab" :key="tab">
                <v-window-item value="1">
                    <span v-if="pageLoader">
                        <v-progress-circular indeterminate :size="30"></v-progress-circular>
                    </span>
                    <AddRole v-else @getData="getDataFromRoles" ref="RoleRef" Mode="Edit" :RoleProps="Role" />
                </v-window-item>
                <v-window-item value="2">
                    <span v-if="pageLoader">
                        <v-progress-circular indeterminate :size="30"></v-progress-circular>
                    </span>
                    <AddPermissions v-else @getData="getDataFromPermissions" ref="PermissionsRef" Mode="Edit"
                        :RoleProps="Role" />
                </v-window-item>
                <v-window-item value="3">
                    <span v-if="pageLoader">
                        <v-progress-circular indeterminate :size="30"></v-progress-circular>
                    </span>
                    <AddUsers v-else @getData="getDataFromUsers" @getListUserByCompany="getListUserByCompany" ref="UsersRef"
                        Mode="Edit" :RoleProps="Role" />
                </v-window-item>
            </v-window>
        </v-card>
    </div>
</template>
<script>
import { UpdateRole, updateMultiPermissions, updateMultiUsers, getRoleById } from "@/api";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import AddPermissions from "../NewRole/AddPermissions.vue";
import AddRole from "../NewRole/AddRole.vue";
import AddUsers from "../NewRole/AddUsers.vue";
export default {
    components: { genericBtnComponent, AddRole, AddPermissions, AddUsers },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.permissions');
    },
    data() {
        return {
            tab: "1",
            NotActive: {
                color: "#667280",
                fontSize: "14px",
                font: "inter",
            },
            RoleId: this.$route.params.id,
            dataFromRoles: {},
            dataFromPermissions: null,
            dataFromUsers: null,
            Role: null,
            buttonLoader: null,
            pageLoader: null,
            UserByCompany: null,
            RoleUsers: null
        };
    },
    created() {
        this.getRoleById()
    },
    methods: {
        Save() {
            this.buttonLoader = true;
            if (this.tab == "1") {
                this.UpdateRole();
            } else if (this.tab == "2") {
                this.UpdatePermissions();
            } else if (this.tab == "3") {
                this.UpdateUsers();
            }
        },
        getRoleById() {
            this.pageLoader = true
            getRoleById(this.RoleId).then((response) => { this.Role = response.data }).finally(() => this.pageLoader = false)
        },
        getDataFromRoles(value) {
            this.dataFromRoles = value;
        },
        UpdateRole: function () {
            if (this.$refs.RoleRef.validateForm()) {
                UpdateRole(
                    {
                        "id": this.RoleId,
                        "name": this.dataFromRoles.Name,
                        "description": this.dataFromRoles.Description
                    }
                ).then((response) => {
                    if (response.status == 200) {
                        this.ActiveSavedSnackBar();
                    }
                })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        getDataFromPermissions(value) {
            this.dataFromPermissions = value;
        },
        UpdatePermissions: function () {
            if (this.$refs.PermissionsRef.validateForm()) {
                this.buttonLoader = true
                var PermissionsidsToDelete = []
                this.Role.features.map((obj) => {
                    let pos = this.dataFromPermissions.indexOf(obj.id)
                    if (pos == -1) {
                        PermissionsidsToDelete.push(obj.id)
                    } else if (pos != -1) {
                        this.dataFromPermissions.splice(pos, 1)
                    }
                })
                updateMultiPermissions(this.RoleId, this.dataFromPermissions, PermissionsidsToDelete)
                    .then((response) => {
                        if (response.status == 200) {
                            this.ActiveSavedSnackBar();
                            this.getRoleById()
                        }
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        getDataFromUsers(value) {
            this.dataFromUsers = value;
        },
        getListUserByCompany(value) {
            this.UserByCompany = value;
            this.RoleUsers = this.Role.users.filter((obj) => this.UserByCompany.includes(obj.id))
        },
        UpdateUsers: function () {
            if (this.$refs.UsersRef.validateForm()) {
                this.buttonLoader = true
                var UsersidsToDelete = []
                this.RoleUsers.map((obj) => {
                    let pos = this.dataFromUsers.indexOf(obj.id)
                    if (pos == -1) {
                        UsersidsToDelete.push(obj.id)
                    } else if (pos != -1) {
                        this.dataFromUsers.splice(pos, 1)
                    }
                })
                updateMultiUsers(this.RoleId, this.dataFromUsers, UsersidsToDelete)
                    .then((response) => {
                        if (response.status == 200) {
                            this.ActiveSavedSnackBar();
                            this.getRoleById()
                        }
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
    },
};
</script>
<style scoped>
.v-card-title {
    font-size: 14px;
    font-family: inter;
    font-weight: 600;
}

.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.bg-basil {
    background-color: #ffffff !important;
}

.text-basil {
    color: #114ef7 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: inter;
}

.btn {
    display: flex;
}

.Save {
    color: #fff;
    height: 45px;
    font-family: inter;
    font-weight: 600;
    display: inline-flex;
}

.icon {
    width: 10px;
    height: 10px;
}

.iconSave {
    width: 20px;
    height: 15px;
    margin-right: 5px;
}

.svg {
    margin-right: 8px;
    margin-top: 4px;
}

.slider {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    border-bottom: "2px solid black";
}
</style>