<template>
    <actions-menu v-model:dialogEdit="dialog2" v-model:dialogDelete="dialog" :Id="Id" />
    <dialogDelete :title="$t('configuration.department.department')" :yes="deleteDepartement" v-model="dialog" />
    <v-dialog v-model="dialog2" v-if="dialog2">
        <v-card width="800px" class="py-6">
            <h1>{{ $t('actions.edit', { itm: $t('configuration.department.department') }) }}</h1>
            <div class="row ma-4">
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.department.departmentName') }} <span class="red">*</span>
                            </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="DepartmentName" maxlength="30" id="DepartmentName" name="DepartmentName" :readonly="!validationButton"
                            hide-details persistent-placeholder
                            :placeholder="$t('placeholder.enter', { itm: $t('configuration.department.departmentName') })"
                            variant="outlined"></v-text-field>
                        <span class="warningHint" id="WarningMsgDepartmentName"> {{ msg.DepartmentName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.department.locationCoverage') }} <span class="red">*</span></v-card-title>
                    </div>
                    <div class="row">
                        <select-country-by-location v-bind:multi="false" v-bind:countryProps="LocationCoverage"
                            @getCountry="getCountryFromSelect" :readonly="!validationButton" :displayActifLocation="true"></select-country-by-location>
                        <span class="warningHint" id="WarningMsgLocationCoverage"> {{ msg.LocationCoverage }}</span>
                    </div>
                </div>
            </div>
            <card-actions :yes="EditDepartment" v-model:no="dialog2" width="300px" :yesDisable="!validationButton" :yesTitle="$t('actions.save')" />
        </v-card>
    </v-dialog>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import { editDepartment, deleteDepartement,CheckPermissionByUser } from "@/api/index"
import SelectCountryByLocation from '../SelectCountry/SelectCountryByLocation.vue';
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
export default defineComponent({
    mounted() {
        this.checkEditDepartmentPermission();
    },
    components: { SelectCountryByLocation, actionsMenu, dialogDelete, cardActions },
    props: ['idDepartment', 'getDepartmentListAfterUpdate', 'idCompany', 'item', "Id"],
    data: () => ({
        dialog: false,
        dialog2: false,
        DepartmentName: null,
        LocationCoverage: null,
        msg: [],
        validationButton:false,

    }),
    created() {
        this.DepartmentName = this.$props.item.departmentName
        if (this.$props.item.locationCountryName) {
            this.LocationCoverage = this.$props.item.idLocation;
        } else {
            this.LocationCoverage = null
        }
    },
    watch: {
        dialog2() {
            this.DepartmentName = this.$props.item.departmentName
            this.DepartmentName = this.$props.item.departmentName
            if (this.$props.item.locationCountryName) {
                this.LocationCoverage = this.$props.item.idLocation;
            } else {
                this.LocationCoverage = null
            }
            this.msg = []
        },
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        getCountryFromSelect(value) {
            this.LocationCoverage = value;
        },
        checkEditDepartmentPermission: function () {
            CheckPermissionByUser("Optima.Company.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        EditDepartment: function () {
            this.msg["DepartmentName"] = "";
            this.msg["LocationCoverage"] = "";
            if (!this.DepartmentName || this.DepartmentName.length == 0) {
                this.msg["DepartmentName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.department.departmentName') });
            } else {
                this.msg["DepartmentName"] = "";
            }
            if (this.LocationCoverage == null) {
                this.msg["LocationCoverage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.department.locationCoverage') });
            } else {
                this.msg["LocationCoverage"] = "";
            }
            if (
                this.msg["DepartmentName"] == "" &&
                this.msg["LocationCoverage"] == ""
            ) {
                if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    this.btnloader = false;
                    return;
                }
                editDepartment(this.idDepartment, {
                    idDepartment: this.idDepartment,
                    departmentName: this.DepartmentName,
                    idLocation: this.LocationCoverage,
                    idCompany: this.idCompany,
                }
                ).then(() => {
                    this.dialog2 = false;
                    this.getDepartmentListAfterUpdate();
                    this.ActiveSavedSnackBar();
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                });
            }
        },
        deleteDepartement() {
            this.dialog = false;
            deleteDepartement(this.idDepartment)
                .then(() => {
                    this.ActiveDeletedSnackBar();
                    this.getDepartmentListAfterUpdate();
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                })
        },
    }
});
</script>
<style scoped>
h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #0d0849;
    text-align: center;
}
.v-card-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #5B6D8E;
}
</style>