<template>
    <div class="row ma-4 mr-16 pr-16">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.tax.taxName') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="TaxName" id="TaxName" name="TaxName" maxlength="30" hide-details
                    persistent-placeholder :placeholder="$t('placeholder.enter', { itm: $t('configuration.tax.taxName') })"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgTaxName"> {{ msg.TaxName }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.tax.taxPercentage') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field maxlength="9" v-model="TaxPercentage" id="TaxPercentage" name="TaxPercentage" hide-details
                    persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.tax.taxPercentage') })" variant="outlined"
                    @update:model-value="TaxPercentage = TaxPercentage.replace(/[^0-9.]/g, '');" min="0"></v-text-field>
                <span class='warningHint' id="WarningMsgTaxPercentage"> {{ msg.TaxPercentage }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            TaxPercentage: null,
            TaxName: "",
            msg: [],
            TaxeData: {
                TaxName: "",
                TaxPercentage: null,
            }
        }
    },
    watch: {
        TaxName(value) {
            this.TaxName = value;
            this.TaxeData.TaxName = this.TaxName;
            if (this.TaxName == "")
                delete this.TaxeData.TaxName
            this.$emit('getData', this.TaxeData)
        },
        TaxPercentage(value) {
            this.TaxPercentage = value;
            this.TaxeData.TaxPercentage = this.TaxPercentage;
            if (this.TaxPercentage == 0 || this.TaxPercentage == null || this.TaxPercentage < 0)
                delete this.TaxeData.TaxPercentage
            this.$emit('getData', this.TaxeData)
        },
    },
    methods: {
        validateForm() {
            this.msg["TaxName"] = "";
            this.msg["TaxPercentage"] = "";
            if (!this.TaxName || this.TaxName.length == 0) {
                this.msg["TaxName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.tax.taxName') });
            } else {
                this.msg["TaxName"] = "";
            }

            if (!this.TaxPercentage || this.TaxPercentage.length == 0) {
                this.msg["TaxPercentage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.tax.taxPercentage') });
            } else {
                this.msg["TaxPercentage"] = "";
            }

            if (
                this.msg["TaxName"] == "" &&
                this.msg["TaxPercentage"] == ""
            ) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5B6D8E;
    margin-left: 12px;
    margin-bottom: 5px;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
</style>
