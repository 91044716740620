import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "@/router/router"
import Vuesax from 'vuesax3'
import axios from 'axios'
import 'vuesax3/dist/vuesax.css'
import "@/Style.css"
import '@/api/index'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import store from './store'
import mitt from 'mitt';
import i18n from './i18n';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
const appInsights = new ApplicationInsights({
  config: {
    connectionString: window.VUE_APP_APPLICATIONINSIGHTS_CONNECTIONSTRING
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();
export const emitter = mitt();
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("accessToken")
axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("accessToken")) {
      config.headers["Authorization"] = 'Bearer ' + localStorage.getItem("accessToken")
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    emitter.emit('Active401WarningSnackBar');
  }
  return Promise.reject(error);
});
var app = createApp(App)
app.use(router)
app.use(vuetify)
app.use(Vuesax)
app.use(store)
app.use(i18n)
app.config.globalProperties.emitter = emitter
app.mount('#app')