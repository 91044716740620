<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('Document.Title.client') }}
                </v-card-title>
            </div>
        </div>
        <v-card elevation="0">
            <v-tabs style="background-color: #fff; border-radius: 6px 6px 0px 0px;" hide-slider grow touchless>
                <v-tab class="slider" value="1">
                    {{ $t('configuration.generalInformation') }}
                </v-tab>
            </v-tabs>
            <v-divider class="ma-0"></v-divider>
            <v-window>
                <v-window-item value="1">
                    <v-card flat>
                        <div v-if="loader" style="height: 250px; line-height: 250px;">
                            <v-progress-circular indeterminate :size="50" />
                        </div>
                        <div v-else class="row ma-4 mr-16 pr-16">
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.brandName')
                                    }}</v-card-title>
                                </div>
                                <div class="row">
                                    <v-text-field v-model="item.brandName" id="brandName" name="brandName" hide-details
                                        :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.brandName') })"
                                        variant="outlined" readonly class="readonly-field" />
                                </div>
                            </div>
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.companyfullname') }}
                                    </v-card-title>
                                </div>
                                <div class="row">
                                    <v-text-field v-model="item.companyFullName" id="companyFullName" name="companyFullName"
                                        :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.companyfullname') })"
                                        variant="outlined" readonly class="readonly-field" hide-details/>
                                </div>
                            </div>
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.address')
                                    }}</v-card-title>
                                </div>
                                <div class="row">
                                    <v-text-field v-model="item.address" id="address" name="address" hide-details
                                        :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.address') })"
                                        variant="outlined" readonly class="readonly-field" />
                                </div>
                            </div>
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.city') }}
                                    </v-card-title>
                                </div>
                                <div class="row">
                                    <v-text-field v-model="item.city" id="city" name="city" hide-details
                                        :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.city') })"
                                        variant="outlined" readonly class="readonly-field" />
                                </div>
                            </div>
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.postalCode')
                                    }}</v-card-title>
                                </div>
                                <div class="row">
                                    <v-text-field v-model="item.postalCode" id="postalCode" name="postalCode" hide-details
                                        :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.postalCode') })"
                                        variant="outlined" readonly class="readonly-field" />
                                </div>
                            </div>
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.country')
                                    }}</v-card-title>
                                </div>
                                <div class="row">
                                    <select-country v-bind:countryProps="item.country" readonly class="readonly-field" />
                                </div>
                            </div>
                            <div class="col-6 justify-content-start">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.defaultmail')
                                    }}</v-card-title>
                                </div>
                                <div class="row">
                                    <v-text-field v-model="item.defaultEmail" id="defaultEmail" name="defaultEmail"
                                        :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.defaultmail') })"
                                        variant="outlined" readonly class="readonly-field" hide-details />
                                </div>
                            </div>
                            <div class="col-6 justify-content-start" v-if="item.logo != 'null'">
                                <div class="row">
                                    <v-card-title class="text-left ma-0">{{ $t('configuration.company.logo')
                                    }}</v-card-title>
                                </div>
                                <div class="row">
                                    <uploadLogo readonly :API="1"
                                        :namelogo="item.logo != 'null' ? item.idCompany + '.' + item.logo : null" />
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-window-item>
            </v-window>
        </v-card>
    </div>
</template>
<script>
import { getSupplierClientID, getClientById } from '@/api/index';
import uploadLogo from '@/genericComponent/uploadLogo.vue'
import SelectCountry from '../SelectCountry/SelectCountry.vue';
export default {
    components: {
        SelectCountry, uploadLogo
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.client');
    },
    created() {
        this.getClientById()
    },
    data: () => ({
        loader: true,
        item: null,
    }),
    methods: {
        getClientById() {
            getSupplierClientID().then(({ data }) => {
                getClientById(data).then((res) => {
                    this.item = res.data;
                    this.loader = false;
                })
            })
        }
    }
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.v-window .v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}

.slider {
    color: #667280;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    pointer-events: none;
    border: none;
}
</style>