<template>
    <actions-menu v-model:dialogEdit="editUserDialog" v-model:dialogDelete="deleteUserDialog" :Id="Id" />
    <dialogDelete :title="$t('configuration.user.user')" :yes="DeleteUser" v-model="deleteUserDialog" />
    <v-dialog v-model="editUserDialog" v-if="editUserDialog">
        <v-card width="800px" class="py-6">
            <h1>{{ $t('actions.edit', { itm: $t('configuration.user.user') }) }}</h1>
            <div class="row ma-4 my-6">
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.user.Fullname') }}<span class="red">*</span>
                            </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="userNameEdit" id="userNameEdit" name="userNameEdit" :readonly="!validationButton" hide-details
                            persistent-placeholder required maxlength="30"
                            :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.Fullname') })"
                            variant="outlined"></v-text-field>
                        <span class="warningHint" id="WarningMsgUserName">{{ msg.userNameEdit }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.user.email') }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field maxlength="40" v-model="userEmailEdit" id="userEmailEdit" :readonly="!validationButton" name="userEmailEdit"
                            hide-details persistent-placeholder
                            :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.email') })"
                            variant="outlined"></v-text-field>
                        <span class="warningHint" id="WarningMsgEmail">{{ msg.userEmailEdit }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.user.department') }}<span class="red">*</span>
                            </v-card-title>
                    </div>
                    <div class="row">
                        <v-select id="Department" name="Department" variant="outlined" :readonly="!validationButton"
                            :placeholder="$t('placeholder.select', { itm: $t('configuration.user.department') })"
                            v-model="departementEdit" :items="DepartmentList" item-title="departmentName"
                            item-value="idDepartment" hide-details>
                            <template v-slot:item="{ props, item, index }">
                                <v-list-item style="background-color: white;" v-bind="props" :readonly="!validationButton"
                                    :title="item.raw.departmentName" variant="text" :id="index" :name="item.title">
                                </v-list-item>
                            </template>
                        </v-select>
                        <span class="warningHint" id="WarningMsgDepartement">
                            {{ msg.departementEdit }}
                        </span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.user.regionCoverage') }}<span class="red">*</span>
                            </v-card-title>
                    </div>
                    <div class="row">
                        <select-country-by-location v-bind:multi="true" v-bind:country-props="regionCoverageEdit" :readonly="!validationButton"
                            @getCountry="getCountryFromSelect" :displayActifLocation="true"></select-country-by-location>
                        <span class="warningHint" id="WarningMsgRegionCoverage">
                            {{ msg.regionCoverageEdit }}
                        </span>
                    </div>
                </div>
            </div>
            <card-actions :yes="validateFormEdit" :yesDisable="!validationButton" v-model:no="editUserDialog" width="300px"
                :yesTitle="$t('actions.save')" />
        </v-card>
    </v-dialog>
</template>
<script>
import SelectCountryByLocation from '../SelectCountry/SelectCountryByLocation.vue';
import { editUser, deleteUser, getUserById, ExistCompanyEmail,CheckPermissionByUser } from "@/api/index";
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    mounted() {
        this.checkEditUserPermission();
    },
    components: { SelectCountryByLocation, actionsMenu, dialogDelete, cardActions },
    props: ['DepartmentList', 'UID', 'userName', 'userEmail', 'departement', 'regionCoverage', 'role', 'getUsersList', "Id"],
    data() {
        return {
            Users: [],
            companyID: this.$route.params.id,
            itemsRoleList: [],
            msg: [],
            timeout: 5000,
            deleteUserDialog: false,
            editUserDialog: false,
            idEdit: this.UID,
            userNameEdit: this.userName,
            departementEdit: this.departement,
            userEmailEdit: this.userEmail,
            existMail: false,
            regionCoverageEdit: this.regionCoverage,
            validationButton:false
        };
    },
    watch: {
        editUserDialog() {
            this.userNameEdit = this.userName
            this.departementEdit = this.departement
            this.userEmailEdit = this.userEmail
            this.existMail = false
            this.regionCoverageEdit = this.regionCoverage
            this.msg = []
        },
        userEmailEdit() {
            this.existMail=null
            ExistCompanyEmail(this.userEmailEdit).then((res) => {
                this.existMail = res.data;
            })
        },
    },
    methods: {
        getUserById: function () {
            getUserById(this.UID).then((res) => {
                this.Users = res.data;
            })
        },
        getCountryFromSelect(value) {
            this.regionCoverageEdit = value.join();
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        DeleteUser() {
            this.deleteUserDialog = false;
            deleteUser(this.UID)
                .then(() => {
                    this.ActiveDeletedSnackBar();
                    this.getUsersList();
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                });
        },
        checkEditUserPermission: function () {
            CheckPermissionByUser("Optima.Company.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        EditUser() {
            editUser(this.UID, this.userNameEdit, this.userEmailEdit, this.regionCoverageEdit, this.departementEdit, this.companyID)
                .then(() => {
                    if (!this.validationButton) {
                this.ActiveWarningSnackBar();
                return;
                }
                    this.ActiveSavedSnackBar();
                    this.editUserDialog = false;
                    this.getUsersList();
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                    this.editUserDialog = false;
                });
        },
        validateFormEdit() {
            if (!(this.userNameEdit) || (this.userNameEdit).length == 0) {
                this.msg['userNameEdit'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.Fullname') });
            }
            else {
                this.userNameEdit = (this.userNameEdit.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.userNameEdit))) {
                    this.msg['userNameEdit'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else if (!(this.userNameEdit.split(/\W+/).length >= 2)) {
                    this.msg['userNameEdit'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else if (!(this.userNameEdit.split(' ')[1].length >= 1)) {
                    this.msg['userNameEdit'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else {
                    this.msg['userNameEdit'] = '';
                }
            }
            if (!this.userEmailEdit || this.userEmailEdit.length == 0) {
                this.msg["userEmailEdit"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.email') });
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.userEmailEdit)
            ) {
                this.msg["userEmailEdit"] = "";
            } else {
                this.msg["userEmailEdit"] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.email') });
            }

            if (!this.regionCoverageEdit || this.regionCoverageEdit.length == 0) {
                this.msg["regionCoverageEdit"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.regionCoverage') });
            } else {
                this.msg["regionCoverageEdit"] = "";
            }

            if (!this.departementEdit || this.departementEdit.length == 0) {
                this.msg["departementEdit"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.department') });
            } else {
                this.msg["departementEdit"] = "";
            }
            if (this.existMail) {
                this.msg["userEmailEdit"] = this.$t('warningInput.exist', { itm: this.$t('configuration.user.email') });
            } else if (
                this.msg["userNameEdit"] == "" &&
                this.msg["userEmailEdit"] == "" &&
                this.msg["regionCoverageEdit"] == "" &&
                this.msg["departementEdit"] == ""
            ) {
                this.EditUser();
            }
        },
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}
.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}
.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>