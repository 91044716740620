<template>
    <actions-menu v-model:dialogEdit="dialog2" v-model:dialogDelete="dialog" v-model:dialogStatus="dialog3"
        ShowDialogStatus="true" :status="status" :Id="Id" />
    <dialogDelete :title="$t('configuration.location.location')" :yes="deleteLocation" v-model="dialog" />
    <dialogDelete DialogType="Status" :status="status" :title="$t('configuration.location.location')"
        :yes="reverseStatutLocation" v-model="dialog3" />
    <v-dialog v-model="dialog2" v-if="dialog2">
        <v-card width="800px" class="py-6">
            <form @submit.prevent="updateLocation">
                <h1>{{ $t('actions.edit', { itm: $t('configuration.location.location') }) }}</h1>
                <div class="row ma-4 mt-2 mb-2">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.location.locationName') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field maxlength="20" id="locationName" name="locationName" hide-details :readonly="!validationButton"
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.locationName') })"
                                variant="outlined" v-model="locationName"></v-text-field>
                            <span class="warningHint" id="WarningMsgLocationName">{{ msg.locationName }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.location.currency') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-autocomplete id="currency" name="currency" style="padding-left: 12px;padding-right: 12px;"
                                variant="outlined" v-model="currency" persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('configuration.location.currency') })"
                                :items="itemsCurrency" item-title="name" item-value="name" :readonly="!validationButton"  hide-details>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.name" :readonly="!validationButton">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <span class="warningHint" id="WarningMsgCurrency">{{ msg.currency }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.location.address') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field maxlength="50" id="address" name="address" hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.address') })"
                                variant="outlined" v-model="address" :readonly="!validationButton"></v-text-field>
                            <span class="warningHint" id="WarningMsgAddress">{{ msg.address }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.location.city') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field maxlength="20" id="city" name="city" hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.city') })"
                                variant="outlined" v-model="city" :readonly="!validationButton"></v-text-field>
                            <span class="warningHint" id="WarningMsgCity">{{ msg.city }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.location.postalCode') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field maxlength="9" id="postalCode" name="postalCode" hide-details
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.postalCode') })"
                                variant="outlined" v-model="postalCode" :readonly="!validationButton"
                                @update:model-value="postalCode = postalCode.replace(/[^0-9.]/g, '');"></v-text-field>
                            <span class="warningHint" id="WarningMsgPostalCode">{{ msg.postalCode }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.location.country') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <select-country v-bind:width="450" v-bind:countryProps=country :readonly="!validationButton"
                                @getCountry="getCountryFromSelect"></select-country>
                            <span class="warningHint" id="WarningMsgCountry">{{ msg.country }}</span>
                        </div>
                    </div>
                </div>
                <card-actions :yes="updateLocation" v-model:no="dialog2" width="300px" :yesDisable="!validationButton" :yesTitle="$t('actions.save')" />
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
import { editLocations,CheckPermissionByUser, deleteLocation } from '@/api/index';
import SelectCountry from '../SelectCountry/SelectCountry.vue';
import currencyJson from "@/data/DevisesList"
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    mounted() {
        this.checkLocalPermission();
    },
    props: ['location', 'getLocationListAfterUpdate', "Id"],
    components: { SelectCountry, actionsMenu, dialogDelete, cardActions },
    data() {
        return {
            itemsCurrency: currencyJson,
            dialog: false,
            dialog2: false,
            dialog3: false,
            locationName: this.location.locationName,
            address: this.location.address,
            city: this.location.city,
            postalCode: parseInt(this.location.postalCode),
            country: this.location.country,
            currency: this.location.currency,
            status: this.location.status,
            msg: [],
            validationButton:false
        };
    },
    watch: {
        dialog2() {
            this.locationName = this.location.locationName,
                this.address = this.location.address,
                this.city = this.location.city,
                this.postalCode = parseInt(this.location.postalCode),
                this.country = this.location.country,
                this.currency = this.location.currency,
                this.status = this.location.status,
                this.msg = []
        },
    },
    methods: {
        openEdit() {
            setTimeout(() => this.dialog2 = true);
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        getCountryFromSelect(value) {
            this.country = value;
        },
        checkLocalPermission: function () {
            CheckPermissionByUser("Optima.Company.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        updateLocation() {
            if (!this.locationName || this.locationName.length == 0) {
                this.msg["locationName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.locationName') });
            } else {
                this.msg["locationName"] = "";
            }
            if (!this.address || this.address.length == 0) {
                this.msg["address"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.address') });
            } else {
                this.msg["address"] = "";
            }
            if (!this.postalCode || this.postalCode.length == 0) {
                this.msg["postalCode"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.postalCode') });
            } else {
                this.msg["postalCode"] = "";
            }
            if (!this.city || this.city.length == 0) {
                this.msg["city"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.city') });
            } else {
                this.msg["city"] = "";
            }
            if (this.currency == null) {
                this.msg["currency"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.currency') });
            } else {
                this.msg["currency"] = "";
            }
            if (this.country == null || this.country == "") {
                this.msg["country"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.country') });
            } else {
                this.msg["country"] = "";
            }

            if (
                this.msg["locationName"] == "" &&
                this.msg["address"] == "" &&
                this.msg["postalCode"] == "" &&
                this.msg["city"] == "" &&
                this.msg["currency"] == "" &&
                this.msg["country"] == ""
            ) {
                if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    return;
                }
                const location = {
                    idLocation: this.location.idLocation,
                    locationName: this.locationName,
                    address: this.address,
                    postalCode: this.postalCode,
                    currency: this.currency,
                    city: this.city,
                    country: this.country,
                    status: this.status,
                    idCompany: this.$route.params.id
                }
                editLocations(this.location.idLocation, location).then(() => {
                    this.dialog2 = false;
                    this.ActiveSavedSnackBar();
                    this.getLocationListAfterUpdate();
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                })
            }
        },
        reverseStatutLocation() {
            const location = {
                idLocation: this.location.idLocation,
                locationName: this.location.locationName,
                address: this.location.address,
                postalCode: this.location.postalCode,
                currency: this.location.currency,
                city: this.location.city,
                country: this.location.country,
                status: !this.location.status,
                idCompany: this.$route.params.id
            }
            editLocations(this.location.idLocation, location).then(() => {
                this.dialog3 = false;
                this.getLocationListAfterUpdate();
                this.ActiveSavedSnackBar();
                this.status = !this.status;
            }).catch(() => {
                this.ActiveWarningSnackBar();
            })
        },
        deleteLocation() {
            this.dialog = false;
            deleteLocation(this.location.idLocation)
                .then(() => {
                    this.getLocationListAfterUpdate();
                    this.ActiveDeletedSnackBar();
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                })
        },
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}
.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}
.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
    margin-top: -20px;
}
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>