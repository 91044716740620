<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['35%', '35%', '25%']" :GetFunction="getTaxesList">
        <template v-slot:item0="{ item }">{{ item.taxName }}</template>
        <template v-slot:item1="{ item }">{{ item.taxPourcentage }}%</template>
        <template v-slot:item2="{ item }">
            <StatusButton :title="statut(item)" />
        </template>
        <template v-slot:edit="{ item, index }">
            <EditTaxes :Id="index" :taxe="item" v-bind:getTaxesListAfterUpdate="getTaxesList" />
        </template>
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('configuration.tax.taxName')" :Filter="TaxFilters.TaxName" :getList="getTaxesList"
                @cancel="delete TaxFilters.TaxName">
                <template v-slot:input>
                    <v-text-field v-model="TaxFilters.TaxName" id="FieldTaxName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('configuration.tax.taxPercentage')" :Filter="TaxFilters.TaxPercentage"
                :getList="getTaxesList" @cancel="delete TaxFilters.TaxPercentage, delete TaxFilters.TaxPercentageCondition">
                <template v-slot:input>
                    <v-row align="center">
                        <v-col class="d-flex" cols="12" sm="20">
                            <v-select :items="TaxPercentageitems" item-title="name" item-value="value"
                                v-model="TaxFilters.TaxPercentageCondition" variant="outlined"
                                :placeholder="$t('ColumnFilter.signsOfComparisons')"></v-select>
                        </v-col>
                    </v-row>
                    <v-text-field v-model="TaxFilters.TaxPercentage" id="FieldTaxPercentage" variant="outlined"
                        maxlength="33" hide-details
                        @update:model-value="TaxFilters.TaxPercentage = TaxFilters.TaxPercentage.replace(/[^0-9.]/g, '');"></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('configuration.tax.status')" :Filter="TaxFilters.Status" :getList="getTaxesList"
                @cancel="delete TaxFilters.Status, delete TaxFilters.withStatus">
                <template v-slot:input>
                    <v-radio-group v-model="TaxFilters.Status" inline style="margin-top:6px;">
                        <v-radio :label="isActive" :value=true id="Active" name="Active"></v-radio>
                        <v-radio :label="isInactive" :value=false id="In Active" name="In Active"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
    </gridComponent>
</template>
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import { getTaxes, GetStatusTaxes } from "@/api/index";
import StatusButton from "@/genericComponent/StatusButton.vue";
import EditTaxes from "../companiesEdit/EditTaxes.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import { status } from "@/enums/Status";
export default {
    components: { gridComponent, EditTaxes, StatusButton, ColumnFilter },
    props: {
        idCompany: String,
    },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: null,
            itemsData: {},
            SortedBy: "",
            SortingType: "ASC",
            TaxFilters: {
                TaxPercentage: 0
            },
        };
    },
    created() {
        this.GetStatusTaxes()
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.TaxFilters.TaxName = event;
            this.getTaxesList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getTaxesList: function (page) {
            if(page){ this.page = page }
            getTaxes(this.page, this.PageSize, this.TaxFilters, this.SortedBy, this.SortingType)
                .then(response => {
                    this.items = response.data.items;
                    this.itemsData.numberOfPage = response.data.totalPages;
                    this.itemsData.totalItemsCount = response.data.totalCount;
                    this.itemsData.pageNumber = response.data.pageNumber;
                    this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                    this.itemsData.hasNextPage = response.data.hasNextPage
                    this.loader = false;
                })
        },
        GetStatusTaxes() {
            GetStatusTaxes().then(response => {
                localStorage.setItem(status.Active + this.$i18n.availableLocales[0], response.data.Active.en);
                localStorage.setItem(status.Active + this.$i18n.availableLocales[1], response.data.Active.fr);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[0], response.data.Inactive.en);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[1], response.data.Inactive.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => item.status ? localStorage.getItem(status.Active + this.$i18n.locale) : localStorage.getItem(status.Inactive + this.$i18n.locale)
        },
        headers() {
            return [this.$t('configuration.tax.taxName'),
            this.$t('configuration.tax.taxPercentage'),
            this.$t('configuration.tax.status')
            ]
        },
        TaxPercentageitems() {
            return [
                { 'name': this.$t('ColumnFilter.=='), 'value': '=' },
                { 'name': this.$t('ColumnFilter.>'), 'value': '>' },
                { 'name': this.$t('ColumnFilter.>='), 'value': '>=' },
                { 'name': this.$t('ColumnFilter.<'), 'value': '<' },
                { 'name': this.$t('ColumnFilter.<='), 'value': '<=' }
            ]
        },
        isActive() {
            return localStorage.getItem(status.Active + this.$i18n.locale);
        },
        isInactive() {
            return localStorage.getItem(status.Inactive + this.$i18n.locale);
        },
    }
};
</script>