<template>
    <div id="SelectCountry">
        <v-select variant="outlined" v-if="loader" disabled hide-details
            :placeholder="$t('placeholder.select', { itm: $t('configuration.company.country') })">
            <template #append-inner>
                <v-progress-circular v-if="loader" indeterminate size="40"></v-progress-circular>
            </template>
        </v-select>
        <v-select v-else v-model="country" id="country" name="country" :items="itemsCountry" variant="outlined"
            :placeholder="$t('placeholder.select', { itm: $t('configuration.company.country') })" persistent-placeholder
            item-title="name" item-value="idLocation" hide-details :multiple="multi" chips :closable-chips="multi">
            <template v-slot:chip="{ props, item }">
                <v-chip v-bind="props" variant="text" size="x-large">
                    <v-list-item v-bind="props" variant="text" :title="item.title" :subtitle="item.raw.locationName" 
                        :id="index" :name="item.title" class="pa-0">
                    </v-list-item>
                    <template v-slot:prepend>
                        <img :src="getFlag(item.raw.alpha3)" class="country-flag" />
                    </template>
                </v-chip>
            </template>
            <template v-slot:item="{ props, item, index }">
                <v-list-item v-bind="props" variant="text" :title="item.title" :subtitle="item.raw.locationName" 
                    :id="index" :name="item.title" v-if="displayActifLocation ? item.raw.status : true">
                    <template v-slot:prepend>
                        <img :src="getFlag(item.raw.alpha3)" class="country-flag mr-2" /> 
                    </template>
                </v-list-item>
            </template>
        </v-select>
    </div>
</template>
<script>
import { getAllLocation } from "@/api/index.js";
import json from "@/data/country.json";
export default {
    props: ["countryProps", "multi", "displayActifLocation"],
    data() {
        return {
            loader: true,
            itemsAllCountry: json,
            itemsCountry: [],
            country: this.multi ? this.countryProps == null ? null : this.countryProps.split(',') : this.countryProps,
            locationList: [],
            country2: "",
        }
    },
    created() {
        this.RemplirItemsCountry();
        if (this.country == "Select country" && this.multi) {
            this.country = { name: "Select country" }
            this.$emit("getCountry", "Select country");
        }
    },
    methods: {
        RemplirItemsCountry() {
            getAllLocation()
                .then(response => {
                    this.locationList = response.data.items;
                    this.locationList.map((location) => {
                        let matchingCountry = this.itemsAllCountry.find((Country) => location.country === Country.name);
                        let newLocation = {};
                        if (matchingCountry) {
                            newLocation.name = matchingCountry.name;
                            newLocation.alpha3 = matchingCountry.alpha3;
                            newLocation.idLocation = location.idLocation;
                            newLocation.locationName = location.locationName;
                            newLocation.status = location.status;
                            this.itemsCountry.push(newLocation);
                        }
                    });
                    this.loader = false;
                });
        },
        getFlag(flagName) {
            try {
                return require(`../../assets/flags/${flagName.toLowerCase()}.svg`)
            } catch (e) {
                return require('../../assets/flags/undefined.svg')
            }
        },
    },
    watch: {
        country(value) {
            this.country = value;
            this.$emit("getCountry", this.country);
        }
    }
}
</script>
<style>
.v-field__input {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    flex-wrap: nowrap;
}

.v-chip {
    border-radius: 0;
}
</style>