<template>
    <v-table theme="white" class="table-responsive" style="width: 100%">
        <thead>
            <tr>
                <th class="table_title text-left" v-for="(item, index) in headers ? headers : Object.keys(items[0])"
                    :style="item == this.SortedBy ? 'background-color: #f2f2f2; color: #114EF7;' : ''"
                    style="white-space: nowrap;" :width="ColonneWidth ? ColonneWidth[index] : 'index'" :key="item">
                    <svg class="mr-1" width="18" height="18" viewBox="0 0 18 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg" @click="sort('arrow-' + index, item);" :id="'arrow-' + index">
                        <path
                            d="M7.71932 5.78025L5.99957 4.06058V15.75C5.99957 15.9489 5.92056 16.1397 5.7799 16.2803C5.63925 16.421 5.44849 16.5 5.24957 16.5C5.05066 16.5 4.8599 16.421 4.71924 16.2803C4.57859 16.1397 4.49957 15.9489 4.49957 15.75V4.06058L2.77982 5.78025C2.63837 5.91687 2.44892 5.99247 2.25227 5.99076C2.05562 5.98905 1.86752 5.91017 1.72846 5.77111C1.5894 5.63206 1.51053 5.44395 1.50882 5.2473C1.50711 5.05065 1.5827 4.8612 1.71932 4.71975L4.71932 1.71975C4.8601 1.57936 5.0508 1.50052 5.24961 1.50052C5.44842 1.50052 5.63912 1.57936 5.7799 1.71975L8.7799 4.71975C8.91702 4.86111 8.99303 5.05073 8.99152 5.24766C8.99002 5.4446 8.91112 5.63303 8.77186 5.77228C8.6326 5.91153 8.44415 5.99042 8.24722 5.99191C8.05029 5.9934 7.86068 5.91738 7.71932 5.78025Z"
                            :fill="item == this.SortedBy ? '#114EF7' : '#667280'" />
                        <path
                            d="M8.25 13.5H15.75C15.9489 13.5 16.1397 13.579 16.2803 13.7197C16.421 13.8603 16.5 14.0511 16.5 14.25C16.5 14.4489 16.421 14.6397 16.2803 14.7803C16.1397 14.921 15.9489 15 15.75 15H8.25C8.05109 15 7.86032 14.921 7.71967 14.7803C7.57902 14.6397 7.5 14.4489 7.5 14.25C7.5 14.0511 7.57902 13.8603 7.71967 13.7197C7.86032 13.579 8.05109 13.5 8.25 13.5Z"
                            :fill="item == this.SortedBy ? '#114EF7' : '#667280'" />
                        <path
                            d="M15.75 12H8.25C8.05109 12 7.86032 11.921 7.71967 11.7803C7.57902 11.6397 7.5 11.4489 7.5 11.25C7.5 11.0511 7.57902 10.8603 7.71967 10.7197C7.86032 10.579 8.05109 10.5 8.25 10.5H15.75C15.9489 10.5 16.1397 10.579 16.2803 10.7197C16.421 10.8603 16.5 11.0511 16.5 11.25C16.5 11.4489 16.421 11.6397 16.2803 11.7803C16.1397 11.921 15.9489 12 15.75 12Z"
                            :fill="item == this.SortedBy ? '#114EF7' : '#667280'" />
                        <path
                            d="M13.5 9H8.25C8.05109 9 7.86032 8.92098 7.71967 8.78033C7.57902 8.63967 7.5 8.44891 7.5 8.24999C7.5 8.05108 7.57902 7.86031 7.71967 7.71966C7.86032 7.57901 8.05109 7.49999 8.25 7.49999H13.5C13.6989 7.49999 13.8897 7.57901 14.0303 7.71966C14.171 7.86031 14.25 8.05108 14.25 8.24999C14.25 8.44891 14.171 8.63967 14.0303 8.78033C13.8897 8.92098 13.6989 9 13.5 9Z"
                            :fill="item == this.SortedBy ? '#114EF7' : '#667280'" />
                    </svg>
                    <a>{{ item[0].toUpperCase() }}{{ item.slice(1) }}</a>
                    <slot :name="'Filter' + index" :index="index"/>
                </th>
                <th class="table_title text-right" width="2%"><a>Actions</a></th>
            </tr>
        </thead>
        <tbody v-if="loader">
            <tr>
                <td :colspan="headers.length + 1">
                    <div v-if="loader" style="height: 250px; line-height: 250px;">
                        <v-progress-circular indeterminate size="50"></v-progress-circular>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody v-else-if="itemsData.totalItemsCount == 0">
            <tr>
                <td :colspan="headers.length + 1">
                    <svg class="mt-5" width="176" height="124" viewBox="0 0 176 124" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M87.6271 111.973C117.327 111.973 141.527 87.8727 141.527 58.2727C141.427 28.5727 117.327 4.47266 87.6271 4.47266C57.8271 4.47266 33.7271 28.5727 33.7271 58.1727C33.7271 87.8727 57.8271 111.973 87.6271 111.973Z"
                            fill="#EAEEF9" />
                        <g filter="url(#filter0_d_114_37512)">
                            <path
                                d="M145.127 65.0725C145.127 76.5725 135.827 85.8725 124.227 85.8725C124.027 85.8725 122.327 85.8725 109.827 85.8725C101.127 85.8725 87.2272 85.8725 65.0272 85.8725H54.4272C40.5272 86.1725 29.4272 75.0725 29.4272 61.6725C29.4272 48.1725 40.6272 36.9725 54.7272 37.6725C66.8272 -0.127504 122.227 5.17249 126.927 44.2725C137.327 45.5725 145.127 54.3725 145.127 65.0725Z"
                                fill="url(#paint0_linear_114_37512)" />
                        </g>
                        <path
                            d="M124.228 85.8725C135.728 85.8725 145.128 76.5725 145.128 65.0725C145.128 53.5725 135.728 44.2725 124.228 44.2725C112.728 44.2725 103.328 53.5725 103.328 65.0725C103.328 76.5725 112.728 85.8725 124.228 85.8725Z"
                            fill="url(#paint1_linear_114_37512)" />
                        <path
                            d="M90.1272 86.073C110.627 86.073 127.327 69.473 127.327 48.973C127.327 28.473 110.627 11.873 90.1272 11.873C69.6272 11.873 52.9272 28.473 52.9272 48.973C52.9272 69.473 69.5272 86.073 90.1272 86.073Z"
                            fill="url(#paint2_linear_114_37512)" />
                        <path
                            d="M76.3694 58.4918C77.9441 58.4918 79.2765 57.1594 79.2765 55.5848C79.2765 54.0101 77.9441 52.6777 76.3694 52.6777C74.7948 52.6777 73.4624 54.0101 73.4624 55.5848C73.4624 57.1594 74.7948 58.4918 76.3694 58.4918Z"
                            fill="#989FB0" />
                        <path
                            d="M98.6565 58.4918C100.231 58.4918 101.564 57.1594 101.564 55.5848C101.564 54.0101 100.231 52.6777 98.6565 52.6777C97.0819 52.6777 95.7495 54.0101 95.7495 55.5848C95.7495 57.2805 97.0819 58.4918 98.6565 58.4918Z"
                            fill="#989FB0" />
                        <path d="M76.2556 45.9671L69.8608 49.4199L70.7241 51.0186L77.1188 47.5658L76.2556 45.9671Z"
                            fill="#989FB0" />
                        <path d="M98.261 45.9207L97.3979 47.5195L103.793 50.9716L104.656 49.3728L98.261 45.9207Z"
                            fill="#989FB0" />
                        <path
                            d="M87.5131 65.2748C89.3862 65.2748 90.9047 64.136 90.9047 62.7312C90.9047 61.3263 89.3862 60.1875 87.5131 60.1875C85.64 60.1875 84.1216 61.3263 84.1216 62.7312C84.1216 64.136 85.64 65.2748 87.5131 65.2748Z"
                            fill="#989FB0" />
                        <path
                            d="M17.8823 18.8281C17.8823 18.8281 3.90378 22.3772 8.1397 25.2165C12.3756 27.9138 22.4006 32.5986 31.8609 29.4754C41.3211 26.3522 31.8609 21.9513 32.0021 21.9513C32.1433 21.9513 17.8823 18.8281 17.8823 18.8281Z"
                            fill="#CED7E2" />
                        <path
                            d="M0.797363 17.6921C5.88047 19.2537 21.5534 16.6983 31.5784 10.168C39.4855 19.9635 42.4506 22.5188 45.2746 22.8028C40.8974 27.2037 26.3541 34.3019 18.1646 25.0742C8.56322 31.1787 0.797363 17.6921 0.797363 17.6921Z"
                            fill="#E9EDF9" />
                        <path
                            d="M130.867 53.1888C136.232 53.4728 150.776 46.9424 158.683 38.1406C168.849 45.6647 172.238 47.3683 175.203 46.9424C171.955 52.337 162.071 64.1201 148.093 64.9719C137.927 59.0094 130.867 53.1888 130.867 53.1888Z"
                            fill="#E9EDF9" />
                        <path
                            d="M88.0363 119.78C86.9747 114.513 77.0275 102.055 66.5336 96.5892C71.2882 84.8692 72.0939 81.163 70.9428 78.3974C76.9764 80.1988 90.85 86.8359 95.1569 100.162C91.9147 111.493 88.0363 119.78 88.0363 119.78Z"
                            fill="white" />
                        <defs>
                            <filter id="filter0_d_114_37512" x="7.42725" y="0.962891" width="159.7" height="117.916"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="11" />
                                <feGaussianBlur stdDeviation="11" />
                                <feColorMatrix type="matrix"
                                    values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_37512" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_114_37512"
                                    result="shape" />
                            </filter>
                            <linearGradient id="paint0_linear_114_37512" x1="87.2772" y1="42.5077" x2="87.2395" y2="86.6753"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" />
                                <stop offset="0.9964" stop-color="#ECF0F5" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_114_37512" x1="101.104" y1="39.7943" x2="119.631" y2="60.058"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#BCCBE1" />
                                <stop offset="0.9942" stop-color="white" stop-opacity="0" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_114_37512" x1="49.4438" y1="31.9488" x2="78.6451" y2="43.3762"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#E2E8F0" />
                                <stop offset="0.9942" stop-color="white" stop-opacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <br />
                    <span class="NoActivityText">{{ $t('noActivityToShow') }}</span>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="(item, index) in items" :key="item" @click="rowClicked(index)" class="Row">
                <td class="text-left" v-for="(head, index) in headers ? headers : Object.keys(items[0])" :key="head">
                    <div v-if="!$slots['item' + index]">{{ item[head] }}</div>
                    <slot :name="'item' + index" :item="item" />
                </td>
                <td class="text-right" width="5%">
                    <slot name="edit" :item="item" :index="index" v-bind:ref="'slotContent' + index"/>
                </td>
            </tr>
        </tbody>
        <tr>
            <td :colspan="headers.length + 1" v-if="itemsData.totalItemsCount > 0 && !loader">
                <v-divider></v-divider>
            </td>
        </tr>
        <Pagination v-if="!$slots['pagination']" :itemsData="itemsData" :page="page" :PageSize="PageSize"
            @pageEventChanged="pageParentWatcher" />
        <slot name="pagination" />
    </v-table>
</template>
<script>
import Pagination from "@/components/pagination/PaginationComponent.vue";
export default {
    name: "gridComponent",
    components: { Pagination },
    props: {
        items: {},
        itemsData: {},
        loader: {},
        headers: {},
        ColonneWidth: {},
        GetFunction: {},
    },
    data() {
        return {
            page: 1,
            PageSize: 10,
            oldId: null,
            SortedBy: null
        }
    },
    created() {
        this.GetFunction(this.page, this.PageSize)
    },
    methods: {
        rowClicked(index) {
            const actionElement = document.getElementById("Action" + index);
            if (actionElement) {
                actionElement.click();
                this.$nextTick(() => {
                    const editElement = document.getElementById("Edit");
                    const consultElement = document.getElementById("Consult");
                    editElement ? editElement.click() : consultElement?.click();
                });
            }
        },
        pageParentWatcher(data) {
            this.page = data.page;
            this.$parent.page = data.page;
            this.PageSize = data.PageSize;
            this.$parent.PageSize = data.PageSize;
            this.$parent.loader = true;
            this.GetFunction(data.page, data.PageSize)
        },
        sort(id, item) {
            if (this.oldId == null) this.oldId = id
            if (this.oldId != null || this.oldId != id) {
                document.getElementById(this.oldId).style.transform = 'rotateZ(0deg)'
            }
            if (this.oldId != id) {
                this.$parent.SortingType = 'DESC'
                document.getElementById(id).style.transform = 'rotateZ(180deg)'
            } else if (this.$parent.SortingType == 'DESC' && this.oldId == id) {
                this.$parent.SortingType = 'ASC'
                document.getElementById(id).style.transform = 'rotateZ(0deg)'
            } else {
                this.$parent.SortingType = 'DESC'
                document.getElementById(id).style.transform = 'rotateZ(180deg)'
            }
            this.oldId = id
            this.SortedBy = item
            if (this.$i18n.locale != this.$i18n.availableLocales[0]) {
                const index = this.$parent.headers.indexOf(item);
                const originalLocale = this.$i18n.locale;
                this.$i18n.locale = this.$i18n.availableLocales[0];
                const translatedText = this.$parent.headers[index]
                this.$i18n.locale = originalLocale;
                this.$parent.SortedBy = translatedText;
            } else {
                this.$parent.SortedBy = item;
            }
            this.GetFunction()
        }
    }
};
</script>
<style>
.v-table--density-default>.v-table__wrapper>table>tbody>tr>th,
.v-table--density-default>.v-table__wrapper>table>thead>tr>th,
.v-table--density-default>.v-table__wrapper>table>tfoot>tr>th {
    height: 36px;
    background-color: #F4F6F8;
    color: #667280;
    padding: 0px 12px;
}

.v-table--density-default>.v-table__wrapper>table>tbody>tr>td,
.v-table--density-default>.v-table__wrapper>table>thead>tr>td,
.v-table--density-default>.v-table__wrapper>table>tfoot>tr>td {
    height: 62px;
    padding: 12px;
}

.v-table>.v-table__wrapper>table>tbody>tr>td,
.v-table>.v-table__wrapper>table>thead>tr>td,
.v-table>.v-table__wrapper>table>tfoot>tr>td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #001E50;
}

.v-table--density-default>.v-table__wrapper>table>thead>tr>th>svg {
    margin-bottom: 4px;
}

.v-table--density-default>.v-table__wrapper>table>thead>tr>th>a {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
}

.Row:hover {
    background-color: #f2f2f2aa;
    cursor: pointer
}

thead>tr>th>.v-btn {
    opacity: 0;
}

thead>tr>th>.v-btn:hover {
    opacity: 1;
}
</style>