<template>
    <v-dialog v-model="dialog2" v-if="dialog2">
        <v-card width="1000px" class="py-2" style="border-radius: 12px;">
            <h1>{{ $t('suppliers.bankAccountsTab.add') }}</h1>
            <div class="row ma-4">
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.iban') }}
                            <v-tooltip text="Tooltip" location="end">
                                <template v-slot:activator="{ props }">
                                    <v-icon class="ml-2" id="tooltip-target-1" v-bind="props">mdi-information</v-icon>
                                </template>
                                <b-tooltip class="tooltip-inner" target="tooltip-target-1" triggers="hover">
                                    {{ $t('suppliers.bankAccountsTab.Toolipiban') }}<b>FR763000100794123456789XXXX</b>
                                </b-tooltip>
                            </v-tooltip>
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="iban" id="iban" name="iban" maxlength="40" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.iban')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.iban }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.bic') }}
                            <v-tooltip text="Tooltip" location="end">
                                <template v-slot:activator="{ props }">
                                    <v-icon class="ml-2" id="tooltip-target-1" v-bind="props">mdi-information</v-icon>
                                </template>
                                <b-tooltip class="tooltip-inner" target="tooltip-target-1" triggers="hover">
                                    {{ $t('suppliers.bankAccountsTab.Toolipbic') }}<b>CEPAXXXX</b>
                                </b-tooltip>
                            </v-tooltip>
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="bic" id="bic" name="bic" maxlength="11" hide-details persistent-placeholder
                            :placeholder="$t('suppliers.bankAccountsTab.placeholder.bic')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.bic }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.bankName') }}
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="bankName" id="bankName" name="bankName" maxlength="30" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.bankName')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.bankName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.accountHolderName') }}
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="accountHolderName" id="accountHolderName" name="accountHolderName"
                            maxlength="30" hide-details persistent-placeholder
                            :placeholder="$t('suppliers.bankAccountsTab.placeholder.accountHolderName')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.accountHolderName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.agencyName') }}</v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="agencyName" id="agencyName" name="agencyName" maxlength="30" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.agencyName')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.agencyName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.bankAdresse')
                        }}</v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="bankAdresse" id="bankAdresse" name="bankAdresse" maxlength="100" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.bankAdresse')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.bankAdresse }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.country') }}
                        </v-card-title>
                    </div>
                    <div class="row">
                        <select-country :placeholder="$t('suppliers.generalInformation.placeholder.country')"
                            v-bind:countryProps="country" @getCountry="getCountryFromSelect"></select-country>
                        <span class='warningHint' id="WarningMsgCountry"> {{ msg.country }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.identityDocument')
                        }} <span class="red">*</span></v-card-title>
                    </div>
                    <div class="row ml-0">
                        <uploadDocument @addDocument="setidentityDocument" :showUpload="true"
                            :fileTypes="['application/pdf']" sub-title="suppliers.identityDocument.FileMSG"
                            sub-title2="suppliers.identityDocument.FileMSG2">
                            <template v-slot:DocumentsList>
                                <span v-if="identityDocumentPDF" style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start"
                                    @click="downloadDocument(identityDocumentPDF[0])"
                                    :title="$t('actions.download') + ' ' + identityDocumentPDF[0].name">
                                    {{ identityDocumentPDF[0].name }}
                                </span>
                            </template>
                        </uploadDocument>
                        <span class='warningHint' id="WarningMsgDocument">{{ msg.identityDocument }}</span>
                    </div>
                </div>
            </div>
            <card-actions :yes="AddSupplierBankAccount" v-model:no="dialog2" width="300px"
                :yesTitle="$t('suppliers.bankAccountsTab.add')" />
        </v-card>
    </v-dialog>
</template>
<script>
import { addSupplierBankAccount, isSupplier, idSupplier } from '@/api/index';
import cardActions from "@/genericComponent/cardActions.vue"
import uploadDocument from "@/genericComponent/uploadDocument.vue"
import SelectCountry from '../../SelectCountry/SelectCountry.vue';
import IBAN from 'iban';
export default {
    props: {
        reloadListBankAccount: {}
    },
    components: {
        cardActions, uploadDocument, SelectCountry
    },
    data() {
        return {
            loader: null,
            dialog2: true,
            bankName: null,
            iban: null,
            bic: null,
            agencyName: null,
            bankAdresse: null,
            accountHolderName: null,
            identityDocumentPDF: null,
            idSupplier: null,
            country: null,
            msg: [],
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
    },
    watch: {
        dialog2(value) {
            this.dialog2 = value
            this.$emit('add-bank-account-close-dialog')
            this.dialog2 = true
            this.iban = null;
            this.bic = null;
            this.bankName = null;
            this.agencyName = null;
            this.bankAdresse = null;
            this.accountHolderName = null;
            this.identityDocumentPDF = null,
            this.msg = [],
            this.country = null
        },
    },
    computed: {
        isValidIBAN() {
            return IBAN.isValid(this.iban);
        },
        isValidBIC() {
            const bicRegex = /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/;
            return bicRegex.test(this.bic);
        }
    },
    methods: {
        downloadDocument(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = file.name;
                downloadLink.click();
            }
        },
        getCountryFromSelect(value) {
            this.country = value;
        },
        setidentityDocument(value) {
            this.identityDocumentPDF = value
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        AddSupplierBankAccount() {
            if (this.identityDocumentPDF == null || this.identityDocumentPDF == "") {
                this.msg["identityDocument"] = this.$t('suppliers.documentsTab.errors.identityDocument');
            } else {
                this.msg["identityDocument"] = "";
            }
            if (
                this.msg["identityDocument"] == ""
            ) {
                this.$emit('add-bank-account-close-dialog');
                addSupplierBankAccount(this.iban !== null ? this.iban : '', this.bic !== null ? this.bic : '', this.bankName !== null ? this.bankName : '', this.agencyName !== null ? this.agencyName : '', this.bankAdresse !== null ? this.bankAdresse : '' , this.accountHolderName !== null ? this.accountHolderName : '' , this.identityDocumentPDF ? true : false, this.identityDocumentPDF, this.country !== null ? this.country : '', this.idSupplier)
                    .then(() => {
                        this.ActiveSavedSnackBar();
                        this.reloadListBankAccount();
                    }).catch(() => {
                        this.ActiveWarningSnackBar();
                    });
                setTimeout(() => {
                    this.iban = null;
                    this.bic = null;
                    this.bankName = null;
                    this.agencyName = null;
                    this.bankAdresse = null;
                    this.accountHolderName = null;
                    this.identityDocumentPDF = null;
                    this.country = null;
                }, 2000);
            }
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-dialog .v-card {
    border-radius: 12px;
    background-color: #fff;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: black;  
    text-align: center;
    border-radius: 0.25rem;
}
</style> 