<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  created() {
    this.$store.dispatch('tryLogin');
    console.log("+------------------+------------------+")
    console.log("| %c Version        ", 'color:#fff;background:#114EF7;', "|  "+window.VUE_APP_VERSION+"         |")
    console.log("+------------------+------------------+")
    console.log("| %c Date Livraison ", 'color:#fff;background:#114EF7;', "|  "+window.VUE_APP_DELIVERY_DATE+"   |")
    console.log("+------------------+------------------+")
    console.log('- %c Release notes %o', 'color:#fff;background:#114EF7;', ""+window.VUE_APP_RELEASE_NOTES+"");
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>