<template>
    <div class="upload-cadre">
        <div class="upload-content row" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <div class="div-Dowload">
                <v-img v-if="hasFile || uploadedFile" src="@/assets/download-icon.png" class="upload-image" />
                <v-img v-else src="@/assets/upload-icon.png" class="upload-image" />
                <a v-if="showDownload" class="browse-file" @click="fnDownload">{{ $t("actions.download") }}</a>
            </div>
            <div v-if="showUpload"
                style="width: max-content; padding: 0px; margin-bottom: 10px; margin-top:10px;">
                <div class="upload-title">{{ $t('upload.drag') }}
                    <a class="browse-file" @click="chooseFiles()">{{ $t('upload.browse') }}</a>
                </div>
                <div class="upload-sub-title">{{ $t(subTitle) }}</div>
                <div v-if="subTitle2" class="upload-sub-title">{{ $t(subTitle2) }}</div>
            </div>
        </div>
    </div>
    <input type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute d-none" @change="onChange" ref="files" :accept="fileTypes" />
    <v-file-input v-model="DocumentUrl" :accept="fileTypes" :id="inputid"
        style="visibility: hidden; display: none;" multiple></v-file-input>
    <slot name="DocumentsList"></slot>
    <div v-if="afficheSucces" class="updateSuccess"
        :style="afficheTrue ? 'background-color: #E8FFF1;' : 'background-color: #F25C5C;'">
        <v-card-text class="text-center">
            <p v-if="this.afficheTrue">{{ $t('document.uploadSucces') }}</p>
            <p v-else-if="!(this.DocumentUrl[0].size / 1024 / 1024 < 5)">{{ $t('configuration.document.warningSize') }}</p>
        </v-card-text>
    </div>
    <v-snackbar v-model="afficheSucces" :timeout="2000" color="transparent" style="opacity: 0;">
    </v-snackbar>
</template>
<script>
export default {
    props: {
        namedocument: {},
        hasFile: {
            type: Boolean,
            default: false
        },
        fnDownload: {
            default: null
        },
        showDownload: {
            type: Boolean,
            default: false
        },
        showUpload: {
            type: Boolean,
            default: false
        },
        fileTypes: {
            default: [
                "image/jpeg",
                "image/png",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ]
        },
        subTitle: {
            default: 'configuration.document.FileMSG'
        },
        subTitle2: {
            default: null
        },
        inputid: {
            default: 'MultiUpload'
        },
    },
    data() {
        return {
            snackbar: true,
            DocumentUrl: [],
            afficheSucces: false,
            afficheTrue: true,
            isLoaded: false,
            uploadedFile: false,
            isHovered: null,
            selectedIndex: null,
            showDeleteDialog: null,
        };
    },
    watch: {
        DocumentUrl() {
            if (this.DocumentUrl.length > 0) {
                this.afficheSucces = true
                if (this.DocumentUrl.every(file => this.fileTypes.includes(file.type) && file.size / 1024 / 1024 < 5)) {
                    this.emitMultiDocument();
                    this.uploadedFile = true
                    this.afficheTrue = true
                } else {
                    this.afficheTrue = false
                }
            }
        }
    },
    methods: {
        emitMultiDocument() {
            this.$emit('addMultiDocument', this.DocumentUrl);
        },
        chooseFiles: function () {
            document.getElementById(this.inputid).click()
        },
        dragover(event) {
            event.preventDefault();
        },
        dragleave(event) {
            event.preventDefault();
        },
        drop(event) {
            event.preventDefault();
            this.$refs.files.files = event.dataTransfer.files;
            this.onChange();
        },
        onChange() {
            const newFiles = [...this.$refs.files.files];
            this.DocumentUrl = newFiles;
        },
    }
};
</script>
<style scoped>
.updateSuccess {
    height: 40px;
    border-radius: 6px;
    font-family: 'Inter';
}

.updateSuccess .v-card-text {
    color: #24c581;
    font-weight: 600;
    font-size: 12px;
    line-height: 50%;
}

.upload-cadre {
    width: 100%;
    height: max-content;
    border-radius: 4px;
    border: 1px dashed rgba(56, 78, 183, 0.30);
    background: #F8F8FF;
    padding-left: 15px;
    padding-right: 15px;
}

.upload-cadre:hover {
    background: #ddd;
}

.upload-content {
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    max-height: 200px;
    overflow-y: auto;
}

.upload-image {
    width: 70px;
    height: 60px;
}

.upload-title {
    color: #0F0F0F;
    text-align: start;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
}

.browse-file {
    color: #3BBAFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration-line: underline;
    cursor: pointer;
    padding-left: 0px;
}

.upload-sub-title {
    color: var(--grays-gray-500, #939EAA);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: start;
    margin-right: 5px;
}

.div-Dowload{
    width: max-content; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
}
</style>