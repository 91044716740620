<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title class="text-left d-flex">{{ $t('purchaseRequest.add') }}</v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :ClickFun="validateFormPostPurchaseRequest" svg="1" :Title="$t('purchaseRequest.save')"
                    :loader="loader" />
            </div>
        </div>
    </div>
    <v-card elevation="0">
        <div class="row ma-4">
            <div class="col-6 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseRequest.title') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Title" id="Title" name="Title" maxlength="30" hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseRequest.title') })"
                        variant="outlined"></v-text-field>
                    <span class="warningHint" id="WarningMsgTitle">{{ msg.Title }}</span>
                </div>
            </div>
            <div class="col-6 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseRequest.requester') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="Requester" name="Requester" v-model="Requester" v-model:search="RequesterSearch"
                        hide-details :placeholder="$t('placeholder.select', { itm: $t('purchaseRequest.requester') })"
                        variant="outlined" persistent-placeholder :items="RequesterList" item-title="fullName"
                        item-value="id" single-line>
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" variant="text" :id="index" :name="item.fullName">
                                {{ item.fullName }}
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgRequester">{{ msg.Requester }}</span>
                </div>
            </div>
            <div class="col-6 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseRequest.deadline') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <dateField v-model="Deadline" />
                    <span class="warningHint" id="WarningMsgDeadline">{{ msg.Deadline }}</span>
                </div>
            </div>
            <div class="col-6 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseRequest.status') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-radio-group v-model="Status" inline style="margin-top:6px ;margin-bottom:6px" hide-details>
                        <v-radio :label="isCompleted" value="0" id="Completed" name="Completed"></v-radio>
                        <v-radio :label="isPending" value="1" id="Pending" name="Pending"></v-radio>
                        <v-radio :label="isError" value="2" id="Error" name="Error"></v-radio>
                    </v-radio-group>
                    <span class="warningHint" id="WarningMsgStatus">{{ msg.Status }}</span>
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
import { PostPurchaseRequest, GetAllRequester } from "@/api/index";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: {
        genericBtnComponent, dateField
    },
    data: () => ({
        Title: "",
        Deadline: "",
        Requester: null,
        RequesterSearch: null,
        RequesterList: [],
        Status: null,
        msg: [],
        loader: false,
    }),
    watch: {
        RequesterSearch(value) {
            this.RequesterSearch = value;
            this.GetAllRequester();
        }
    },
    created() {
        this.GetAllRequester();
    },
    methods: {
        GetAllRequester() {
            GetAllRequester(this.RequesterSearch).then((res) => {
                this.RequesterList = res.data;
            }
            )
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        navigateToList() {
            this.$router.push({ path: "/PurchaseRequest" });
        },
        validateForm() {
            PostPurchaseRequest(
                this.Title,
                this.Deadline,
                this.Requester,
                parseInt(this.Status)
            ).then(() => {
                this.ActiveSavedSnackBar();
            }).catch(() => {
                this.ActiveWarningSnackBar();
            });
            this.navigateToList();
            this.loader = false
        },
        validateFormPostPurchaseRequest() {
            this.loader = true
            if (!this.Title || this.Title.length == 0) {
                this.msg["Title"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseRequest.title') });
            } else {
                this.msg["Title"] = "";
            }
            if (!this.Deadline || this.Deadline.length == 0) {
                this.msg["Deadline"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseRequest.deadline') });
            } else if (this.Deadline < "1000-01-01") {
                this.msg["Deadline"] = this.$t('warningInput.wrong', { itm: this.$t('purchaseRequest.deadline') });
            } else {
                this.msg["Deadline"] = "";
            }
            if (!this.Requester || this.Requester.length == 0) {
                this.msg["Requester"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseRequest.requester') });
            } else {
                this.msg["Requester"] = "";
            }

            if (this.Status == null) {
                this.msg["Status"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.status') });
            } else {
                this.msg["Status"] = "";
            }

            if (this.msg["Requester"] == "" && this.msg["Title"] == "" && this.msg["Deadline"] == ""
                && this.msg["Status"] == "") {
                this.validateForm();
            }
            else {
                this.loader = false
            }
        },
    },
    computed: {
        isCompleted() {
            return localStorage.getItem(status.Completed + this.$i18n.locale);
        },
        isPending() {
            return localStorage.getItem(status.Pending + this.$i18n.locale);
        },
        isError() {
            return localStorage.getItem(status.Error + this.$i18n.locale);
        }
    },
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>