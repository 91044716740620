<template>
  <v-dialog v-model="dialog2">
    <v-card width="800px" class="py-6">
      <h1>{{ $t('configuration.department.add') }}</h1>
      <div class="row ma-4">
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.department.departmentName') }} <span class="red">*</span>
              </v-card-title>
          </div>
          <div class="row">
            <v-text-field v-model="DepartmentName" maxlength="30" id="DepartmentName" name="DepartmentName" hide-details
              persistent-placeholder
              :placeholder="$t('placeholder.enter', { itm: $t('configuration.department.departmentName') })"
              variant="outlined"></v-text-field>
            <span class='warningHint' id="WarningMsgDepartmentName"> {{ msg.DepartmentName }}</span>
          </div>
        </div>
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.department.locationCoverage') }} <span class="red">*</span>
              </v-card-title>
          </div>
          <div class="row">
            <select-country-by-location v-bind:multi="false" v-bind:countryProps="LocationCoverage"
              @getCountry="getCountryFromSelect" :displayActifLocation="true"></select-country-by-location>
            <span class='warningHint' id="WarningMsgLocationCoverage"> {{ msg.LocationCoverage }}</span>
          </div>
        </div>
      </div>
      <card-actions :yes="AddDepartement" v-model:no="dialog2" width="300px"
        :yesTitle="$t('configuration.department.add')" />
    </v-card>
  </v-dialog>
</template>
<script>
import { AddDepartement } from '@/api/index';
import SelectCountryByLocation from '../SelectCountry/SelectCountryByLocation.vue';
import cardActions from "@/genericComponent/cardActions.vue"
export default {
  components: { SelectCountryByLocation, cardActions },
  data() {
    return {
      dialog2: true,
      DepartmentName: null,
      LocationCoverage: null,
      idCompany: this.$route.params.id,
      msg: [],
    };
  },
  watch: {
    dialog2() {
      this.$emit('add-departement-close-dialog')
      this.dialog2 = true
      this.DepartmentName = null
      this.LocationCoverage = null
      this.msg = []
    }
  },
  methods: {
    ActiveDeletedSnackBar() {
      this.getParent('MainLayout').ActiveDeletedSnackBar();
    },
    ActiveWarningSnackBar() {
      this.getParent('MainLayout').ActiveWarningSnackBar();
    },
    ActiveSavedSnackBar() {
      this.getParent('MainLayout').ActiveSavedSnackBar();
    },
    getParent(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name == name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return false;
    },
    getCountryFromSelect(value) {
      this.LocationCoverage = value;
    },
    AddDepartement() {
      if (!this.DepartmentName || this.DepartmentName.length == 0) {
        this.msg["DepartmentName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.department.departmentName') });
      } else {
        this.msg["DepartmentName"] = "";
      }

      if (this.LocationCoverage == null || this.LocationCoverage == "") {
        this.msg["LocationCoverage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.department.locationCoverage') });
      } else {
        this.msg["LocationCoverage"] = "";
      }

      if (
        this.msg["DepartmentName"] == "" &&
        this.msg["LocationCoverage"] == ""
      ) {
        this.$emit('add-departement-close-dialog');
        AddDepartement(this.DepartmentName, this.LocationCoverage, this.idCompany)
          .then(() => {
            this.ActiveSavedSnackBar();
            this.$emit("reloadListDepartements");
          }).catch(() => {

            this.ActiveWarningSnackBar();
          });
        setTimeout(() => {
          this.DepartmentName = null;
          this.LocationCoverage = null;
        }, 2000);
      }
    }
  }
};
</script>
<style scoped>
h1 {
  font-family: inter;
  font-size: 18px;
  line-height: 29px;
  font-weight: 600;
  color: #0d0849;
  text-align: center;
}

.v-card-title {
  font-family: inter;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #0d0849;
  margin-left: 40px;
  margin-top: -30px;
}

.v-card-text {
  margin-left: 40px;
  font-family: inter;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #768da9;
}

.v-card-title {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #5b6d8e;
  margin-left: 12px;
  margin-bottom: 5px;
}
</style>