<template>
    <div class="row mb-8">
        <div class="col d-flex justify-content-start">
            <span class="title">{{ $t('purchaseOrder.add') }}</span>
        </div>
        <div class="col d-flex justify-content-end">
            <genericBtnComponent :disabled="!isClient()" :ClickFun="validateFormPurchaseOrder" svg="1"
                :Title="$t('purchaseOrder.save')" :loader="loader" />
        </div>
    </div>
    <v-card elevation="0">
        <div class="row ma-4">
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.reference') }}<span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Reference" id="Reference" name="Reference" maxlength="30" hide-details
                        persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.reference') })" variant="outlined"
                        class="rounded-xl"></v-text-field>
                    <span class="warningHint" id="WarningMsgReference">{{ msg.Reference }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.title') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Title" id="Title" name="Title" maxlength="30" hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.title') })" variant="outlined"
                        class="rounded-xl"></v-text-field>
                    <span class="warningHint" id="WarningMsgTitle">{{ msg.Title }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.supplier') }}<span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="Supplier" name="Supplier" class="pl-0 pr-6" variant="outlined" v-model="IdSupplier"
                        v-model:search="SupplierSearch" persistent-placeholder
                        :placeholder="$t('placeholder.select', { itm: $t('purchaseOrder.supplier') })" :items="SupplierList"
                        item-title="brandName" item-value="id" hide-details>
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" variant="text" :id="index" :name="item.brandName">
                                {{ item.brandName }}
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgCompany">{{ msg.Supplier }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.expirationDate') }}<span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <dateField v-model="ExpirationDate" :minDate="new Date()" id="ExpirationDate" name="ExpirationDate" />
                    <span class="warningHint" id="WarningMsgExpirationDate">{{ msg.ExpirationDate }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.devise') }}<span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="Devise" name="Devise" class="pl-0 pr-6" variant="outlined" v-model="Devise"
                        persistent-placeholder :placeholder="$t('placeholder.select', { itm: $t('purchaseOrder.devise') })"
                        :items="DeviseList" item-title="name" item-value="symbol" hide-details>
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                                {{ item.name }}
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgDevise">{{ msg.Devise }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.amountHT') }} <span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Threshold" id="AmountHT" name="AmountHT" maxlength="9" type="number" hide-details
                        persistent-placeholder :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.amountHT') })"
                        variant="outlined" :suffix="Devise"></v-text-field>
                </div>
                <span class="warningHint" id="WarningMsgAmountHT">{{ msg.AmountHT }}</span>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.tax') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-select id="Tax" name="Tax" class="" variant="outlined" v-model="Tax" persistent-placeholder
                        :placeholder="$t('placeholder.select', { itm: $t('purchaseOrder.tax') })" :items="TaxList"
                        item-title="taxPourcentage" item-value="taxPourcentage" :suffix="Tax ? '%' : ''" hide-details>
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" :id="index" :name="item.name" :title="item.value + '%'"
                                :subtitle="item.raw.taxName">
                            </v-list-item>
                        </template>
                    </v-select>
                    <span class="warningHint" id="WarningMsgTax">{{ msg.Tax }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.quantity') }}<span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Quantity" id="Quantity" name="Quantity" maxlength="9" hide-details type="number"
                        persistent-placeholder :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.quantity') })"
                        variant="outlined" class="rounded-xl" :min="1"></v-text-field>
                    <span class="warningHint" id="WarningMsgTitle">{{ msg.Quantity }}</span>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.montantHT') }}</v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="MTHTVA" id="MTHTVA" name="MTHTVA" maxlength="9" type="text" hide-details
                        persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.montantHT') })" readonly
                        variant="outlined" :suffix="Devise"></v-text-field>
                </div>
            </div>
            <div class="col-3 justify-content-start" style="margin-bottom: 15px;">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.montantTotal') }}</v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="MTTotal" id="MTTotal" name="MTTotal" maxlength="9" type="text" hide-details
                        persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.montantTotal') })" variant="outlined"
                        readonly :suffix="Tax ? Devise : ''"></v-text-field>
                </div>
            </div>
            <div class="col-3 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.paymentMethod') }}<span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-select v-model="PaymentMethod" :items="selectedPaymentMethod" name="paymentMethods" maxlength="30"
                        hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.paymentMethod') })"
                        variant="outlined" class="rounded-xl"></v-select>
                    <span class="warningHint" id="WarningMsgTitle">{{ msg.PaymentMethod }}</span>
                </div>
            </div>
            <div class="col-12 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.description') }} <span
                            class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-textarea v-model="Description" id="description" name="description" hide-details
                        persistent-placeholder single-line auto-grow
                        :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.description') })" variant="outlined"
                        class="rounded-xl"></v-textarea>
                    <span class="warningHint" id="WarningMsgTitle">{{ msg.Description }}</span>
                </div>
            </div>
            <div class="col-5 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.purchaseOrder') }}</v-card-title>
                </div>
                <div class="row ml-0">
                    <uploadDocument v-on:addDocument="setidentityDocument" :showUpload="true">
                        <template v-slot:DocumentsList>
                            <div @mouseover="setFileHover(true)" @mouseout="setFileHover(null)"
                                style="display: flex; position: relative;">
                                <span v-if="identityDocument"
                                    style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start"
                                    @click="downloadDocumentPurhaseOrder(identityDocument[0])"
                                    :title="$t('actions.download') + ' ' + identityDocument[0].name">
                                    {{ identityDocument[0].name }}
                                </span>
                                <v-icon v-show="isFileHovered" @click="showDeleteConfirmationFileDialog()"
                                    class="ml-2" style="color: red">mdi-close-circle-outline</v-icon>
                            </div>
                        </template>
                    </uploadDocument>
                </div>
            </div>
            <div class="col-2"></div>
            <div class="col-5 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('purchaseOrder.AutresDocument') }}</v-card-title>
                </div>
                <div class="row ml-0">
                    <uploadMultiDocument v-on:addMultiDocument="setidentityDocuments" :showUpload="true">
                        <template v-slot:DocumentsList>
                            <div style="max-height: 60px; overflow-y:scroll; overflow-x: hidden;">
                                <div v-for="(fileName, index) in MultiFileList" :key="index">
                                    <div @mouseover="setHover(index)" @mouseout="setHover(null)"
                                        style="display: flex; position: relative;">
                                        <span style="color: blue; cursor: pointer; text-align: start; max-width: 95%;"
                                            @click="downloadMultiDocumentPurchaseOrder(fileName, index)"
                                            :title="$t('actions.download') + ' ' + fileName">{{ fileName }}</span>
                                        <v-icon v-show="isHovered == index"
                                            @click="showDeleteConfirmationDialog(fileName, index)" class="ml-2"
                                            style="color: red">mdi-close-circle-outline</v-icon>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </uploadMultiDocument>
                </div>
            </div>
        </div>
    </v-card>
    <dialogDelete v-if="selectedIndex !== null" v-model="showDeleteDialog" :yes="confirmDelete" :status="false"
        :title="selectedFileName" v-model:no="showDeleteDialog"></dialogDelete>
    <dialogDelete v-model="showDeleteFileDialog" :yes="confirmFileDelete" :status="false" 
        :title="identityDocument ? identityDocument[0].name : ''" v-model:no="showDeleteFileDialog"></dialogDelete>
</template>
<script>
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import uploadMultiDocument from "@/genericComponent/uploadMultiDocument.vue"
import uploadDocument from "@/genericComponent/uploadDocument.vue"
import { CreatePurchaseOrder, GetCompanyFromPurchaseOrders, GetTaxListByCmpanyId, PurchaseOrderIsExistReference, isClient, idClient } from "@/api/index";
import deviseJson from "@/data/DevisesList.json"
import dateField from "@/genericComponent/date-field.vue";

export default {
    components: {
        genericBtnComponent, dateField, uploadMultiDocument, uploadDocument, dialogDelete
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.purchasesOrder');
    },
    data: () => ({
        PaymentMethod: null,
        Quantity: 1,
        Description: null,
        Reference: null,
        Title: null,
        Threshold: null,
        Devise: null,
        IdSupplier: null,
        SupplierSearch: null,
        SupplierList: [],
        msg: [],
        DeviseList: deviseJson,
        loader: false,
        identityDocument: null,
        identityDocuments: [],
        ExpirationDate: null,
        Tax: null,
        TaxList: [],
        isHovered: null,
        selectedIndex: null,
        selectedFileName: null,
        showDeleteDialog: null,
        showDeleteFileDialog: null,
        isFileHovered: null,
    }),
    created() {
        this.GetSupplierList();
        this.GetTaxListByCmpanyId(idClient());
    },
    watch: {
        SupplierSearch(value) {
            if (this.SupplierSearch != null && this.SupplierSearch.length == 1) this.SupplierSearch = value.trim();
            this.GetSupplierList();
        },
    },
    methods: {
        confirmDelete() {
            if (this.selectedIndex !== null) {
                this.identityDocuments.splice(this.selectedIndex, 1);
                this.selectedIndex = null;
                this.selectedFileName = null;
                this.showDeleteDialog = false;
            }
        },
        confirmFileDelete(){
            this.identityDocument = null;
            this.showDeleteFileDialog = false;
        },
        showDeleteConfirmationFileDialog(){
            this.showDeleteFileDialog = true;
        },
        showDeleteConfirmationDialog(filename, index) {
            this.showDeleteDialog = true;
            this.selectedFileName = filename;
            this.selectedIndex = index;
        },
        downloadMultiDocumentPurchaseOrder(fileName, index) {
            var reader = new FileReader();
            reader.readAsDataURL(this.identityDocuments[index]);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        },
        downloadDocumentPurhaseOrder(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = file.name;
                downloadLink.click();
            }
        },
        setHover(index) {
            this.isHovered = index;
        },
        setidentityDocuments(value) {
            this.identityDocuments.push(...value);
        },
        setidentityDocument(value) {
            this.identityDocument = value
        },
        setFileHover(isHover){
            this.isFileHovered = isHover
        },
        isClient() {
            return isClient()
        },
        idClient() {
            return idClient()
        },
        GetTaxListByCmpanyId(companyId) {
            GetTaxListByCmpanyId(companyId).then((res) => {
                this.TaxList = res.data;
            })
        },
        GetSupplierList() {
            GetCompanyFromPurchaseOrders(this.SupplierSearch)
                .then((res) => {
                    this.SupplierList = res.data;
                })
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        navigateToList() {
            this.$router.push({ path: "/PurchaseOrder" });
        },
        validateForm() {
            CreatePurchaseOrder(
                this.Reference,
                this.Description,
                this.Title,
                this.Threshold,
                this.Devise,
                this.Tax,
                this.Quantity,
                this.PaymentMethod,
                this.ExpirationDate,
                this.IdSupplier,
                this.identityDocument,
                this.identityDocuments
            ).then(() => {
                this.ActiveSavedSnackBar();
            }).catch(() => {
                this.ActiveWarningSnackBar();
            }).finally(() => {
                this.loader = false
                this.navigateToList();
            });
        },
        async IsExistReference() {
            const r = await PurchaseOrderIsExistReference(null, this.Reference);
            return r.data;
        },
        async validateFormPurchaseOrder() {
            this.loader = true

            if (!this.Reference || this.Reference.length == 0) {
                this.msg["Reference"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.reference') });
            } else if (await this.IsExistReference() == true) {
                this.msg["Reference"] = this.$t('warningInput.exist', { itm: this.$t('purchaseOrder.reference') });
            } else {
                this.msg["Reference"] = "";
            }

            if (!this.Title || this.Title.length == 0) {
                this.msg["Title"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.title') });
            } else {
                this.msg["Title"] = "";
            }

            if (!this.IdSupplier || this.IdSupplier.length == 0) {
                this.msg["Supplier"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.supplier') });
            } else {
                this.msg["Supplier"] = "";
            }

            if (!this.ExpirationDate || this.ExpirationDate.length == 0) {
                this.msg["ExpirationDate"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.expirationDate') });
            } else {
                this.msg["ExpirationDate"] = "";
            }

            if (!this.Devise || this.Devise.length == 0) {
                this.msg["Devise"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.devise') });
            } else {
                this.msg["Devise"] = "";
            }

            if (!this.Threshold || this.Threshold.length == 0) {
                this.msg["AmountHT"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.amountHT') });
            } else if (this.Threshold <= 0) {
                this.msg["AmountHT"] = this.$t('warningInput.notValid', { itm: this.$t('purchaseOrder.amountHT') });
            } else {
                this.msg["AmountHT"] = "";
            }

            if (this.Tax == null || this.Tax.length == 0) {
                this.msg["Tax"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.tax') });
            } else {
                this.msg["Tax"] = "";
            }

            if (this.PaymentMethod == null || this.PaymentMethod == "") {
                this.msg["PaymentMethod"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.paymentMethod') });
            } else {
                this.msg["PaymentMethod"] = "";
            }

            if (this.Description == null || this.Description.length == 0) {
                this.msg["Description"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.description') });
            } else {
                this.msg["Description"] = "";
            }

            if (this.Quantity == null || this.Quantity == "") {
                this.msg["Quantity"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.quantity') });
            } else if (this.Quantity <= 0) {
                this.msg["Quantity"] = this.$t('warningInput.notValid', { itm: this.$t('purchaseOrder.quantity') });
            } else {
                this.msg["Quantity"] = "";
            }

            if (this.msg["Reference"] == "" && this.msg["Title"] == ""
                && this.msg["Supplier"] == "" && this.msg["ExpirationDate"] == ""
                && this.msg["Devise"] == "" && this.msg["AmountHT"] == ""
                && this.msg["Tax"] == "" && this.msg["PaymentMethod"] == ""
                && this.msg["Description"] == ""
                && this.msg["Quantity"] == "") {
                this.validateForm();
            } else {
                this.loader = false
            }
        },
    },
    computed: {
        selectedPaymentMethod() {
            return [
                { 'value': 'Chèque bancaire', 'title': this.$t('purchaseOrder.paymentMethodChoise.BankCheck') },
                { 'value': 'Virement', 'title': this.$t('purchaseOrder.paymentMethodChoise.Transfer') },
                { 'value': 'Prélèvement', 'title': this.$t('purchaseOrder.paymentMethodChoise.DirectDebit') }
            ]
        },
        MTHTVA() {
            if ((this.Quantity != null) && (this.Threshold != null)) {
                let total = parseFloat(this.Quantity) * parseFloat(this.Threshold);
                let parts = total.toString().split('.');
                if (parts[1] && parts[1].length > 3) {
                    return total.toFixed(3).toString().replace(/\.?0+$/, '');
                } else {
                    return total;
                }
            } else {
                return null
            }
        },
        MTTotal() {
            if ((this.Quantity != null) && (this.Tax != null)) {
                const amountTTC = this.Threshold * (1 + (this.Tax / 100))
                let total = parseFloat(this.Quantity) * amountTTC;
                let parts = total.toString().split('.');
                if (parts[1] && parts[1].length > 3) {
                    return total.toFixed(3).toString().replace(/\.?0+$/, '');
                } else {
                    return total;
                }
            } else {
                return null;
            }
        },
        MultiFileList() {
            let list = [];
            if (this.identityDocuments) {
                this.identityDocuments.forEach(file => {
                    list.push(file.name)
                });
            }
            return list
        }
    }
};
</script>
<style scoped>
.title {
    margin-top: auto;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    padding-left: 0;
    margin-top: 0;
    padding-top: 0;
}

.v-autocomplete {
    margin-left: 12px !important;
}
</style>