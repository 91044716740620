<template>
    <v-dialog v-model="dialog2" v-if="dialog2">
        <v-card width="950px" class="py-2" style="border-radius: 12px;">
            <h1>{{ $t('suppliers.documentsTab.add') }}</h1>
            <div class="row ma-4">
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.documentsTab.name') }}<span
                                class="red">*</span>
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="name" id="name" name="name" maxlength="30" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.documentsTab.placeholder.name')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.name }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.documentsTab.date') }}<span
                                class="red">*</span>
                        </v-card-title>
                    </div>
                    <div class="row">
                        <dateField v-model="expiryDate" :minDate="new Date()"/>
                        <span class='warningHint' id="WarningMsgExpiryDate"> {{ msg.expiryDate }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row mb-4">
                    </div>
                    <div class="row ml-0">
                        <uploadDocument @addDocument="setidentityDocument" :showDownload="showDownload" :showUpload="true">
                            <template v-slot:DocumentsList>
                                <span v-if="identityDocument" style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start"
                                    @click="downloadDocument(identityDocument[0])"
                                    :title="$t('actions.download') + ' ' + identityDocument[0].name">
                                    {{ identityDocument[0].name }}
                                </span>
                            </template>
                        </uploadDocument>
                        <span class='warningHint' id="WarningMsgStatus">{{ msg.identityDocument }}</span>
                    </div>
                </div>
            </div>
            <card-actions :yes="AddSupplierDocument" v-model:no="dialog2" width="300px"
                :yesTitle="$t('suppliers.documentsTab.add')" />
        </v-card>
    </v-dialog>
</template>
<script>
import { addSupplierDocument, isSupplier, idSupplier } from '@/api/index';
import cardActions from "@/genericComponent/cardActions.vue"
import dateField from "@/genericComponent/date-field.vue";
import uploadDocument from "@/genericComponent/uploadDocument.vue";
export default {
    props: {
        reloadListDocuments: {}
    },
    components: {
        cardActions, dateField, uploadDocument
    },
    data() {
        return {
            dialog2: true,
            name: null,
            identityDocument: null,
            expiryDate: null,
            status: null,
            idSupplier: null,
            msg: [],
            showDownload: false
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
    },
    watch: {
        dialog2(value) {
            this.dialog2 = value
            this.$emit('add-document-close-dialog')
            this.dialog2 = true
            this.name = null
            this.identityDocument = null
            this.expiryDate = null
            this.status = null
            this.msg = []
            this.showDownload = !this.showDownload
        }
    },
    methods: {
        downloadDocument(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = file.name;
                downloadLink.click();
            }
        },
        setidentityDocument(value) {
            this.identityDocument = value
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        AddSupplierDocument() {
            if (!this.name || this.name.length == 0) {
                this.msg["name"] = this.$t('suppliers.documentsTab.errors.name');
            } else {
                this.msg["name"] = "";
            }

            if (this.expiryDate == null || this.expiryDate == "") {
                this.msg["expiryDate"] = this.$t('suppliers.documentsTab.errors.date');
            } else if (this.expiryDate < "1000-01-01") {
                this.msg["expiryDate"] = this.$t('warningInput.wrong', { itm: this.$t('suppliers.documentsTab.date') });
            } else {
                this.msg["expiryDate"] = "";
            }
            if (this.identityDocument == null || this.identityDocument == "") {
                this.msg["identityDocument"] = this.$t('suppliers.documentsTab.errors.identityDocument');
            } else {
                this.msg["identityDocument"] = "";
            }
            if (
                this.msg["name"] == "" &&
                this.msg["expiryDate"] == "" &&
                this.msg["identityDocument"] == ""
            ) {
                this.$emit('add-document-close-dialog');
                addSupplierDocument(this.name, this.identityDocument, this.expiryDate, this.idSupplier)
                    .then(() => {
                        this.ActiveSavedSnackBar();
                        this.reloadListDocuments();
                    }).catch(() => {
                        this.ActiveWarningSnackBar();
                    });
                setTimeout(() => {
                    this.name = null;
                    this.expiryDate = null;
                    this.status = null;
                }, 2000);
            }
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>