import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/lib/styles/main.sass'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'
export default createVuetify({
  components,
  directives,
  defaults: {
    global: {
      ripple: false,
    },
    VSheet: {
      elevation: 4,
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
  },
})