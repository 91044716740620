<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('permissions.permissions') }}
                </v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :ClickFun="navigateToNewRole" svg="2" :Title="$t('permissions.CreateNewRole')" />
            </div>
        </div>
        <v-card elevation="0">
            <v-tabs v-model="tab" background-color="transparent" color="basil">
                <v-tab class="slider" value="option-1" :style="NotActive" centered v-model="RolesandSecurity">
                    {{ $t('permissions.RolesandSecurity') }}
                </v-tab>
            </v-tabs>
            <v-divider class="ma-0"></v-divider>
            <v-window v-model="tab" :key="tab">
                <v-window-item value="option-1">
                    <RoleList />
                </v-window-item>
                <v-window-item value="option-2">
                    <UsersList />
                </v-window-item>
            </v-window>
        </v-card>
    </div>
</template>
<script>
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import RoleList from "../Permissions/RoleList.vue";
import UsersList from "../Permissions/UsersList.vue";
export default {
    components: { genericBtnComponent, RoleList, UsersList },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.permissions');
    },
    data() {
        return {
            tab: "option-1",
            NotActive: {
                color: "#667280",
                fontSize: "14px",
                font: "inter",
            },
            Active: {
                color: "#00CBA0",
                fontSize: "14px",
                font: "inter",
            },
        };
    },
    methods: {
        navigateToNewRole() {
            this.$router.push({ path: '/NewRole' });
        },
    }
};
</script>
<style scoped>
.v-card-title {
    font-size: 14px;
    font-family: inter;
    font-weight: 600;
}

.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.bg-basil {
    background-color: #ffffff !important;
}

.text-basil {
    color: #114ef7 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: inter;
}

.btn {
    display: flex;
}

.Save {
    color: #fff;
    height: 45px;
    font-family: inter;
    font-weight: 600;
    display: inline-flex;
}

.icon {
    width: 10px;
    height: 10px;
}

.iconSave {
    width: 20px;
    height: 15px;
    margin-right: 5px;
}

.svg {
    margin-right: 8px;
    margin-top: 4px;
}
</style>