import { createI18n } from 'vue-i18n'
import en from "./en.json";
import fr from "./fr.json";

const DEFAULT_LOCALE = "en";
export const SUPPORTED_LOCALES = ["en", "fr"];

export default createI18n({
  locale: localStorage.getItem("UserLang") ? localStorage.getItem("UserLang") : DEFAULT_LOCALE,
  legacy: false,
  fallbackLocale: "en",
  globalInjection: true,
  messages: { en, fr }
})