<template>
    <div v-if="loader" style="height: 250px; line-height: 250px;">
        <v-progress-circular indeterminate :size="50"></v-progress-circular>
    </div>
    <div v-else class="row ma-4" style="width: 70%;">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.name') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="Name" id="Name" name="Name" maxlength="30" hide-details persistent-placeholder
                    :placeholder="$t('suppliers.generalInformation.placeholder.name')" variant="outlined"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgName"> {{ msg.Name }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.code') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="Code" id="Code" name="Code" hide-details persistent-placeholder maxlength="20"
                    :placeholder="$t('suppliers.generalInformation.placeholder.code')" variant="outlined"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgCode"> {{ msg.Code }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.payment') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-autocomplete v-model="PaymentCondition" :items="PaymentConditionItems" :suffix="'Days'"
                    :placeholder="$t('suppliers.generalInformation.placeholder.payment')" variant="outlined"
                    v-model:search="manualPaymentCondition" hide-no-data hide-details
                    @update:search="manualPaymentCondition = manualPaymentCondition.replace(/[^0-9.]/g, '')"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''">
                </v-autocomplete>
                <span class='warningHint' id="WarningMsgPaymentCondition"> {{ msg.PaymentCondition }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.currencies') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-autocomplete id="Currencies" name="Currencies" v-model="Currencies" :items="CurrenciesItems"
                    item-title="name" item-value="symbol" hide-details persistent-placeholder
                    :placeholder="$t('suppliers.generalInformation.placeholder.currencies')" variant="outlined"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''">
                    <template v-slot:item="{ props, item, index }">
                        <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                            {{ item.name }}
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <span class='warningHint' id="WarningMsgCurrencies"> {{ msg.Currencies }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.defaultPhone') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="DefaultPhone" id="DefaultPhone" name="DefaultPhone" hide-details
                    persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.defaultPhone')"
                    maxlength="15" @update:model-value="DefaultPhone = DefaultPhone.replace(/[^0-9.]/g, '');"
                    variant="outlined" :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgDefaultPhone"> {{ msg.DefaultPhone }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.defaultEmail') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="DefaultEmail" id="DefaultEmail" name="DefaultEmail" hide-details maxlength="40"
                    persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.defaultEmail')"
                    variant="outlined" :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgDefaultEmail"> {{ msg.DefaultEmail }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.delivery') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="Delivery" id="Delivery" name="Delivery" hide-details persistent-placeholder
                    maxlength="30" :placeholder="$t('suppliers.generalInformation.placeholder.delivery')" variant="outlined"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgDelivery"> {{ msg.Delivery }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.total') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="TotalMinimumOrder" id="TotalMinimumOrder" name="TotalMinimumOrder" hide-details
                    maxlength="9" persistent-placeholder :placeholder="$t('suppliers.generalInformation.placeholder.total')"
                    variant="outlined" :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgTotalMinimumOrder"> {{ msg.TotalMinimumOrder }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.country') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <select-country v-bind:countryProps="country" @getCountry="getCountryFromSelect" :readonly="!canEdit"
                    :class="!canEdit ? 'readonly-field' : ''"></select-country>
                <span class='warningHint' id="WarningMsgCountry"> {{ msg.country }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.postalCode') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="PostalCode" id="PostalCode" name="PostalCode" hide-details persistent-placeholder
                    maxlength="9" :placeholder="$t('suppliers.generalInformation.placeholder.postalCode')"
                    variant="outlined" :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgPostalCode"> {{ msg.PostalCode }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.city') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="City" id="City" name="City" hide-details persistent-placeholder maxlength="20"
                    :placeholder="$t('suppliers.generalInformation.placeholder.city')" variant="outlined"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgCity"> {{ msg.City }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.generalInformation.address') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="Address" id="Address" name="Address" hide-details persistent-placeholder
                    maxlength="50" :placeholder="$t('suppliers.generalInformation.placeholder.address')" variant="outlined"
                    :readonly="!canEdit" :class="!canEdit ? 'readonly-field' : ''"></v-text-field>
                <span class='warningHint' id="WarningMsgAddress"> {{ msg.Address }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import currenciesjson from "@/data/DevisesList"
import SelectCountry from '../../SelectCountry/SelectCountry.vue'
import { getSupplierById, isSupplier, idSupplier } from "@/api/index"
export default {
    components: { SelectCountry, },
    data: () => ({
        Name: "",
        loader: true,
        item: null,
        msg: [],
        Code: "",
        PaymentCondition: null,
        PaymentConditionItems: ["10", "15", "20", "30"],
        manualPaymentCondition: '',
        CurrenciesItems: currenciesjson,
        Currencies: null,
        DefaultPhone: "",
        DefaultEmail: "",
        Delivery: "",
        TotalMinimumOrder: "",
        PostalCode: "",
        City: "",
        Address: "",
        country: null,
        suppliersData: {},
        idSupplier: null,
        canEdit: null,
    }),
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
        this.getSupplierById(this.idSupplier);
    },
    watch: {
        manualPaymentCondition(value) {
            let findValue = this.PaymentConditionItems.find(v => v == value);
            if (value != '' && findValue == undefined) {
                this.PaymentConditionItems[4] = value;
            } else {
                this.PaymentConditionItems.splice(4)
            }
        },
        Name(value) {
            this.Name = value;
            this.suppliersData.Name = this.Name;
            if (this.Name == "")
                delete this.suppliersData.Name
            this.$emit('getData', this.suppliersData)
        },
        Code(value) {
            this.Code = value;
            this.suppliersData.Code = this.Code;
            if (this.Code == "")
                delete this.suppliersData.Code
            this.$emit('getData', this.suppliersData)
        },
        PaymentCondition(value) {
            this.PaymentCondition = value;
            this.suppliersData.PaymentCondition = this.PaymentCondition;
            if (this.PaymentCondition == null || this.PaymentCondition == "")
                delete this.suppliersData.PaymentCondition
            this.$emit('getData', this.suppliersData)
        },
        Currencies(value) {
            this.Currencies = value;
            this.suppliersData.Currencies = this.Currencies;
            if (this.Currencies == null || this.Currencies == "")
                delete this.suppliersData.Currencies
            this.$emit('getData', this.suppliersData)
        },
        DefaultPhone(value) {
            this.DefaultPhone = value;
            this.suppliersData.DefaultPhone = this.DefaultPhone;
            if (this.DefaultPhone == "")
                delete this.suppliersData.DefaultPhone
            this.$emit('getData', this.suppliersData)
        },
        DefaultEmail(value) {
            this.DefaultEmail = value;
            this.suppliersData.DefaultEmail = this.DefaultEmail;
            if (this.DefaultEmail == "")
                delete this.suppliersData.DefaultEmail
            // eslint-disable-next-line
            if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.DefaultEmail)) == false)
                delete this.suppliersData.DefaultEmail
            this.$emit('getData', this.suppliersData)
        },
        Delivery(value) {
            this.Delivery = value;
            this.suppliersData.Delivery = this.Delivery;
            if (this.Delivery == "")
                delete this.suppliersData.Delivery
            this.$emit('getData', this.suppliersData)
        },
        TotalMinimumOrder(value) {
            this.TotalMinimumOrder = value;
            this.suppliersData.TotalMinimumOrder = this.TotalMinimumOrder;
            if (this.TotalMinimumOrder == "")
                delete this.suppliersData.TotalMinimumOrder
            this.$emit('getData', this.suppliersData)
        },
        PostalCode(value) {
            this.PostalCode = value;
            this.suppliersData.PostalCode = this.PostalCode;
            if (this.PostalCode == 0)
                delete this.suppliersData.PostalCode
            this.$emit('getData', this.suppliersData)
        },
        City(value) {
            this.City = value;
            this.suppliersData.City = this.City;
            if (this.City == "")
                delete this.suppliersData.City
            this.$emit('getData', this.suppliersData)
        },
        Address(value) {
            this.Address = value;
            this.suppliersData.Address = this.Address;
            if (this.Address == "")
                delete this.suppliersData.Address
            this.$emit('getData', this.suppliersData)
        },
        country(value) {
            this.country = value;
            this.suppliersData.country = this.country;
            if (this.country == null || this.country == "")
                delete this.suppliersData.country
            this.$emit('getData', this.suppliersData)
        },
    },
    methods: {
        getCountryFromSelect(value) {
            this.country = value;
        },
        getSupplierById: function (idSupplier) {
            getSupplierById(idSupplier)
                .then(response => {
                    this.item = response.data;
                    this.suppliersData.idSupplier = this.item.idSupplier,
                        this.suppliersData.canEdit = this.item.canEdit;
                    this.canEdit = this.item.canEdit;
                    this.Name = this.item.name;
                    this.Code = this.item.code;
                    this.PaymentCondition = this.item.paymentCondition;
                    this.Currencies = this.item.currencies;
                    this.City = this.item.city;
                    this.DefaultPhone = this.item.defaultPhone;
                    this.DefaultEmail = this.item.defaultEmail;
                    this.Delivery = this.item.delivery;
                    this.TotalMinimumOrder = this.item.totalMinimumOrder;
                    this.PostalCode = this.item.postalCode;
                    this.Address = this.item.address;
                    this.country = this.item.country;
                    this.suppliersData.status = this.item.status;
                    this.loader = false;
                    this.$emit('getData', this.suppliersData)
                })
        },
        ControleSaisir() {
            if (this.Name == "") {
                this.msg['Name'] = this.$t('suppliers.generalInformation.errors.name');
            } else {
                this.msg['Name'] = "";
            }
            if (this.Code == "") {
                this.msg['Code'] = this.$t('suppliers.generalInformation.errors.code');
            } else {
                this.msg['Code'] = "";
            }
            if (this.PaymentCondition == null) {
                this.msg['PaymentCondition'] = this.$t('suppliers.generalInformation.errors.payment');
            } else {
                this.msg['PaymentCondition'] = "";
            }
            if (this.Currencies == null) {
                this.msg['Currencies'] = this.$t('suppliers.generalInformation.errors.currencyks');
            } else {
                this.msg['Currencies'] = "";
            }
            if (!(this.DefaultPhone) || (this.DefaultPhone).length == 0) {
                this.msg['DefaultPhone'] = this.$t('suppliers.generalInformation.errors.defaultPhone');
            } else if (((this.DefaultPhone).length < 8) || ((this.DefaultPhone).length > 15)) {
                this.msg['DefaultPhone'] = this.$t('suppliers.generalInformation.errors.wrongNumber');
            } // eslint-disable-next-line
            else if (this.DefaultPhone.match(/^[\+]?[0-9]{8,15}$/im)) {
                this.msg['DefaultPhone'] = '';
            } else {
                this.msg['DefaultPhone'] = this.$t('suppliers.generalInformation.errors.wrongNumber');
            }
            if (this.DefaultEmail == "") {
                this.msg['DefaultEmail'] = this.$t('suppliers.generalInformation.errors.defaultEmail');
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.DefaultEmail)) {
                this.msg['DefaultEmail'] = "";
            } else {
                this.msg['DefaultEmail'] = this.$t('suppliers.generalInformation.errors.wrongEmail');
            }
            if (this.City == "") {
                this.msg['City'] = this.$t('suppliers.generalInformation.errors.city');
            } else {
                this.msg['City'] = "";
            }
            if (this.country == null || this.country == "") {
                this.msg['country'] = this.$t('suppliers.generalInformation.errors.country');
            } else {
                this.msg['country'] = "";
            }
            if (this.Delivery == "") {
                this.msg['Delivery'] = this.$t('suppliers.generalInformation.errors.delivery');
            } else {
                this.msg['Delivery'] = "";
            }
            if (this.Address == "") {
                this.msg['Address'] = this.$t('suppliers.generalInformation.errors.address');
            } else {
                this.msg['Address'] = "";
            }
            if (this.TotalMinimumOrder == "") {
                this.msg['TotalMinimumOrder'] = this.$t('suppliers.generalInformation.errors.total');
            } else {
                this.msg['TotalMinimumOrder'] = "";
            }
            if (this.PostalCode == "") {
                this.msg['PostalCode'] = this.$t('suppliers.generalInformation.errors.postalCode');
            } else {
                this.msg['PostalCode'] = "";
            }
            if (this.msg['Name'] == "" &&
                this.msg['Code'] == "" &&
                this.msg['PaymentCondition'] == "" &&
                this.msg['Currencies'] == "" &&
                this.msg['DefaultPhone'] == "" &&
                this.msg['DefaultEmail'] == "" &&
                this.msg['City'] == "" &&
                this.msg['country'] == "" &&
                this.msg['Delivery'] == "" &&
                this.msg['Address'] == "" &&
                this.msg['TotalMinimumOrder'] == "" &&
                this.msg['PostalCode'] == ""
            ) {
                return true
            } else {
                return false
            }
        },
    }
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>