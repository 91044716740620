<template>
    <v-btn :id="Title.replaceAll(' ', '')" :name="Title" :width="width" :variant="variant"
        :class="CustomClass ? CustomClass : 'v-btn1'" @click="ClickFun" :disabled="loader">
        <div v-if="!svg">
            <slot name="logo" />
        </div>
        <div v-else>
            <svg v-if="(svg == 1)" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4"
                    d="M16.1917 2H7.82173C4.18173 2 2.01172 4.17 2.01172 7.81V16.18C2.01172 19.82 4.18173 21.99 7.82173 21.99H16.1917C19.8317 21.99 22.0017 19.82 22.0017 16.18V7.81C22.0017 4.17 19.8317 2 16.1917 2Z"
                    fill="#fff" />
                <path
                    d="M9.17156 14.0919C9.36156 14.0919 9.55155 14.0219 9.70155 13.8719L14.0816 9.49187V11.9219C14.0816 12.3319 14.4216 12.6719 14.8316 12.6719C15.2416 12.6719 15.5816 12.3319 15.5816 11.9219V7.68188C15.5816 7.58188 15.5616 7.49188 15.5216 7.39188C15.4416 7.21188 15.3016 7.06188 15.1116 6.98188C15.0216 6.94188 14.9216 6.92188 14.8216 6.92188H10.5816C10.1716 6.92188 9.83156 7.26188 9.83156 7.67188C9.83156 8.08187 10.1716 8.42188 10.5816 8.42188H13.0116L8.63156 12.8019C8.34156 13.0919 8.34156 13.5719 8.63156 13.8619C8.79156 14.0219 8.98156 14.0919 9.17156 14.0919Z"
                    fill="#fff" />
                <path
                    d="M18.7107 16.2819C18.5807 15.8919 18.1607 15.6819 17.7607 15.8119C14.0407 17.0519 9.95066 17.0519 6.23066 15.8119C5.84066 15.6819 5.41067 15.8919 5.28067 16.2819C5.15067 16.6719 5.36067 17.1019 5.75067 17.2319C7.76067 17.9019 9.87067 18.2419 11.9907 18.2419C14.1107 18.2419 16.2207 17.9019 18.2307 17.2319C18.6307 17.0919 18.8407 16.6719 18.7107 16.2819Z"
                    fill="#fff" />
            </svg>
            <svg v-if="(svg == 2)" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4"
                    d="M21 7V17C21 20 19.5 22 16 22H9C10.21 21.09 11 19.63 11 18C11 15.24 8.76 13 6 13C4.87 13 3.83 13.37 3 14V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                    fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.5 3.75C14.9142 3.75 15.25 4.08579 15.25 4.5V6.5C15.25 7.18579 15.8142 7.75 16.5 7.75H18.5C18.9142 7.75 19.25 8.08579 19.25 8.5C19.25 8.91421 18.9142 9.25 18.5 9.25H16.5C14.9858 9.25 13.75 8.01421 13.75 6.5V4.5C13.75 4.08579 14.0858 3.75 14.5 3.75Z"
                    fill="white" />
                <path
                    d="M6 13C3.24 13 1 15.24 1 18C1 20.76 3.24 23 6 23C8.76 23 11 20.76 11 18C11 15.24 8.76 13 6 13ZM5.16 19.27C5.41 19.52 5.41 19.93 5.16 20.19C5.03 20.32 4.87001 20.38 4.70001 20.38C4.53001 20.38 4.36999 20.32 4.23999 20.19L2.51001 18.46C2.26001 18.21 2.26001 17.8 2.51001 17.54L4.23999 15.81C4.48999 15.56 4.9 15.56 5.16 15.81C5.41 16.06 5.41 16.47 5.16 16.73L3.89001 18L5.16 19.27ZM9.48001 18.46L7.75 20.19C7.62 20.32 7.46001 20.38 7.29001 20.38C7.12001 20.38 6.95999 20.32 6.82999 20.19C6.57999 19.94 6.57999 19.53 6.82999 19.27L8.10001 18L6.82999 16.73C6.57999 16.48 6.57999 16.07 6.82999 15.81C7.07999 15.56 7.49 15.56 7.75 15.81L9.48001 17.54C9.73001 17.8 9.73001 18.2 9.48001 18.46Z"
                    fill="white" />
            </svg>
        </div>
        <span :style="svg ? 'margin-left: 8px':''">{{ Title }}</span>
        <span v-if="loader">
            <v-progress-circular indeterminate :size="30" bg-color="black"></v-progress-circular>
        </span>
    </v-btn>
</template>
<script>
export default {
    name: "genericBtnComponent",
    props: {
        CustomClass: { default: null },
        ClickFun: { type: Function },
        width: {},
        Title: {},
        svg: {
            type: String,
            default: null
        },
        backgroundColor: {
            type: String,
            default: "#114EF7"
        },
        color: {
            type: String,
            default: "#fff"
        },
        height: {
            type: String,
            default: "45px"
        },
        fontWeight: {
            type: [String, Number],
            default: 600
        },
        variant: {
            type: String,
            default: null
        },
        loader: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
<style scoped>
.v-btn1 {
    font-family: 'Inter';
    font-style: normal;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 29px;
    height: v-bind(height);
    background: v-bind(backgroundColor);
    font-weight: v-bind(fontWeight);
    color: v-bind(color);
    display: flex;
    flex-direction: column;
    padding: 8px 12px 8px 12px;
    border-radius: 12px;
}

div {
    padding-bottom: 3px
}

.loader-color {
  color: #001e50;
}
</style>