<template>
    <actions-menu v-model:dialogEdit="editInvoiceDialog" v-model:dialogDelete="dialog"
        v-model:dialogConsult="editInvoiceDialog" :ShowDialogConsult="!isEditMode" v-model:dialogPayement="dialogPayment"
        v-model:dialogRefuse="dialogCommentRefuse" :Id="Id" v-model:dialogValidation="dialogValidation"
        :ShowDialogEdit="isEditMode" :ShowDialogDelete="isEditMode" :ShowDialogPayement="getInvoice.displayPaiementButton"
        :ShowDialogRefuse="getInvoice.displayRefusButton" :ShowDialogValidation="getInvoice.displayValidateButton" />
    <dialogDelete :title="$t('invoices.invoice')" :yes="deleteInvoice" v-model="dialog" />
    <v-dialog v-model="editInvoiceDialog">
        <v-card width="950px" class="py-2" style="border-radius: 12px;">
            <v-form ref="form" lazy-validation>
                <div class="row ma-4 my-0">
                    <h1>{{ isEditMode ? $t('invoices.edit') : $t('invoices.consult') }}</h1>
                </div>
                <div class="row ma-4 my-0">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.reference') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.reference" id="Reference" name="Reference" maxlength="50"
                                hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('invoices.reference') })" variant="outlined"
                                :readonly="!isEditMode" :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgReference">{{ msg.Reference }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.title') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.title" id="title" name="title" maxlength="30" hide-details
                                persistent-placeholder :placeholder="$t('placeholder.enter', { itm: $t('invoices.title') })"
                                variant="outlined" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgTitle">{{ msg.Title }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.purchaseOrder') }}</v-card-title>
                        </div>
                        <div class="row">
                            <v-autocomplete id="PurchaseOrder" name="PurchaseOrder" class="pl-0 pr-6" variant="outlined"
                                v-model="editData.idPurchaseOrder" v-model:search="PurchaseOrderSearch"
                                persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('invoices.purchaseOrder') })"
                                :items="PurchaseOrderList" item-title="title" item-value="id" hide-details clearable
                                :readonly="!isEditMode" :class="!isEditMode ? 'readonly-field' : ''" no-filter>
                                <template v-slot:selection v-if="PurchaseOrderLoader">
                                    <div>
                                        <v-progress-circular indeterminate :size="30" color="#A1B3C9"></v-progress-circular>
                                    </div>
                                </template>
                            </v-autocomplete>
                            <span class="warningHint" id="WarningMsgPurchaseOrder">{{ msg.PurchaseOrder }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.currency') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-autocomplete id="devise" name="devise" class="pl-0 pr-6" variant="outlined"
                                :readonly="!isEditMode || editData.idPurchaseOrder"
                                :class="!isEditMode ? 'readonly-field' : ''" v-model="editData.devise"
                                persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('invoices.currency') })"
                                :items="DeviseList" item-title="name" item-value="symbol" hide-details>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <span class="warningHint" id="WarningMsgDevise">{{ msg.Devise }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.deadline') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <dateField v-model="editData.deadline" :minDate="new Date()" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''" />
                            <span class="warningHint" id="WarningMsgDeadline">{{ msg.Deadline }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.amount') }}<span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.amount" id="amount" name="amount" maxlength="9"
                                @update:model-value="editData.amount = editData.amount.replace(/[^0-9.]/g, '');"
                                hide-spin-buttons hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('invoices.amount') })" variant="outlined"
                                :readonly="!isEditMode" :class="!isEditMode ? 'readonly-field' : ''"
                                :suffix="editData.amount ? editData.devise : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgAmount">{{ msg.Amount }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.tax') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="Tax" name="Tax" variant="outlined" v-model="editData.tax" persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('invoices.tax') })" :items="TaxList"
                                item-title="taxPourcentage" item-value="taxPourcentage" hide-details
                                :suffix="editData.tax ? '%' : ''" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''">
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" :id="index" :name="item.name" :title="item.value + '%'"
                                        :subtitle="item.raw.taxName">
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class="warningHint" id="WarningMsgTax">{{ msg.Tax }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.amountTTC') }}</v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="AmountTTC" id="amountTTC" name="amountTTC" hide-details
                                persistent-placeholder :placeholder="$t('invoices.amountTTC')" variant="outlined" readonly
                                :suffix="editData.devise" :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('invoices.invoice') }}<span class="red">*</span></v-card-title>
                        </div>
                        <div class="row mx-1">
                            <uploadDocument v-on:addDocument="setidentityDocument" :fn-download="downloadDocumentInvoices"
                                :showDownload="showDownload" :showUpload="isEditMode ? true : false" :has-file="true">
                                <template v-slot:DocumentsList>
                                    <span style="color: blue; cursor: pointer; display: flex; text-align: start"
                                        @click="downloadDocumentInvoices()"
                                        :title="$t('actions.download') + ' ' + (editData.file ? editData.file.name : editData.title + '.' + editData.type)">
                                        {{ editData.file ? editData.file.name : editData.title + "." + editData.type }}
                                    </span>
                                </template>
                            </uploadDocument>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row ">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.AutresDocument') }}</v-card-title>
                        </div>
                        <div class="row mx-1">
                            <uploadMultiDocument v-on:addMultiDocument="setidentityDocuments"
                                :fnDownload="DownloadallMultiDocument" :showDownload="showDownload"
                                :showUpload="isEditMode ? true : false" :has-file="true">
                                <template v-slot:DocumentsList>
                                    <div style="max-height: 60px; overflow-y:scroll;">
                                        <div v-for="(fileName, index) in MultiFileList" :key="index">
                                            <div @mouseover="setHover(index)" @mouseout="setHover(null)"
                                                style="display: flex; position: relative;">
                                                <span style="color: blue; cursor: pointer; text-align: start; max-width: 95%;"
                                                    @click="downloadMultiDocumentInvoices(fileName, index)"
                                                    :title="$t('actions.download') + ' ' + fileName">{{ fileName }}</span>
                                                <br v-if="index < this.editData.uriFiles.split(',').length - 1">
                                                <v-icon v-show="isHovered == index"
                                                    @click="showDeleteConfirmationDialog(fileName, index)" class="ml-2"
                                                    style="color: red">mdi-close-circle-outline</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </uploadMultiDocument>
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateFormEditInvoice" v-model:no="editInvoiceDialog" :loader="loader"
                    :yesDisable="loader ? true : !isEditMode ? true : false" width="300px" :yesTitle="$t('invoices.saveBtn')" />
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCommentRefuse" v-if="dialogCommentRefuse">
        <v-card width="max-content" height="max-content" class="px-8 py-4">
            <div class="row justify-content-center">
                <v-card-title class="text-left">{{ $t('invoices.validationTab.commentaire') }} : <span
                        class="red">*</span></v-card-title>
            </div>
            <div class="row justify-content-center">
                <v-textarea id="Comment" v-model="commentaire" variant="outlined" counter
                    :label="$t('invoices.validationTab.commentaireLabel')" single-line auto-grow hide-details
                    maxlength="500"></v-textarea>
                <span class="warningHint" id="WarningMsgComment">{{ msg.comment }}</span>
            </div>
            <div class="row justify-content-center">
                <card-actions :yes="checkComment" v-model:no="dialogCommentRefuse" width="200px"
                    :yesTitle="$t('invoices.validationTab.refuseBtn')" :YesColor="'#F25C5C'" />
            </div>
        </v-card>
    </v-dialog>
    <dialogDelete v-if="selectedIndex !== null" v-model="showDeleteDialog" :yes="confirmDelete" :status="false"
        :title="selectedFileName"></dialogDelete>
    <dialogDelete :loader="buttonLoader" DialogType="Payment" :title="$t('invoices.validationTab.validationStatus')"
        :yes="PaidInvoices" v-model="dialogPayment" />
    <dialogDelete :loader="buttonLoader" DialogType="Refuse" :title="$t('invoices.validationTab.validationStatus')"
        :yes="RefuseInvoices" v-model="dialogRefuse" />
    <dialogDelete :loader="buttonLoader" DialogType="Validation" :title="$t('invoices.validationTab.validationStatus')"
        :yes="ValideInvoices" v-model="dialogValidation" />
</template>
<script>
import { DeleteInvoice, EditInvoice, isSupplier, PaidInvoices, RefuseInvoices, ValideInvoices, downloadDocumentInvoices, downloadMultiDocumentInvoices, InvoiceIsExistReference, GetPurchaseOrderFromInvoices, GetTaxListByCmpanyId, CanValideFactureByPurchaseOrder, GetDeviseFromPurchaseOrder } from "@/api/index";
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import deviseJson from "../../data/DevisesList.json"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
import dateField from "@/genericComponent/date-field.vue";
import uploadDocument from "@/genericComponent/uploadDocument.vue"
import uploadMultiDocument from "@/genericComponent/uploadMultiDocument.vue"
import { status } from "@/enums/Status";
export default {
    components: { actionsMenu, dialogDelete, cardActions, dateField, uploadDocument, uploadMultiDocument },
    props: ["getInvoicesListAfterDelete", "getInvoice", "Id"],
    Update:
    {
        default: []
    },
    data: () => ({
        dialog: false,
        editInvoiceDialog: false,
        dialogCommentRefuse: false,
        identityDocument: null,
        identityDocuments: [],
        selectedIndex: null,
        selectedFileName: null,
        isHovered: null,
        showDeleteDialog: null,
        loader: false,
        editData: {
            idInvoices: "",
            reference: "",
            title: "",
            amount: 0,
            tax: 0,
            idPurchaseOrder: null,
            devise: "",
            file: null,
            type: "",
            uriFiles: [],
            filesToAdd: [],
            filesToDelete: []
        },
        TaxList: [],
        msg: [],
        DeviseList: deviseJson,
        afficheCommentaire: false,
        commentaire: null,
        dialogPayment: false,
        dialogRefuse: false,
        dialogValidation: false,
        buttonLoader: false,
        showDownload: false,
        PurchaseOrderSearch: null,
        PurchaseOrderList: [],
        PurchaseOrderLoader: false
    }),
    mounted() {
        this.editData.idInvoices = this.getInvoice.idInvoices;
        this.editData.reference = this.getInvoice.reference;
        this.editData.title = this.getInvoice.title;
        this.editData.amount = this.getInvoice.amount;
        this.editData.tax = this.getInvoice.tax;
        this.editData.deadline = this.getInvoice.deadline.split('T')[0];
        this.editData.idPurchaseOrder = this.getInvoice.idPurchaseOrder;
        this.editData.devise = this.getInvoice.devise;
        this.editData.file = this.identityDocument ? this.identityDocument[0] : null;
        this.editData.type = this.getInvoice.type;
        if (this.identityDocuments && this.identityDocuments.length > 0) {
            this.editData.filesToAdd = this.identityDocuments;
        } else {
            this.editData.filesToAdd = null;
        }
        this.editData.uriFiles = this.getInvoice.uriFiles
    },
    watch: {
        editInvoiceDialog(value) {
            if (value) {
                this.PurchaseOrderLoader = true;
                this.GetPurchaseOrderList();
                this.GetTaxListByCmpanyId(this.getInvoice.idCompany);
                this.editData.reference = this.getInvoice.reference;
                this.editData.title = this.getInvoice.title;
                this.editData.amount = this.getInvoice.amount;
                this.editData.tax = this.getInvoice.tax;
                this.editData.deadline = this.getInvoice.deadline.split('T')[0];
                this.editData.idPurchaseOrder = this.getInvoice.idPurchaseOrder;
                this.editData.devise = this.getInvoice.devise;
                this.identityDocument = null,
                this.identityDocuments = [],
                this.editData.file = this.identityDocument ? this.identityDocument[0] : null;
                this.editData.type = this.getInvoice.type;
                if (this.identityDocuments && this.identityDocuments.length > 0) {
                    this.editData.filesToAdd = this.identityDocuments;
                } else {
                    this.editData.filesToAdd = null;
                }
                this.editData.uriFiles = this.getInvoice.uriFiles
                this.msg = [];
                this.showDownload = true;
            }
        },
        dialogCommentRefuse(value) {
            if (value == false) {
                this.msg['comment'] = ""
                this.commentaire = null
            }
        },
        PurchaseOrderSearch(value) {
            if (this.PurchaseOrderSearch && this.PurchaseOrderSearch.length == 1) {
                this.PurchaseOrderSearch = value.trim();
            } else {
                this.GetPurchaseOrderList();
            }
        },
        'editData.idPurchaseOrder'(value) {
            if (value && this.editInvoiceDialog) {
                this.GetDeviseFromPurchaseOrder(value)
            }
        }
    },
    methods: {
        getFileName(list) {
            return list.map(fileName => fileName.replace(/_(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\./, '.'));
        },
        showDeleteConfirmationDialog(filename, index) {
            this.showDeleteDialog = true;
            this.selectedFileName = filename;
            this.selectedIndex = index;
        },
        confirmDelete() {
            if (this.selectedIndex !== null) {
                this.removeFile(this.selectedIndex);
                this.selectedIndex = null;
                this.selectedFileName = null;
                this.showDeleteDialog = false;
            }
        },
        setHover(index) {
            this.isHovered = index;
        },
        removeFile(fileToRemove) {
            let arr = this.editData.uriFiles.split(',');
            if (fileToRemove >= arr.length) {
                this.editData.filesToAdd.splice(fileToRemove - arr.length, 1);
            } else {
                this.editData.filesToDelete.push(arr[fileToRemove]);
                arr.splice(fileToRemove, 1)
            }
            this.editData.uriFiles = arr.join(',');
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        ActivePurchaseOrderInsufficientSnackBar() {
            this.getParent('MainLayout').ActivePurchaseOrderInsufficientSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        deleteInvoice: function () {
            this.dialog = false
            DeleteInvoice(this.getInvoice.idInvoices)
                .then(() => {
                    this.ActiveDeletedSnackBar();
                    this.getInvoicesListAfterDelete();
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        GetTaxListByCmpanyId(companyId) {
            GetTaxListByCmpanyId(companyId).then((res) => {
                this.TaxList = res.data;
            })
        },
        validateFormEdit() {
            this.loader = true;
            if (!this.editData.idPurchaseOrder) {
                delete this.editData.idPurchaseOrder
            }
            EditInvoice(this.getInvoice.idInvoices, this.editData).then((res) => {
                if (res.status == 200) {
                    this.editInvoiceDialog = false;
                    this.getInvoicesListAfterDelete();
                } else {
                    this.editInvoiceDialog = true;
                }
                this.ActiveSavedSnackBar();
            }).catch(() => {
                this.ActiveWarningSnackBar();
            }).finally(()=>{
                this.loader = false;
            })
        },
        async IsExistReference() {
            const r = await InvoiceIsExistReference(this.getInvoice.idInvoices, this.editData.reference);
            return r.data;
        },
        async validateFormEditInvoice() {
            if (!this.editData.reference || this.editData.reference.length == 0) {
                this.msg["Reference"] = this.$t('invoices.referenceRequired');
            } else if(await this.IsExistReference()==true) {
                this.msg["Reference"] = this.$t('warningInput.exist', { itm: this.$t('invoices.reference') });
            } else {
                this.msg["Reference"] = "";
            }
            if (!this.editData.title || this.editData.title.length == 0) {
                this.msg["Title"] = this.$t('invoices.titleRequired');
            } else {
                this.msg["Title"] = "";
            }
            if (!this.editData.devise || this.editData.devise.length == 0) {
                this.msg["Devise"] = this.$t('invoices.currencyRequired');
            } else {
                this.msg["Devise"] = "";
            }
            if (!this.editData.amount || this.editData.amount.length == 0) {
                this.msg["Amount"] = this.$t('invoices.amountRequired');
            } else if (this.editData.amount <= 0) {
                this.msg["Amount"] = this.$t('warningInput.notValid', { itm: this.$t('invoices.amount') });
            } else {
                this.msg["Amount"] = "";
            }
            if (this.editData.tax == null || this.editData.tax.length == 0) {
                this.msg["Tax"] = this.$t('invoices.taxRequired');
            } else {
                this.msg["Tax"] = "";
            }
            if (!this.editData.deadline || this.editData.deadline.length == 0) {
                this.msg["Deadline"] = this.$t('invoices.deadlineRequired');
            } else if (this.editData.deadline < "1000-01-01") {
                this.msg["Deadline"] = this.$t('warningInput.wrong', { itm: this.$t('invoices.deadline') });
            } else {
                this.msg["Deadline"] = "";
            }
            if (this.msg["Devise"] == "" && this.msg["Title"] == ""
                && this.msg["Amount"] == "" && this.msg["Deadline"] == ""
                && this.msg["Reference"] == "" && this.msg["Tax"] == "") {
                this.validateFormEdit();
            }
        },
        isSupplier() {
            return isSupplier()
        },
        PaidInvoices: function () {
            this.buttonLoader = true
            PaidInvoices(this.getInvoice.idInvoices).then(() => {
                this.ActiveSavedSnackBar()
            }).catch(() => {
                this.ActiveWarningSnackBar();
            }).finally(() => {
                this.dialogPayment = false;
                this.dialogCommentRefuse = false;
                this.getInvoicesListAfterDelete();
            });
        },
        checkComment: function () {
            if (this.commentaire == null || this.commentaire.length == 0) {
                this.msg['comment'] = this.$t('warningInput.isrequired', { itm: this.$t('invoices.validationTab.commentaire') });
            } else {
                this.dialogRefuse = true
            }
        },
        RefuseInvoices: function () {
            this.buttonLoader = true
            RefuseInvoices(this.getInvoice.idInvoices, this.commentaire).then(() => {
                this.ActiveSavedSnackBar()
            }).catch(() => {
                this.ActiveWarningSnackBar();
            }).finally(() => {
                this.afficheCommentaire = false
                this.dialogRefuse = false;
                this.dialogCommentRefuse = false;
                this.getInvoicesListAfterDelete();
            })
        },
        ValideInvoices: function () {
            this.buttonLoader = true
            if (this.getInvoice.idPurchaseOrder) {
                CanValideFactureByPurchaseOrder(this.getInvoice.idPurchaseOrder, this.AmountTTC).then((r) => {
                    if (r.data) {
                        ValideInvoices(this.getInvoice.idInvoices).then(() => {
                            this.ActiveSavedSnackBar()
                        }).catch(() => {
                            this.ActiveWarningSnackBar();
                        }).finally(() => {
                            this.dialogValidation = false;
                            this.getInvoicesListAfterDelete();
                            this.buttonLoader = false;
                        })
                    } else {
                        this.dialogValidation = false;
                        this.ActivePurchaseOrderInsufficientSnackBar();
                        this.buttonLoader = false;
                    }
                })
            } else {
                ValideInvoices(this.getInvoice.idInvoices).then(() => {
                    this.ActiveSavedSnackBar()
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                }).finally(() => {
                    this.dialogValidation = false;
                    this.getInvoicesListAfterDelete();
                    this.buttonLoader = false;
                })
            }
        },
        GetPurchaseOrderList() {
            GetPurchaseOrderFromInvoices(this.PurchaseOrderSearch, this.getInvoice.idSupplier)
                .then((res) => {
                    this.PurchaseOrderList = res.data;
                    this.PurchaseOrderList.map((item) => { item.title = item.reference + ' - ' + item.title })
                }).finally(() => {
                    this.PurchaseOrderLoader = false
                });
        },
        GetDeviseFromPurchaseOrder(IdPurchaseOrder) {
            GetDeviseFromPurchaseOrder(IdPurchaseOrder).then(r => {
                this.editData.devise = r.data;
            })
        },
        setidentityDocuments(value) {
            this.identityDocuments.push(...value);
            this.editData.filesToAdd = this.identityDocuments;
        },
        setidentityDocument(value) {
            this.identityDocument = value
            this.editData.file = this.identityDocument ? this.identityDocument[0] : null;
        },
        DownloadallMultiDocument() {
            this.MultiFileList.forEach((fileName, index) => {
                this.downloadMultiDocumentInvoices(fileName, index)
            });
        },
        downloadMultiDocumentInvoices(fileName, index) {
            let arr = this.editData.uriFiles.split(',');
            if (index >= arr.length) {
                var reader = new FileReader();
                reader.readAsDataURL(this.editData.filesToAdd[index - arr.length]);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const linkSource = `${base64data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            } else {
                downloadMultiDocumentInvoices(arr[index])
                    .then(response => {
                        const linkSource = `data:application/pdf;base64,${response.data}`;
                        const downloadLink = document.createElement("a");
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    })
            }
        },
        downloadDocumentInvoices() {
            const file = this.identityDocument ? this.identityDocument[0] : null
            if (file != null){
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const linkSource = `${base64data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = file.name;
                    downloadLink.click();
                }
            } else {
                let fileName = this.getInvoice.idInvoices + '.' + this.getInvoice.type
                downloadDocumentInvoices(fileName)
                    .then(response => {
                        const linkSource = `data:application/pdf;base64,${response.data}`;
                        const downloadLink = document.createElement("a");
                        const fileName = this.getInvoice.title + '.' + this.getInvoice.type;
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    })
            }
        }
    },
    computed: {
        statusText() {
            switch (this.getInvoice.validationStatus) {
                case 0: return localStorage.getItem(status.Submitted + this.$i18n.locale);
                case 1: return localStorage.getItem(status.Refused + this.$i18n.locale);
                case 2: return localStorage.getItem(status.Validated + this.$i18n.locale);
                case 3: return localStorage.getItem(status.Paid + this.$i18n.locale);
                default: return localStorage.getItem(status.Submitted + this.$i18n.locale);
            }
        },
        isEditMode() {
            return isSupplier() && this.getInvoice.validationStatus in [0, 1];
        },
        AmountTTC() {
            const amountTTC = this.editData.amount * (1 + (this.editData.tax / 100))
            let parts = amountTTC.toString().split('.');
            if (parts[1] && parts[1].length > 3) {
                return amountTTC.toFixed(3).toString().replace(/\.?0+$/, '');
            } else {
                return amountTTC;
            }
        },
        MultiFileList() {
            let list = this.getFileName(this.editData.uriFiles.split(','));
            if (this.editData.filesToAdd) {
                this.editData.filesToAdd.forEach(file => {
                    list.push(file.name)
                });
            }
            return list
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-autocomplete {
    margin-left: 12px;
}

.v-card-text {
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5b6d8e;
}
</style>