<template>
    <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12">
                <v-window v-model="step" disabled>
                    <v-window-item :value="1">
                        <span class="loginText" style="width: 377px;
                            height: 37px; 
                            font-family: 'Manrope'; 
                            font-style: normal; 
                            font-weight: 800; 
                            font-size: 28px; 
                            line-height: 29px; 
                            display: flex; 
                            align-items: center; 
                            color: #080C3E;">
                            {{ $t('auth.forget') }} ?
                        </span>
                        <span class="welcomeText" style="width: 424px; 
                            height: 21px;
                            font-family: 'Manrope';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 29px;
                            display: flex;
                            align-items: center;
                            margin-top: 10px;
                            color: #768DA9;">
                            {{ $t('auth.mailRecive') }}
                        </span>
                        <br>
                        <v-text-field v-model="email" id="email" name="email" :label="$t('auth.emailPlaceholder')"
                            variant="outlined" single-line full-width :hint="msg.email" persistent-hint hide-details shaped>
                            <template v-slot:prepend-inner>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z"
                                        fill="#C5CDD4" />
                                </svg>
                            </template>
                        </v-text-field>
                        <span class='warningHint' id="emailErrorMsg"> {{ msg.email }}</span>
                    </v-window-item>
                    <v-window-item :value="2">
                        <svg class="d-flex mb-5" width="88" height="88" viewBox="0 0 88 88" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="44" cy="44" r="44" fill="#3BBAFF" fill-opacity="0.12" />
                            <path opacity="0.3"
                                d="M30.1636 61.7511C29.687 61.7511 29.2103 61.5604 28.8289 61.1791L25.0154 57.3656C24.2527 56.6029 24.2527 55.4588 25.0154 54.6961C25.7781 53.9334 27.0175 53.9334 27.6849 54.6961L30.1636 57.1749L36.456 50.8826C37.2187 50.1199 38.3627 50.1199 39.1254 50.8826C39.8881 51.6453 39.8881 52.7893 39.1254 53.552L31.4984 61.1791C31.117 61.5604 30.6403 61.7511 30.1636 61.7511Z"
                                fill="#3BBAFF" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M46.2982 56.2802C45.7862 48.5026 39.3155 42.3525 31.4083 42.3525C30.3862 42.3525 29.3881 42.4553 28.4238 42.651V34.3939C28.4238 32.1962 30.2054 30.4146 32.4032 30.4146H60.2585C62.4562 30.4146 64.2378 32.1962 64.2378 34.3939V52.3009C64.2378 54.4986 62.4562 56.2802 60.2585 56.2802H46.2982ZM58.5561 34.0748L46.3308 40.6577L34.1055 34.0748C33.3798 33.6841 32.4748 33.9556 32.0841 34.6812C31.6934 35.4069 31.9649 36.3119 32.6905 36.7026L45.6233 43.6664C46.065 43.9042 46.5966 43.9042 47.0383 43.6664L59.9711 36.7026C60.6967 36.3119 60.9682 35.4069 60.5775 34.6812C60.1868 33.9556 59.2818 33.6841 58.5561 34.0748Z"
                                fill="#3BBAFF" />
                        </svg>
                        <span class="loginText"> {{ $t('auth.verify') }} </span>
                        <span class="forgetmailText" style="margin-top: 12px;">
                            <span>{{ $t('auth.sendMail') }} </span>
                            <span style="color:#114EF7; font-weight: bold; text-decoration: none; ">{{ email
                            }}</span>
                        </span>
                    </v-window-item>
                </v-window>
                <br>
                <v-btn class="loginp" id="loginBTN" name="loginBTN" block size="x-large" rounded="lg" dark color="#114EF7"
                    text-color="white" @click="validateForm()" :disabled="loader">
                    <span v-if="loader" class="buttonText">
                        <v-progress-circular indeterminate :size="30"></v-progress-circular>
                    </span>
                    <span v-else class="buttonText"> {{ $t('auth.login').toUpperCase() }} </span></v-btn>
                <br>
                <v-row align="justify" style="margin-top: 15px;">
                    <v-col align="justify" v-if="step == 1">
                        <router-link class="backText" to="/" id="ToSignIn" name="ToSignIn">
                            <v-icon>mdi-chevron-left</v-icon> {{ $t('auth.back') }}
                        </router-link>
                    </v-col>
                    <v-col align="justify" v-if="step == 2">
                        <span class="linkText">
                            {{ $t('auth.notRecive') }}
                            <router-link @click="step = 1" class="linkText" id="Resend" name="Resend"
                                style="color:#114EF7; font-weight: bold;" to="/ForgotPassword">
                                {{ $t('auth.resend') }}
                            </router-link>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { SendResetPasswordMail, ExistUserMail } from "@/api/index";
export default {
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.forgetPassword');
    },
    name: "ForgotPassword",
    data: () => ({
        ExistMail: null,
        loader: false,
        step: 1,
        email: '',
        msg: [],
    }),
    methods: {
        validateForm() {
            if (this.step >= 2) {
                this.$router.push({ name: 'login' })
            } else {
                // eslint-disable-next-line
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                    this.loader = true;
                    ExistUserMail(this.email)
                        .then((res) => {
                            this.ExistMail = res.data
                            if (this.ExistMail) {
                                this.msg['email'] = '';
                                SendResetPasswordMail(this.email)
                                    .then(response => {
                                        if (response.status == 200)
                                            this.step = 2;
                                        this.loader = false;
                                    })
                            } else {
                                this.msg['email'] = this.$t('auth.EmailNotExist');
                                this.loader = false;
                            }
                        })
                } else {
                    this.msg['email'] = this.$t('auth.wrongEmail');
                }
            }
        },
    }
}
</script>
<style scoped>
.loginp {
    margin-top: 25px;
}
</style>