<template>
    <v-btn class="button1 text-left" style="cursor: default; pointer-events: none;" variant="flat" rounded="pill"
        :color="color ? color : choosecolor(title)">
        {{ title }}
        <template v-slot:prepend>
            <div v-if="(disableIcon == true)">
                <slot name="icon" />
            </div>
            <div v-else>
                <svg v-if="isCompleted  || isValidated" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.7998 0.5C3.6673 0.5 0.299805 3.8675 0.299805 8C0.299805 12.1325 3.6673 15.5 7.7998 15.5C11.9323 15.5 15.2998 12.1325 15.2998 8C15.2998 3.8675 11.9323 0.5 7.7998 0.5ZM11.3848 6.275L7.1323 10.5275C7.0273 10.6325 6.8848 10.6925 6.7348 10.6925C6.5848 10.6925 6.4423 10.6325 6.33731 10.5275L4.2148 8.405C3.9973 8.1875 3.9973 7.8275 4.2148 7.61C4.4323 7.3925 4.7923 7.3925 5.0098 7.61L6.7348 9.335L10.5898 5.48C10.8073 5.2625 11.1673 5.2625 11.3848 5.48C11.6023 5.6975 11.6023 6.05 11.3848 6.275Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isPending || isSubmitted" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.7998 0.5C3.6673 0.5 0.299805 3.8675 0.299805 8C0.299805 12.1325 3.6673 15.5 7.7998 15.5C11.9323 15.5 15.2998 12.1325 15.2998 8C15.2998 3.8675 11.9323 0.5 7.7998 0.5ZM7.2373 5C7.2373 4.6925 7.4923 4.4375 7.7998 4.4375C8.1073 4.4375 8.3623 4.6925 8.3623 5V8.75C8.3623 9.0575 8.1073 9.3125 7.7998 9.3125C7.4923 9.3125 7.2373 9.0575 7.2373 8.75V5ZM8.48981 11.285C8.4523 11.3825 8.39981 11.4575 8.3323 11.5325C8.2573 11.6 8.1748 11.6525 8.0848 11.69C7.9948 11.7275 7.8973 11.75 7.7998 11.75C7.7023 11.75 7.6048 11.7275 7.5148 11.69C7.4248 11.6525 7.3423 11.6 7.2673 11.5325C7.1998 11.4575 7.1473 11.3825 7.1098 11.285C7.0723 11.195 7.0498 11.0975 7.0498 11C7.0498 10.9025 7.0723 10.805 7.1098 10.715C7.1473 10.625 7.1998 10.5425 7.2673 10.4675C7.3423 10.4 7.4248 10.3475 7.5148 10.31C7.6948 10.235 7.9048 10.235 8.0848 10.31C8.1748 10.3475 8.2573 10.4 8.3323 10.4675C8.39981 10.5425 8.4523 10.625 8.48981 10.715C8.5273 10.805 8.5498 10.9025 8.5498 11C8.5498 11.0975 8.5273 11.195 8.48981 11.285Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isError || isRefused" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.7998 0.5C3.6673 0.5 0.299805 3.8675 0.299805 8C0.299805 12.1325 3.6673 15.5 7.7998 15.5C11.9323 15.5 15.2998 12.1325 15.2998 8C15.2998 3.8675 11.9323 0.5 7.7998 0.5ZM10.3198 9.725C10.5373 9.9425 10.5373 10.3025 10.3198 10.52C10.2073 10.6325 10.0648 10.685 9.9223 10.685C9.7798 10.685 9.6373 10.6325 9.5248 10.52L7.7998 8.795L6.0748 10.52C5.9623 10.6325 5.8198 10.685 5.6773 10.685C5.5348 10.685 5.3923 10.6325 5.2798 10.52C5.0623 10.3025 5.0623 9.9425 5.2798 9.725L7.0048 8L5.2798 6.275C5.0623 6.0575 5.0623 5.6975 5.2798 5.48C5.4973 5.2625 5.8573 5.2625 6.0748 5.48L7.7998 7.205L9.5248 5.48C9.7423 5.2625 10.1023 5.2625 10.3198 5.48C10.5373 5.6975 10.5373 6.0575 10.3198 6.275L8.59481 8L10.3198 9.725Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isActive" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9324 2.71988C9.99995 -0.212617 5.23745 -0.212617 2.30495 2.71988C-0.68005 5.70488 -0.62755 10.5724 2.45495 13.4974C5.30495 16.1899 9.92495 16.1899 12.7749 13.4974C15.8649 10.5724 15.9174 5.70488 12.9324 2.71988ZM10.8849 11.4874C9.98495 12.3424 8.79995 12.7699 7.61495 12.7699C6.42995 12.7699 5.24495 12.3424 4.34495 11.4874C4.11995 11.2699 4.11245 10.9174 4.32245 10.6924C4.53995 10.4674 4.89245 10.4599 5.11745 10.6699C6.48995 11.9674 8.73245 11.9749 10.1124 10.6699C10.3374 10.4599 10.6974 10.4674 10.9074 10.6924C11.1249 10.9174 11.1099 11.2699 10.8849 11.4874Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isOpen" width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.9375 6.86227C14.205 4.13977 11.67 2.57227 9 2.57227C7.665 2.57227 6.3675 2.96227 5.1825 3.68977C3.9975 4.42477 2.9325 5.49727 2.0625 6.86227C1.3125 8.03977 1.3125 9.95227 2.0625 11.1298C3.795 13.8598 6.33 15.4198 9 15.4198C10.335 15.4198 11.6325 15.0298 12.8175 14.3023C14.0025 13.5673 15.0675 12.4948 15.9375 11.1298C16.6875 9.95977 16.6875 8.03977 15.9375 6.86227ZM9 12.0298C7.32 12.0298 5.97 10.6723 5.97 8.99977C5.97 7.32727 7.32 5.96977 9 5.96977C10.68 5.96977 12.03 7.32727 12.03 8.99977C12.03 10.6723 10.68 12.0298 9 12.0298Z"
                        fill="white" />
                    <path
                        d="M8.9998 6.85547C7.8223 6.85547 6.8623 7.81547 6.8623 9.00047C6.8623 10.178 7.8223 11.138 8.9998 11.138C10.1773 11.138 11.1448 10.178 11.1448 9.00047C11.1448 7.82297 10.1773 6.85547 8.9998 6.85547Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isClosed" width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.9526 6.88473C15.7351 6.53973 15.5026 6.21723 15.2626 5.91723C14.9851 5.56473 14.4601 5.53473 14.1451 5.84973L11.8951 8.09973C12.0601 8.59473 12.0901 9.16473 11.9401 9.75723C11.6776 10.8147 10.8226 11.6697 9.76508 11.9322C9.17258 12.0822 8.60258 12.0522 8.10758 11.8872C8.10758 11.8872 7.03508 12.9597 6.26258 13.7322C5.88758 14.1072 6.00758 14.7672 6.51008 14.9622C7.31258 15.2697 8.14508 15.4272 9.00008 15.4272C10.3351 15.4272 11.6326 15.0372 12.8176 14.3097C14.0251 13.5597 15.1126 12.4572 15.9901 11.0547C16.7026 9.92223 16.6651 8.01723 15.9526 6.88473Z"
                        fill="white" />
                    <path
                        d="M10.5155 7.48547L7.48547 10.5155C7.10297 10.1255 6.85547 9.58547 6.85547 9.00047C6.85547 7.82297 7.81547 6.85547 9.00047 6.85547C9.58547 6.85547 10.1255 7.10297 10.5155 7.48547Z"
                        fill="white" />
                    <path
                        d="M13.6875 4.31227L11.145 6.85477C10.5975 6.29977 9.84 5.96977 9 5.96977C7.32 5.96977 5.97 7.32726 5.97 8.99976C5.97 9.83976 6.3075 10.5973 6.855 11.1448L4.32 13.6873H4.3125C3.48 13.0123 2.715 12.1498 2.0625 11.1298C1.3125 9.95226 1.3125 8.03976 2.0625 6.86226C2.9325 5.49726 3.9975 4.42477 5.1825 3.68977C6.3675 2.96977 7.665 2.57227 9 2.57227C10.6725 2.57227 12.2925 3.18727 13.6875 4.31227Z"
                        fill="white" />
                    <path
                        d="M11.1446 8.99957C11.1446 10.1771 10.1846 11.1446 8.99957 11.1446C8.95457 11.1446 8.91707 11.1446 8.87207 11.1296L11.1296 8.87207C11.1446 8.91707 11.1446 8.95457 11.1446 8.99957Z"
                        fill="white" />
                    <path
                        d="M16.3277 1.67266C16.1027 1.44766 15.7352 1.44766 15.5102 1.67266L1.67266 15.5177C1.44766 15.7427 1.44766 16.1102 1.67266 16.3352C1.78516 16.4402 1.92766 16.5002 2.07766 16.5002C2.22766 16.5002 2.37016 16.4402 2.48266 16.3277L16.3277 2.48266C16.5602 2.25766 16.5602 1.89766 16.3277 1.67266Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isInactive" width="19" height="18" viewBox="0 0 19 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.1475 4.1475L4.6475 14.6475C4.6325 14.6625 4.625 14.67 4.61 14.6775C4.325 14.4375 4.0625 14.175 3.8225 13.89C2.6825 12.5775 2 10.8675 2 9C2 4.86 5.36 1.5 9.5 1.5C11.3675 1.5 13.0775 2.1825 14.39 3.3225C14.675 3.5625 14.9375 3.825 15.1775 4.11C15.17 4.125 15.1625 4.1325 15.1475 4.1475Z"
                        fill="white" />
                    <path
                        d="M16.9997 8.99965C16.9997 13.1172 13.6172 16.4997 9.49965 16.4997C8.37465 16.4997 7.30965 16.2522 6.34965 15.8022C5.88465 15.5847 5.79465 14.9621 6.15465 14.5947L15.0947 5.65465C15.4546 5.29465 16.0847 5.38465 16.3022 5.84965C16.7522 6.80215 16.9997 7.87465 16.9997 8.99965Z"
                        fill="white" />
                    <path
                        d="M16.8277 1.67266C16.6027 1.44766 16.2352 1.44766 16.0102 1.67266L2.17266 15.5177C1.94766 15.7427 1.94766 16.1102 2.17266 16.3352C2.28516 16.4402 2.42766 16.5002 2.57766 16.5002C2.72766 16.5002 2.87016 16.4402 2.98266 16.3277L16.8277 2.48266C17.0602 2.25766 17.0602 1.89766 16.8277 1.67266Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isPaid" width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4"
                        d="M16.5 5.8273V6.7498H1.5V5.6548C1.5 3.9373 2.895 2.5498 4.6125 2.5498H12V4.4773C12 5.4298 12.57 5.9998 13.5225 5.9998H15.7275C16.0275 5.9998 16.2825 5.9473 16.5 5.8273Z"
                        fill="white" />
                    <path
                        d="M1.5 6.75V12.345C1.5 14.0625 2.895 15.45 4.6125 15.45H13.3875C15.105 15.45 16.5 14.0625 16.5 12.345V6.75H1.5ZM6 12.9375H4.5C4.1925 12.9375 3.9375 12.6825 3.9375 12.375C3.9375 12.0675 4.1925 11.8125 4.5 11.8125H6C6.3075 11.8125 6.5625 12.0675 6.5625 12.375C6.5625 12.6825 6.3075 12.9375 6 12.9375ZM10.875 12.9375H7.875C7.5675 12.9375 7.3125 12.6825 7.3125 12.375C7.3125 12.0675 7.5675 11.8125 7.875 11.8125H10.875C11.1825 11.8125 11.4375 12.0675 11.4375 12.375C11.4375 12.6825 11.1825 12.9375 10.875 12.9375Z"
                        fill="white" />
                    <path
                        d="M15.7275 0.75H13.5225C12.57 0.75 12 1.32 12 2.2725V4.4775C12 5.43 12.57 6 13.5225 6H15.7275C16.68 6 17.25 5.43 17.25 4.4775V2.2725C17.25 1.32 16.68 0.75 15.7275 0.75ZM16.4325 3.6975C16.3575 3.7725 16.245 3.825 16.125 3.8325H15.0675L15.075 4.875C15.0675 5.0025 15.0225 5.1075 14.9325 5.1975C14.8575 5.2725 14.745 5.325 14.625 5.325C14.3775 5.325 14.175 5.1225 14.175 4.875V3.825L13.125 3.8325C12.8775 3.8325 12.675 3.6225 12.675 3.375C12.675 3.1275 12.8775 2.925 13.125 2.925L14.175 2.9325V1.8825C14.175 1.635 14.3775 1.425 14.625 1.425C14.8725 1.425 15.075 1.635 15.075 1.8825L15.0675 2.925H16.125C16.3725 2.925 16.575 3.1275 16.575 3.375C16.5675 3.5025 16.515 3.6075 16.4325 3.6975Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isDraft" width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9324 2.71988C9.99995 -0.212617 5.23745 -0.212617 2.30495 2.71988C-0.68005 5.70488 -0.62755 10.5724 2.45495 13.4974C5.30495 16.1899 9.92495 16.1899 12.7749 13.4974C15.8649 10.5724 15.9174 5.70488 12.9324 2.71988ZM10.8849 11.4874C9.98495 12.3424 8.79995 12.7699 7.61495 12.7699C6.42995 12.7699 5.24495 12.3424 4.34495 11.4874C4.11995 11.2699 4.11245 10.9174 4.32245 10.6924C4.53995 10.4674 4.89245 10.4599 5.11745 10.6699C6.48995 11.9674 8.73245 11.9749 10.1124 10.6699C10.3374 10.4599 10.6974 10.4674 10.9074 10.6924C11.1249 10.9174 11.1099 11.2699 10.8849 11.4874Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isDisplay " width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9324 2.71988C9.99995 -0.212617 5.23745 -0.212617 2.30495 2.71988C-0.68005 5.70488 -0.62755 10.5724 2.45495 13.4974C5.30495 16.1899 9.92495 16.1899 12.7749 13.4974C15.8649 10.5724 15.9174 5.70488 12.9324 2.71988ZM10.8849 11.4874C9.98495 12.3424 8.79995 12.7699 7.61495 12.7699C6.42995 12.7699 5.24495 12.3424 4.34495 11.4874C4.11995 11.2699 4.11245 10.9174 4.32245 10.6924C4.53995 10.4674 4.89245 10.4599 5.11745 10.6699C6.48995 11.9674 8.73245 11.9749 10.1124 10.6699C10.3374 10.4599 10.6974 10.4674 10.9074 10.6924C11.1249 10.9174 11.1099 11.2699 10.8849 11.4874Z"
                        fill="white" />
                </svg>
                <svg v-else-if="isUnavailable || isInitialized" width="19" height="18" viewBox="0 0 19 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.1475 4.1475L4.6475 14.6475C4.6325 14.6625 4.625 14.67 4.61 14.6775C4.325 14.4375 4.0625 14.175 3.8225 13.89C2.6825 12.5775 2 10.8675 2 9C2 4.86 5.36 1.5 9.5 1.5C11.3675 1.5 13.0775 2.1825 14.39 3.3225C14.675 3.5625 14.9375 3.825 15.1775 4.11C15.17 4.125 15.1625 4.1325 15.1475 4.1475Z"
                        fill="white" />
                    <path
                        d="M16.9997 8.99965C16.9997 13.1172 13.6172 16.4997 9.49965 16.4997C8.37465 16.4997 7.30965 16.2522 6.34965 15.8022C5.88465 15.5847 5.79465 14.9621 6.15465 14.5947L15.0947 5.65465C15.4546 5.29465 16.0847 5.38465 16.3022 5.84965C16.7522 6.80215 16.9997 7.87465 16.9997 8.99965Z"
                        fill="white" />
                    <path
                        d="M16.8277 1.67266C16.6027 1.44766 16.2352 1.44766 16.0102 1.67266L2.17266 15.5177C1.94766 15.7427 1.94766 16.1102 2.17266 16.3352C2.28516 16.4402 2.42766 16.5002 2.57766 16.5002C2.72766 16.5002 2.87016 16.4402 2.98266 16.3277L16.8277 2.48266C17.0602 2.25766 17.0602 1.89766 16.8277 1.67266Z"
                        fill="white" />
                </svg>
            </div>
        </template>
    </v-btn>
</template>
<script>
import { status } from "@/enums/Status";
export default {
    name: 'StatusButton',
    props: {
        title: {
            type: String,
            default: null,
        },
        disableIcon: {
            type: Boolean,
            default: false
        },
        color: {
            default: null,
        }
    },
    methods: {
        choosecolor(title) {
            switch (title) {
                case localStorage.getItem(status.created + this.$i18n.locale):
                    {
                        return "#BEBEBE"
                    }
                case localStorage.getItem(status.Completed + this.$i18n.locale):
                    {
                        return "#00CBA0"
                    }
                case localStorage.getItem(status.Initialized + this.$i18n.locale):
                    {
                        return "#BEBEBE"
                    }
                case localStorage.getItem(status.Pending + this.$i18n.locale):
                    {
                        return "#FF8934"
                    }
                case localStorage.getItem(status.Error + this.$i18n.locale):
                    {
                        return "#F25C5C"
                    }
                case localStorage.getItem(status.Active + this.$i18n.locale):
                    {
                        return "#3BBAFF"
                    }
                case localStorage.getItem(status.Closed + this.$i18n.locale):
                    {
                        return "#F25C5C"
                    }
                case localStorage.getItem(status.Open + this.$i18n.locale):
                    {
                        return "#00CBA0"
                    }
                case localStorage.getItem(status.Inactive + this.$i18n.locale):
                    {
                        return "#F25C5C"
                    }
                case localStorage.getItem(status.Paid + this.$i18n.locale):
                    {
                        return "#00CBA0"
                    }
                case localStorage.getItem(status.Submitted + this.$i18n.locale):
                    {
                        return "#FFDF00"
                    }
                case localStorage.getItem(status.Refused + this.$i18n.locale):
                    {
                        return "#F25C5C"
                    }
                case localStorage.getItem(status.Draft + this.$i18n.locale):
                    {
                        return "#3BBAFF"
                    }
                case localStorage.getItem(status.Display + this.$i18n.locale):
                    {
                        return "#3BBAFF"
                    }
                case localStorage.getItem(status.Unavailable + this.$i18n.locale):
                    {
                        return "#F25C5C"
                    }
                case this.$t('suppliers.validationTab.status.validatedByClient2'):
                    {
                        return "#00CBA0"
                    }
                default:
                    {
                        return "#3BBAFF"
                    }
            }
        },
    },
    computed: {
        isInitialized() {
            return this.title === localStorage.getItem(status.Initialized + this.$i18n.locale);
        },
        isActive() {
            return this.title === localStorage.getItem(status.Active + this.$i18n.locale);
        },
        isInactive() {
            return this.title === localStorage.getItem(status.Inactive + this.$i18n.locale);
        },
        isOpen() {
            return this.title === localStorage.getItem(status.Open + this.$i18n.locale);
        },
        isClosed() {
            return this.title === localStorage.getItem(status.Closed + this.$i18n.locale);
        },
        isRefused() {
            return this.title === localStorage.getItem(status.Refused + this.$i18n.locale);
        },
        isValidated() {
            return this.title === localStorage.getItem(status.Validated + this.$i18n.locale);
        },
        isCompleted() {
            return this.title === localStorage.getItem(status.Completed + this.$i18n.locale);
        },
        isPending() {
            return this.title === localStorage.getItem(status.Pending + this.$i18n.locale);
        },
        isError() {
            return this.title === localStorage.getItem(status.Error + this.$i18n.locale);
        },
        isSubmitted() {
            return this.title === localStorage.getItem(status.Submitted + this.$i18n.locale);
        },
        isPaid() {
            return this.title === localStorage.getItem(status.Paid + this.$i18n.locale);
        },
        isDraft() {
            return this.title === localStorage.getItem(status.Draft + this.$i18n.locale);
        },
        isDisplay() {
            return this.title === localStorage.getItem(status.Display + this.$i18n.locale);
        },
        isUnavailable() {
            return this.title === localStorage.getItem(status.Unavailable + this.$i18n.locale);
        }
    },

}
</script>
<style>
.button1 {
    color: white;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-align: left;
}
</style>