<template>
    <VueDatePicker :model-value="date" @update:model-value="newValue => date = newValue" hide-input-icon
        :format-locale="local" :format="format" auto-apply placeholder="MM:DD:YYYY" no-today :enable-time-picker="false"
        :offset="10" menu-class-name="v-card menu-calender" calendar-cell-class-name="dp-custom-cell" position="center" :min-date="minDate">
        <template #trigger>
            <v-text-field :model-value="format.toUpperCase()" variant="outlined" persistent-placeholder single-line
                hide-details append-inner-icon="mdi-chevron-down" class="dp-custom-input" readonly>
                <template #prepend-inner>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75023V2C8.75023 1.59 8.41023 1.25 8.00023 1.25C7.59023 1.25 7.25023 1.59 7.25023 2V3.56C4.55023 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56Z"
                            fill="#C5CDD4" />
                        <path
                            d="M20 9.83984H4C3.45 9.83984 3 10.2898 3 10.8398V16.9998C3 19.9998 4.5 21.9998 8 21.9998H16C19.5 21.9998 21 19.9998 21 16.9998V10.8398C21 10.2898 20.55 9.83984 20 9.83984ZM9.21 18.2098C9.11 18.2998 9 18.3698 8.88 18.4198C8.76 18.4698 8.63 18.4998 8.5 18.4998C8.37 18.4998 8.24 18.4698 8.12 18.4198C8 18.3698 7.89 18.2998 7.79 18.2098C7.61 18.0198 7.5 17.7598 7.5 17.4998C7.5 17.2398 7.61 16.9798 7.79 16.7898C7.89 16.6998 8 16.6298 8.12 16.5798C8.36 16.4798 8.64 16.4798 8.88 16.5798C9 16.6298 9.11 16.6998 9.21 16.7898C9.39 16.9798 9.5 17.2398 9.5 17.4998C9.5 17.7598 9.39 18.0198 9.21 18.2098ZM9.42 14.3798C9.37 14.4998 9.3 14.6098 9.21 14.7098C9.11 14.7998 9 14.8698 8.88 14.9198C8.76 14.9698 8.63 14.9998 8.5 14.9998C8.37 14.9998 8.24 14.9698 8.12 14.9198C8 14.8698 7.89 14.7998 7.79 14.7098C7.7 14.6098 7.63 14.4998 7.58 14.3798C7.53 14.2598 7.5 14.1298 7.5 13.9998C7.5 13.8698 7.53 13.7398 7.58 13.6198C7.63 13.4998 7.7 13.3898 7.79 13.2898C7.89 13.1998 8 13.1298 8.12 13.0798C8.36 12.9798 8.64 12.9798 8.88 13.0798C9 13.1298 9.11 13.1998 9.21 13.2898C9.3 13.3898 9.37 13.4998 9.42 13.6198C9.47 13.7398 9.5 13.8698 9.5 13.9998C9.5 14.1298 9.47 14.2598 9.42 14.3798ZM12.71 14.7098C12.61 14.7998 12.5 14.8698 12.38 14.9198C12.26 14.9698 12.13 14.9998 12 14.9998C11.87 14.9998 11.74 14.9698 11.62 14.9198C11.5 14.8698 11.39 14.7998 11.29 14.7098C11.11 14.5198 11 14.2598 11 13.9998C11 13.7398 11.11 13.4798 11.29 13.2898C11.39 13.1998 11.5 13.1298 11.62 13.0798C11.86 12.9698 12.14 12.9698 12.38 13.0798C12.5 13.1298 12.61 13.1998 12.71 13.2898C12.89 13.4798 13 13.7398 13 13.9998C13 14.2598 12.89 14.5198 12.71 14.7098Z"
                            fill="#C5CDD4" />
                    </svg>
                </template>
            </v-text-field>
        </template>
    </VueDatePicker>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { fr, enUS } from 'date-fns/locale';
export default {
    components: { VueDatePicker },
    props: {
        modelValue: {},
        minDate: { type: Date, default: Date("2020-01-01") },
        max: { default: "2099-12-31" },
    },
    data() {
        return {
            date: null,
        };
    },
    mounted() {
        this.setDate()
    },
    methods: {
        setDate() {
            this.date = this.$props.modelValue != null ? new Date(this.$props.modelValue) : null;
        }
    },
    watch: {
        modelValue() {
            this.date = this.$props.modelValue != null ? new Date(this.$props.modelValue) : null;
        },
        date() {
            const date = this.date != null ? new Date(this.date+ " UTC").toISOString().split('T')[0] : null;
            this.$emit('update:modelValue', date)
        },
    },
    computed: {
        local() {
            switch (this.$i18n.locale) {
                case 'fr': return fr;
                case 'en': return enUS;
                default: return enUS;
            }
        },
        format() {
            if (this.date) {
                var year = this.date.getFullYear();
                var month = String(this.date.getMonth() + 1).padStart(2, '0');
                var day = String(this.date.getDate()).padStart(2, '0');
                return day + ':' + month + ':' + year;
            } else {
                return `dd:MM:yyyy`;
            }
        }
    }
}
</script>

<style>
.dp-custom-cell {
    border-radius: 50%;
}

.dp-custom-input {
    cursor: pointer !important;
}

.menu-calender{
    position: fixed;
}
</style>