import jwt_decode from "jwt-decode";
import router from '@/router/router'
import { login, register, VerifToken } from "@/api/index";
import axios from "axios";
export default {
  async login(context, payload) {
    await login(payload).then((res) => {
        let token = res.data;
        localStorage.setItem('accessToken', token);
        var decoded = jwt_decode(token);
        localStorage.setItem('Name', decoded.UserName);
        localStorage.setItem('UserId', decoded.UserId);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        context.commit('setUser', {
          token: token,
          userName: decoded.UserName
        });
        VerifToken()
        router.push('/PurchaseOrder')
    })
  },

  tryLogin(context) {
    const token = localStorage.getItem('accessToken');
    const userName = localStorage.getItem('Name');
    if (token && userName) {
      context.commit('setUser', {
        token: token,
        userName: userName
      });
    }
  },
  logout(context) {
    context.commit('setUser', {
      token: null,
      userName: null
    });
  },
  async signup(_, payload) {
    await register(payload)
  },
};