<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['15%', '15%', '18%', '15%']" :GetFunction="GetDocumentslist">
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('suppliers.documentsTab.headers.documentName')" :Filter="FieldName"
                :getList="GetDocumentslist" @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('suppliers.documentsTab.headers.date')" :Filter="FieldDeadline"
                :getList="GetDocumentslist" @cancel="FieldDeadline = null">
                <template v-slot:input>
                    <dateField v-model="FieldDeadline" :minDate="new Date()"/>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="this.$t('suppliers.documentsTab.headers.status')" :Filter="FieldStatus"
                :getList="GetDocumentslist" @cancel="FieldStatus = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldStatus" inline style="margin-top:6px ;">
                        <v-radio :label="isActive" :value=parseInt(0) id="Active" name="Active"></v-radio>
                        <v-radio :label="isInactive" :value=parseInt(1) id="InActive" name="InActive"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('suppliers.creationDate')" :Filter="FieldCreatedDate" :getList="GetDocumentslist"
                @cancel="FieldCreatedDate = null">
                <template v-slot:input>
                    <span lang="en-CA" xml:lang="en-CA">
                        <dateField v-model="FieldCreatedDate" />
                    </span>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:item0="{ item }">{{ item.name }}</template>
        <template v-slot:item1="{ item }">{{ item.expiryDate.split("T")[0] }}</template>
        <template v-slot:item2="{ item }">
            <StatusButton v-if="item" :title="statut(item)" />
        </template>
        <template v-slot:item3="{ item }">{{ item.created.split('T')[0] }}</template>
        <template v-slot:edit="{ item, index }">
            <EditDocument :Id="index" :document="item" v-bind:getDocumentslist="GetDocumentslist" />
        </template>
    </gridComponent>
</template>
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import { getDocumentslist, GetStatusDocuments, isSupplier, idSupplier } from "@/api/index";
import StatusButton from "@/genericComponent/StatusButton.vue";
import EditDocument from "./SupplierDocumentEditMenu.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: {
        gridComponent,
        EditDocument,
        StatusButton,
        ColumnFilter,
        dateField
    },
    props: {
        idCompany: String,
    },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: null,
            idSupplier: null,
            itemsData: {},
            search: null,
            FieldName: null,
            FieldDeadline: null,
            FieldStatus: null,
            FieldCreatedDate: null,
            dialogCompose: false,
            SortedBy: null,
            SortingType: null,
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
        this.GetStatusDocuments();
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.GetDocumentslist();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        GetDocumentslist: function (page) {
            if(page){ this.page = page }
            getDocumentslist(this.page, this.PageSize, this.idSupplier, this.FieldName == null ? this.search : this.FieldName, this.FieldDeadline, this.FieldStatus, this.FieldCreatedDate, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items;
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            })
        },
        GetStatusDocuments() {
            GetStatusDocuments().then(response => {
                localStorage.setItem(status.Active + this.$i18n.availableLocales[0], response.data.Active.en);
                localStorage.setItem(status.Active + this.$i18n.availableLocales[1], response.data.Active.fr);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[0], response.data.Inactive.en);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[1], response.data.Inactive.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => {
                switch (item.status) {
                    case 0: return localStorage.getItem(status.Active + this.$i18n.locale);
                    case 1: return localStorage.getItem(status.Inactive + this.$i18n.locale);
                    default: return localStorage.getItem(status.Inactive + this.$i18n.locale);
                }
            };
        },
        headers() {
            return [
                this.$t('suppliers.documentsTab.headers.documentName'),
                this.$t('suppliers.documentsTab.headers.date'),
                this.$t('suppliers.documentsTab.headers.status'),
                this.$t('suppliers.creationDate'),
            ]
        },
        isActive() {
            return localStorage.getItem(status.Active + this.$i18n.locale);
        },
        isInactive() {
            return localStorage.getItem(status.Inactive + this.$i18n.locale);
        },
    }
};
</script>