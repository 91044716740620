<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['45%', '45%', '8%']" :GetFunction="getDepartmentList">
        <template v-slot:item0="{ item }">{{ item.departmentName }}</template>
        <template v-slot:item1="{ item }">
            <flag-country v-if="item.locationCountryName" :locationName="item.locationName" :countryProps=item.locationCountryName
                key="item.locationCountryName"></flag-country>
            <v-chip v-else color="red">
                {{ $t('configuration.department.delete') }}
            </v-chip>
        </template>
        <template v-slot:edit="{ item, index }">
            <edit-department :Id="index" v-bind:idCompany="item.idCompany" v-bind:idDepartment="item.idDepartment"
                v-bind:getDepartmentListAfterUpdate="getDepartmentList" v-bind:item="item" />
        </template>
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('configuration.department.departmentName')" :Filter="DepartmentFilters.DepartmentName"
                :getList="getDepartmentList" @cancel="delete DepartmentFilters.DepartmentName">
                <template v-slot:input>
                    <v-text-field v-model="DepartmentFilters.DepartmentName" id="FieldDepartmentName" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('configuration.department.locationCoverage')"
                :Filter="DepartmentFilters.LocationCoverage" :getList="getDepartmentList"
                @cancel="delete DepartmentFilters.LocationCoverage">
                <template v-slot:input>
                    <v-text-field v-model="DepartmentFilters.LocationCoverage" id="FieldLocationCoverage" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
    </gridComponent>
</template>
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import { getDepartment } from "@/api/index";
import EditDepartment from '../companiesEdit/EditDepartment.vue';
import FlagCountry from "../SelectCountry/FlagCountry.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
export default {
    components: { gridComponent, EditDepartment, FlagCountry, ColumnFilter },
    props: {
        idCompany: String,
    },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: [],
            itemsData: {},
            SortedBy: "",
            SortingType: "ASC",
            DepartmentFilters: {},
        };
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.DepartmentFilters.DepartmentName = event;
            this.getDepartmentList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getDepartmentList: function (page) {
            if(page){ this.page = page }
            getDepartment(this.page, this.PageSize, this.DepartmentFilters, this.SortedBy, this.SortingType)
                .then(response => {
                    this.items = response.data.items;
                    this.itemsData.numberOfPage = response.data.totalPages;
                    this.itemsData.totalItemsCount = response.data.totalCount;
                    this.itemsData.pageNumber = response.data.pageNumber + 0;
                    this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                    this.itemsData.hasNextPage = response.data.hasNextPage
                    this.loader = false;
                });
        },
    },
    computed: {
        headers() {
            return [this.$t('configuration.department.departmentName'),
            this.$t('configuration.department.locationCoverage')
            ]
        }
    }
};
</script>