<template>
    <v-card-actions class="justify-center">
        <genericBtnComponent :loader="loader" class="yesDelete" :ClickFun="yes" customClass="yesDelete" :width="width" height="50px;"
            fontWeight="600" :backgroundColor="YesColor" color="#fff" variant="outlined" :Title=yesTitle :disabled="yesDisable"/>
        <genericBtnComponent class="Cancel" @Click="$emit('update:no', false)" :ClickFun="noFun" customClass="Cancel" :width="width"
            height="50px;" fontWeight="500" backgroundColor="#fff" color="#768da9" variant="outlined" :Title="cancel" />
    </v-card-actions>
</template>
<script>
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    name: "cardAction",
    components: {
        genericBtnComponent
    },
    props: {
        YesColor : { default: '#114ef7' },
        CustomClass: { default: 'Cancel' },
        yes: { type: Function },
        no: { type: Boolean },
        noFun: { type: Function },
        width: { default: "220px" },
        yesTitle: { default: "Yes, Delete" },
        loader: { type: Boolean, default: false, },
        yesDisable: { type: Boolean, default: false, }
    },
    computed: {
        cancel() {
            return this.$t("actions.cancel")
        }
    }
};
</script>
<style scoped>
.yesDelete {
    background-color: #114ef7;
    font-weight: 600;
    color: #fff;
}

.Cancel {
    font-weight: 500;
    color: #768da9;
}

.v-card-actions {
    padding: 0px;
}
</style>