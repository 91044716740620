<template>
  <v-dialog v-model="dialog2">
    <v-card width="800px" class="py-6">
      <h1>{{ $t('configuration.location.add') }}</h1>
      <div class="row ma-4 mt-3 mb-3">
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.location.locationName') }} <span class="red">*</span></v-card-title>
          </div>
          <div class="row">
            <v-text-field maxlength="20" hide-details persistent-placeholder
              :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.locationName') })" variant="outlined"
              v-model="locationName" id="locationName" name="locationName"></v-text-field>
            <span class='warningHint' id="WarningMsglocationName">{{ msg.locationName }}</span>
          </div>
        </div>
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.location.currency') }} <span class="red">*</span></v-card-title>
          </div>
          <div class="row">
            <v-autocomplete id="currency" name="currency" style="padding-left: 12px;padding-right: 12px;"
              variant="outlined" v-model="currency" persistent-placeholder
              :placeholder="$t('placeholder.select', { itm: $t('configuration.location.currency') })" :items="itemsCurrency"
              item-title="name" item-value="name" hide-details>
              <template v-slot:item="{ props, item, index }">
                <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                  {{ item.name }}
                </v-list-item>
              </template>
            </v-autocomplete>
            <span class='warningHint' id="WarningMsgCurrency">{{ msg.currency }} </span>
          </div>
        </div>
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.location.address') }} <span class="red">*</span></v-card-title>
          </div>
          <div class="row">
            <v-text-field hide-details maxlength="50" persistent-placeholder
              :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.address') })" variant="outlined"
              v-model="address" id="address" name="address"></v-text-field>
            <span class='warningHint' id="WarningMsgAddress">{{ msg.address }}</span>
          </div>
        </div>
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.location.city') }} <span class="red">*</span></v-card-title>
          </div>
          <div class="row">
            <v-text-field hide-details maxlength="20" persistent-placeholder
              :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.city') })" variant="outlined"
              v-model="city" id="city" name="city"></v-text-field>
            <span class='warningHint' id="WarningMsgCity">{{ msg.city }}</span>
          </div>
        </div>
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.location.postalCode') }} <span class="red">*</span></v-card-title>
          </div>
          <div class="row">
            <v-text-field maxlength="9" hide-details persistent-placeholder
              :placeholder="$t('placeholder.enter', { itm: $t('configuration.location.postalCode') })" variant="outlined"
              v-model="postalCode" @update:model-value="postalCode = postalCode.replace(/[^0-9.]/g, '');" id="postalCode"
              name="postalCode"></v-text-field>
            <span class='warningHint' id="WarningMsgPostalCode">{{ msg.postalCode }}</span>
          </div>
        </div>
        <div class="col-6 justify-content-start">
          <div class="row">
            <v-card-title class="text-left ma-0">{{ $t('configuration.location.country') }} <span class="red">*</span></v-card-title>
          </div>
          <div class="row">
            <select-country v-bind:countryProps="country" @getCountry="getCountryFromSelect"
              v-bind:width="450"></select-country>
            <span class='warningHint' id="WarningMsgCountry">{{ msg.country }}</span>
          </div>
        </div>
      </div>
      <card-actions :yes="validateForm" v-model:no="dialog2" width="300px" :yesTitle="$t('configuration.location.add')" />
    </v-card>
  </v-dialog>
</template>
<script>
import SelectCountry from '../SelectCountry/SelectCountry.vue';
import currencyJson from "@/data/DevisesList"
import { addLocation } from "@/api/index";
import cardActions from "@/genericComponent/cardActions.vue"
export default {
  components: { SelectCountry, cardActions },
  data() {
    return {
      itemsCurrency: currencyJson,
      msg: [],
      idCompany: this.$route.params.id,
      status: true,
      locationName: "",
      address: "",
      postalCode: "",
      currency: null,
      city: "",
      country: null,
      dialog2: true,
    }
  },
  watch: {
    dialog2(value) {
      this.dialog2 = value
      this.$emit('add-location-close-dialog')
      this.dialog2 = true
      this.locationName = null
      this.currency = null
      this.address = null
      this.city = null
      this.postalCode = null
      this.country = null
      this.msg = []
    }
  },
  methods: {
    ActiveDeletedSnackBar() {
      this.getParent('MainLayout').ActiveDeletedSnackBar();
    },
    ActiveWarningSnackBar() {
      this.getParent('MainLayout').ActiveWarningSnackBar();
    },
    ActiveSavedSnackBar() {
      this.getParent('MainLayout').ActiveSavedSnackBar();
    },
    getParent(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name == name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return false;
    },
    getCountryFromSelect(value) {
      this.country = value;
    },
    AddLocation() {
      this.$emit("add-location-close-dialog");
      addLocation(
        this.idCompany,
        this.locationName,
        this.address,
        this.postalCode,
        this.currency,
        this.city,
        this.country,
      )
        .then(() => {
          this.ActiveSavedSnackBar();
          this.$emit("reloadListLocation");
        })
        .catch(() => {
          this.ActiveWarningSnackBar();
        });
      setTimeout(() => {
        this.locationName = null;
        this.address = null;
        this.postalCode = null;
        this.currency = null;
        this.city = null;
        this.country = null;
      }, 2000);
    },
    validateForm() {
      if (!this.locationName || this.locationName.length == 0) {
        this.msg["locationName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.locationName') });
      } else {
        this.msg["locationName"] = "";
      }
      if (!this.address || this.address.length == 0) {
        this.msg["address"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.address') });
      } else {
        this.msg["address"] = "";
      }
      if (!this.postalCode || this.postalCode.length == 0) {
        this.msg["postalCode"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.postalCode') });
      } else {
        this.msg["postalCode"] = "";
      }
      if (!this.city || this.city.length == 0) {
        this.msg["city"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.city') });
      } else {
        this.msg["city"] = "";
      }
      if (this.currency == null) {
        this.msg["currency"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.currency') });
      } else {
        this.msg["currency"] = "";
      }
      if (this.country == null || this.country == "") {
        this.msg["country"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.location.country') });
      } else {
        this.msg["country"] = "";
      }
      if (
        this.msg["locationName"] == "" &&
        this.msg["address"] == "" &&
        this.msg["postalCode"] == "" &&
        this.msg["city"] == "" &&
        this.msg["currency"] == "" &&
        this.msg["country"] == ""
      ) {
        this.AddLocation();
      }
    },
  },
}
</script>
<style scoped>
h1 {
  font-family: inter;
  font-size: 18px;
  line-height: 29px;
  font-weight: 600;
  color: #0d0849;
  text-align: center;
}

.v-card-title {
  font-family: inter;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #0d0849;
}

.v-card-title {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #5b6d8e;
}

.v-card-text {
  margin-left: 40px;
  font-family: inter;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #768da9;
}
</style>