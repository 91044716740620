<template>
    <span v-if="OptimaApplicationLoader">
        <br><br><br><br><br>
        <v-progress-circular indeterminate :size="50"></v-progress-circular>
        <br><br><br><br><br>
    </span>
    <div v-else>
        <div class="row ma-4 justify-content-center">
            <span v-if="activePermissionsItems.length == 0" class="selectHint" style="color: #F25C5C;">{{
                $t('permissions.mustSelectPermissions') }}</span>
            <span v-else class="selectHint">{{ activePermissionsItems.length }}
                {{ $t('permissions.permissionsSelected') }}</span>
        </div>
        <div class="row ma-4">
            <div class="col-6 justify-content-start">
                <v-card>
                    <v-list two-line subheader class=" justify-content-start">
                        <v-list-item v-for="(item) in scopesItems" @click="toggleActiveScopes(item)"
                            :class="{ active: activeScopesItems.indexOf(item) > -1 }" :key="item.title">
                            <v-list-item-content>
                                <v-list-item-title v-text="ApplicationModuleName(item)"></v-list-item-title>
                            </v-list-item-content>
                            <template v-slot:append>
                                <v-icon v-if="activeScopesItems.indexOf(item) > -1" icon="mdi-chevron-right"></v-icon>
                                <v-badge v-else-if="SelectedPermissionsInScope(item.features) > 0" color="info"
                                    :content="SelectedPermissionsInScope(item.features)" inline></v-badge>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
            <div class="col-6 justify-content-start">
                <v-card v-if="permissionsItems.length > 0">
                    <v-list two-line subheader>
                        <v-list-item v-if="permissionsItems.length > 1" style="height: 36px;"
                            @click="toggleActiveAllPermissionsInScope(activeScopesItems[0].features)"
                            :class="{ active: IsActiveAllPermissionsInScope(activeScopesItems[0].features) }">
                            <v-list-item-content>
                                <v-list-item-title v-text="$t('permissions.allPermissions')"></v-list-item-title>
                            </v-list-item-content>
                            <template v-slot:append>
                                <v-icon
                                    v-if="IsActiveAllPermissionsInScope(activeScopesItems[0].features)">mdi-check-circle</v-icon>
                            </template>
                        </v-list-item>
                        <v-list-item v-for="(item) in permissionsItems" @click="toggleActivePermissions(item.id)"
                            :class="{ active: activePermissionsItems.indexOf(item.id) > -1 }" :key="item.title">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                            </v-list-item-content>
                            <template v-slot:append>
                                <v-icon v-if="activePermissionsItems.indexOf(item.id) > -1">mdi-check-circle</v-icon>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script>
import { GetApplicationModule } from "@/api/index";
export default {
    props: {
        Mode: {
            type: String,
            default: "Add"
        },
        RoleProps: {
            default: null
        },
    },
    data() {
        return {
            activeScopesItems: [],
            activePermissionsItems: [],
            scopesItems: [],
            permissionsItems: [],
            OptimaApplicationLoader: null
        };
    },
    created() {
        this.GetApplicationModules();
        if (this.Mode == "Edit") {
            this.activePermissionsItems = this.RoleProps.features.map((obj) => obj.id)
        }
    },
    watch: {
        activePermissionsItems(value) {
            this.$emit('getData', value)
        },
    },
    methods: {
        validateForm() {
            this.OptimaApplicationLoader = true
            if (this.activePermissionsItems.length > 0) {
                this.$emit('getData', this.activePermissionsItems)
                return true
            } else {
                this.OptimaApplicationLoader = false
                return false
            }
        },
        GetApplicationModules: function () {
            this.OptimaApplicationLoader = true
            GetApplicationModule(this.RoleProps.id).then((response) => {
                this.scopesItems = response.data.items;
            }).finally(() => this.OptimaApplicationLoader = false);
        },
        toggleActiveScopes(idx) {
            this.activeScopesItems = [idx]
            this.permissionsItems = this.activeScopesItems[0].features;
        },
        toggleActivePermissions(idx) {
            let pos = this.activePermissionsItems.indexOf(idx)
            pos === -1 ? this.activePermissionsItems.push(idx) : this.activePermissionsItems.splice(pos, 1)
        },
        SelectedPermissionsInScope(features) {
            let count = 0
            features.forEach(ft => { count = this.activePermissionsItems.indexOf(ft.id) == -1 ? count : count + 1 });
            return count
        },
        IsActiveAllPermissionsInScope(features) {
            let count = 0
            features.forEach(ft => { count = this.activePermissionsItems.indexOf(ft.id) == -1 ? count : count + 1 });
            return count == features.length
        },
        toggleActiveAllPermissionsInScope(features) {
            if (!this.IsActiveAllPermissionsInScope(features)) {
                features.forEach(ft => { this.activePermissionsItems.indexOf(ft.id) == -1 ? this.activePermissionsItems.push(ft.id) : '' });
            } else {
                features.forEach(ft => { this.activePermissionsItems.indexOf(ft.id) != -1 ? this.activePermissionsItems.splice(this.activePermissionsItems.indexOf(ft.id), 1) : '' });
            }
        }
    },
    computed: {
        ApplicationModuleName() {
            return item => {
                switch (item.name) {
                    case "Contact": return this.$t('permissions.applicationModule.Contact');
                    case "PurchaseOrder": return this.$t('permissions.applicationModule.PurchaseOrder');
                    case "SupplierClient": return this.$t('permissions.applicationModule.SupplierClient');
                    case "Permissions": return this.$t('permissions.applicationModule.Permissions');
                    case "Invoices": return this.$t('permissions.applicationModule.Invoices');
                    case "Suppliers": return this.$t('permissions.applicationModule.Suppliers');
                    case "Company": return this.$t('permissions.applicationModule.Company');
                }
            };
        }
    }
};
</script>
<style scoped>
.v-list-item {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #080C3E;
    stroke: #114EF7;
    height: 56px;
    line-height: 18px;
    text-align: start;
}
.selectHint {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #5b6d8e;
    text-align: center;
}
.active {
    color: #114EF7;
    background-color: #F0F5FF;
}
</style>