<template>
    <table style="border-style: hidden; white-space: nowrap;">
        <tr>
            <td>
                <img :src="getFlag(country)" class="country-flag" />
            </td>
            <td>
                <table style="border-style: hidden; white-space: nowrap;">
                    <tr>
                        <td>
                            <span class="ml-2">
                                {{ countryProps }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span v-if="locationName" class="ml-2 font-weight-light">
                                {{ locationName }}
                            </span>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>
<script>
import json from "../../data/country.json";
export default {
    props: ['countryProps','locationName'],
    data() {
        return {
            itemsCountry: json,
            country: this.countryProps,
        };
    },
    methods: {
        getCountry() {
            this.itemsCountry.map(e => {
                if (e.name == this.countryProps) this.country = e.alpha3;
            });
        },
        Updatedd() {
            this.$forceUpdate();
        },
        getFlag(flagName) {
            try {
                return require(`../../assets/flags/${flagName.toLowerCase()}.svg`)
            } catch (e) {
                return require('../../assets/flags/undefined.svg')
            }
        },
    },
    mounted() {
        this.getCountry();
    },
    created() {
        this.getCountry();
    },
    updated() {
        this.getCountry();
    }
};
</script>