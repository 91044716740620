<template>
    <div class="row mb-8">
        <div class="col-6 d-flex justify-content-start">
            <v-card-title>{{ $t('suppliers.add') }}</v-card-title>
        </div>
        <div class="col-6 d-flex justify-content-end">
            <genericBtnComponent :loader="buttonLoader" :ClickFun="SaveAndContinue" svg="1" :Title="$t('suppliers.save')" />
        </div>
    </div>
    <v-card elevation="0">
        <v-tabs style="background-color: #fff; border-radius: 6px 6px 0px 0px;" v-model="tab" grow touchless>
            <v-tab class="slider" value="1"
                :style="[parseInt(tab) > 1 ? Active : [parseInt(tab) == 1 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '1'">mdi-check-circle</v-icon>
                {{ $t('suppliers.general') }}
            </v-tab>
            <v-tab class="slider" value="2"
                :style="[parseInt(tab) > 2 ? Active : [parseInt(tab) == 2 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '2'">
                    mdi-check-circle
                </v-icon>
                {{ $t('suppliers.users') }}
            </v-tab>
            <v-tab class="slider" value="3"
                :style="[parseInt(tab) > 3 ? Active : [parseInt(tab) == 3 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '3'">
                    mdi-check-circle
                </v-icon>
                {{ $t('suppliers.documents') }}
            </v-tab>
        </v-tabs>
        <v-divider class="ma-0"></v-divider>
        <v-window v-model="tab" :key="item">
            <v-window-item value="1">
                <general-information-tab @getData="getDataFromSuppliers" ref="generalInformationRef" />
            </v-window-item>
            <v-window-item value="2">
                <UsersTab @getData="getDataFromUserTab" ref="UserTabRef" :idsupplier="idsupplier" />
            </v-window-item>
            <v-window-item value="3">
                <DocumentsTab @getData="getDataFromDocumentsTab" :idsupplier="idsupplier" ref="DocumentTabRef" />
            </v-window-item>
        </v-window>
    </v-card>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import { addSupplier, addSupplierUser, addSupplierDocument } from "@/api/index";
import GeneralInformationTab from "./GeneralInformationTab.vue";
import DocumentsTab from "./DocumentsTab.vue"
import UsersTab from "./UsersTab.vue"
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default defineComponent({
    components: {
        GeneralInformationTab,
        DocumentsTab,
        UsersTab,
        genericBtnComponent
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.suppliers');
    },
    data: () => ({
        buttonLoader: false,
        tab: "1",
        NotActive: {
            disabled: true,
            color: "#667280",
            fontSize: "14px",
            font: "inter",
            width: "41px",
            "pointer-events": "none",
        },
        Active: {
            color: "#00CBA0",
            fontSize: "14px",
            font: "inter",
            width: "41px",
            "border-bottom": "2px solid #00CBA0",
            "pointer-events": "none",
        },
        dataFromSuppliers: {},
        dataFromUserTab: {},
        dataFromDocumentsTab: {},
        idsupplier: "",
    }),
    watch: {
        tab() {
            this.buttonLoader = false;
        }
    },
    methods: {
        SaveAndContinue() {
            if (this.tab == "1") {
                this.postGeneralInformation();
            } else if (this.tab == "2") {
                this.postUserTab();
            } else if (this.tab == "3") {
                this.postDocumentsTab();
            }
        },
        getDataFromSuppliers(value) {
            this.dataFromSuppliers = value;
        },
        postGeneralInformation: function () {
            if (this.$refs.generalInformationRef.ControleSaisir()) {
                this.buttonLoader = true
                addSupplier(this.dataFromSuppliers)
                    .then(response => {
                        this.idsupplier = response.data
                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.tab = "2";
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false
                    });
            }
        },
        getDataFromUserTab(value) {
            this.dataFromUserTab = value;
        },
        postUserTab: function () {
            if (this.$refs.UserTabRef.validateForm()) {
                this.buttonLoader = true
                this.dataFromUserTab.idsupplier = this.idsupplier
                addSupplierUser(
                    this.dataFromUserTab.fullName,
                    this.dataFromUserTab.phoneNumber,
                    this.dataFromUserTab.email,
                    this.dataFromUserTab.supplierPortal,
                    this.dataFromUserTab.role = null,
                    this.dataFromUserTab.idsupplier
                )
                    .then(response => {

                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.tab = "3";
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false
                    });
            }
        },
        getDataFromDocumentsTab(value) {
            this.dataFromDocumentsTab = value;
        },
        postDocumentsTab: function () {
            if (this.$refs.DocumentTabRef.validateForm()) {
                this.buttonLoader = true
                this.dataFromDocumentsTab.idsupplier = this.idsupplier
                addSupplierDocument(
                    this.dataFromDocumentsTab.name,
                    this.dataFromDocumentsTab.identityDocument,
                    this.dataFromDocumentsTab.expiryDate,
                    this.dataFromDocumentsTab.idsupplier,
                )
                    .then((response) => {
                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.$router.push({ name: 'Suppliers' });
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false
                    });
            }
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
    }
});
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.slider {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    border-bottom: "2px solid black";
}
</style>