import axios from "axios";
import jwt_decode from "jwt-decode";

const urlPurchaseOrders = window.VUE_APP_PURCHASEORDERS_API_URL;
const urlcontacts = window.VUE_APP_CONTACTS_API_URL;
const urlcompanies = window.VUE_APP_COMPANIES_API_URL;
const urlRequestForProposals = window.VUE_APP_REQUESTFORPROPOSALS_API_URL;
const urlPurchaseRequest = window.VUE_APP_PURCHASEREQUEST_API_URL;
const urlSuppliers = window.VUE_APP_SUPPLIERS_API_URL;
const urlInvoices = window.VUE_APP_INVOICES_API_URL;
const urlSecurity = window.VUE_APP_SECURITY_API_URL;

axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Access-Control-Allow-Methods"] = "GET, PUT, POST, DELETE, OPTIONS, post, get";
axios.defaults.headers.post["Access-Control-Max-Age"] = "3600";
axios.defaults.headers.post["Access-Control-Allow-Headers"] = "Origin, Content-Type, X-Auth-Token";
axios.defaults.headers.post["Access-Control-Allow-Credentials"] = true;

var idCompanyFromToken
var idSupplierFromToken
VerifToken()

export function VerifToken() {
  try {
    var decodedToken = jwt_decode(GetToken());
    var currentTimeStamp = Math.floor(Date.now() / 1000);
    if (currentTimeStamp > decodedToken.exp) {
      logout()
    }
    idCompanyFromToken = decodedToken.CompanyId;
    idSupplierFromToken = decodedToken.SupplierId;
  } catch (error) {
    logout()
  }
}

export function GetToken() {
  return localStorage.getItem('accessToken');
}

export function isClient() {
  if (idCompanyFromToken == null) return false;
  return idCompanyFromToken != '00000000-0000-0000-0000-000000000000';
}

export function isSupplier() {
  if (idSupplierFromToken == null) return false;
  return idSupplierFromToken != '00000000-0000-0000-0000-000000000000';
}

export function idClient() {
  return idCompanyFromToken
}

export function idSupplier() {
  return idSupplierFromToken
}

export function logout() {
  localStorage.clear();
  sessionStorage.clear();
}

axios.defaults.headers.common['Authorization'] = 'Bearer ' + GetToken()

export function login(user) {
  return axios.post(urlcontacts + '/api/Account/Authenticate', user)
}

export function SendResetPasswordMail(email) {
  return axios.get(urlcontacts + "/api/Account/SendResetPasswordMail?mail=" + email)
}

export function register(registerData) {
  return axios.post(urlcompanies + '/api/Companies', registerData)
}

export function SendEmailConfirmationCode(email) {
  return axios.get(urlcontacts + '/api/Account/SendEmailConfirmationCode?mail=' + email)
}

export function EmailConfirmation(Email, VerificationCode) {
  return axios.post(urlcontacts + '/api/Account/EmailConfirmation?mail=' + Email + '&code=' + VerificationCode)
}

export function resetPassword(ResetPasswordKey, password) {
  return axios.post(urlcontacts + "/api/Account/ResetPassword?ResetPasswordCode=" + ResetPasswordKey,
    {
      "password": password
    }
  )
}

export function ExistUserMail(email) {
  return axios.get(urlcontacts + "/api/user/ExistEmail/" + email)
}

export function ActivateAccount(ActivateAccountKey, password) {
  return axios.post(urlcontacts + "/api/Account/ActivateAccount?ActivateAccountCode=" + ActivateAccountKey,
    {
      "password": password
    }
  )
}

/************************  Company  APIs *************************/

export function getCompany(page, PageSize, CompanyFilters, sortedBy, sortingType) {
  CompanyFilters.SortedBy = sortedBy.toUpperCase()
  CompanyFilters.SortingType = sortingType
  CompanyFilters.Status != null ? CompanyFilters.withStatus = true : false
  return axios.post(urlcompanies + "/api/Companies/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    {
      "companyFilters": CompanyFilters
    })
}

export function getCompanyByFilterName(BrandName) {
  return axios.get(urlcompanies + "/api/Companies/ByFilterName?BrandName=" + BrandName)
}

export function getSupplierClientID() {
  return axios.get(urlSuppliers + "/api/Suppliers/GetSupplierClientID")
}

export function getClientById(idCompany) {
  return axios.get(urlcompanies + "/api/Companies/Client/" + idCompany)
}

export function getCompanyById(idCompany) {
  return axios.get(urlcompanies + "/api/Companies/" + idCompany)
}

export function addCompany(CompanyInfo) {
  return axios.post(urlcompanies + "/api/Companies/Company", CompanyInfo);
}

export function deleteCompany(id) {
  return axios.delete(urlcompanies + "/api/Companies/" + id);
}

export function editCompany(idCompany, dataFromEditCompanies) {
  return axios.put(urlcompanies + "/api/Companies/", dataFromEditCompanies)
}

export function getCompanyByTokenId() {
  return axios.get(urlcompanies + "/api/Companies/companyFromToken")
}

/************************  Company Logo APIs *************************/

export function addlogoCompany(imageName, image) {
  if (imageName == null) imageName = idCompanyFromToken
  let formData = new FormData();
  formData.append('model', image[0]);
  return axios.post(urlcompanies + "/File/logo?imageName=" + imageName, formData, {
    headers: { "Content-Type": "multipart/form-data", },
  });
}

export function getlogoCompany(API, imageName) {
  switch (API) {
    case 1:
      return axios.get(urlcompanies + '/File/logo/Client?fileName=' + imageName, { responseType: 'arraybuffer' })
    default:
      return axios.get(urlcompanies + '/File/logo?fileName=' + imageName, { responseType: 'arraybuffer' })
  }
}


/********************** Company's Users APIs **********************/

export function getUsers(page, PageSize, UserFilters, sortedBy, sortingType) {
  UserFilters.SortedBy = sortedBy.toUpperCase()
  UserFilters.SortingType = sortingType
  UserFilters.Status != null ? UserFilters.withStatus = true : false
  return axios.post(urlcompanies + "/api/User/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    UserFilters
  )
}

export function getUserById(idUser) {
  return axios.get(urlcompanies + "/api/User/" + idUser);
}

export function editUser(id, userName, userEmail, regionCoverage, departement, companyID) {
  return axios.put(urlcompanies + "/api/User/" + id, {
    id: id,
    fullName: userName,
    email: userEmail,
    regionCoverage: regionCoverage,
    departmentId: departement,
    companyID: companyID ? companyID : idCompanyFromToken,
  });
}

export function addUser(idCompany, id, userName, email, regionCoverage, departement, role) {
  return axios.post(urlcompanies + "/api/User/", {
    companyID: idCompany ? idCompany : idCompanyFromToken,
    id: id,
    fullName: userName,
    email: email,
    regionCoverage: regionCoverage,
    departmentId: departement,
    role: role,
  });
}

export function deleteUser(id) {
  return axios.delete(urlcompanies + "/api/User/" + id);
}

export function ExistCompanyEmail(email) {
  return axios.get(urlcompanies + "/api/User/ExistCompanyEmail/" + email)
}

export function GetStatusUsers() {
  return axios.get(urlcompanies + "/api/user/GetStatusFromConfiguration/")
}

/********************* Company's Location APIs ********************/
export function getLocation(page, PageSize, locationFilters, sortedBy, sortingType) {
  locationFilters.SortedBy = sortedBy.toUpperCase()
  locationFilters.SortingType = sortingType
  locationFilters.Status != null ? locationFilters.withStatus = true : false
  return axios.post(urlcompanies + "/api/Locations/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    locationFilters
  )
}

export function getLocationById(idLocation) {
  return axios.get(urlcompanies + "/api/Locations/" + idLocation)
}

export function getAllLocation() {
  var page = 1;
  var PageSize = 99;
  return axios.post(urlcompanies + "/api/Locations/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    { SortedBy: "", SortingType: "ASC" }
  )
}

export function addLocation(idCompany, locationName, address, postalCode, currency, city, country) {
  return axios.post(urlcompanies + "/api/Locations", {
    IdCompany: idCompany,
    LocationName: locationName,
    Address: address,
    PostalCode: parseInt(postalCode),
    Currency: currency,
    City: city,
    Status: true,
    Country: country,
  });
}

export function editLocations(idLocation, location) {
  return axios.put(urlcompanies + "/api/Locations/" + idLocation, location);
}

export function deleteLocation(id) {
  return axios.delete(urlcompanies + "/api/Locations/" + id);
}

export function GetStatusLocations() {
  return axios.get(urlcompanies + "/api/Locations/GetStatusFromConfiguration/")
}

/********************* Company's Department APIs ********************/

export function getDepartment(page, PageSize, DepartmentFilters, sortedBy, sortingType) {
  DepartmentFilters.SortedBy = sortedBy.toUpperCase()
  DepartmentFilters.SortingType = sortingType
  return axios.post(urlcompanies + "/api/Department/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    DepartmentFilters
  )
}

export function getAllDepartment() {
  var page = 1;
  var PageSize = 50;
  return axios.post(urlcompanies + "/api/Department/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    { SortedBy: "", SortingType: "ASC" }
  )
}

export function getRolesByCompany() {
  let SecurityGroupFilters = {}
  var pageNumber = 1;
  var pageSize = 100;
  SecurityGroupFilters.SortedBy = ''
  SecurityGroupFilters.SortingType = 'ASC'
  return axios.post(urlSecurity + "/v1/SecurityGroup/ByCompanyID?pageNumber=" + pageNumber + "&pageSize=" + pageSize,
    SecurityGroupFilters
  )
}

export function getRolesBySupplier(idSupplier) {
  let SecurityGroupFilters = {}
  var pageNumber = 1;
  var pageSize = 100;
  SecurityGroupFilters.SortedBy = ''
  SecurityGroupFilters.SortingType = 'ASC'
  SecurityGroupFilters.Organisation = idSupplier
  return axios.post(urlSecurity + "/v1/SecurityGroup/BySupplierID?pageNumber=" + pageNumber + "&pageSize=" + pageSize,
    SecurityGroupFilters
  )
}

export function AddDepartement(DepartmentName, LocationCoverage, idCompany) {
  return axios.post(urlcompanies + "/api/Department",
    {
      departmentName: DepartmentName,
      idLocation: LocationCoverage,
      idCompany: idCompany
    });
}

export function getDepartmentById(idDepartment) {
  return axios.get(urlcompanies + "/api/Department/" + idDepartment);
}

export function getDepartmentByName(DepartmentName) {
  return axios.get(urlcompanies + "/api/Department/Name?Name=" + DepartmentName);
}

export function editDepartment(idDepartment, Department) {
  return axios.put(urlcompanies + "/api/Department/" + idDepartment, Department);
}

export function deleteDepartement(idDepartment) {
  return axios.delete(urlcompanies + "/api/Department/" + idDepartment);
}

/********************* Company's Taxe APIs ********************/

export function getTaxes(page, PageSize, TaxFilters, sortedBy, sortingType) {
  TaxFilters.SortedBy = sortedBy.toUpperCase()
  TaxFilters.SortingType = sortingType
  TaxFilters.Status != null ? TaxFilters.withStatus = true : false
  return axios.post(urlcompanies + "/api/Taxes/GET?PageNumber=" + page + "&PageSize=" + PageSize,
    TaxFilters
  )
}

export function AddTaxe(TaxName, TaxPercentage, idCompany) {
  return axios.post(urlcompanies + "/api/Taxes",
    {
      taxName: TaxName,
      taxPourcentage: TaxPercentage,
      status: true,
      idCompany: idCompany,
    });
}

export function editTaxe(idTaxe, taxe) {
  return axios.put(urlcompanies + "/api/Taxes/" + idTaxe, taxe);
}

export function deleteTaxe(id) {
  return axios.delete(urlcompanies + "/api/Taxes/" + id);
}

export function GetStatusTaxes() {
  return axios.get(urlcompanies + "/api/Taxes/GetStatusFromConfiguration/")
}

export function GetTaxListByCmpanyId(companyId) {
  return axios.get(urlcompanies + "/api/Taxes/GET/" + companyId)
}


/************************ Suppliers APIs *************************/

export function getSuppliers(page, pageSize, title, manager, country, paymentCondition, status, createdDate, sortedBy, sortingType) {
  const SuppliersFilters = {};
  if (title !== null) SuppliersFilters.Title = title;
  if (manager !== null) SuppliersFilters.Manager = manager;
  if (country !== null) SuppliersFilters.Country = country;
  if (paymentCondition !== null) SuppliersFilters.PaymentCondition = paymentCondition;
  if (status !== null) {
    SuppliersFilters.ValidationStatus = status;
    SuppliersFilters.WithStatus = true;
  }
  if (createdDate !== null) SuppliersFilters.Created = createdDate;
  if (sortedBy !== null) SuppliersFilters.SortedBy = sortedBy.toUpperCase();
  if (sortingType !== null) SuppliersFilters.SortingType = sortingType;

  return axios.post(urlSuppliers + "/api/Suppliers/GET", {
    pageNumber: page,
    pageSize,
    SuppliersFilters
  });
}

export function getSupplierById(idSupplier) {
  return axios.get(urlSuppliers + "/api/Suppliers/" + idSupplier)
}

export function addSupplier(supplier) {
  return axios.post(urlSuppliers + "/api/Suppliers", {
    name: supplier.name,
    code: supplier.code,
    paymentCondition: supplier.paymentCondition,
    defaultPhone: supplier.defaultPhone,
    delivery: supplier.delivery,
    country: supplier.country,
    city: supplier.city,
    address: supplier.address,
    defaultEmail: supplier.defaultEmail,
    currencies: supplier.currencies,
    postalCode: supplier.postalCode,
    totalMinimumOrder: supplier.totalMinimumOrder,
  });
}

export function editSupplier(idSupplier, dataFromEditSupplier) {
  return axios.put(urlSuppliers + "/api/Suppliers/" + idSupplier, dataFromEditSupplier)
}

export function getUserlist(page, PageSize, idSupplier, FullName, Email, PhoneNumber, Role, SupplierPortal, SortedBy, SortingType) {
  let UsersFilters = {}
  FullName !== null ? UsersFilters.FullName = FullName : delete UsersFilters.FullName
  Email !== null ? UsersFilters.Email = Email.replace('\t', '') : delete UsersFilters.Email
  PhoneNumber !== null ? UsersFilters.PhoneNumber = PhoneNumber : delete UsersFilters.PhoneNumber
  Role !== null ? UsersFilters.Role = Role : delete UsersFilters.Role
  SupplierPortal !== null ? UsersFilters.SupplierPortal = SupplierPortal : delete UsersFilters.SupplierPortal
  SortedBy !== null ? UsersFilters.SortedBy = SortedBy.toUpperCase() : delete UsersFilters.SortedBy
  SortingType !== null ? UsersFilters.SortingType = SortingType : delete UsersFilters.SortingType
  return axios.post(urlSuppliers + "/api/Users/GET", {
    "pageNumber": page,
    "pageSize": PageSize, "IdSupplier": idSupplier, "UsersFilters": UsersFilters
  })
}

export function getDocumentslist(page, PageSize, idSupplier, name, expiryDate, status, createdDate, sortedBy, sortingType) {
  const DocumentsFilters = {};

  if (name !== null) DocumentsFilters.Name = name;
  if (expiryDate !== null) DocumentsFilters.ExpiryDate = expiryDate;
  if (status !== null) {
    DocumentsFilters.Status = status;
    DocumentsFilters.WithStatus = true;
  }
  if (createdDate !== null) DocumentsFilters.Created = createdDate;
  if (sortedBy !== null) DocumentsFilters.SortedBy = sortedBy.toUpperCase();
  if (sortingType !== null) DocumentsFilters.SortingType = sortingType;

  return axios.post(urlSuppliers + "/api/Documents/GET", {
    "pageNumber": page,
    "pageSize": PageSize,
    "IdSupplier": idSupplier,
    "DocumentsFilters": DocumentsFilters
  })
}

export function deleteDocumentSupplier(idUser) {
  return axios.delete(urlSuppliers + '/api/Documents/' + idUser)
}

export function downloadDocumentSupplier(fileName) {
  return axios.get(urlSuppliers + '/api/Documents/' + fileName)
}

export function editSupplierDocument(id, documentSupplier) {
  let formData = new FormData();
  formData.append('idDocument', documentSupplier.idDocument);
  formData.append('name', documentSupplier.name);
  formData.append('File', documentSupplier.identityDocument ? documentSupplier.identityDocument[0] : null);
  formData.append('expiryDate', documentSupplier.expiryDate);
  formData.append('status', documentSupplier.status);
  formData.append('Type', documentSupplier.type);

  return axios.put(urlSuppliers + "/api/Documents/" + id, formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function addSupplierDocument(name, identityDocument, expiryDate, idSupplier) {
  let formData = new FormData();
  formData.append('name', name);
  formData.append('File', identityDocument ? identityDocument[0] : null);
  formData.append('expiryDate', expiryDate);
  formData.append('idSupplier', idSupplier);
  return axios.post(urlSuppliers + "/api/Documents", formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function GetStatusDocuments() {
  return axios.get(urlSuppliers + "/api/Documents/GetStatusFromConfiguration/")
}


export function editSupplierUser(id, userSupplier) {
  return axios.put(urlSuppliers + "/api/Users/" + id, {
    idUser: userSupplier.idUser,
    fullName: userSupplier.fullName,
    phoneNumber: userSupplier.phoneNumber,
    email: userSupplier.email,
    supplierPortal: userSupplier.supplierPortal,
  });
}

export function addSupplierUser(fullName, phoneNumber, email, supplierPortal, role, idSupplier) {
  return axios.post(urlSuppliers + "/api/Users/", {
    fullName: fullName,
    phoneNumber: phoneNumber,
    email: email,
    supplierPortal: supplierPortal,
    role: role,
    idSupplier: idSupplier,
  });
}

export function deleteSupplierUser(idUser) {
  return axios.delete(urlSuppliers + '/api/Users/' + idUser)
}

export function addSupplierAlert(title, type, dateIssued, criticity, idSupplier) {
  return axios.post(urlSuppliers + "/api/Alerts/", {
    title: title,
    dateIssued: dateIssued,
    type: type,
    criticity: criticity,
    idSupplier: idSupplier,
  });
}

export function editSupplierAlert(id, alertSupplier) {
  return axios.put(urlSuppliers + "/api/Alerts/" + id, {
    idAlert: alertSupplier.idAlert,
    title: alertSupplier.title,
    type: alertSupplier.type,
    dateIssued: alertSupplier.dateIssued,
    criticity: alertSupplier.criticity,

  });
}

export function deleteSupplierAlert(idAlert) {
  return axios.delete(urlSuppliers + '/api/Alerts/' + idAlert)
}

export function getAlert(PageNumber, PageSize, idSupplier, Title, Type, DateIssued, Criticity, SortedBy, SortingType) {
  let AlertsFilters = {}
  Title !== null ? AlertsFilters.Title = Title : delete AlertsFilters.Title
  Type !== null ? AlertsFilters.Type = Type : delete AlertsFilters.Type
  DateIssued !== null ? AlertsFilters.DateIssued = DateIssued : delete AlertsFilters.DateIssued
  Criticity !== null ? AlertsFilters.Criticity = Criticity : delete AlertsFilters.Criticity
  SortedBy !== null ? AlertsFilters.SortedBy = SortedBy.toUpperCase() : delete AlertsFilters.SortedBy
  SortingType !== null ? AlertsFilters.SortingType = SortingType : delete AlertsFilters.SortingType

  return axios.post(urlSuppliers + "/api/Alerts/GET", {
    "pageNumber": PageNumber,
    "pageSize": PageSize, "idSupplier": idSupplier, "AlertsFilters": AlertsFilters
  })
}

export function DeleteSuppliers(idSupplier) {
  return axios.delete(urlSuppliers + "/api/Suppliers/" + idSupplier);
}

export function getBankAccount(PageNumber, PageSize, idSupplier, iban, bic, bankName, accountHolderName, identityDocument, SortedBy, SortingType) {
  let bankAccountFilters = {}
  iban !== null ? bankAccountFilters.iban = iban : delete bankAccountFilters.iban
  bic !== null ? bankAccountFilters.bic = bic : delete bankAccountFilters.bic
  bankName !== null ? bankAccountFilters.bankName = bankName : delete bankAccountFilters.bankName
  accountHolderName !== null ? bankAccountFilters.accountHolderName = accountHolderName : delete bankAccountFilters.accountHolderName
  identityDocument !== null ? bankAccountFilters.identityDocument = identityDocument : delete bankAccountFilters.identityDocument
  SortedBy !== null ? bankAccountFilters.SortedBy = SortedBy.toUpperCase() : delete bankAccountFilters.SortedBy
  SortingType !== null ? bankAccountFilters.SortingType = SortingType : delete bankAccountFilters.SortingType
  return axios.post(urlSuppliers + "/api/BankAccount/GET", {
    "pageNumber": PageNumber,
    "pageSize": PageSize, "idSupplier": idSupplier, "bankAccountFilters": bankAccountFilters
  })
}

export function addSupplierBankAccount(iban, bic, bankName, agencyName, bankAdresse, accountHolderName, identityDocument, identityDocumentPDF, country, idSupplier) {
  let formData = new FormData();
  formData.append('AccountHolderName', accountHolderName);
  formData.append('IBAN', iban);
  formData.append('IdSupplier', idSupplier);
  formData.append('BankName', bankName);
  formData.append('AgencyName', agencyName);
  formData.append('BankAdresse', bankAdresse);
  formData.append('IdentityDocument', identityDocument);
  formData.append('BIC', bic);
  formData.append('Country', country);
  formData.append('File', identityDocumentPDF ? identityDocumentPDF[0] : null);
  return axios.post(urlSuppliers + "/api/BankAccount", formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function editSupplierBankAccount(id, BankAccount) {
  let formData = new FormData();
  formData.append('IdBankAccount', BankAccount.idBankAccount);
  formData.append('IBAN', BankAccount.iban);
  formData.append('BIC', BankAccount.bic);
  formData.append('BankName', BankAccount.bankName);
  formData.append('AgencyName', BankAccount.agencyName);
  formData.append('BankAdresse', BankAccount.bankAdresse);
  formData.append('AccountHolderName', BankAccount.accountHolderName);
  formData.append('IdentityDocument', BankAccount.identityDocument);
  formData.append('Country', BankAccount.country);
  formData.append('File', BankAccount.identityDocumentPDF ? BankAccount.identityDocumentPDF[0] : null);
  return axios.put(urlSuppliers + "/api/BankAccount/" + id, formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function DeleteSupplierBankAccount(idBankAccount) {
  return axios.delete(urlSuppliers + "/api/BankAccount/" + idBankAccount);
}

export function getIdentityDocument(identityDocument) {
  return axios.get(urlSuppliers + "/api/BankAccount/identityDocument?fileName=" + identityDocument);
}

export function ValidationButton(idSupplier) {
  return axios.get(urlSuppliers + "/api/Suppliers/validationButton/" + idSupplier);
}

export function ValidateSupplier(idSupplier) {
  return axios.put(urlSuppliers + "/api/Suppliers/api/validate/" + idSupplier);
}

export function RefuseSupplier(idSupplier, commentaire) {
  return axios.put(urlSuppliers + "/api/Suppliers/api/refuse/" + idSupplier + "?comment=" + commentaire);
}

export function ExistSupplierMail(email) {
  return axios.get(urlSuppliers + "/api/Users/ExistSupplierEmail/" + email)
}

export function GetStatusSuppliers() {
  return axios.get(urlSuppliers + "/api/Suppliers/GetStatusFromConfiguration/")
}

export function GetTraceValidationListBySupplierId(idSupplier) {
  return axios.get(urlSuppliers + "/api/Suppliers/GetTraceValidationListBySupplierId/" + idSupplier)
}


/************************ Request For Proposal APIs *************************/

export function RequestForProposal(page, PageSize, title, deadline, category, submissions, status, SortedBy, SortingType) {
  let RequestProposalFilters = {}
  title !== null ? RequestProposalFilters.Title = title : delete RequestProposalFilters.Title
  deadline !== null ? RequestProposalFilters.Deadline = deadline : delete RequestProposalFilters.Deadline
  category !== null ? RequestProposalFilters.Category = parseInt(category, 10) : delete RequestProposalFilters.Category
  status !== null ? RequestProposalFilters.Status = parseInt(status, 10) : delete RequestProposalFilters.Status
  status !== null ? RequestProposalFilters.withStatus = true : false
  submissions !== null ? RequestProposalFilters.Submissions = submissions : delete RequestProposalFilters.Submissions
  submissions !== null ? RequestProposalFilters.WithSubmission = true : false
  SortedBy !== null ? RequestProposalFilters.SortedBy = SortedBy.toUpperCase() : delete RequestProposalFilters.SortedBy
  SortingType !== null ? RequestProposalFilters.SortingType = SortingType : delete RequestProposalFilters.SortingType

  return axios.post(urlRequestForProposals + "/api/RequestProposal/GET", {
    "pageNumber": page,
    "pageSize": PageSize, "RequestProposalFilters": RequestProposalFilters
  })
}

export function PostForProposal(Title, Deadline, Category, Submission, Status) {
  return axios.post(urlRequestForProposals + "/api/RequestProposal", {
    title: Title,
    deadline: Deadline,
    category: Category,
    submissions: Submission,
    status: Status,
  })
}

export function deleteRequest(idRequestForProposal) {
  return axios.delete(urlRequestForProposals + '/api/RequestProposal/' + idRequestForProposal)
}

export function updateRequest(idRequestForProposal, dataToUpdate) {
  return axios.put(urlRequestForProposals + '/api/RequestProposal/' + idRequestForProposal, dataToUpdate)
}

export function GetStatusProposalRequest() {
  return axios.get(urlRequestForProposals + "/api/RequestProposal/GetStatusFromConfiguration/")
}

/************************ Purchase Order APIs *************************/

export function GetAllPurchaseOrders(page, PageSize, reference, title, company, supplier, status, consumption, expirationDate, createdDate, SortedBy, SortingType, IsEqual, IsGreater, IsLess, HasPermissionValidation) {
  let PurchaseOrderFilters = {
    IsEqual: IsEqual || undefined,
    IsGreater: IsGreater || undefined,
    IsLess: IsLess || undefined,
    Reference: reference || undefined,
    Title: title || undefined,
    Company: company || undefined,
    Supplier: supplier || undefined,
    Status: status !== null ? parseInt(status, 10) : undefined,
    withStatus: status !== null ? status !== null : undefined,
    Consumption: consumption || undefined,
    ExpirationDate: expirationDate || undefined,
    Created: createdDate || undefined,
    SortedBy: SortedBy ? SortedBy.toUpperCase() : undefined,
    SortingType: SortingType || undefined,
  };

  return axios.post(urlPurchaseOrders + "/api/PurchaseOrder/GET", {
    "pageNumber": page,
    "pageSize": PageSize,
    "HasPermissionValidation": HasPermissionValidation,
    "PurchaseOrderFilters": PurchaseOrderFilters
  })
}

export function CreatePurchaseOrder(Reference, Description, Title, Threshold, Devise, Tax, Quantity, PaymentMethod, ExpirationDate, IdSupplier, IdentityDocument, IdentityDocuments) {
  let formData = new FormData();
  formData.append('purchaseOrder.reference', Reference);
  formData.append('purchaseOrder.Description', Description);
  formData.append('PurchaseOrder.title', Title);
  formData.append('PurchaseOrder.threshold', Threshold);
  formData.append('PurchaseOrder.devise', Devise);
  formData.append('purchaseOrder.Taxe', Tax);
  formData.append('purchaseOrder.Quantity', Quantity);
  formData.append('purchaseOrder.PaymentMethod', PaymentMethod);
  formData.append('purchaseOrder.ExpirationDate', ExpirationDate);
  formData.append('PurchaseOrder.idSupplier', IdSupplier);
  if (IdentityDocument && IdentityDocument.length > 0) {
    formData.append('PurchaseOrder.document', IdentityDocument[0]);
  }
  if (IdentityDocuments && IdentityDocuments.length > 0) {
    for (let i = 0; i < IdentityDocuments.length; i++) {
      formData.append('PurchaseOrder.Documents', IdentityDocuments[i]);
    }
  }

  return axios.post(urlPurchaseOrders + "/api/PurchaseOrder", formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function DownloadPurchaseOrderPdf(purchaseOrderId) {
  return axios.get(urlPurchaseOrders + `/api/PurchaseOrder/${purchaseOrderId}/pdf`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/pdf' 
    }
  });
}

export function DeletePurchaseOrder(idPurchaseOrder) {
  return axios.delete(urlPurchaseOrders + "/api/PurchaseOrder/" + idPurchaseOrder)
}

export function EditPurchaseOrder(idPurchaseOrder, editData) {
  let formData = new FormData();
  for (let key in editData) {
    let prefixedKey = "PurchaseOrder." + key;
    if (key == 'filesToAdd') {
      if (editData.filesToAdd && editData.filesToAdd.length > 0) {
        for (let i = 0; i < editData.filesToAdd.length; i++) {
          formData.append('PurchaseOrder.FilesToAdd', editData.filesToAdd[i]);
        }
      }
    } else if (key == 'filesToDelete') {
      if (editData.filesToDelete && editData.filesToDelete.length > 0) {
        for (let i = 0; i < editData.filesToDelete.length; i++) {
          formData.append('PurchaseOrder.FilesToDelete', editData.filesToDelete[i]);
        }
      }
    } else {
      formData.append(prefixedKey, editData[key]);
    }
  }
  return axios.put(urlPurchaseOrders + "/api/PurchaseOrder/" + idPurchaseOrder, formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function PurchaseOrderIsExistReference(IdPurchaseOrder,Reference) {
  var idquary = !IdPurchaseOrder ? "" : "&IdPurchaseOrder="+IdPurchaseOrder;
  return axios.get(urlPurchaseOrders + "/api/PurchaseOrder/CheckExistReference?Reference=" + Reference + idquary)
}

export function downloadMultiDocumentPurchaseOrder(fileName) {
  return axios.get(urlPurchaseOrders + "/api/PurchaseOrder/Multifile/" + fileName)
}

export function downloadDocumentPurchaseOrder(fileName) {
  return axios.get(urlPurchaseOrders + "/api/PurchaseOrder/file/" + fileName)
}


export function GetCompanyFromPurchaseOrders(BrandName) {
  if (BrandName == null || BrandName == "") {
    return axios.get(urlPurchaseOrders + "/api/Company")
  } else {
    return axios.get(urlPurchaseOrders + "/api/Company?BrandName=" + BrandName)
  }
}

export function ValidatePurchaseOrder(idPurchaseOrder) {
  return axios.put(urlPurchaseOrders + "/api/PurchaseOrder/api/validate/" + idPurchaseOrder);
}

export function RefusePurchaseOrder(idPurchaseOrder, commentaire) {
  return axios.put(urlPurchaseOrders + "/api/PurchaseOrder/api/refuse/" + idPurchaseOrder + "?comment=" + commentaire);
}

export function GetStatusPurchaseOrders() {
  return axios.get(urlPurchaseOrders + "/api/PurchaseOrder/GetStatusFromConfiguration/")
}

/************************ Purchase Request APIs *************************/

export function GetAllPurchaseRequest(page, PageSize, title, deadline, status, requester, SortedBy, SortingType) {

  let PurchaseRequestFilters = {}

  title !== null ? PurchaseRequestFilters.Title = title : delete PurchaseRequestFilters.Title
  deadline !== null ? PurchaseRequestFilters.Deadline = deadline : delete PurchaseRequestFilters.Deadline
  status !== null ? PurchaseRequestFilters.Status = parseInt(status, 10) : delete PurchaseRequestFilters.Status
  status !== null ? PurchaseRequestFilters.withStatus = true : false
  requester !== null ? PurchaseRequestFilters.Requester = requester : delete PurchaseRequestFilters.Requester
  SortedBy !== null ? PurchaseRequestFilters.SortedBy = SortedBy.toUpperCase() : delete PurchaseRequestFilters.SortedBy
  SortingType !== null ? PurchaseRequestFilters.SortingType = SortingType : delete PurchaseRequestFilters.SortingType

  return axios.post(urlPurchaseRequest + "/api/PurchaseRequests/GET", {
    "pageNumber": page,
    "pageSize": PageSize, "PurchaseRequestFilters": PurchaseRequestFilters
  })
}

export function PostPurchaseRequest(Title, Deadline, idRequester, Status) {
  return axios.post(urlPurchaseRequest + "/api/PurchaseRequests", {
    purchaseRequest: {
      title: Title,
      deadline: Deadline,
      status: Status,
      userId: idRequester,
    }
  })
}

export function EditPurchaseRequest(idPurchaseRequest, editData) {
  return axios.put(urlPurchaseRequest + "/api/PurchaseRequests/" + idPurchaseRequest, editData)
}

export function DeletePurchaseRequest(idPurchaseRequest) {
  return axios.delete(urlPurchaseRequest + "/api/PurchaseRequests/" + idPurchaseRequest)
}

export function GetAllRequester(FullName) {
  if (FullName == null || FullName == "") {
    return axios.get(urlPurchaseRequest + "/api/User")
  } else {
    return axios.get(urlPurchaseRequest + "/api/User?FullName=" + FullName)
  }
}

export function GetStatusPurchaseRequest() {
  return axios.get(urlPurchaseRequest + "/api/PurchaseRequests/GetStatusFromConfiguration/")
}

/************************ Dashboard APIs *************************/
export function GetInvoiceSummary() {
  return axios.post(urlInvoices + "/api/Dashboard/GETSummary")
}

export function GetPurchaseOrderSummary() {
  return axios.post(urlPurchaseOrders + "/api/Dashboard/GETSummary")
}
/************************ Invoices APIs *************************/
export function GetAllInvoices(page, PageSize, reference, title, company, supplier, amount, deadline, validationStatus, createdDate, SortedBy, SortingType, HasPermissionValidation) {
  let InvoicesFilters = {
    Reference: reference || undefined,
    Title: title || undefined,
    Company: company || undefined,
    Supplier: supplier || undefined,
    AmountTTC: amount || undefined,
    Deadline: deadline || undefined,
    validationStatus: validationStatus !== null ? validationStatus : undefined,
    WithStatus: validationStatus !== null ? true : false,
    created: createdDate || undefined,
    SortedBy: SortedBy ? SortedBy.toUpperCase() : undefined,
    SortingType: SortingType || undefined,
  };

  return axios.post(urlInvoices + "/api/Invoices/GET", {
    "pageNumber": page,
    PageSize,
    HasPermissionValidation,
    InvoicesFilters,
  })
}

export function CreateInvoice(Reference, Title, Amount, Tax, Devise, Deadline, IdCompany, idPurchaseOrder, IdentityDocument, IdentityDocuments) {
  let formData = new FormData();
  formData.append('reference', Reference);
  formData.append('title', Title);
  formData.append('amount', Amount);
  formData.append('tax', Tax);
  formData.append('devise', Devise);
  formData.append('deadline', Deadline);
  formData.append('idCompany', IdCompany);
  formData.append('document', IdentityDocument ? IdentityDocument[0] : null);
  if (IdentityDocuments && IdentityDocuments.length > 0) {
    for (let i = 0; i < IdentityDocuments.length; i++) {
      formData.append('documents', IdentityDocuments[i]);
    }
  }
  if (idPurchaseOrder != null) formData.append('idPurchaseOrder', idPurchaseOrder);
  return axios.post(urlInvoices + "/api/Invoices", formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function DeleteInvoice(idInvoices) {
  return axios.delete(urlInvoices + "/api/Invoices/" + idInvoices)
}

export function EditInvoice(idInvoices, editData) {
  let formData = new FormData();
  for (let key in editData) {
    let prefixedKey = "Invoice." + key;
    if (key == 'filesToAdd') {
      if (editData.filesToAdd && editData.filesToAdd.length > 0) {
        for (let i = 0; i < editData.filesToAdd.length; i++) {
          formData.append('Invoice.FilesToAdd', editData.filesToAdd[i]);
        }
      }
    } else if (key == 'filesToDelete') {
      if (editData.filesToDelete && editData.filesToDelete.length > 0) {
        for (let i = 0; i < editData.filesToDelete.length; i++) {
          formData.append('Invoice.FilesToDelete', editData.filesToDelete[i]);
        }
      }
    } else {
      formData.append(prefixedKey, editData[key]);
    }
  }
  return axios.put(urlInvoices + "/api/Invoices/" + idInvoices, formData, { headers: { "Content-Type": "multipart/form-data", }, });
}

export function InvoiceIsExistReference(IdInvoice,Reference) {
  var idquary = !IdInvoice ? "" : "&IdInvoice="+IdInvoice;
  return axios.get(urlInvoices + "/api/Invoices/CheckExistReference?Reference=" + Reference + idquary)
}

export function downloadMultiDocumentInvoices(fileName) {
  return axios.get(urlInvoices + "/api/Invoices/Multifile/" + fileName)
}

export function downloadDocumentInvoices(fileName) {
  return axios.get(urlInvoices + "/api/Invoices/file/" + fileName)
}

export function GetCompanyFromInvoices(BrandName) {
  if (BrandName == null || BrandName == "") {
    return axios.get(urlInvoices + "/api/Company")
  } else {
    return axios.get(urlInvoices + "/api/Company?BrandName=" + BrandName)
  }
}

export function isValidatedSupplier() {
  return axios.get(urlInvoices + "/api/isValidatedSupplier")
}

export function PaidInvoices(idInvoices) {
  return axios.put(urlInvoices + "/api/Invoices/api/paid/" + idInvoices);
}

export function RefuseInvoices(idInvoices, commentaire) {
  return axios.put(urlInvoices + "/api/Invoices/api/refuse/" + idInvoices + "?comment=" + commentaire);
}

export function ValideInvoices(idInvoices) {
  return axios.put(urlInvoices + "/api/Invoices/api/valide/" + idInvoices);
}

export function GetPurchaseOrderFromInvoices(Title, SupplierId) {
  const queryParams = {
    ...(Title && { Title: Title }),
    ...(SupplierId && { SupplierID: SupplierId }),
  };
  return axios.get(urlInvoices + "/api/PurchaseOrder", { params: queryParams });
}

export function GetStatusInvoices() {
  return axios.get(urlInvoices + "/api/Invoices/GetStatusFromConfiguration/")
}

export function GetDeviseFromPurchaseOrder(idPurchaseOrder) {
  return axios.get(urlPurchaseOrders + "/api/PurchaseOrder/" + idPurchaseOrder + "/Devise")
}

export function CanValideFactureByPurchaseOrder(idPurchaseOrder, factureAmout) {
  return axios.get(urlPurchaseOrders + "/api/PurchaseOrder/" + idPurchaseOrder + "/CanValideFacture/" + factureAmout)
}


/************************ Menu APIs *************************/

export function GetMenuById(id) {
  return axios.get(urlSecurity + "/v1/Menu/" + id)
}

/************************ Role APIs *************************/

export function addRole(Roleinfo) {
  return axios.post(urlSecurity + "/v1/SecurityGroup/", Roleinfo)
}

export function deleteRole(idRole) {
  return axios.delete(urlSecurity + "/v1/SecurityGroup/" + idRole)
}

export function getRoleByName(RoleName) {
  return axios.get(urlSecurity + "/v1/SecurityGroup/name/" + RoleName)
}

export function GetAllSecurityGroup(pageNumber, pageSize, SecurityGroupFilters, SortedBy, SortingType) {
  SecurityGroupFilters.SortedBy = SortedBy.toUpperCase()
  SecurityGroupFilters.SortingType = SortingType
  return axios.post(urlSecurity + "/v1/SecurityGroup/ByOrganisationID?pageNumber=" + pageNumber + "&pageSize=" + pageSize,
    SecurityGroupFilters
  )
}

export function GetOptimaApplication() {
  return axios.get(urlSecurity + "/v1/Application/name/optima")
}

export function GetApplicationModule(id) {
  return axios.get(urlSecurity + "/v1/Application/ApplicationModule/" + id)
}

export function addMultiPermissions(RoleId, PermissionsIds) {
  var MultiPermissionsData =
  {
    "securityGroupId": RoleId,
    "featureId": PermissionsIds
  }
  return axios.post(urlSecurity + "/v1/SecurityGroup/MultiSecurityGroupFeatures/", MultiPermissionsData)
}

export function updateMultiPermissions(RoleId, PermissionsIdstoAdd, PermissionsIdstoDelete) {
  var MultiPermissionsData =
  {
    "securityGroupId": RoleId,
    "featureIdToAdd": PermissionsIdstoAdd,
    "featureIdToDelete": PermissionsIdstoDelete
  }
  return axios.put(urlSecurity + "/v1/SecurityGroup/UpdateMultiSecurityGroupFeatures/", MultiPermissionsData)
}

export function addMultiUsers(RoleId, UsersIds) {
  var MultiUsersData = {
    "securityGroupId": RoleId,
    "usersId": UsersIds
  }
  return axios.post(urlSecurity + "/v1/SecurityGroup/MultiSecurityGroupUsers/", MultiUsersData)
}

export function updateMultiUsers(RoleId, UsersIdstoAdd, UsersIdstoDelete) {
  var MultiUsersData =
  {
    "securityGroupId": RoleId,
    "usersIdToAdd": UsersIdstoAdd,
    "usersIdToDelete": UsersIdstoDelete
  }
  return axios.put(urlSecurity + "/v1/SecurityGroup/UpdateMultiSecurityGroupUsers/", MultiUsersData)
}

export function getRoleById(RoleId) {
  return axios.get(urlSecurity + "/v1/SecurityGroup/" + RoleId)
}

export function UpdateRole(Roleinfo) {
  return axios.put(urlSecurity + "/v1/SecurityGroup/" + Roleinfo.id, Roleinfo)
}

export function GetOrganisationParentDefaultRoles() {
  return axios.get(urlSecurity + "/v1/SecurityGroup/OrganisationParentRoles")
}

export function ResetRole(RoleId) {
  return axios.put(urlSecurity + "/v1/SecurityGroup/ResetSecurityGroupFeatures?idsecuritygroup=" + RoleId)
}

export function GetUsersByOrganisationId(name, SecurityGroupId) {
  return axios.post(urlSecurity + "/v1/User/GetUsers?name=" + name, { "id": SecurityGroupId })
}

export function CheckPermissionByUser(feature) {
  return axios.get(urlSecurity + "/v1/SecurityGroup/CheckFeatureByUser?feature=" + feature)
}

export function GetStatusSecurityGroup() {
  return axios.get(urlSecurity + "/v1/SecurityGroup/GetStatusFromConfiguration/")
}