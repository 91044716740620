<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers" :ColonneWidth="colonneWidth"
        :GetFunction="InvoicesList">
        <template v-slot:[SlotsFilters.Filter0]="{index}" >
            <ColumnFilter :title="$t('invoices.reference')" :Filter="FieldReference" :getList="InvoicesList"
                @cancel="FieldReference = null" :index="index">
                <template v-slot:input> 
                    <v-text-field v-model="FieldReference" id="FieldReference" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter1]="{index}" >
            <ColumnFilter :title="$t('invoices.title')" :Filter="FieldName" :getList="InvoicesList"
                @cancel="FieldName = null" :index="index">
                <template v-slot:input> 
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter2]>
            <ColumnFilter :title="$t('invoices.company')" :Filter="FieldCompany" :getList="InvoicesList"
                @cancel="FieldCompany = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldCompany" id="FieldCompany" variant="outlined" maxlength="33"
                        hide-details></v-text-field> 
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter3]>
            <ColumnFilter :title="$t('purchaseOrder.supplier')" :Filter="FieldSupplier" :getList="InvoicesList"
                @cancel="FieldSupplier = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldSupplier" id="FieldSupplier" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter4]>
            <ColumnFilter :title="$t('invoices.amountTTC')" :Filter="FieldAmountTTC" :getList="InvoicesList"
                @cancel="FieldAmountTTC = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldAmountTTC" id="FieldAmountTTC" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter5]>
            <ColumnFilter :title="$t('invoices.deadline')" :Filter="FieldDeadline" :getList="InvoicesList"
                @cancel="FieldDeadline = null">
                <template v-slot:input>
                    <dateField v-model="FieldDeadline" />
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter6]>
            <ColumnFilter :title="$t('invoices.status')" :Filter="FieldStatus" :getList="InvoicesList"
                @cancel="FieldStatus = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldStatus" inline style="margin-top:6px ;">
                        <v-radio :label="isSubmitted" :value=0 id="Submitted" name="Submitted"></v-radio>
                        <v-radio :label="isRefused" :value=1 id="Refused" name="Refused"></v-radio>
                        <v-radio :label="isValidated" :value=2 id="Validated" name="Validated"></v-radio>
                        <v-radio :label="isPaid" :value=3 id="Paid" name="Paid"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter7]>
            <ColumnFilter :title="$t('invoices.creationDate')" :Filter="FieldCreatedDate" :getList="InvoicesList"
                @cancel="FieldCreatedDate = null">
                <template v-slot:input>
                    <span lang="en-CA" xml:lang="en-CA">
                        <dateField v-model="FieldCreatedDate" />
                    </span>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsItems.item0]="{ item }">{{ item.reference }}</template>
        <template v-slot:[SlotsItems.item1]="{ item }">{{ item.title }}</template>
        <template v-slot:[SlotsItems.item2]="{ item }">
            <span v-if="item.company">{{ item.company.brandName }}</span>
            <v-chip v-else color="red">
                {{ $t('invoices.alertDelete') }}
            </v-chip>
        </template>
        <template v-slot:[SlotsItems.item3]="{ item }">
            <span v-if="item.supplier">{{ item.supplier.brandName }}</span>
            <v-chip v-else color="red">
                {{ $t('invoices.alertDelete') }}
            </v-chip>
        </template>
        <template v-slot:[SlotsItems.item4]="{ item }">{{ item.amountTTC }} {{ item.devise }}</template>
        <template v-slot:[SlotsItems.item5]="{ item }">{{ item.deadline.split('T')[0] }}</template>
        <template v-slot:[SlotsItems.item6]="{ item }">
            <StatusButton v-if="item" :title="statut(item)" />
        </template>
        <template v-slot:[SlotsItems.item7]="{ item }">{{ item.created.split('T')[0] }}</template>
        <template v-slot:edit="{ item, index }">
            <EditInvoice v-bind:getInvoice="item" :Id="index" v-bind:getInvoicesListAfterDelete="InvoicesList" />
        </template>
    </gridComponent>
</template>
<script>
import EditInvoice from "./EditInvoice.vue";
import StatusButton from "@/genericComponent/StatusButton.vue";
import gridComponent from "@/genericComponent/gridComponent.vue";
import { GetAllInvoices, GetCompanyFromInvoices, CheckPermissionByUser, isClient, isSupplier, GetStatusInvoices } from "@/api/index";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: { EditInvoice, StatusButton, gridComponent, ColumnFilter, dateField },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: {},
            itemsData: {},
            CompanyList: [],
            search: null,
            FieldReference: null,
            FieldName: null,
            FieldDeadline: null,
            FieldCompany: null,
            FieldSupplier: null,
            FieldAmountTTC: null,
            FieldStatus: null,
            FieldCreatedDate: null,
            WithStatus: false,
            dialogCompose: false,
            SortedBy: null,
            SortingType: null,
            HasPermissionValidation: false,
            isClientVar: this.isClient(),
            isSupplierVar: this.isSupplier(),
        };
    },
    created() {
        this.GetStatusInvoices();
    },
    mounted() {
        this.GetCompanyList();
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.InvoicesList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        GetCompanyList() {
            GetCompanyFromInvoices("")
                .then((res) => {
                    this.CompanyList = res.data;
                })
        },
        InvoicesList: function (page) {
            if(page){ this.page = page }
            CheckPermissionByUser("Optima.Invoices.Validate").then((response) => {
                this.HasPermissionValidation = response.data
            }).finally(() => {
                GetAllInvoices(this.page, this.PageSize, this.FieldReference, !this.FieldName ? this.search : this.FieldName, this.FieldCompany, this.FieldSupplier, this.FieldAmountTTC, this.FieldDeadline, this.FieldStatus, this.FieldCreatedDate, this.SortedBy, this.SortingType, this.HasPermissionValidation).then((response) => {
                    this.items = response.data.items;
                    this.itemsData.numberOfPage = response.data.totalPages;
                    this.itemsData.totalItemsCount = response.data.totalCount;
                    this.itemsData.pageNumber = response.data.pageNumber;
                    this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                    this.itemsData.hasNextPage = response.data.hasNextPage
                    this.loader = false;
                });
            })
        },
        isClient() {
            return isClient()
        },
        isSupplier() {
            return isSupplier()
        },
        GetStatusInvoices() {
            GetStatusInvoices().then(response => {
                localStorage.setItem(status.Submitted + this.$i18n.availableLocales[0], response.data.Submitted.en);
                localStorage.setItem(status.Submitted + this.$i18n.availableLocales[1], response.data.Submitted.fr);
                localStorage.setItem(status.Refused + this.$i18n.availableLocales[0], response.data.Refused.en);
                localStorage.setItem(status.Refused + this.$i18n.availableLocales[1], response.data.Refused.fr);
                localStorage.setItem(status.Validated + this.$i18n.availableLocales[0], response.data.Validated.en);
                localStorage.setItem(status.Validated + this.$i18n.availableLocales[1], response.data.Validated.fr);
                localStorage.setItem(status.Paid + this.$i18n.availableLocales[0], response.data.Paid.en);
                localStorage.setItem(status.Paid + this.$i18n.availableLocales[1], response.data.Paid.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => {
                switch (item.validationStatus) {
                    case 0: return localStorage.getItem(status.Submitted + this.$i18n.locale);
                    case 1: return localStorage.getItem(status.Refused + this.$i18n.locale);
                    case 2: return localStorage.getItem(status.Validated + this.$i18n.locale);
                    case 3: return localStorage.getItem(status.Paid + this.$i18n.locale);
                    default: return localStorage.getItem(status.Submitted + this.$i18n.locale);
                }
            };
        },
        headers() {
            const headers = [
                this.$t('invoices.reference'),
                this.$t('invoices.title'),
                this.$t('invoices.company'),
                this.$t('invoices.supplier'),
                this.$t('invoices.amountTTC'),
                this.$t('invoices.deadline'),
                this.$t('invoices.status'),
                this.$t('invoices.creationDate'),
            ];

            const isClient = this.isClientVar;
            const isSupplier = this.isSupplierVar;

            if (isClient && !isSupplier) {
                return headers.slice(0, 2).concat(headers.slice(3));
            }
            if (isSupplier && !isClient) {
                return headers.slice(0, 3).concat(headers.slice(4));
            }

            return headers;
        },
        SlotsItems() {
            const items = {
                item0: 'item',
                item1: 'item',
                item2: 'item',
                item3: 'item',
                item4: 'item',
                item5: 'item',
                item6: 'item',
                item7: 'item',
            }
            if (this.isSupplierVar && !this.isClientVar) {
                delete items.item3;
            }
            if (this.isClientVar && !this.isSupplierVar) {
                delete items.item2;
            }
            let i = 0;
            for (const key in items) {
                items[key] = 'item' + i
                i = i + 1;
            }
            return items;
        },
        SlotsFilters() {
            const filters = {
                Filter0: 'Filter',
                Filter1: 'Filter',
                Filter2: 'Filter',
                Filter3: 'Filter',
                Filter4: 'Filter',
                Filter5: 'Filter',
                Filter6: 'Filter',
                Filter7: 'Filter',
            }
            if (this.isSupplierVar && !this.isClientVar) {
                delete filters.Filter3;
            }
            if (this.isClientVar && !this.isSupplierVar) {
                delete filters.Filter2;
            }
            let i = 0;
            for (const key in filters) {
                filters[key] = 'Filter' + i
                i = i + 1;
            }
            return filters;
        },
        colonneWidth() {
            const headers = ['10%', '35%', '15%', '15%', '5%', '5%', '5%', '5%'];
            const isClient = this.isClientVar;
            const isSupplier = this.isSupplierVar;

            if (isClient && !isSupplier) {
                return headers.slice(0, 1).concat(headers.slice(2));
            }
            if (isSupplier && !isClient) {
                return headers.slice(0, 2).concat(headers.slice(3));
            }

            return headers;
        },
        isSubmitted() {
            return localStorage.getItem(status.Submitted + this.$i18n.locale);
        },
        isPaid() {
            return localStorage.getItem(status.Paid + this.$i18n.locale);
        },
        isRefused() {
            return localStorage.getItem(status.Refused + this.$i18n.locale);
        },
        isValidated() {
            return localStorage.getItem(status.Validated + this.$i18n.locale);
        }
    }
};
</script>