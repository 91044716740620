<template>
    <v-dialog v-model="addUserDialog">
        <v-card width="800px" class="py-6">
            <v-form ref="form" lazy-validation>
                <h1>{{ $t('configuration.user.add') }}</h1>
                <div class="row ma-2 my-8">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.user.Fullname') }} <span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="userName" id="userName" name="userName" hide-details
                                persistent-placeholder required maxlength="30"
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.Fullname') })"
                                variant="outlined">
                            </v-text-field>
                            <span class="warningHint" id="WarningMsgUserName">{{ msg.userName }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.user.email') }} <span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="email" id="email" name="email" type="email" maxlength="40" hide-details
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.email') })"
                                variant="outlined">
                            </v-text-field>
                            <span class="warningHint" id="WarningMsgEmail">{{ msg.email }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.user.role') }} <span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="role" name="role" variant="outlined" v-model="role" :items="itemsRoleList"
                                item-title="name" item-value="name"
                                :placeholder="$t('placeholder.select', { itm: $t('configuration.user.role') })" hide-details>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.title">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class="warningHint" id="WarningMsgRole">{{ msg.role }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.user.department') }} <span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-select variant="outlined" v-if="LoaderDepartment == false" disabled hide-details
                                :placeholder="$t('placeholder.select', { itm: $t('configuration.user.department') })">
                                <template #append-inner>
                                    <v-progress-circular v-if="LoaderDepartment == false" indeterminate size="40">
                                    </v-progress-circular>
                                </template>
                            </v-select>
                            <v-select id="Department" name="Department" v-else variant="outlined" v-model="departement"
                                :items="DepartmentList" item-title="departmentName" item-value="idDepartment"
                                :placeholder="$t('placeholder.select', { itm: $t('configuration.user.department') })"
                                hide-details>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item style="background-color: white;" v-bind="props"
                                        :title="item.raw.departmentName" variant="text" :id="index" :name="item.title">
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class="warningHint" id="WarningMsgDepartment">{{ msg.departement }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.user.regionCoverage') }} <span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <select-country-by-location v-bind:multi="true" v-bind:countryProps="regionCoverage"
                                @getCountry="getCountryFromSelect" :displayActifLocation="true"></select-country-by-location>
                            <span class="warningHint" id="WarningMsgRegionCoverage">{{ msg.regionCoverage }}</span>
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateForm" v-model:no="addUserDialog" width="300px"
                    :yesTitle="$t('configuration.user.add')" />
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import SelectCountryByLocation from '../SelectCountry/SelectCountryByLocation.vue';
import { addUser, getAllDepartment, getRolesByCompany, ExistCompanyEmail } from "@/api/index";
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    components: {
        SelectCountryByLocation, cardActions
    },
    data() {
        return {
            DepartmentList: [],
            itemsRoleList: [],
            msg: [],
            idCompany: this.$route.params.id,
            addUserDialog: true,
            id: "",
            userName: null,
            role: null,
            departement: null,
            LoaderDepartment: false,
            email: "",
            existMail: false,
            regionCoverage: null,
        };
    },
    watch: {
        addUserDialog() {
            this.$emit("add-user-close-dialog");
            this.addUserDialog = true
            this.userName = null
            this.email = null
            this.role = null
            this.departement = null
            this.regionCoverage = null
            this.msg = []
        },
        email() {
            this.existMail=null
            // eslint-disable-next-line
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                ExistCompanyEmail(this.email).then((res) => {
                    this.existMail = res.data;
                })
            }
        },
    },
    methods: {
        getDepartmentList: function () {
            getAllDepartment().then((res) => {
                this.DepartmentList = res.data.items;
                this.LoaderDepartment = true
            })
        },
        getRolesByCompany: function () {
            getRolesByCompany().then((res) => {
                this.itemsRoleList = res.data.items;
            })
        },

        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        createNewUUID() {
            this.id = this.generateUUID();
        },
        generateUUID() { 
            var d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
                d += performance.now();
            }
            var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return newGuid;
        },
        getCountryFromSelect(value) {
            this.regionCoverage = value;
        },
        AddUser() {
            addUser(
                this.idCompany,
                this.id,
                this.userName,
                this.email,
                this.regionCoverage.join(),
                this.departement,
                this.role
            )
                .then(() => {
                    this.$emit("reloadListUser");
                    this.ActiveSavedSnackBar();
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                });
            setTimeout(() => {
                this.id = null;
                this.userName = null;
                this.email = null;
                this.regionCoverage = null;
                this.departement = null;
                this.role = null;
            }, 2000);
        },
        validateForm() {
            this.id = this.generateUUID();
            if (!(this.userName) || (this.userName).length == 0) {
                this.msg['userName'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.Fullname') });
            }
            else {
                this.userName = (this.userName.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.userName))) {
                    this.msg['userName'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else if (!(this.userName.split(/\W+/).length >= 2)) {
                    this.msg['userName'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else if (!(this.userName.split(' ')[1].length >= 1)) {
                    this.msg['userName'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else {
                    this.msg['userName'] = '';
                }
            }
            if (!this.email || this.email.length == 0) {
                this.msg["email"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.email') });
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
            ) {
                this.msg["email"] = "";
            } else {
                this.msg["email"] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.email') });
            }
            if (!this.id || this.id.length == 0) {
                this.msg["id"] = "ID is required";
            } else if (this.id.length != 36) {
                this.msg["id"] = "ID is invalid";
            } else {
                this.msg["id"] = "";
            }
            if (!this.departement || this.departement.length == 0 || this.departement == "Select departement") {
                this.msg["departement"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.department') });
            } else {
                this.msg["departement"] = "";
            }
            if (this.regionCoverage == null || this.regionCoverage == "") {
                this.msg["regionCoverage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.regionCoverage') });
            } else {
                this.msg["regionCoverage"] = "";
            }
            if (this.role == "Select role" || this.role == null) {
                this.msg["role"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.role') });
            } else {
                this.msg["role"] = "";
            }
            if (this.existMail) {
                this.msg["email"] = this.$t('warningInput.exist', { itm: this.$t('configuration.user.email') });
            } else if (
                this.msg["id"] == "" &&
                this.msg["userName"] == "" &&
                this.msg["email"] == "" &&
                this.msg["departement"] == "" &&
                this.msg["role"] == "" &&
                this.msg["regionCoverage"] == ""
            ) {
                this.addUserDialog = false;
                this.$emit("add-user-close-dialog");
                this.AddUser();
            }
        },
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}
.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>