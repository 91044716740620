<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['20%', '15%', '20%', '10%']" :GetFunction="SecurityGroupList">
        <template v-slot:item0="{ item }">{{ item.name }}</template>
        <template v-slot:item1="{ item }">{{ item.company }}</template>
        <template v-slot:item2="{ item }">{{ item.description }}</template>
        <template v-slot:item3="{ item }">
            <StatusButton :title="statut(item)" />
        </template>
        <template v-slot:item4="{ item }">{{ item.createdOnUtc.split('T')[0] }}</template>
        <template v-slot:edit="{ item, index }">
            <EditRole v-bind:getRole="item" :RoleId="item.id" :Id="index"
                v-bind:getRoleListAfterDelete="SecurityGroupList" />
        </template>
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('permissions.title')" :Filter="SecurityGroupFilters.name" :getList="SecurityGroupList"
                @cancel="delete SecurityGroupFilters.name">
                <template v-slot:input>
                    <v-text-field v-model="SecurityGroupFilters.name" id="FieldTitle" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('permissions.company')" :Filter="SecurityGroupFilters.organisation"
                :getList="SecurityGroupList" @cancel="delete SecurityGroupFilters.organisation">
                <template v-slot:input>
                    <v-text-field v-model="SecurityGroupFilters.organisation" id="FieldOrganisation" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('permissions.description')" :Filter="SecurityGroupFilters.description"
                :getList="SecurityGroupList" @cancel="delete SecurityGroupFilters.description">
                <template v-slot:input>
                    <v-text-field v-model="SecurityGroupFilters.description" id="FieldDescription" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter4>
            <ColumnFilter :title="$t('permissions.creationDate')" :Filter="SecurityGroupFilters.createdDate"
                :getList="SecurityGroupList" @cancel="delete SecurityGroupFilters.createdDate">
                <template v-slot:input>
                    <span lang="en-CA" xml:lang="en-CA">
                        <dateField v-model="SecurityGroupFilters.createdDate" />
                    </span>
                </template>
            </ColumnFilter>
        </template>
    </gridComponent>
</template>
<script>
import EditRole from "./EditRole/EditRole.vue";
import StatusButton from "@/genericComponent/StatusButton.vue";
import gridComponent from "@/genericComponent/gridComponent.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
import { GetAllSecurityGroup, GetStatusSecurityGroup } from "@/api/index";
import { status } from "@/enums/Status";
export default {
    components: { gridComponent, StatusButton, EditRole, ColumnFilter, dateField },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            SecurityGroupFilters: {},
            SortedBy: "",
            SortingType: "DESC",
            items: {},
            itemsData: {},
        };
    },
    created() {
        this.GetStatusSecurityGroup();
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.SecurityGroupFilters.name = event;
            this.SecurityGroupList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        SecurityGroupList: function (page) {
            if(page){ this.page = page }
            GetAllSecurityGroup(this.page, this.PageSize, this.SecurityGroupFilters, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items;
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            });
        },
        GetStatusSecurityGroup() {
            GetStatusSecurityGroup().then(response => {
                localStorage.setItem(status.Completed + this.$i18n.availableLocales[0], response.data.Completed.en);
                localStorage.setItem(status.Completed + this.$i18n.availableLocales[1], response.data.Completed.fr);
                localStorage.setItem(status.Draft + this.$i18n.availableLocales[0], response.data.Draft.en);
                localStorage.setItem(status.Draft + this.$i18n.availableLocales[1], response.data.Draft.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => item.status == 0 ? localStorage.getItem(status.Draft + this.$i18n.locale) : localStorage.getItem(status.Completed + this.$i18n.locale)
        },
        headers() {
            return [this.$t('permissions.title'), this.$t('permissions.company'), this.$t('permissions.description'), this.$t('permissions.status'), this.$t('permissions.creationDate')]
        }
    }
};
</script>