<template>
    <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12">
                <v-window v-model="step" disabled>
                    <v-window-item :value="1">
                        <svg class="d-flex mb-5" width="88" height="88" viewBox="0 0 88 88" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="44" cy="44" r="44" fill="#3BBAFF" fill-opacity="0.12" />
                            <path opacity="0.3"
                                d="M30.1636 61.7511C29.687 61.7511 29.2103 61.5604 28.8289 61.1791L25.0154 57.3656C24.2527 56.6029 24.2527 55.4588 25.0154 54.6961C25.7781 53.9334 27.0175 53.9334 27.6849 54.6961L30.1636 57.1749L36.456 50.8826C37.2187 50.1199 38.3627 50.1199 39.1254 50.8826C39.8881 51.6453 39.8881 52.7893 39.1254 53.552L31.4984 61.1791C31.117 61.5604 30.6403 61.7511 30.1636 61.7511Z"
                                fill="#3BBAFF" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M46.2982 56.2802C45.7862 48.5026 39.3155 42.3525 31.4083 42.3525C30.3862 42.3525 29.3881 42.4553 28.4238 42.651V34.3939C28.4238 32.1962 30.2054 30.4146 32.4032 30.4146H60.2585C62.4562 30.4146 64.2378 32.1962 64.2378 34.3939V52.3009C64.2378 54.4986 62.4562 56.2802 60.2585 56.2802H46.2982ZM58.5561 34.0748L46.3308 40.6577L34.1055 34.0748C33.3798 33.6841 32.4748 33.9556 32.0841 34.6812C31.6934 35.4069 31.9649 36.3119 32.6905 36.7026L45.6233 43.6664C46.065 43.9042 46.5966 43.9042 47.0383 43.6664L59.9711 36.7026C60.6967 36.3119 60.9682 35.4069 60.5775 34.6812C60.1868 33.9556 59.2818 33.6841 58.5561 34.0748Z"
                                fill="#3BBAFF" />
                        </svg>
                        <span class="loginText"> {{ $t('verifyMail.verifyCode') }} </span>
                        <span class="forgetmailText">
                            <span>{{ $t('verifyMail.sentAlink') }} </span>
                            <a style="color:#114EF7; font-weight: bold; text-decoration: none;">{{ email }}</a>
                        </span>
                        <br>
                        <v-text-field v-model="VerificationCode" id="VerificationCode" name="VerificationCode" maxlength="6"
                            :label="$t('verifyMail.enterVerificationCode')" variant="outlined" single-line full-width
                            hide-details shaped>
                            <template v-slot:prepend-inner>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17 3H7C4 3 2 4.5 2 8V15C2 18.5 4 20 7 20H10.57C11.16 20 11.64 19.48 11.56 18.89C11.46 18.18 11.48 17.44 11.63 16.68C12.16 14.08 14.3 12.01 16.92 11.58C18.23 11.37 19.48 11.55 20.58 12.03C21.25 12.32 22 11.86 22 11.13V8C22 4.5 20 3 17 3ZM17.47 8.59L14.34 11.09C13.68 11.62 12.84 11.88 12 11.88C11.16 11.88 10.31 11.62 9.66 11.09L6.53 8.59C6.21 8.33 6.16 7.86 6.41 7.53C6.67 7.21 7.14 7.15 7.46 7.41L10.59 9.91C11.35 10.52 12.64 10.52 13.4 9.91L16.53 7.41C16.85 7.15 17.33 7.2 17.58 7.53C17.84 7.86 17.79 8.33 17.47 8.59Z"
                                        fill="#C5CDD4" />
                                    <path
                                        d="M18 13C15.24 13 13 15.23 13 18C13 20.76 15.24 23 18 23C20.77 23 23 20.76 23 18C23 15.23 20.77 13 18 13ZM20.05 17.55L19.68 17.91L17.5 20.1C17.4 20.19 17.21 20.29 17.07 20.31L16.09 20.45C15.74 20.5 15.49 20.25 15.54 19.9L15.68 18.92C15.7 18.78 15.79 18.59 15.89 18.49L18.07 16.32L18.43 15.95C18.67 15.71 18.94 15.55 19.22 15.55C19.46 15.55 19.73 15.66 20.03 15.95C20.7 16.6 20.49 17.11 20.05 17.55Z"
                                        fill="#C5CDD4" />
                                </svg>
                            </template>
                        </v-text-field>
                        <span class='warningHint' id="codeErrorMsg"> {{ msg.VerificationCode }}</span>
                    </v-window-item>
                    <v-window-item :value="2">
                        <svg class="d-flex mb-5" width="88" height="88" viewBox="0 0 88 88" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="44" cy="44" r="44" fill="#3BBAFF" fill-opacity="0.12" />
                            <path
                                d="M43.9999 17.3335C29.3066 17.3335 17.3333 29.3068 17.3333 44.0002C17.3333 58.6935 29.3066 70.6668 43.9999 70.6668C58.6932 70.6668 70.6666 58.6935 70.6666 44.0002C70.6666 29.3068 58.6932 17.3335 43.9999 17.3335ZM56.7466 37.8668L41.6266 52.9868C41.2533 53.3602 40.7466 53.5735 40.2133 53.5735C39.6799 53.5735 39.1733 53.3602 38.7999 52.9868L31.2533 45.4402C30.4799 44.6668 30.4799 43.3868 31.2533 42.6135C32.0266 41.8402 33.3066 41.8402 34.0799 42.6135L40.2133 48.7468L53.9199 35.0402C54.6932 34.2668 55.9733 34.2668 56.7466 35.0402C57.5199 35.8135 57.5199 37.0668 56.7466 37.8668Z"
                                fill="#3BBAFF" />
                        </svg>
                        <span class="loginText"> {{ $t('verifyMail.thank') }} </span>
                        <span class="forgetmailText" style="margin-top: 20px;">
                            {{ $t('verifyMail.thankMSG') }}
                        </span>
                        <br>
                    </v-window-item>
                </v-window>
                <br>
                <v-btn block size="x-large" id="VerifyBTN" name="VerifyBTN" rounded="lg" dark color="#114EF7"
                    text-color="white" @click="validateForm()">
                    <span class="buttonText" v-if="step == 2"> {{ $t('verifyMail.startedToPortal').toUpperCase() }} </span>
                    <span class="buttonText" v-if="step == 1"> {{ $t('verifyMail.verify').toUpperCase() }} </span>
                </v-btn>
                <br>
                <v-row align="justify">
                    <v-col align="justify" v-if="step == 1">
                        <span class="linkText" style="cursor: default;">
                            {{ $t('verifyMail.noReceiveEmail') }}
                            <span class="linkText" id="Resend" name="Resend"
                                style="color:#114EF7; font-weight: bold; cursor: pointer;"
                                @click="SendEmailConfirmationCode()">
                                {{ $t('verifyMail.resend') }}
                            </span>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { SendEmailConfirmationCode, EmailConfirmation } from "@/api/index";
export default {
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.verifyCode');
        this.email = this.$route.params.email
    },
    name: "VerifyEmail",
    data: () => ({
        step: 1,
        email: '',
        VerificationCode: '',
        msg: [],
    }),
    methods: {
        SendEmailConfirmationCode() {
            SendEmailConfirmationCode(this.email);
        },
        validateForm() {
            if (this.step == 1) {
                if (!(this.VerificationCode) || (this.VerificationCode).length == 0) {
                    this.msg['VerificationCode'] = this.$t('verifyMail.verificationCodeIsRequired');
                }
                else {
                    EmailConfirmation(this.email, this.VerificationCode).then(() => {
                        this.msg['VerificationCode'] = '';
                        this.step = 2;
                    }).catch(() => {
                        this.msg['VerificationCode'] = this.$t('verifyMail.wrongVerificationCode');
                    });
                }
            }
            if (this.step >= 2) {
                this.$router.push({ name: 'login' })
            } else if (this.msg['VerificationCode'] == '') {
                this.step = 2;
            }
        },
    }
}
</script>