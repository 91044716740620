<template>
    <span class="backgruond"></span>
    <div v-if="window.width <= 800" class="header">
        <div class="d-flex justify-center  py-4 ">
            <v-img max-height="150" max-width="200" src="@/assets/logo.png" />
        </div>
    </div>
    <div v-if="window.width <= 800" class="main">
        <router-view></router-view>
    </div>
    <div class="left">
        <div class="backgruond"></div>
        <div class="home">
            <div v-if="window.width >= 800" class="d-flex justify-start">
                <v-img max-height="150" max-width="200" src="@/assets/logo.png" />
            </div>
            <v-img max-width="80%" style="margin: 5% 10%" src="@/assets/photoSlider.svg" />
            <div class="Some">
                {{ $t('AuthTemplate.SomeUSPTextHere') }}
            </div>
            <br>
            <div class="Nisl">
                {{ $t('AuthTemplate.SomeUSPSubTextHere') }}
            </div>
            <br>
            <div class="term">
                <a>{{ $t('AuthTemplate.TermsOfService') }}</a> • <a>{{ $t('AuthTemplate.TermsOfUse') }}</a> •
                <a>{{ $t('AuthTemplate.PrivacyPolicy') }}</a>
            </div>
            <div class="right justify-end">
                {{ $t('AuthTemplate.AllRightsReserved') }}
            </div>
        </div>
    </div>
    <div class="picker">
        <the-language-picker />
    </div>
    <div v-if="window.width >= 800" class="main">
        <router-view></router-view>
    </div>
</template>
<script>
import TheLanguagePicker from "@/genericComponent/TheLanguagePicker.vue";
export default {
    components: {
        TheLanguagePicker
    },
    data() {
        return {
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    }
}
</script>
<style scoped>
.left {
    float: left;
    width: 40%;
    background-color: #114EF7;
    position: relative;
    z-index: 1;
}

.main {
    float: right;
    width: 60%;
    padding: 10% 10% 0;
    align-content: center;
    flex-direction: column;
}

.header {
    float: left;
    width: 100%;
    background-color: #114EF7;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
}

@media screen and (max-width: 800px) {

    .left,
    .main {
        width: 100%;
        padding: 10% 5%;
    }

    .left {
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
    }
}

@media screen and (min-width: 800px) {
    .backgruond {
        background-color: #114EF7 !important;
        height: 100%;
        width: 40%;
        display: block;
        position: fixed;
        background-size: cover;
        z-index: -1;
    }
}

.home {
    text-align: center;
    margin: 5% 10% 0;
    color: #FFFFFF
}

.Some {
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    letter-spacing: 0px;
    text-align: center;
}

.Nisl {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #E6EAEE;
}

.term {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.right {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

.picker {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 2%;
    margin-right: 2%;
}
</style>