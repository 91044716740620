<template>
    <div class="row ma-4 mt-2 mb-2">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.documentsTab.name') }}<span
                        class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="name" id="name" name="name" hide-details persistent-placeholder required
                    maxlength="30" :placeholder="$t('suppliers.documentsTab.placeholder.name')"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgName">{{ msg.name }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.documentsTab.date') }}<span
                        class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <dateField v-model="expiryDate" :minDate="new Date()"/>
                <span class='warningHint' id="WarningMsgExpiryDate"> {{ msg.expiryDate }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row mb-4">
            </div>
            <div class="row ml-0">
                <uploadDocument @addDocument="setidentityDocument" :showUpload="true">
                    <template v-slot:DocumentsList>
                        <span v-if="identityDocument" style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start"
                            @click="downloadDocument(identityDocument[0])" 
                            :title="$t('actions.download') + ' ' + identityDocument[0].name">
                            {{ identityDocument[0].name }}
                        </span>
                    </template>
                </uploadDocument>
                <span class='warningHint' id="WarningMsgDoc">{{ msg.identityDocument }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import dateField from "@/genericComponent/date-field.vue";
import uploadDocument from "@/genericComponent/uploadDocument.vue"
export default {
    components: { dateField, uploadDocument },
    props: {
        idSupplier: {},
    },
    data() {
        return {
            name: "",
            expiryDate: null,
            identityDocument: null,
            msg: [],
            documentData: {}
        }
    },
    watch: {
        name(value) {
            this.name = value;
            this.documentData.name = this.name;
            if (this.name == "")
                delete this.documentData.name
            this.$emit('getData', this.documentData)
        },
        expiryDate(value) {
            this.expiryDate = value;
            this.documentData.expiryDate = this.expiryDate;
            if (this.expiryDate == null)
                delete this.documentData.expiryDate
            this.$emit('getData', this.documentData)
        },
    },
    methods: {
        downloadDocument(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = file.name;
                downloadLink.click();
            }
        },
        setidentityDocument(value) {
            this.identityDocument = value
            this.documentData.identityDocument = this.identityDocument;
            if (this.identityDocument == null)
                delete this.documentData.identityDocument
            this.$emit('getData', this.documentData)
        },
        validateForm() {
            if (!this.name || this.name.length == 0) {
                this.msg["name"] = this.$t('suppliers.documentsTab.errors.name');
            } else {
                this.msg["name"] = "";
            }

            if (!this.expiryDate || this.expiryDate.length == 0) {
                this.msg["expiryDate"] = this.$t('suppliers.documentsTab.errors.date');
            } else if (this.expiryDate < "1000-01-01") {
                this.msg["expiryDate"] = this.$t('warningInput.wrong', { itm: this.$t('suppliers.documentsTab.date') });
            } else {
                this.msg["expiryDate"] = "";
            }
            if (this.identityDocument == null || this.identityDocument == "") {
                this.msg["identityDocument"] = this.$t('suppliers.documentsTab.errors.identityDocument');
            } else {
                this.msg["identityDocument"] = "";
            }
            if (
                this.msg["name"] == "" &&
                this.msg["expiryDate"] == "" &&
                this.msg["identityDocument"] == ""

            ) {
                return true;
            }
        },
    }
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>