<template>
    <div>
    </div>
</template>
<script>
export default {
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.myDashboard');
    },
}
</script>