
<template>
    <v-dialog :modelValue="modelValue">
        <v-card :width="width" style="padding: 20px; height: fit-content;">
            <v-avatar color="#FFF3EE" size="58">
                <svg v-if="DialogType == 'Delete'" width="28" height="28" viewBox="0 0 28 28" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24.4584 6.18822C22.6014 6.00368 20.7444 5.86527 18.8759 5.76146V5.74993L18.6221 4.2505C18.4491 3.18936 18.1953 1.59766 15.4964 1.59766H12.4744C9.78699 1.59766 9.53324 3.12016 9.34869 4.23896L9.10648 5.71533C8.03381 5.78453 6.96114 5.85374 5.88847 5.95754L3.53551 6.18822C3.05108 6.23436 2.70506 6.66112 2.75119 7.13402C2.79733 7.60692 3.21256 7.95294 3.69699 7.9068L6.04994 7.67612C12.0938 7.07635 18.1838 7.30703 24.2969 7.91834C24.3315 7.91834 24.3545 7.91834 24.3891 7.91834C24.8274 7.91834 25.2081 7.58385 25.2542 7.13402C25.2888 6.66112 24.9428 6.23436 24.4584 6.18822Z"
                        fill="#FF5C5C" />
                    <path
                        d="M22.3365 9.54553C22.0596 9.25718 21.679 9.0957 21.2868 9.0957H6.70776C6.3156 9.0957 5.92344 9.25718 5.65816 9.54553C5.39287 9.83389 5.24293 10.226 5.266 10.6297L5.98111 22.4637C6.10799 24.2169 6.26946 26.4084 10.2949 26.4084H17.6997C21.7251 26.4084 21.8866 24.2284 22.0135 22.4637L22.7286 10.6413C22.7517 10.226 22.6017 9.83389 22.3365 9.54553ZM15.912 20.6298H12.0711C11.5982 20.6298 11.2061 20.2376 11.2061 19.7647C11.2061 19.2918 11.5982 18.8997 12.0711 18.8997H15.912C16.3849 18.8997 16.777 19.2918 16.777 19.7647C16.777 20.2376 16.3849 20.6298 15.912 20.6298ZM16.8808 16.0162H11.1138C10.6409 16.0162 10.2487 15.624 10.2487 15.1511C10.2487 14.6782 10.6409 14.286 11.1138 14.286H16.8808C17.3537 14.286 17.7459 14.6782 17.7459 15.1511C17.7459 15.624 17.3537 16.0162 16.8808 16.0162Z"
                        fill="#FF5C5C" />
                </svg>
                <svg v-else-if="(DialogType == 'Status' && status)" width="23" height="23" viewBox="0 0 23 23" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.7166 5.29698L5.29991 18.7136C5.28074 18.7328 5.27116 18.7424 5.25199 18.752C4.88783 18.4453 4.55241 18.1099 4.24574 17.7457C2.78908 16.0686 1.91699 13.8836 1.91699 11.4974C1.91699 6.2074 6.21033 1.91406 11.5003 1.91406C13.8866 1.91406 16.0716 2.78615 17.7487 4.24281C18.1128 4.54948 18.4482 4.8849 18.7549 5.24906C18.7453 5.26823 18.7357 5.27781 18.7166 5.29698Z"
                        fill="#F25C5C" />
                    <path
                        d="M21.0833 11.5C21.0833 16.7612 16.7612 21.0833 11.5 21.0833C10.0625 21.0833 8.70165 20.7671 7.47499 20.1921C6.88082 19.9142 6.76582 19.1187 7.22582 18.6492L18.6492 7.22582C19.1092 6.76582 19.9142 6.88082 20.1921 7.47499C20.7671 8.69207 21.0833 10.0625 21.0833 11.5Z"
                        fill="#F25C5C" />
                    <path
                        d="M20.8633 2.1375C20.5758 1.85 20.1063 1.85 19.8188 2.1375L2.1375 19.8283C1.85 20.1158 1.85 20.5854 2.1375 20.8729C2.28125 21.0071 2.46333 21.0838 2.655 21.0838C2.84667 21.0838 3.02875 21.0071 3.1725 20.8633L20.8633 3.1725C21.1604 2.885 21.1604 2.425 20.8633 2.1375Z"
                        fill="#F25C5C" />
                </svg>
                <svg v-else-if="(DialogType == 'Status' && !status)" width="23" height="23" viewBox="0 0 23 23" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.1105 4.95984C15.2005 1.04984 8.85045 1.04984 4.94045 4.95984C0.960454 8.93984 1.03045 15.4298 5.14045 19.3298C8.94045 22.9198 15.1005 22.9198 18.9005 19.3298C23.0205 15.4298 23.0905 8.93984 19.1105 4.95984ZM16.3805 16.6498C15.1805 17.7898 13.6005 18.3598 12.0205 18.3598C10.4405 18.3598 8.86045 17.7898 7.66045 16.6498C7.36045 16.3598 7.35045 15.8898 7.63045 15.5898C7.92045 15.2898 8.39045 15.2798 8.69045 15.5598C10.5205 17.2898 13.5105 17.2998 15.3505 15.5598C15.6505 15.2798 16.1305 15.2898 16.4105 15.5898C16.7005 15.8898 16.6805 16.3598 16.3805 16.6498Z"
                        fill="#F25C5C" />
                </svg>
                <svg v-else-if="(DialogType == 'Retry')" width="28" height="28" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.6667 2.5L13.7 4.45002L7.07499 4.43334C4.09999 4.43334 1.65833 6.87502 1.65833 9.86669C1.65833 11.3584 2.26666 12.7167 3.24999 13.7"
                        stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M8.33338 17.4998L6.30005 15.5498L12.9251 15.5665C15.9001 15.5665 18.3417 13.1248 18.3417 10.1331C18.3417 8.64145 17.7334 7.28314 16.7501 6.2998"
                        stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10.2084 12.2249V7.7749L8.95837 9.16655" stroke="#FF5C5C" stroke-width="1.25"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg v-else-if="(DialogType == 'Reset')" width="28" height="28" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.6667 2.5L13.7 4.45002L7.07499 4.43334C4.09999 4.43334 1.65833 6.87502 1.65833 9.86669C1.65833 11.3584 2.26666 12.7167 3.24999 13.7"
                        stroke="#0057FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M8.33332 17.4998L6.29999 15.5498L12.925 15.5665C15.9 15.5665 18.3417 13.1248 18.3417 10.1331C18.3417 8.64145 17.7333 7.28314 16.75 6.2998"
                        stroke="#0057FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg v-else-if="(DialogType == 'Validation')" width="28" height="28" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" stroke="green" stroke-width="2" d="m2.13,10.7 4.87,4.87 11.3-11.3" />
                </svg>
                <svg v-else-if="(DialogType == 'Refuse')" xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                <svg v-else-if="(DialogType == 'Payment')" width="28" height="28" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4"
                        d="M16.5 5.8273V6.7498H1.5V5.6548C1.5 3.9373 2.895 2.5498 4.6125 2.5498H12V4.4773C12 5.4298 12.57 5.9998 13.5225 5.9998H15.7275C16.0275 5.9998 16.2825 5.9473 16.5 5.8273Z"
                        fill="#00CBA0" />
                    <path
                        d="M1.5 6.75V12.345C1.5 14.0625 2.895 15.45 4.6125 15.45H13.3875C15.105 15.45 16.5 14.0625 16.5 12.345V6.75H1.5ZM6 12.9375H4.5C4.1925 12.9375 3.9375 12.6825 3.9375 12.375C3.9375 12.0675 4.1925 11.8125 4.5 11.8125H6C6.3075 11.8125 6.5625 12.0675 6.5625 12.375C6.5625 12.6825 6.3075 12.9375 6 12.9375ZM10.875 12.9375H7.875C7.5675 12.9375 7.3125 12.6825 7.3125 12.375C7.3125 12.0675 7.5675 11.8125 7.875 11.8125H10.875C11.1825 11.8125 11.4375 12.0675 11.4375 12.375C11.4375 12.6825 11.1825 12.9375 10.875 12.9375Z"
                        fill="#00CBA0" />
                    <path
                        d="M15.7275 0.75H13.5225C12.57 0.75 12 1.32 12 2.2725V4.4775C12 5.43 12.57 6 13.5225 6H15.7275C16.68 6 17.25 5.43 17.25 4.4775V2.2725C17.25 1.32 16.68 0.75 15.7275 0.75ZM16.4325 3.6975C16.3575 3.7725 16.245 3.825 16.125 3.8325H15.0675L15.075 4.875C15.0675 5.0025 15.0225 5.1075 14.9325 5.1975C14.8575 5.2725 14.745 5.325 14.625 5.325C14.3775 5.325 14.175 5.1225 14.175 4.875V3.825L13.125 3.8325C12.8775 3.8325 12.675 3.6225 12.675 3.375C12.675 3.1275 12.8775 2.925 13.125 2.925L14.175 2.9325V1.8825C14.175 1.635 14.3775 1.425 14.625 1.425C14.8725 1.425 15.075 1.635 15.075 1.8825L15.0675 2.925H16.125C16.3725 2.925 16.575 3.1275 16.575 3.375C16.5675 3.5025 16.515 3.6075 16.4325 3.6975Z"
                        fill="#00CBA0" />
                </svg>
            </v-avatar>
            <v-card-title>{{ DialogType == 'Delete' ? $t("actions.delete") : DialogType == 'Status' ? $t("actions.mark") :
                DialogType == 'Validation' ? $t("actions.validationOf") : DialogType == 'Refuse' ? $t("actions.refuseOf") :
                    DialogType == 'Payment' ? $t("actions.payment") : $t("actions.retryStatut") }} {{ title }}
                {{ DialogType == 'Status' ? status ? $t("actions.as", { itm: $t("status.inActive") }) :
                    $t("actions.as", { itm: $t("status.active") }) : '' }} ?
            </v-card-title>
            <v-card-text v-if="DialogType == 'Delete'">
                {{ $t("dialogDelete.delete", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Status' && status)">
                {{ $t("dialogDelete.statusInActive", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Status' && !status)">
                {{ $t("dialogDelete.statusActive", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Retry')">
                {{ $t("dialogDelete.pending", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Reset')">
                {{ $t("dialogDelete.reset", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Validation')">
                {{ $t("dialogDelete.validation", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Refuse')">
                {{ $t("dialogDelete.refuse", { itm: title }) }}
            </v-card-text>
            <v-card-text v-else-if="(DialogType == 'Payment')">
                {{ $t("dialogDelete.payment", { itm: title }) }}
            </v-card-text>
            <card-actions :loader="loader" :yesTitle="confirmDelete" :yes="yes" v-model:no="noValue"
                :width="(((parseInt(width.split('p')[0])) - 50) / 2) + 'px'" />
        </v-card>
    </v-dialog>
</template> 
<script>
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    components: { cardActions },
    props: {
        DialogType: { default: 'Delete' },
        yes: { type: Function },
        no: { type: Boolean },
        status: {},
        title: {},
        width: { default: "420px" },
        yesTitle: { default: "Yes, Delete" },
        modelValue: { type: Boolean },
        loader: { type: Boolean, default: false, }
    },
    data() {
        return {
            noValue: true
        }
    },
    watch: {
        modelValue() {
            this.noValue = this.$props.modelValue
        },
        noValue(value) {
            this.noValue = value;
            if (this.noValue == false) {
                this.$emit('update:modelValue', false)
            }
        },
    },
    computed: {
        confirmDelete() {
            return this.DialogType == 'Delete' ? this.$t("dialogDelete.confirmDelete") : this.$t("dialogDelete.confirm")
        },
    }
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    color: #0d0849;
    padding-left: 0px !important;
    padding-top: 10px;
    padding-bottom: 0px;
    white-space: normal !important;
}

.v-card-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #768da9;
    padding-left: 0px !important;
    padding-top: 5px !important;
    padding-right: 0px !important;
}
</style>