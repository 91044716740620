<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['20%', '25%', '15%', '10%', '10%', '25%']" :GetFunction="getSuppliersList">
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('suppliers.title')" :Filter="FieldName" :getList="getSuppliersList"
                @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('suppliers.manager')" :Filter="FieldManager" :getList="getSuppliersList"
                @cancel="FieldManager = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldManager" id="FieldManager" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('suppliers.country')" :Filter="FieldCountry" :getList="getSuppliersList"
                @cancel="FieldCountry = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldCountry" id="FieldCountry" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('suppliers.payment')" :Filter="FieldPayment" :getList="getSuppliersList"
                @cancel="FieldPayment = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldPayment" id="FieldPayment" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter4>
            <ColumnFilter :title="$t('suppliers.status')" :Filter="FieldStatus" :getList="getSuppliersList"
                @cancel="FieldStatus = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldStatus" column style="margin-top: 6px;">
                        <v-radio :label="isCreated" :value="1" id="Created" name="Created" class="my-2"></v-radio>
                        <v-radio :label="isvalidatedClient1" :value="2" id="validatedClient1" name="validatedClient1"
                            class="my-2"></v-radio>
                        <v-radio :label="isvalidatedBySupplier" :value="3" id="validatedBySupplier"
                            name="validatedBySupplier" class="my-2"></v-radio>
                        <v-radio :label="isvalidatedByClient2" :value="4" id="validatedByClient2" name="validatedByClient2"
                            class="my-2"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter5>
            <ColumnFilter :title="$t('suppliers.creationDate')" :Filter="FieldCreatedDate" :getList="getSuppliersList"
                @cancel="FieldCreatedDate = null">
                <template v-slot:input>
                    <span lang="en-CA" xml:lang="en-CA">
                        <dateField v-model="FieldCreatedDate" />
                    </span>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:item0="{ item }">{{ item.name }}</template>
        <template v-slot:item1="{ item }">
            <span v-if="item.manager">
                <v-avatar class="mr-2">
                    <img :src="require('@/assets/UserAvatar.svg')" class="UserAvatar">
                </v-avatar>
                {{ item.manager.fullName }}
            </span>
            <v-chip v-else color="red">
                {{ $t('suppliers.noManager') }}
            </v-chip>
        </template>
        <template v-slot:item2="{ item }">
            <flag-country :countryProps="item.country" :key="item.country"></flag-country>
        </template>
        <template v-slot:item3="{ item }">
            {{ item.paymentCondition }}
        </template>
        <template v-slot:item4="{ item }">
            <StatusButton v-if="item" disableIcon :title="statut(item)"
                :color="item.validationStatus == 1 ? '#3BBAFF' : item.validationStatus == 4 ? '#00CBA0' : '#FF8934'">
                <template v-slot:icon>

                    <svg v-if="item.validationStatus == 1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M18.6695 2H16.7695C14.5895 2 13.4395 3.15 13.4395 5.33V7.23C13.4395 9.41 14.5895 10.56 16.7695 10.56H18.6695C20.8495 10.56 21.9995 9.41 21.9995 7.23V5.33C21.9995 3.15 20.8495 2 18.6695 2Z"
                            fill="#FFFFFF" />
                        <path opacity="0.4"
                            d="M7.24 13.4302H5.34C3.15 13.4302 2 14.5802 2 16.7602V18.6602C2 20.8502 3.15 22.0002 5.33 22.0002H7.23C9.41 22.0002 10.56 20.8502 10.56 18.6702V16.7702C10.57 14.5802 9.42 13.4302 7.24 13.4302Z"
                            fill="#FFFFFF" />
                        <path
                            d="M6.29 10.58C8.6593 10.58 10.58 8.6593 10.58 6.29C10.58 3.9207 8.6593 2 6.29 2C3.9207 2 2 3.9207 2 6.29C2 8.6593 3.9207 10.58 6.29 10.58Z"
                            fill="#FFFFFF" />
                        <path
                            d="M17.7099 21.9999C20.0792 21.9999 21.9999 20.0792 21.9999 17.7099C21.9999 15.3406 20.0792 13.4199 17.7099 13.4199C15.3406 13.4199 13.4199 15.3406 13.4199 17.7099C13.4199 20.0792 15.3406 21.9999 17.7099 21.9999Z"
                            fill="#FFFFFF" />
                    </svg>

                    <svg v-else-if="item.validationStatus == 4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                            fill="#FFFFFF" />
                        <path
                            d="M10.5795 15.5801C10.3795 15.5801 10.1895 15.5001 10.0495 15.3601L7.21945 12.5301C6.92945 12.2401 6.92945 11.7601 7.21945 11.4701C7.50945 11.1801 7.98945 11.1801 8.27945 11.4701L10.5795 13.7701L15.7195 8.6301C16.0095 8.3401 16.4895 8.3401 16.7795 8.6301C17.0695 8.9201 17.0695 9.4001 16.7795 9.6901L11.1095 15.3601C10.9695 15.5001 10.7795 15.5801 10.5795 15.5801Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#FFFFFF" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#FFFFFF" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#FFFFFF" />
                    </svg>
                </template>
            </StatusButton>
        </template>
        <template v-slot:item5="{ item }">{{ item.created.split('T')[0] }}</template>
        <template v-slot:edit="{ item, index }">
            <supplier-item-menu :Id="index" :supplierId="item.idSupplier" :GetSuppliersAfterDeleted="getSuppliersList" />
        </template>
    </gridComponent>
</template>
<script>
import { getSuppliers, GetStatusSuppliers } from "@/api/index";
import FlagCountry from "../../SelectCountry/FlagCountry.vue";
import SupplierItemMenu from "./SupplierItemMenu.vue";
import gridComponent from '@/genericComponent/gridComponent.vue'
import StatusButton from "@/genericComponent/StatusButton.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: {
        FlagCountry,
        SupplierItemMenu,
        gridComponent,
        StatusButton,
        ColumnFilter,
        dateField
    },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: null,
            itemsData: {},
            search: null,
            FieldName: null,
            FieldManager: null,
            FieldCountry: null,
            FieldPayment: null,
            FieldStatus: null,
            FieldCreatedDate: null,
            WithStatus: false,
            dialogCompose: false,
            SortedBy: null,
            SortingType: null,
        };
    },
    created() {
        this.GetStatusSuppliers();
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.getSuppliersList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getSuppliersList: function (page) {
            if(page){ this.page = page }
            getSuppliers(this.page, this.PageSize, this.FieldName == null ? this.search : this.FieldName, this.FieldManager, this.FieldCountry, this.FieldPayment, this.FieldStatus, this.FieldCreatedDate, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items;
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            });
        },
        GetStatusSuppliers() {
            GetStatusSuppliers().then(response => {
                localStorage.setItem(status.created + this.$i18n.availableLocales[0], response.data.created.en);
                localStorage.setItem(status.created + this.$i18n.availableLocales[1], response.data.created.fr);
                localStorage.setItem(status.validatedClient1 + this.$i18n.availableLocales[0], response.data.validatedClient1.en);
                localStorage.setItem(status.validatedClient1 + this.$i18n.availableLocales[1], response.data.validatedClient1.fr);
                localStorage.setItem(status.validatedBySupplier + this.$i18n.availableLocales[0], response.data.validatedBySupplier.en);
                localStorage.setItem(status.validatedBySupplier + this.$i18n.availableLocales[1], response.data.validatedBySupplier.fr);
                localStorage.setItem(status.validatedByClient2 + this.$i18n.availableLocales[0], response.data.validatedByClient2.en);
                localStorage.setItem(status.validatedByClient2 + this.$i18n.availableLocales[1], response.data.validatedByClient2.fr);
            })
        },

    },
    computed: {
        statut() {
            return (item) => {
                switch (item.validationStatus) {
                    case 1: return localStorage.getItem(status.created + this.$i18n.locale);
                    case 2: return localStorage.getItem(status.validatedClient1 + this.$i18n.locale);
                    case 3: return localStorage.getItem(status.validatedBySupplier + this.$i18n.locale);
                    case 4: return localStorage.getItem(status.validatedByClient2 + this.$i18n.locale);
                    default: return localStorage.getItem(status.created + this.$i18n.locale);
                }
            };
        },
        headers() {
            return [
                this.$t('suppliers.title'),
                this.$t('suppliers.manager'),
                this.$t('suppliers.country'),
                this.$t('suppliers.payment'),
                this.$t('suppliers.status'),
                this.$t('suppliers.creationDate'),
            ]
        },
        isCreated() {
            return localStorage.getItem(status.created + this.$i18n.locale);
        },
        isvalidatedClient1() {
            return localStorage.getItem(status.validatedClient1 + this.$i18n.locale);
        },
        isvalidatedBySupplier() {
            return localStorage.getItem(status.validatedBySupplier + this.$i18n.locale);
        },
        isvalidatedByClient2() {
            return localStorage.getItem(status.validatedByClient2 + this.$i18n.locale);
        }
    }
};
</script>