<template>
    <actions-menu v-model:dialogEdit="EditDialog" v-model:dialogDelete="DeleteDialog" :Id="Id" />
    <dialogDelete :title="$t('suppliers.usersTab.user')" :yes="deleteSupplierUser" v-model="DeleteDialog" />
    <v-dialog v-model="EditDialog" v-if="EditDialog">
        <v-card width="800px" class="py-2" style="border-radius: 12px;">
            <h1 class="text-center dialog-title"> {{ $t('suppliers.usersTab.edit') }}</h1>
            <form>
                <div class="row ma-4 mt-2 mb-2">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.name') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="NameEdit" id="NameEdit" name="NameEdit" :readonly="!validationButton" hide-details
                                persistent-placeholder required maxlength="30"
                                :placeholder="$t('suppliers.usersTab.placeholder.name')" variant="outlined"></v-text-field>
                            <span class='warningHint' id="WarningMsgFullName">{{ msg.NameEdit }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.email') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="EmailEdit" id="EmailEdit" name="EmailEdit" :readonly="!validationButton" maxlength="40" hide-details
                                persistent-placeholder :placeholder="$t('suppliers.usersTab.placeholder.email')"
                                variant="outlined"></v-text-field>
                            <span class='warningHint' id="WarningMsgEmail">{{ msg.EmailEdit }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.phone') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="PhoneEdit" id="PhoneEdit" name="PhoneEdit" :readonly="!validationButton" hide-details maxlength="15"
                                persistent-placeholder @update:model-value="PhoneEdit = PhoneEdit.replace(/[^0-9.]/g, '');"
                                :placeholder="$t('suppliers.usersTab.placeholder.phone')" variant="outlined">
                            </v-text-field>
                            <span class='warningHint' id="WarningMsgPhoneNumber">{{ msg.PhoneEdit }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.portal') }}<span class="red">*</span>
                                <v-tooltip :text="$t('suppliers.usersTab.webAccessInfo')" :readonly="!validationButton" location="right" max-width="350px">
                                    <template v-slot:activator="{ props }">
                                        <img class="ml-1" v-bind="props" :src="require('@/assets/infoBulle.svg')"/>
                                    </template>
                                </v-tooltip>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <div class="col-2 switchLabel"  @click="PortalEdit= PortalEdit==itemsPortal[0].value?itemsPortal[1].value:itemsPortal[0].value">
                                <img v-if="PortalEdit==itemsPortal[0].value" :readonly="!validationButton" :src="require('@/assets/Switch/SwitchOn.png')" style="width: 44px;"/>
                                <img v-else :src="require('@/assets/Switch/SwitchOff.png')" :readonly="!validationButton" style="width: 44px;"/>
                            </div>
                            <div class="col-10 switchLabel">
                                {{ $t('suppliers.usersTab.webAccess') }}
                            </div>
                            <span class='warningHint' id="WarningMsgSupplierPortal">{{ msg.PortalEdit }}</span>
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateFormEdit" v-model:no="EditDialog" :yesDisable="!validationButton" width="300px"
                    :yesTitle="$t('suppliers.save')" />
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
import cardActions from "@/genericComponent/cardActions.vue"
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import { editSupplierUser, deleteSupplierUser, ExistSupplierMail, isSupplier, idSupplier,CheckPermissionByUser } from "@/api/index";
export default {
    mounted() {
    this.checkSupplierUserPermission();
    },
    components: { actionsMenu, dialogDelete, cardActions },
    name: "SupplierUserEditMenu",
    props: {
        getUserlist: {},
        Supplier: {
            type: Object,
            required: true,
        },
        Id: {}
    },
    data() {
        return {
            DeleteDialog: false,
            msg: [],
            EditDialog: false,
            NameEdit: this.Supplier.fullName,
            PortalEdit: this.Supplier.supplierPortal,
            PhoneEdit: this.Supplier.phoneNumber,
            EmailEdit: this.Supplier.email,
            idUser: this.Supplier.idUser,
            existMail: false,
            validationButton:false
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
    },
    watch: {
        EditDialog() {
            this.msg = []
            this.NameEdit = this.Supplier.fullName
            this.PortalEdit = this.Supplier.supplierPortal
            this.PhoneEdit = this.Supplier.phoneNumber
            this.EmailEdit = this.Supplier.email
            this.existMail = false
        },
        EmailEdit() {
            this.existMail = null
            ExistSupplierMail(this.EmailEdit).then((res) => {
                this.existMail = res.data;
            })
        },
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        deleteSupplierUser() {
            this.DeleteDialog = false;
            deleteSupplierUser(this.idUser)
                .then(() => {
                    this.getUserlist();
                    this.ActiveDeletedSnackBar();

                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        checkSupplierUserPermission: function () {
            CheckPermissionByUser("Optima.Suppliers.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        EditSupplierUser() {
            editSupplierUser(this.idUser, {
                idUser: this.idUser,
                fullName: this.NameEdit,
                phoneNumber: this.PhoneEdit,
                email: this.EmailEdit,
                supplierPortal: this.PortalEdit,
            })
                .then(() => {
                    if (!this.validationButton) {
                this.ActiveWarningSnackBar();
                return;
                }
                  this.getUserlist();
                  this.ActiveSavedSnackBar()
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                });
        },
        validateFormEdit() {
            if (!(this.NameEdit) || (this.NameEdit).length == 0) {
                this.msg['NameEdit'] = this.$t('suppliers.usersTab.errors.name');
            }
            else {
                this.NameEdit = (this.NameEdit.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.NameEdit))) {
                    this.msg['NameEdit'] = 'You have entered wrong full name';
                }
                else if (!(this.NameEdit.split(/\W+/).length >= 2)) {
                    this.msg['NameEdit'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else if (!(this.NameEdit.split(' ')[1].length >= 1)) {
                    this.msg['NameEdit'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else {
                    this.msg['NameEdit'] = '';
                }
            }

            if (!this.EmailEdit || this.EmailEdit.length == 0) {
                this.msg["EmailEdit"] = this.$t('suppliers.usersTab.errors.email');
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.EmailEdit)
            ) {
                this.msg["EmailEdit"] = "";
            } else {
                this.msg["EmailEdit"] = this.$t('suppliers.usersTab.errors.wrongEmail');
            }

            this.PhoneEdit = this.PhoneEdit + ""
            if (!(this.PhoneEdit) || (this.PhoneEdit).length == 0) {
                this.msg['PhoneEdit'] = this.$t('suppliers.usersTab.errors.phone');
            } else if (((this.PhoneEdit).length < 8) || ((this.PhoneEdit).length > 15)) {
                this.msg['PhoneEdit'] = this.$t('suppliers.usersTab.errors.wrongNumber');
            } // eslint-disable-next-line
            else if (this.PhoneEdit.match(/^[\+]?[0-9]{8,15}$/im)) {
                this.msg['PhoneEdit'] = '';
            } else {
                this.msg['PhoneEdit'] = this.$t('suppliers.usersTab.errors.wrongNumber');
            }

            if (!this.PortalEdit || this.PortalEdit.length == 0) {
                this.msg["PortalEdit"] = this.$t('suppliers.usersTab.errors.portal');
            } else {
                this.msg["PortalEdit"] = "";
            }

            if (this.existMail) {
                this.msg["EmailEdit"] = this.$t('warningInput.exist', { itm: this.$t('suppliers.usersTab.email') });
            } else if (
                this.msg["PhoneEdit"] == "" &&
                this.msg["EmailEdit"] == "" &&
                this.msg["NameEdit"] == "" &&
                this.msg["PortalEdit"] == ""
            ) {
                this.EditSupplierUser();
                this.EditDialog = false;
            }
        },
    },
    computed: {
        itemsPortal() {
            return [
                { name: this.$t('suppliers.usersTab.portalType.WebSite'), value: "Website" },
                { name: this.$t('suppliers.usersTab.portalType.physical'), value: "Phisical" },
            ]
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-dialog .v-card {
    width: 400px;
    border-radius: 12px;
    background-color: #ffffff;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
    cursor: pointer;
}

.v-card-text {
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}


.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}

.dialog-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}
</style>