<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title class="text-left d-flex">{{ $t('routes.purchasesRequests') }}</v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :ClickFun="navigateToNewPurchaseRequest" svg="2"
                    :Title="$t('purchaseRequest.create')" />
            </div>
        </div>
        <div>
            <PurchaseRequestList />
        </div>
    </div>
</template>
<script>
import PurchaseRequestList from "../PurchaseRequest/PurchaseRequestList.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    components: {
        PurchaseRequestList,
        genericBtnComponent
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.purchasesRequests');
    },
    methods: {
        navigateToNewPurchaseRequest() {
            this.$router.push({ path: '/NewPurchaseRequest' });
        },
    }
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>
