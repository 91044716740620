<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['15%', '15%', '18%', '15%', '11%']" :GetFunction="getUsersList">
        <template v-slot:item0="{ item }">{{ item.fullName }}</template>
        <template v-slot:item1="{ item }">{{ item.email }}</template>
        <template v-slot:item2="{ item }">
            <table style="max-width: 50px;">
                <tr>
                    <td v-for="rg in item.rgList" :key="rg.idLocation">
                        <flag-country :countryProps=rg.country :locationName="rg.locationName" :key="rg.idLocation" 
                            class="mr-3" style="white-space: nowrap;">
                        </flag-country>
                    </td>
                </tr>
            </table>
        </template>
        <template v-slot:item3="{ item }">{{ item.departmentName }}</template>
        <template v-slot:item4="{ item }">
            <StatusButton :title="statut(item)" />
        </template>
        <template v-slot:edit="{ item, index }">
            <edit-users :UID="item.id" :Id="index" :userName="item.fullName" :userEmail="item.email"
                :regionCoverage="item.rgList.length == 0 ? null : item.regionCoverageList.join()" :role="item.role"
                :departement="item.departmentName ? item.departmentId : null" :DepartmentList="DepartmentList"
                v-bind:getUsersList="getUsersList" />
        </template>
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('configuration.user.Fullname')" :Filter="UserFilters.fullName" :getList="getUsersList"
                @cancel="delete UserFilters.fullName">
                <template v-slot:input>
                    <v-text-field v-model="UserFilters.fullName" id="FieldfullName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('configuration.user.email')" :Filter="UserFilters.email" :getList="getUsersList"
                @cancel="delete UserFilters.email">
                <template v-slot:input>
                    <v-text-field v-model="UserFilters.email" id="FieldEmail" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('configuration.user.regionCoverage')" :Filter="UserFilters.RegionCoverage"
                :getList="getUsersList" @cancel="delete UserFilters.RegionCoverage">
                <template v-slot:input>
                    <select-country-by-location @getCountry="getCountryFromSelect"
                        v-bind:countryProps="UserFilters.RegionCoverage">
                    </select-country-by-location>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('configuration.user.department')" :Filter="UserFilters.departmentName"
                :getList="getUsersList" @cancel="delete UserFilters.departmentName">
                <template v-slot:input>
                    <v-text-field v-model="UserFilters.departmentName" id="FieldDepartment" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter4>
            <ColumnFilter :title="$t('configuration.user.status')" :Filter="UserFilters.Status" :getList="getUsersList"
                @cancel="delete UserFilters.Status, delete UserFilters.withStatus">
                <template v-slot:input>
                    <v-radio-group v-model="UserFilters.Status" inline style="margin-top:6px">
                        <v-radio :label="isActive" :value=true id="Active" name="Active"></v-radio>
                        <v-radio :label="isInactive" :value=false id="In Active" name="In Active"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
    </gridComponent>
</template>
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import FlagCountry from "../SelectCountry/FlagCountry.vue";
import StatusButton from "@/genericComponent/StatusButton.vue";
import EditUsers from "../companiesEdit/EditUsers.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import SelectCountryByLocation from '../SelectCountry/SelectCountryByLocation.vue';
import { getUsers, getAllDepartment, GetStatusUsers } from "@/api/index";
import { status } from "@/enums/Status";
export default {
    components: { gridComponent, EditUsers, FlagCountry, StatusButton, ColumnFilter, SelectCountryByLocation },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            Users: [],
            items: null,
            DepartmentList: [],
            itemsData: {},
            idCompany: this.$route.params.id,
            SortedBy: "",
            SortingType: "ASC",
            UserFilters: {},
        };
    },
    created() {
        this.getAllDepartment()
        this.GetStatusUsers()
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.UserFilters.fullName = event;
            this.getUsersList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getCountryFromSelect(value) {
            this.UserFilters.RegionCoverage = value;
        },
        getAllDepartment() {
            getAllDepartment(this.idCompany).then((res) => {
                this.DepartmentList = res.data.items;
            })
        },
        getUsersList: function (page) {
            if(page){ this.page = page }
            getUsers(this.page, this.PageSize, this.UserFilters, this.SortedBy, this.SortingType)
                .then(response => {
                    this.items = response.data.items;
                    this.itemsData.numberOfPage = response.data.totalPages;
                    this.itemsData.totalItemsCount = response.data.totalCount;
                    this.itemsData.pageNumber = response.data.pageNumber;
                    this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                    this.itemsData.hasNextPage = response.data.hasNextPage
                    this.loader = false;
                })
        },
        GetStatusUsers() {
            GetStatusUsers().then(response => {
                localStorage.setItem(status.Active + this.$i18n.availableLocales[0], response.data.Active.en);
                localStorage.setItem(status.Active + this.$i18n.availableLocales[1], response.data.Active.fr);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[0], response.data.Inactive.en);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[1], response.data.Inactive.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => item.status ? localStorage.getItem(status.Active + this.$i18n.locale) : localStorage.getItem(status.Inactive + this.$i18n.locale)
        },
        headers() {
            return [this.$t('configuration.user.Fullname'),
            this.$t('configuration.user.email'),
            this.$t('configuration.user.regionCoverage'),
            this.$t('configuration.user.department'),
            this.$t('configuration.user.status')
            ]
        },
        isActive() {
            return localStorage.getItem(status.Active + this.$i18n.locale);
        },
        isInactive() {
            return localStorage.getItem(status.Inactive + this.$i18n.locale);
        },
    }
};
</script>