<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['15%', '10%', '15%', '20%', '10%']" :GetFunction="getBankAccountList">
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('suppliers.bankAccountsTab.headers.IBAN')" :Filter="FielIBAN"
                :getList="getBankAccountList" @cancel="FielIBAN = null">
                <template v-slot:input>
                    <v-text-field v-model="FielIBAN" id="FielIBAN" variant="outlined" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('suppliers.bankAccountsTab.headers.BIC')" :Filter="FielBIC"
                :getList="getBankAccountList" @cancel="FielBIC = null">
                <template v-slot:input>
                    <v-text-field v-model="FielBIC" id="FielBIC" variant="outlined" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('suppliers.bankAccountsTab.headers.bankName')" :Filter="FieldName"
                :getList="getBankAccountList" @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('suppliers.bankAccountsTab.headers.accountHolderName')"
                :Filter="FieldAccountHolderName" :getList="getBankAccountList" @cancel="FieldAccountHolderName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldAccountHolderName" id="FieldAccountHolderName" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter4>
            <ColumnFilter :title="$t('suppliers.bankAccountsTab.headers.identityDocument')" :Filter="FieldIdentityDocument"
                :getList="getBankAccountList" @cancel="FieldIdentityDocument = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldIdentityDocument" inline style="margin-top:6px ;">
                        <v-radio label="With Documents" value="true" id="true" name="With Documents"></v-radio>
                        <v-radio label="Without Document" value="false" id="false" name="Without Document"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:item0="{ item }">
            {{ item.iban ? item.iban.replace(/\s+/g, '') : item.iban}}
        </template>
        <template v-slot:item1="{ item }">
            {{ item.bic }}
        </template>
        <template v-slot:item2="{ item }">
            {{ item.bankName }}
        </template>
        <template v-slot:item3="{ item }">
            {{ item.accountHolderName }}
        </template>
        <template v-slot:item4="{ item }">
            <v-dialog v-if="item.identityDocument == true" transition="dialog-bottom-transition" width="auto">
                <template v-slot:activator="{ props }">
                    <v-btn variant="plain" v-bind="props" flat width="100" @click="GetIdentityDocument(item.id)">
                        <StatusButton :title="statut(item)" />
                    </v-btn>
                </template>
                <template v-slot:default="{ isActive }">
                    <v-card min-width="90vh">
                        <v-toolbar color="primary"
                            :title="$t('suppliers.bankAccountsTab.headers.identityDocument')"></v-toolbar>
                        <div v-if="docloader" style="height: 60vh; text-align: center;">
                            <span>
                                <v-progress-circular style="height: 60vh; " indeterminate :size="50"></v-progress-circular>
                            </span>
                        </div>
                        <iframe v-else style="height: 60vh" :src="'data:application/pdf;base64,' + doc"
                            type="application/pdf" width="100%" frameborder="0"></iframe>
                        <v-card-actions class="justify-end" style="bottom: 0%;">
                            <v-btn variant="text" @click="isActive.value = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
            <StatusButton v-else :title="statut(item)" />
        </template>
        <template v-slot:edit="{ item, index }">
            <SupplierBankAccountEditMenu :Id="index" :Supplier="item" :getBankAccountList="getBankAccountList" />
        </template>
    </gridComponent>
</template>
<script>
import { getBankAccount, getIdentityDocument, isSupplier, idSupplier } from "@/api/index";
import gridComponent from '@/genericComponent/gridComponent.vue'
import SupplierBankAccountEditMenu from "./SupplierBankAccountEditMenu.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import StatusButton from "@/genericComponent/StatusButton.vue";
import { status } from "@/enums/Status";
export default {
    components: { SupplierBankAccountEditMenu, gridComponent, ColumnFilter, StatusButton },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: null,
            itemsData: {},
            idSupplier: null,
            search: null,
            FielIBAN: null,
            FielBIC: null,
            FieldName: null,
            FieldAccountHolderName: null,
            FieldIdentityDocument: null,
            dialogCompose: false,
            SortedBy: "",
            SortingType: "",
            doc: null,
            docloader: null,
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
        this.GetStatusBankAccount();
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.getBankAccountList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        GetStatusBankAccount() {
            localStorage.setItem(status.Display + this.$i18n.availableLocales[0], 'Display');
            localStorage.setItem(status.Display + this.$i18n.availableLocales[1], 'Afficher');
            localStorage.setItem(status.Unavailable + this.$i18n.availableLocales[0], 'Unavailable');
            localStorage.setItem(status.Unavailable + this.$i18n.availableLocales[1], 'Indisponible');
        },
        GetIdentityDocument: function (id) {
            this.docloader = true
            getIdentityDocument(id).then((response) => {
                this.doc = response.data;
            })
                .finally(() =>
                    this.docloader = false
                );
        },
        getBankAccountList: function (page) {
            if(page){ this.page = page }
            getBankAccount(this.page, this.PageSize, this.idSupplier, this.FielIBAN, this.FielBIC, this.FieldName == null ? this.search : this.FieldName, this.FieldAccountHolderName, this.FieldIdentityDocument, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items;
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            });
        },
    },
    computed: {
        headers() {
            return [this.$t('suppliers.bankAccountsTab.headers.IBAN'), this.$t('suppliers.bankAccountsTab.headers.BIC'), this.$t('suppliers.bankAccountsTab.headers.bankName'), this.$t('suppliers.bankAccountsTab.headers.accountHolderName'), this.$t('suppliers.bankAccountsTab.headers.identityDocument')]
        },
        statut() {
            return item => item.identityDocument == true ? localStorage.getItem(status.Display + this.$i18n.locale) : localStorage.getItem(status.Unavailable + this.$i18n.locale);
        },
    }
};
</script>