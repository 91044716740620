<template>
    <v-menu anchor="start">
        <template v-slot:activator="{ props }">
            <v-btn icon variant="text" :id="'Action' + Id" :name="'Action' + Id"
                style="border-radius: 0; width: min-content; height: min-content; color: transparent;" v-bind="props"
                :style="HasDialog ? '' : 'opacity: 0.75; pointer-events:none !important; cursor: none !important;'">
                <svg width="14" height="34" viewBox="0 0 14 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="34" width="34" height="14" rx="7" transform="rotate(-90 0 34)" fill="#EDF5F9" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 22C5.89543 22 5 22.8954 5 24C5 25.1046 5.89543 26 7 26C8.10457 26 9 25.1046 9 24C9 22.8954 8.10457 22 7 22Z"
                        fill="#768DA9" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15Z"
                        fill="#768DA9" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 8C5.89543 8 5 8.89543 5 10C5 11.1046 5.89543 12 7 12C8.10457 12 9 11.1046 9 10C9 8.89543 8.10457 8 7 8Z"
                        fill="#768DA9" />
                </svg>
            </v-btn>
        </template>
        <div style="border:none">
            <v-card class="text-left" color="#FFFFFF">
                <div id="Exporter" name="Exporter" class="row align-items-start py-2 my-2"  
                 @click="$emit('update:PDFExport', true)" v-if="ShowExport" >
                        <div class="col-2">
                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9 17L7 15" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <!-- <img  id="downloadIcon" width="24" height="24" src="https://img.icons8.com/material-outlined/24/000000/download--v1.png" alt="download--v1"/> -->
                      </div>  
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#0D0849;">{{ $t("actions.download") }}</div>
                    </div>
                </div>
                <div id="Edit" name="Edit" v-if="ShowDialogEdit" class="row align-items-start py-2 my-2"
                    @click="dialogEdit == false ? $emit('update:dialogEdit', true) : dialogEditUrl == null ? $router.push(dialogEdit) : $router.push({ name: dialogEdit, params: { id: dialogEditUrl } })">
                    <div class="col-2">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.67063 13.4386L7.44563 17.2136C8.99563 18.7636 11.5123 18.7636 13.0706 17.2136L16.729 13.5553C18.279 12.0053 18.279 9.48864 16.729 7.93031L12.9456 4.16364C12.154 3.37197 11.0623 2.94697 9.94563 3.00531L5.77896 3.20531C4.1123 3.28031 2.7873 4.60531 2.70396 6.26364L2.50396 10.4303C2.45396 11.5553 2.87896 12.647 3.67063 13.4386Z"
                                stroke="#0D0849" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M8.11263 10.6882C9.26322 10.6882 10.196 9.75541 10.196 8.60482C10.196 7.45422 9.26322 6.52148 8.11263 6.52148C6.96204 6.52148 6.0293 7.45422 6.0293 8.60482C6.0293 9.75541 6.96204 10.6882 8.11263 10.6882Z"
                                stroke="#0D0849" stroke-width="1.25" stroke-linecap="round" />
                            <path d="M11.0293 14.8548L14.3626 11.5215" stroke="#0D0849" stroke-width="1.25"
                                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#0D0849;">{{ $t("actions.edit") }}</div>
                    </div>
                </div>
                <div id="Consult" name="Consult" v-if="ShowDialogConsult" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogConsult', true)">
                    <div class="col-2">
                        <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                                fill="#C5CDD4" />
                            <path
                                d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
                                fill="#C5CDD4" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#0D0849;">{{ $t("actions.consult") }}</div>
                    </div>
                </div>
                <div id="RetryStatus" name="RetryStatus" v-if="ShowDialogRetry" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogRetry', true)">
                    <div class="col-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6667 2.5L13.7 4.45002L7.07499 4.43334C4.09999 4.43334 1.65833 6.87502 1.65833 9.86669C1.65833 11.3584 2.26666 12.7167 3.24999 13.7"
                                stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M8.33338 17.4998L6.30005 15.5498L12.9251 15.5665C15.9001 15.5665 18.3417 13.1248 18.3417 10.1331C18.3417 8.64145 17.7334 7.28314 16.7501 6.2998"
                                stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.2084 12.2249V7.7749L8.95837 9.16655" stroke="#FF5C5C" stroke-width="1.25"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#ff5c5c;">{{ $t("actions.retry") }}</div>
                    </div>
                </div>
                <div id="Reset" name="Reset" v-if="ShowDialogReset" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogReset', true)">
                    <div class="col-2">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.6667 2.5L13.7 4.45002L7.07499 4.43334C4.09999 4.43334 1.65833 6.87502 1.65833 9.86669C1.65833 11.3584 2.26666 12.7167 3.24999 13.7"
                                stroke="#0057FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M8.33332 17.4998L6.29999 15.5498L12.925 15.5665C15.9 15.5665 18.3417 13.1248 18.3417 10.1331C18.3417 8.64145 17.7333 7.28314 16.75 6.2998"
                                stroke="#0057FF" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#0057FF;">{{ $t("actions.reset") }}</div>
                    </div>
                </div>
                <div id="ValidationStatus" name="ValidationStatus" v-if="ShowDialogValidation"
                    class="row align-items-start py-2 my-2" @click="$emit('update:dialogValidation', true)">
                    <div class="col-2">
                        <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
                                fill="#00CBA0" />
                            <path
                                d="M10.5795 15.5801C10.3795 15.5801 10.1895 15.5001 10.0495 15.3601L7.21945 12.5301C6.92945 12.2401 6.92945 11.7601 7.21945 11.4701C7.50945 11.1801 7.98945 11.1801 8.27945 11.4701L10.5795 13.7701L15.7195 8.6301C16.0095 8.3401 16.4895 8.3401 16.7795 8.6301C17.0695 8.9201 17.0695 9.4001 16.7795 9.6901L11.1095 15.3601C10.9695 15.5001 10.7795 15.5801 10.5795 15.5801Z"
                                fill="#00CBA0" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#00CBA0;">{{ $t("actions.validation") }}</div>
                    </div>
                </div>
                <div id="Payement" name="Payement" v-if="ShowDialogPayement" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogPayement', true)">
                    <div class="col-2">
                        <svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                d="M16.5 5.8273V6.7498H1.5V5.6548C1.5 3.9373 2.895 2.5498 4.6125 2.5498H12V4.4773C12 5.4298 12.57 5.9998 13.5225 5.9998H15.7275C16.0275 5.9998 16.2825 5.9473 16.5 5.8273Z"
                                fill="#00CBA0" />
                            <path
                                d="M1.5 6.75V12.345C1.5 14.0625 2.895 15.45 4.6125 15.45H13.3875C15.105 15.45 16.5 14.0625 16.5 12.345V6.75H1.5ZM6 12.9375H4.5C4.1925 12.9375 3.9375 12.6825 3.9375 12.375C3.9375 12.0675 4.1925 11.8125 4.5 11.8125H6C6.3075 11.8125 6.5625 12.0675 6.5625 12.375C6.5625 12.6825 6.3075 12.9375 6 12.9375ZM10.875 12.9375H7.875C7.5675 12.9375 7.3125 12.6825 7.3125 12.375C7.3125 12.0675 7.5675 11.8125 7.875 11.8125H10.875C11.1825 11.8125 11.4375 12.0675 11.4375 12.375C11.4375 12.6825 11.1825 12.9375 10.875 12.9375Z"
                                fill="#00CBA0" />
                            <path
                                d="M15.7275 0.75H13.5225C12.57 0.75 12 1.32 12 2.2725V4.4775C12 5.43 12.57 6 13.5225 6H15.7275C16.68 6 17.25 5.43 17.25 4.4775V2.2725C17.25 1.32 16.68 0.75 15.7275 0.75ZM16.4325 3.6975C16.3575 3.7725 16.245 3.825 16.125 3.8325H15.0675L15.075 4.875C15.0675 5.0025 15.0225 5.1075 14.9325 5.1975C14.8575 5.2725 14.745 5.325 14.625 5.325C14.3775 5.325 14.175 5.1225 14.175 4.875V3.825L13.125 3.8325C12.8775 3.8325 12.675 3.6225 12.675 3.375C12.675 3.1275 12.8775 2.925 13.125 2.925L14.175 2.9325V1.8825C14.175 1.635 14.3775 1.425 14.625 1.425C14.8725 1.425 15.075 1.635 15.075 1.8825L15.0675 2.925H16.125C16.3725 2.925 16.575 3.1275 16.575 3.375C16.5675 3.5025 16.515 3.6075 16.4325 3.6975Z"
                                fill="#00CBA0" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#00CBA0;">{{ $t("actions.payment") }}</div>
                    </div>
                </div>
                <div id="Refuse" name="Refuse" v-if="ShowDialogRefuse" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogRefuse', true)">
                    <div class="col-2">                        
                        <svg width="21" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16937 15.5801C8.97937 15.5801 8.78938 15.5101 8.63938 15.3601C8.34938 15.0701 8.34938 14.5901 8.63938 14.3001L14.2994 8.64011C14.5894 8.35011 15.0694 8.35011 15.3594 8.64011C15.6494 8.93011 15.6494 9.41011 15.3594 9.70011L9.69937 15.3601C9.55937 15.5101 9.35937 15.5801 9.16937 15.5801Z" fill="#F25C5C"/>
                            <path d="M14.8294 15.5801C14.6394 15.5801 14.4494 15.5101 14.2994 15.3601L8.63938 9.70011C8.34938 9.41011 8.34938 8.93011 8.63938 8.64011C8.92937 8.35011 9.40937 8.35011 9.69937 8.64011L15.3594 14.3001C15.6494 14.5901 15.6494 15.0701 15.3594 15.3601C15.2094 15.5101 15.0194 15.5801 14.8294 15.5801Z" fill="#F25C5C"/>
                            <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#F25C5C"/>
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#F25C5C;">{{ $t("actions.refuse") }}</div>
                    </div>
                </div>
                <div id="ChangeStatus" name="ChangeStatus" v-if="ShowDialogStatus" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogStatus', true)">
                    <div class="col-2">
                        <svg v-if="status" width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.7052 13.566L3.70524 13.5659L3.70023 13.5602C2.63681 12.3358 2.00024 10.7414 2.00024 8.99805C2.00024 5.13419 5.13639 1.99805 9.00024 1.99805C10.7436 1.99805 12.338 2.63462 13.5624 3.69804L13.5623 3.69808L13.5682 3.703C13.7157 3.82721 13.8566 3.95792 13.991 4.09515L4.09735 13.9888C3.96011 13.8544 3.82941 13.7135 3.7052 13.566Z"
                                stroke="#F25C5C" />
                            <path
                                d="M6.06223 15.3494L6.06186 15.3492C5.93491 15.2899 5.87374 15.0877 6.0108 14.946L14.9486 6.0082C15.0073 5.94943 15.0913 5.9238 15.1756 5.93687C15.2576 5.9496 15.3184 5.99481 15.3496 6.0615L15.3504 6.06324C15.7687 6.94864 16 7.94816 16 8.99965C16 12.841 12.8414 15.9997 9.00002 15.9997C7.94931 15.9997 6.95668 15.7687 6.06223 15.3494Z"
                                stroke="#F25C5C" />
                            <path
                                d="M15.9741 2.02621L15.9741 2.02626L15.9799 2.03196C15.9979 2.04938 16.002 2.06558 16.002 2.07766C16.002 2.08974 15.9979 2.10594 15.9799 2.12336L15.9799 2.12331L15.9741 2.1291L2.1291 15.9741C2.10579 15.9974 2.08871 16.0002 2.07766 16.0002C2.06569 16.0002 2.04562 15.9963 2.02137 15.9763C1.99657 15.9461 1.99818 15.8992 2.02621 15.8712L2.02631 15.8711L15.8637 2.02621C15.8934 1.99647 15.9444 1.99647 15.9741 2.02621Z"
                                stroke="#F25C5C" />
                        </svg>
                        <svg v-else width="18" height="18" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.001 22.7198C9.33104 22.7198 6.65103 21.7599 4.62103 19.8299C2.49103 17.8099 1.29104 15.0898 1.25104 12.1698C1.21104 9.23984 2.33103 6.48984 4.40103 4.41984C8.59103 0.229844 15.411 0.229844 19.601 4.41984C21.671 6.48984 22.791 9.23984 22.751 12.1698C22.711 15.0998 21.511 17.8199 19.381 19.8299C17.351 21.7599 14.671 22.7198 12.001 22.7198ZM12.001 2.77989C9.63104 2.77989 7.26103 3.67984 5.46103 5.47984C3.68103 7.25984 2.72104 9.62988 2.75104 12.1499C2.78104 14.6699 3.81103 17.0099 5.65103 18.7399C9.15103 22.0599 14.851 22.0499 18.351 18.7399C20.181 17.0099 21.211 14.6599 21.251 12.1499C21.291 9.63988 20.321 7.25984 18.541 5.47984C16.741 3.67984 14.371 2.77989 12.001 2.77989Z"
                                fill="#F25C5C" />
                            <path
                                d="M12.0007 18.3199C10.4207 18.3199 8.85073 17.7499 7.65073 16.6199C7.35073 16.3299 7.34073 15.8599 7.62073 15.5599C7.91073 15.2599 8.38073 15.2499 8.68073 15.5299C10.5107 17.2599 13.4907 17.2599 15.3207 15.5299C15.6207 15.2499 16.1007 15.2599 16.3807 15.5599C16.6607 15.8599 16.6507 16.3399 16.3507 16.6199C15.1507 17.7499 13.5807 18.3199 12.0007 18.3199Z"
                                fill="#F25C5C" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div v-if="status" class="TitleMenu" style="color:#ff5c5c; ">{{ $t("actions.mark") +
                            ' ' + $t("actions.as", { itm: $t("status.inActive") }) }}</div>
                        <div v-else class="TitleMenu" style="color:#ff5c5c; ">{{ $t("actions.mark") +
                            ' ' + $t("actions.as", { itm: $t("status.active") }) }}</div>
                    </div>
                </div>
                <div id="Delete" name="Delete" v-if="ShowDialogDelete" class="row align-items-start py-2 my-2"
                    @click="$emit('update:dialogDelete', true)">
                    <div class="col-2">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.5 5.48307C14.725 5.20807 11.9333 5.06641 9.15 5.06641C7.5 5.06641 5.85 5.14974 4.2 5.31641L2.5 5.48307"
                                stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M7.08301 4.64102L7.26634 3.54935C7.39967 2.75768 7.49967 2.16602 8.90801 2.16602H11.0913C12.4997 2.16602 12.608 2.79102 12.733 3.55768L12.9163 4.64102"
                                stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M15.7087 8.11719L15.167 16.5089C15.0753 17.8172 15.0003 18.8339 12.6753 18.8339H7.32533C5.00033 18.8339 4.92533 17.8172 4.83366 16.5089L4.29199 8.11719"
                                stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.6084 14.25H11.3834" stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M7.91699 10.916H12.0837" stroke="#FF5C5C" stroke-width="1.25" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div class="col ml-5">
                        <div class="TitleMenu" style="color:#ff5c5c;">{{ $t("actions.delete") }}</div>
                    </div>
                </div>
            </v-card>
        </div>
    </v-menu>
</template>
<script>
export default {
    name: "actionsMenu",
    props: {
        dialogEdit: { type: [Boolean, String] },
        PDFExport: { type: [Boolean, String] },
        dialogEditUrl: {},
        ExportUrl: {},
        dialogDelete: {},
        dialogStatus: {},
        dialogRetry: {},
        dialogReset: {},
        status: { default: true },
        ShowDialogEdit: { default: true },
        ShowDialogConsult: { default: false },
        ShowDialogDelete: { default: true },
        ShowDialogStatus: { default: false },
        ShowDialogRetry: { default: false },
        ShowDialogReset: { default: false },
        ShowDialogValidation: { default: false },
        ShowDialogPayement: { default: false },
        ShowDialogRefuse: { default: false },
        ShowExport: { default: false },
        Id: {}
    },
    computed: {
        HasDialog() {
            let HasDialog = false;
            const dialogs = [
                this.ShowDialogEdit,
                this.ShowDialogConsult,
                this.ShowDialogDelete,
                this.ShowDialogStatus,
                this.ShowDialogRetry,
                this.ShowDialogReset,
                this.ShowDialogValidation,
                this.ShowDialogPayement,
                this.ShowDialogRefuse,
                this.ShowExport
            ];
            for (const showDialog of dialogs) {
                if (showDialog) { HasDialog = true; }
            }
            return HasDialog;
        }
    }
};
</script>
<style scoped>
.row:hover {
    background-color: #F0F5FF;
}

.v-card {
    width: 180px;
    box-shadow: 0px 18px 107px rgba(13, 8, 73, 0.18);
}

div>svg {
    margin-left: 20px;
}

.TitleMenu {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top: 2px;
}

.disable-cursor {
    cursor: none;
}
.align-right {
    margin-right: 10px;
}
.v-card>div {
    cursor: pointer;
}
</style>