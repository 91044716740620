<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['15%', '20%', '15%', '15%', '10%']" :GetFunction="getAlertsList"
        :class="{ 'disabled': isDisabled }">
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('suppliers.alertsTab.headers.title')" :Filter="FieldName" :getList="getAlertsList"
                @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('suppliers.alertsTab.headers.alertType')" :Filter="FieldTypeAlert"
                :getList="getAlertsList" @cancel="FieldTypeAlert = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldTypeAlert" inline style="margin-top:6px ;">
                        <v-radio label="Push Alert" value="Push Alert" id="Push Alert" name="Push Alert"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('suppliers.alertsTab.headers.date')" :Filter="FieldDateIssued" :getList="getAlertsList"
                @cancel="FieldDateIssued = null">
                <template v-slot:input>
                    <dateField v-model="FieldDateIssued" :minDate="new Date()"/>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('suppliers.alertsTab.headers.criticity')" :Filter="FieldCriticity"
                :getList="getAlertsList" @cancel="FieldCriticity = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldCriticity" inline style="margin-top:6px ;">
                        <v-radio label="Critical" value="Critical" id="Critical" name="Critical"></v-radio>
                        <v-radio label="Medium" value="Medium" id="Medium" name="Medium"></v-radio>
                        <v-radio label="High" value="High" id="High" name="High"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:item0="{ item }">
            {{ item.title }}
        </template>
        <template v-slot:item1="{ item }">
            {{ item.type }}
        </template>
        <template v-slot:item2="{ item }">{{ item.dateIssued.split('T')[0] }}</template>
        <template v-slot:item3="{ item }">
            {{ item.criticity }}
        </template>
        <template v-slot:edit="{ item, index }">
            <SupplierAlertEditMenu :Id="index" :Supplier="item" :getAlertsList="getAlertsList" />
        </template>
    </gridComponent>
</template>
<script>
import { getAlert, isSupplier, idSupplier } from "@/api/index";
import gridComponent from '@/genericComponent/gridComponent.vue'
import SupplierAlertEditMenu from "./SupplierAlertEditMenu.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
export default {
    components: { SupplierAlertEditMenu, gridComponent, ColumnFilter, dateField },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: null,
            itemsData: {},
            idSupplier: null,
            search: null,
            FieldName: null,
            FieldTypeAlert: null,
            FieldDateIssued: null,
            FieldCriticity: null,
            dialogCompose: false,
            SortedBy: "",
            SortingType: "",
            isDisabled: {
                type: Boolean,
                default: false
            }
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.getAlertsList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getAlertsList: function (page) {
            if(page){ this.page = page }
            getAlert(this.page, this.PageSize, this.idSupplier, this.FieldName == null ? this.search : this.FieldName, this.FieldTypeAlert, this.FieldDateIssued, this.FieldCriticity, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items;
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            });
        },
    },
    computed: {
        headers() {
            return [this.$t('suppliers.alertsTab.headers.title'), this.$t('suppliers.alertsTab.headers.alertType'), this.$t('suppliers.alertsTab.headers.date'), this.$t('suppliers.alertsTab.headers.criticity')]
        }
    }
};
</script>
<style>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>