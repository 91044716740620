<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <span class="title">
                    {{ $t('requestForProposal.add') }}
                </span>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :ClickFun="validateFormPostRequest" svg="1" :Title="$t('requestForProposal.save')"
                    :loader="loader" />
            </div>
        </div>
        <v-card class="v--card-position">
            <div class="row">
                <div class="col-6 justify-content-start">
                    <div class="row mx-1">
                        <v-card-title class="text-left">{{ $t('requestForProposal.title') }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row mx-1">
                        <v-text-field v-model="Title" id="Title" name="Title" maxlength="30" hide-details
                            persistent-placeholder
                            :placeholder="$t('placeholder.enter', { itm: $t('requestForProposal.title') })"
                            variant="outlined"></v-text-field>
                        <span class="warningHint" id="WarningMsgTitle">{{ msg.Title }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row mx-1">
                        <v-card-title class="text-left ">{{ $t('requestForProposal.categories') }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row mx-1">
                        <v-select id="Category" name="Category" ref="ChooseRole" v-model="Category" hide-details
                            :placeholder="$t('placeholder.select', { itm: $t('requestForProposal.categories') })"
                            variant="outlined" persistent-placeholder :items="ChoseCategory" item-title="name"
                            item-value="value">
                            <template v-slot:item="{ props, item, index }">
                                <v-list-item v-bind="props" variant="text" :id="index" :name="item.title">
                                    {{ item.name }}
                                </v-list-item>
                            </template>
                        </v-select>
                    </div>
                    <span class="warningHint" id="WarningMsgCategory">{{ msg.Category }}</span>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row mx-1">
                        <v-card-title class="text-left">{{ $t('requestForProposal.submissions') }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row mx-1">
                        <v-text-field type="number" id="Submission" name="Submission" max="7" min="0" maxlength="3"
                            v-model="Submission" hide-details persistent-placeholder
                            :placeholder="$t('placeholder.enter', { itm: $t('requestForProposal.submissions') })"
                            variant="outlined"></v-text-field>
                        <span class="warningHint" id="WarningMsgSubmission">{{ msg.Submission }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row mx-1">
                        <v-card-title class="text-left">{{ $t('requestForProposal.deadline') }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row mx-1">
                        <dateField v-model="Deadline" />
                        <span class="warningHint" id="WarningMsgDeadline">{{ msg.Deadline }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row mx-1">
                        <v-card-title class="text-left">{{ $t('requestForProposal.status') }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row mx-1">
                        <v-radio-group v-model="Status" inline style="margin-top:6px ;margin-bottom:6px" hide-details>
                            <v-radio :label="isOpen" value="0" id="Open" name="Open"></v-radio>
                            <v-radio style="margin-left: 30px" :label="isClosed" value="1" id="Close" name="Close"></v-radio>
                        </v-radio-group>
                        <span class="warningHint" id="WarningMsgStatus">{{ msg.Status }}</span>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>
<script scoped>
import { PostForProposal } from "@/api/index";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: {
        genericBtnComponent, dateField
    },
    data: () => ({
        Title: null,
        Deadline: null,
        Submission: null,
        Status: null,
        Category: null,
        msg: [],
        loader: false
    }),
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        navigateToList() {
            this.$router.push({ path: "/RequestForProposal" });
        },
        validateForm() {
            PostForProposal(
                this.Title,
                this.Deadline,
                parseInt(this.Category),
                this.Submission,
                parseInt(this.Status)
            ).then(() => {
                this.ActiveSavedSnackBar();
                this.navigateToList();
                this.loader = false
            });
        },
        validateFormPostRequest() {
            this.loader = true
            if (!this.Title || this.Title.length == 0) {
                this.msg["Title"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.title') });
            } else {
                this.msg["Title"] = "";
            }
            if (!this.Deadline || this.Deadline.length == 0) {
                this.msg["Deadline"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.deadline') });
            } else if (this.Deadline < "1000-01-01") {
                this.msg["Deadline"] = this.$t('warningInput.wrong', { itm: this.$t('requestForProposal.deadline') });
            } else {
                this.msg["Deadline"] = "";
            }
            if (this.Submission == null || this.Submission == "") {
                this.msg["Submission"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.submissions') });
            } else if (this.Submission < 0 || this.Submission > 7) {
                this.msg["Submission"] = this.$t('warningInput.positive&under7', { itm: this.$t('requestForProposal.submissions') });
            } else {
                this.msg["Submission"] = "";
            }
            if (this.Category == "" || this.Category == null) {
                this.msg["Category"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.categories') });
            } else {
                this.msg["Category"] = "";
            }
            if (this.Status == null) {
                this.msg["Status"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.status') });
            } else {
                this.msg["Status"] = "";
            }

            if (
                this.msg["Title"] == "" &&
                this.msg["Deadline"] == "" &&
                this.msg["Submission"] == "" &&
                this.msg["Category"] == "" &&
                this.msg["Status"] == ""
            ) {
                this.validateForm();
            } else {
                this.loader = false
            }
        },
    },
    computed: {
        ChoseCategory() {
            return [
                {
                    name: this.$t('requestForProposal.Services'), value: '0'
                }
            ]
        },
        isClosed() {
            return localStorage.getItem(status.Closed + this.$i18n.locale);
        },
        isOpen() {
            return localStorage.getItem(status.Open + this.$i18n.locale);
        },
    }
};
</script>
<style scoped>
.v-card-title {
    font-size: 14px;
    font-family: inter;
    font-weight: 600;
}

.v-card-title {
    color: #5B6D8E;
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
}

.title {
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>