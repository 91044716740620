<template>
    <div>
        <div>
            <div class="row mb-8">
                <div class="col-6 d-flex justify-content-start">
                    <v-card-title>
                        {{ $t('routes.invoices') }}
                    </v-card-title>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <genericBtnComponent v-if="isSupplier()" :disabled="!ValidatedSupplier" :ClickFun="navigateToNewInvoices" svg="2"
                        :Title="$t('invoices.create')" />
                </div>
            </div>
        </div>
        <v-card flat>
            <InvoiceList />
        </v-card>
    </div>
</template>
<script>
import { isSupplier,isValidatedSupplier } from "@/api/index";
import InvoiceList from "./InvoiceList.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    components: {
        InvoiceList,
        genericBtnComponent
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.invoices');
    },
    data: () => ({
        ValidatedSupplier: false,
    }),
    created() {
        this.isValidatedSupplier();
    },
    methods: {
        navigateToNewInvoices() {
            this.$router.push({ path: '/NewInvoice' });
        },
        isSupplier() {
            return isSupplier()
        },
        isValidatedSupplier(){
            if (this.isSupplier()){
                isValidatedSupplier().then(({data})=>{
                    this.ValidatedSupplier = data
                }) 
            }
        }
    }
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>