<template>
    <div v-if="loader" style="height: 200px; line-height: 250px;">
        <v-progress-circular indeterminate :size="50"></v-progress-circular>
    </div>
    <div v-else-if="!loader" class="ma-8">
        <div class="title-cadre mb-10">
            <div class="title">
                {{ $t('suppliers.validationTab.supplierStatus') }}
            </div>
        </div>
        <div class="timelineStatus my-2">
            <StatusButton disableIcon :title="statusTitle(1)"
                :color="Status > 1 ? '#00CBA0' : Status == 1 ? '#FF8934' : '#C5CDD4'"
                :style="Status < 1 ? 'color:black' : ''">
                <template v-slot:icon>
                    <svg v-if="Status > 1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else-if="Status == 1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#FFFFFF" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#FFFFFF" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#FFFFFF" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#FFFFFF" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#FFFFFF" />
                    </svg>
                </template>
            </StatusButton>
            <v-sheet elevation="0" :height="5" :width="120"
                :color="Status > 2 ? '#00CBA0' : Status == 2 ? '#FF8934' : '#C5CDD4'"
                style="margin: auto; margin-right: -20px; margin-left: -20px;"></v-sheet>
            <StatusButton disableIcon :title="statusTitle(2)"
                :color="Status > 2 ? '#00CBA0' : Status == 2 ? '#FF8934' : '#C5CDD4'"
                :style="Status < 2 ? 'color:black' : ''">
                <template v-slot:icon>
                    <svg v-if="Status > 2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else-if="Status == 2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#FFFFFF" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#FFFFFF" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#474F5A" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#474F5A" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#474F5A" />
                    </svg>
                </template>
            </StatusButton>
            <v-sheet elevation="0" :height="5" :width="120"
                :color="Status > 3 ? '#00CBA0' : Status == 3 ? '#FF8934' : '#C5CDD4'"
                style="margin: auto; margin-right: -20px; margin-left: -20px;"></v-sheet>
            <StatusButton disableIcon :title="statusTitle(3)"
                :color="Status > 3 ? '#00CBA0' : Status == 3 ? '#FF8934' : '#C5CDD4'"
                :style="Status < 3 ? 'color:black' : ''">
                <template v-slot:icon>
                    <svg v-if="Status > 3" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else-if="Status == 3" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#FFFFFF" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#FFFFFF" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#474F5A" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#474F5A" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#474F5A" />
                    </svg>
                </template>
            </StatusButton>
            <v-sheet elevation="0" :height="5" :width="120" :color="Status >= 4 ? '#00CBA0' : '#C5CDD4'"
                style="margin: auto; margin-right: -20px; margin-left: -20px;"></v-sheet>
            <StatusButton disableIcon :title="statusTitle(4)" :color="Status >= 4 ? '#00CBA0' : '#C5CDD4'"
                :style="Status < 4 ? 'color:black' : ''">
                <template v-slot:icon>
                    <svg v-if="Status == 4" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                            fill="#FFFFFF" />
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4"
                            d="M12.0001 21.9999C16.7884 21.9999 20.6701 18.1182 20.6701 13.3299C20.6701 8.5416 16.7884 4.65991 12.0001 4.65991C7.21177 4.65991 3.33008 8.5416 3.33008 13.3299C3.33008 18.1182 7.21177 21.9999 12.0001 21.9999Z"
                            fill="#474F5A" />
                        <path
                            d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
                            fill="#474F5A" />
                        <path
                            d="M14.8906 3.45H9.11062C8.71062 3.45 8.39062 3.13 8.39062 2.73C8.39062 2.33 8.71062 2 9.11062 2H14.8906C15.2906 2 15.6106 2.32 15.6106 2.72C15.6106 3.12 15.2906 3.45 14.8906 3.45Z"
                            fill="#474F5A" />
                    </svg>
                </template>
            </StatusButton>
        </div>
        <div v-if="affichValidationButton" class="my-8 row justify-content-center">
            <div class="col-6 justify-content-center">
                <div v-if="Supplier.validationStatus < 4 && !afficheCommentaire" class="row justify-content-center">
                    <div class="col-auto">
                        <genericBtnComponent backgroundColor="#114EF7" @click="dialogValide = true"
                            :Title="$t('suppliers.validationTab.valideBtn')" width="150">
                            <template v-slot:logo>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.4"
                                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                        fill="#FFFFFF" />
                                    <path
                                        d="M10.5795 15.5801C10.3795 15.5801 10.1895 15.5001 10.0495 15.3601L7.21945 12.5301C6.92945 12.2401 6.92945 11.7601 7.21945 11.4701C7.50945 11.1801 7.98945 11.1801 8.27945 11.4701L10.5795 13.7701L15.7195 8.6301C16.0095 8.3401 16.4895 8.3401 16.7795 8.6301C17.0695 8.9201 17.0695 9.4001 16.7795 9.6901L11.1095 15.3601C10.9695 15.5001 10.7795 15.5801 10.5795 15.5801Z"
                                        fill="#FFFFFF" />
                                </svg>
                            </template>
                        </genericBtnComponent>
                    </div>
                    <div v-if="Supplier.validationStatus == 3" class="col-auto">
                        <genericBtnComponent backgroundColor="#F25C5C" @click="afficheCommentaire = true"
                            :Title="$t('suppliers.validationTab.refuseBtn')" width="150" />
                    </div>
                    <div class="validationText">{{ $t('suppliers.validationTab.validation') }}</div>

                </div>
                <div v-if="Supplier.validationStatus == 3 && afficheCommentaire == true" class="row">
                    <v-card-title class="text-left">{{ $t('suppliers.validationTab.commentaire') }} :</v-card-title>
                </div>
                <div v-if="Supplier.validationStatus == 3 && afficheCommentaire == true" class="row">
                    <div class="col">
                        <v-textarea id="Comment" v-model="commentaire" variant="outlined" counter 
                            :label="$t('suppliers.validationTab.commentaire')"
                            single-line auto-grow hide-details maxlength="500"></v-textarea>
                        <span class="warningHint" id="WarningMsgComment">{{ msg.comment }}</span>
                    </div>
                </div>
                <div v-if="Supplier.validationStatus == 3 && afficheCommentaire == true" class="row justify-content-center">
                    <div class="col-auto">
                        <genericBtnComponent backgroundColor="#F25C5C" :ClickFun="checkComment"
                            :Title="$t('suppliers.validationTab.refuseBtn')" width="150" />
                    </div>
                    <div v-if="Supplier.validationStatus == 3" class="col-auto">
                        <genericBtnComponent backgroundColor="#FFF" color="#768da9" variant="outlined"
                            @click="afficheCommentaire = false" :Title="$t('suppliers.validationTab.backBtn')"
                            width="150" />
                    </div>
                </div>
            </div>
        </div>
        <div class="title-cadre mt-10 mb-4" v-if="TraceValidationList">
            <div class="title">
                {{ $t('suppliers.validationTab.validationHistory') }}
            </div>
        </div>
        <v-table fixed-header height="200px" v-if="TraceValidationList">
            <thead>
                <tr>
                    <th class="text-center">
                        {{ $t('suppliers.validationTab.validationDate') }}
                    </th>
                    <th class="text-center">
                        {{ $t('suppliers.validationTab.validatedBy') }}
                    </th>
                    <th class="text-center">
                        {{ $t('suppliers.validationTab.statut') }}
                    </th>
                    <th class="text-center">
                        {{ $t('suppliers.validationTab.commentaire') }}
                    </th>
                </tr>
            </thead>
            <tbody>
            <tr v-for="item in TraceValidationList" :key="item.validationDate" >
                <td style="text-wrap: nowrap">{{ formattedDateTime(item) }}</td>
                <td style="text-wrap: nowrap">
                    <v-list-item-title v-text="item.validatedBy.fullName"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.validatedBy.email"></v-list-item-subtitle>
                </td>
                <td>
                    <StatusButton disableIcon :title="statusTitle(item.lastStatus)" :color="item.status < item.lastStatus ? '#F25C5C' : '#00CBA0'" style="zoom: 0.9;">
                        <template v-slot:icon>
                            <svg v-if="item.status < item.lastStatus" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.7998 0.5C3.6673 0.5 0.299805 3.8675 0.299805 8C0.299805 12.1325 3.6673 15.5 7.7998 15.5C11.9323 15.5 15.2998 12.1325 15.2998 8C15.2998 3.8675 11.9323 0.5 7.7998 0.5ZM10.3198 9.725C10.5373 9.9425 10.5373 10.3025 10.3198 10.52C10.2073 10.6325 10.0648 10.685 9.9223 10.685C9.7798 10.685 9.6373 10.6325 9.5248 10.52L7.7998 8.795L6.0748 10.52C5.9623 10.6325 5.8198 10.685 5.6773 10.685C5.5348 10.685 5.3923 10.6325 5.2798 10.52C5.0623 10.3025 5.0623 9.9425 5.2798 9.725L7.0048 8L5.2798 6.275C5.0623 6.0575 5.0623 5.6975 5.2798 5.48C5.4973 5.2625 5.8573 5.2625 6.0748 5.48L7.7998 7.205L9.5248 5.48C9.7423 5.2625 10.1023 5.2625 10.3198 5.48C10.5373 5.6975 10.5373 6.0575 10.3198 6.275L8.59481 8L10.3198 9.725Z" fill="white" />
                            </svg>
                            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#FFFFFF" />
                            </svg>
                        </template>
                    </StatusButton>
                </td>
                <td>
                    {{ item.comment }}
                </td>
            </tr>
            </tbody>
        </v-table>
    </div>
    <dialogDelete :loader="buttonLoader" DialogType="Validation" :title="$t('suppliers.validationTab.supplierStatus')"
        :yes="validateSupplier" v-model="dialogValide" />
    <dialogDelete :loader="buttonLoader" DialogType="Refuse" :title="$t('suppliers.validationTab.supplierStatus')"
        :yes="refuseSupplier" v-model="dialogRefuse" />
</template>
<script>
import { getSupplierById, ValidateSupplier, RefuseSupplier, ValidationButton, CheckPermissionByUser, isSupplier, idSupplier, GetTraceValidationListBySupplierId } from "@/api/index"
import StatusButton from "@/genericComponent/StatusButton.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue";
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import { status } from "@/enums/Status";
export default {
    components: { StatusButton, genericBtnComponent, dialogDelete },
    data() {
        return {
            loader: true,
            affichValidationButton: false,
            Supplier: null,
            Status: null,
            afficheCommentaire: false,
            commentaire: null,
            dialogValide: false,
            dialogRefuse: false,
            msg: [],
            buttonLoader: false,
            TraceValidationList: null
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
        this.getSupplierById(this.idSupplier);
    },
    methods: {
        getTraceValidationListBySupplierId: function (idSupplier) {
            GetTraceValidationListBySupplierId(idSupplier).then(({data}) => {
                if(data.length>0) this.TraceValidationList = data
            })
        },
        getSupplierById: function (idSupplier) {
            getSupplierById(idSupplier)
                .then(({ data }) => {
                    this.Supplier = data;
                    this.Status = data.validationStatus
                    CheckPermissionByUser("Optima.Suppliers.Validate").then(({ data }) => {
                        if (data == true) {
                            this.validationButton();
                            this.getTraceValidationListBySupplierId(this.idSupplier);
                        } else {
                            this.loader = false;
                        }
                    })
                }).finally(() => {
                    this.buttonLoader = false;
                    this.$emit("getStatusSupplier");
                });
        },
        validationButton: function () {
            ValidationButton(this.Supplier.idSupplier).then(({ data }) => {
                if (data == true)
                    this.affichValidationButton = true
            }).finally(() => {
                this.loader = false;
            });
        },
        validateSupplier: function () {
            this.buttonLoader = true
            ValidateSupplier(this.Supplier.idSupplier).then(() => {
                this.ActiveSavedSnackBar()
            }).finally(() => {
                this.dialogValide = false;
                this.affichValidationButton = false;
                this.getSupplierById(this.idSupplier);
            });
        },
        checkComment: function () {
            if (this.commentaire == null || this.commentaire.length == 0) {
                this.msg['comment'] = this.$t('warningInput.isrequired', { itm: this.$t('suppliers.validationTab.commentaire') });
            } else {
                this.dialogRefuse = true
            }
        },
        refuseSupplier: function () {
            this.buttonLoader = true
            RefuseSupplier(this.Supplier.idSupplier, this.commentaire).then(() => {
                this.ActiveSavedSnackBar()
            }).finally(() => {
                this.afficheCommentaire = false
                this.dialogRefuse = false;
                this.affichValidationButton = false;
                this.getSupplierById(this.idSupplier);
            })
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        statusTitle(option) {
            switch (option) {
                case 1: return localStorage.getItem(status.created + this.$i18n.locale);
                case 2: return localStorage.getItem(status.validatedClient1 + this.$i18n.locale);
                case 3: return localStorage.getItem(status.validatedBySupplier + this.$i18n.locale);
                case 4: return localStorage.getItem(status.validatedByClient2 + this.$i18n.locale);
                default: return localStorage.getItem(status.created + this.$i18n.locale);
            }
        }
    },
    computed: {
        statusText() {
            switch (this.Status) {
                case 1: return localStorage.getItem(status.created + this.$i18n.locale);
                case 2: return localStorage.getItem(status.validatedClient1 + this.$i18n.locale);
                case 3: return localStorage.getItem(status.validatedBySupplier + this.$i18n.locale);
                case 4: return localStorage.getItem(status.validatedByClient2 + this.$i18n.locale);
                default: return localStorage.getItem(status.created + this.$i18n.locale);
            }
        },
        formattedDateTime() {
            return item => {
                const date = new Date(item.validationDate);
                
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                const hours = date.getHours();
                const minutes = date.getMinutes();

                const formattedDay = day < 10 ? `0${day}` : day;
                const formattedMonth = month < 10 ? `0${month}` : month;
                const formattedHours = hours < 10 ? `0${hours}` : hours;
                const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

                return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
            }
        },
    }
};
</script>
<style>
.title-cadre {
    height: 40px;
    border-radius: 6px;
    background: var(--grays-gray-100, #F9FAFB);

}

.title {
    color: var(--accent-accent-black, #080C3E);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
}

.timelineStatus {
    display: flex;
    flex-direction: row;
    padding: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.validationText {
    color: var(--Black, #1B194B);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.5;
    margin-top: 20px;
}
</style>