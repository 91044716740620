<template>
    <actions-menu v-model:dialogEdit="EditDialog" v-model:dialogDelete="DeleteDialog" :Id="Id" />
    <dialogDelete :title="$t('suppliers.alertsTab.alert')" :yes="deleteSupplierAlert" v-model="DeleteDialog" :class="{ 'disabled': isDisabled }"/>
    <v-dialog v-model="EditDialog" v-if="EditDialog">
        <v-card width="800px" class="py-2" style="border-radius: 12px;">
            <h1 class="text-center dialog-title"> {{ $t('suppliers.alertsTab.edit') }}</h1>
            <form>
                <div class="row ma-4 mt-2 mb-2">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.alertsTab.title') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="TitleEdit" id="TitleEdit" name="TitleEdit" :readonly="!validationButton" hide-details
                                persistent-placeholder required maxlength="30"
                                :placeholder="$t('suppliers.alertsTab.placeholder.title')"
                                variant="outlined"></v-text-field>
                            <span class='warningHint' id="WarningMsgTitle">{{ msg.TitleEdit }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.alertsTab.type') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="type" name="type" :placeholder="$t('suppliers.alertsTab.placeholder.type')"
                                variant="outlined" :readonly="!validationButton"  v-model="TypeEdit" :items="itemsType" hide-details>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.title">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class='warningHint' id="WarningMsgType">{{ msg.TypeEdit }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.alertsTab.date') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <dateField v-model="DateIssuedEdit" :minDate="new Date()" :readonly="!validationButton" />
                            <span class="warningHint" id="WarningMsgDateIssued">{{ msg.dateIssued }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.alertsTab.criticity') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="criticity" name="criticity" :readonly="!validationButton" 
                                :placeholder="$t('suppliers.alertsTab.placeholder.criticity')" variant="outlined"
                                v-model="CriticityEdit" :items="itemsCriticity" hide-details>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.title">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class='warningHint' id="WarningMsgCriticity">{{ msg.CriticityEdit }}</span>
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateFormEdit" v-model:no="EditDialog" :yesDisable="!validationButton"  width="300px"
                    :yesTitle="$t('suppliers.saveChanges')" />
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
import cardActions from "@/genericComponent/cardActions.vue"
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import { editSupplierAlert, deleteSupplierAlert,CheckPermissionByUser } from "@/api/index";
import dateField from "@/genericComponent/date-field.vue";
export default {
    mounted() {
        this.checkSupplierPermission();
    },
    components: { actionsMenu, dialogDelete, cardActions, dateField },
    name: "SupplierAlertEditMenu",
    props: {
        getAlertsList: {},
        Supplier: {
            type: Object,
            required: true,
        },
        Id: {}
    },
    data() {
        return {
            DeleteDialog: false,
            msg: [],
            EditDialog: false,
            TitleEdit: this.Supplier.title,
            TypeEdit: this.Supplier.type,
            DateIssuedEdit: this.Supplier.dateIssued.split('T')[0],
            CriticityEdit: this.Supplier.criticity,
            idAlert: this.Supplier.idAlert,
            itemsCriticity: ["Critical", "Medium", "High"],
            itemsType: ["Push Alert"],
            isDisabled: {
            type: Boolean,
            default: false
            }
        };
    },
    watch: {
        EditDialog() {
            this.TitleEdit = this.Supplier.title,
                this.TypeEdit = this.Supplier.type,
                this.DateIssuedEdit = this.Supplier.dateIssued.split('T')[0],
                this.CriticityEdit = this.Supplier.criticity,
                this.idAlert = this.Supplier.idAlert,
                this.msg = []
        }
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        deleteSupplierAlert() {
            this.DeleteDialog = false;
            deleteSupplierAlert(this.idAlert)
                .then(() => {
                    this.getAlertsList();
                    this.ActiveDeletedSnackBar();
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        checkSupplierPermission: function () {
            CheckPermissionByUser("Optima.Suppliers.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        EditSupplierAlert() {
            editSupplierAlert(this.idAlert, {
                idAlert: this.idAlert,
                title: this.TitleEdit,
                type: this.TypeEdit,
                dateIssued: this.DateIssuedEdit,
                criticity: this.CriticityEdit,
            })
                .then(() => {
                    if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    this.btnloader = false;
                    return;
                }
                    this.getAlertsList();
                    this.ActiveSavedSnackBar()
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                });
        },
        validateFormEdit() {
            if (!this.TypeEdit || this.TypeEdit.length == 0) {
                this.msg["TypeEdit"] = this.$t('suppliers.alertsTab.errors.type');
            } else {
                this.msg["TypeEdit"] = "";
            }

            if (!this.DateIssuedEdit || this.DateIssuedEdit.length == 0) {
                this.msg["DateIssuedEdit"] = this.$t('suppliers.alertsTab.errors.date');
            } else if (this.DateIssuedEdit < "1000-01-01") {
                this.msg["DateIssuedEdit"] = this.$t('warningInput.wrong', { itm: this.$t('suppliers.alertsTab.date') });
            } else {
                this.msg["DateIssuedEdit"] = "";
            }

            if (!this.CriticityEdit || this.CriticityEdit.length == 0) {
                this.msg["CriticityEdit"] = this.$t('suppliers.alertsTab.errors.criticity');
            } else {
                this.msg["CriticityEdit"] = "";
            }
            if (!this.TitleEdit || this.TitleEdit.length == 0) {
                this.msg["TitleEdit"] = this.$t('suppliers.alertsTab.errors.title');
            } else {
                this.msg["TitleEdit"] = "";
            }

            if (
                this.msg["CriticityEdit"] == "" &&
                this.msg["DateIssuedEdit"] == "" &&
                this.msg["TypeEdit"] == "" &&
                this.msg["TitleEdit"] == ""

            ) {
                this.EditSupplierAlert();
                this.EditDialog = false;
            }
        },
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    margin-top: 25px;
    text-align: center;
}

.v-dialog .v-card {
    border-radius: 12px;
    background-color: #ffffff;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
    cursor: pointer;
}

.v-card-text {
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}

.dialog-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>