<template>
    <v-card elevation="0">
        <div class="row ma-4">
            <div class="col-6 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('permissions.RoleName') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field :disabled="loader" v-model="Name" id="Name" name="Name" maxlength="30" hide-details
                        persistent-placeholder :placeholder="$t('placeholder.enter', { itm: $t('permissions.RoleName') })"
                        variant="outlined">
                        <template v-slot:append-inner>
                            <span v-if="loader">
                                <v-progress-circular indeterminate :size="30"></v-progress-circular>
                            </span>
                        </template>
                    </v-text-field>
                    <span class="warningHint" id="WarningMsgName">{{ msg.Name }}</span>
                </div>
            </div>
            <div class="col-6 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('permissions.Description') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field :disabled="loader" v-model="Description" id="Description" name="Description"
                        maxlength="100" hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('permissions.Description') })" variant="outlined">
                        <template v-slot:append-inner>
                            <span v-if="loader">
                                <v-progress-circular indeterminate :size="30"></v-progress-circular>
                            </span>
                        </template>
                    </v-text-field>
                    <span class="warningHint" id="WarningMsgDescription">{{ msg.Description }}</span>
                </div>
            </div>
            <div class="col-6 justify-content-start" v-if="Mode != 'Edit'">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('permissions.defaultRole') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="defaultRole" name="defaultRole" v-model="defaultRole"
                        v-model:search="defaultRoleSearch" hide-details
                        :placeholder="$t('placeholder.select', { itm: $t('permissions.defaultRole') })" variant="outlined"
                        persistent-placeholder :items="defaultRoleList" item-title="name" item-value="id" single-line>
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                                {{ item.name }}
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgDefaultRole">{{ msg.defaultRole }}</span>
                </div>
            </div>
        </div>
    </v-card>
</template>
<script>
import { GetOrganisationParentDefaultRoles } from "@/api/index"
export default {
    props: {
        Mode: {
            type: String,
            default: "Add"
        },
        RoleProps: {
            default: null
        },
    },
    data: () => ({
        Name: null,
        Description: "",
        msg: [],
        loader: false,
        rolesData: {},
        defaultRole: null,
        defaultRoleSearch: null,
        defaultRoleList: []
    }),
    created() {
        if (this.Mode == "Edit") {
            this.Name = this.RoleProps.name
            this.Description = this.RoleProps.description
            this.defaultRole = this.RoleProps.parentSecurityGroup
        }
        this.GetOrganisationParentDefaultRoles();
    },
    watch: {
        Name(value) {
            this.Name = value;
            this.rolesData.Name = this.Name;
            if (this.Name == "")
                delete this.rolesData.Name
            this.$emit('getData', this.rolesData)
        },
        Description(value) {
            this.Description = value;
            this.rolesData.Description = this.Description;
            if (this.Description == "")
                delete this.rolesData.Description
            this.$emit('getData', this.rolesData)
        },
        defaultRole(value) {
            this.defaultRole = value;
            this.rolesData.parentSecurityGroup = this.defaultRole;
            if (this.defaultRole == "")
                delete this.rolesData.parentSecurityGroup
            this.$emit('getData', this.rolesData)
        },
    },
    methods: {
        GetOrganisationParentDefaultRoles() {
            GetOrganisationParentDefaultRoles().then((res) => {
                this.defaultRoleList = res.data;
            })
        },
        validateForm() {
            if (!this.Name || this.Name.length == 0) {
                this.msg["Name"] = this.$t('warningInput.isrequired', { itm: this.$t('permissions.RoleName') });
            } else {
                this.msg["Name"] = "";
            }
            if (!this.Description || this.Description.length == 0) {
                this.msg["Description"] = this.$t('warningInput.isrequired', { itm: this.$t('permissions.Description') });
            } else {
                this.msg["Description"] = "";
            }
            if (!this.defaultRole || this.defaultRole.length == 0) {
                this.msg["defaultRole"] = this.$t('warningInput.isrequired', { itm: this.$t('permissions.defaultRole') });
            } else {
                this.msg["defaultRole"] = "";
            }
            if (this.msg['Name'] == "" &&
                this.msg['Description'] == "" &&
                this.msg["defaultRole"] == ""
            ) {
                return true
            } else {
                return false
            }
        },
    },
};
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
}
</style>