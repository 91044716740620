<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <span class="title">{{ $t('invoices.add') }}</span>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :disabled="!isSupplier() || !ValidatedSupplier || loader" :ClickFun="validateFormInvoice" svg="1"
                    :Title="$t('invoices.save')" :loader="loader" />
            </div>
        </div>
    </div>
    <v-card elevation="0">
        <div class="row ma-4">
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.reference') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Reference" id="Reference" name="Reference" maxlength="50" hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('invoices.reference') })"
                        variant="outlined"></v-text-field>
                    <span class="warningHint" id="WarningMsgReference">{{ msg.Reference }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.title') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Title" id="Title" name="Title" maxlength="30" hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('invoices.title') })"
                        variant="outlined"></v-text-field>
                    <span class="warningHint" id="WarningMsgTitle">{{ msg.Title }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.company') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="Company" name="Company" class="pl-0 pr-6" variant="outlined" v-model="IdCompany"
                        v-model:search="CompanySearch" persistent-placeholder
                        :placeholder="$t('placeholder.select', { itm: $t('invoices.company') })" :items="CompanyList"
                        item-title="brandName" item-value="id" hide-details>
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" variant="text" :id="index" :name="item.brandName" />
                        </template>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgCompany">{{ msg.Company }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.purchaseOrder') }}</v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="PurchaseOrder" name="PurchaseOrder" class="pl-0 pr-6" variant="outlined"
                        v-model="IdPurchaseOrder" v-model:search="PurchaseOrderSearch" persistent-placeholder
                        :placeholder="$t('placeholder.select', { itm: $t('invoices.purchaseOrder') })"
                        :items="PurchaseOrderList" item-title="title" item-value="id" hide-details clearable no-filter>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgPurchaseOrder">{{ msg.PurchaseOrder }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.currency') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-autocomplete id="Devise" name="Devise" class="pl-0 pr-6" variant="outlined" v-model="Devise"
                        persistent-placeholder :placeholder="$t('placeholder.select', { itm: $t('invoices.currency') })"
                        :items="DeviseList" item-title="name" item-value="symbol" hide-details :readonly="IdPurchaseOrder">
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                                {{ item.name }}
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                    <span class="warningHint" id="WarningMsgDevise">{{ msg.Devise }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.deadline') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <dateField v-model="Deadline" :minDate="new Date()"/>
                    <span class="warningHint" id="WarningMsgDeadline">{{ msg.Deadline }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.amount') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="Amount" id="Amount" name="Amount" maxlength="9"
                        @update:model-value="Amount = Amount.replace(/[^0-9.]/g, '');" hide-details persistent-placeholder
                        :placeholder="$t('placeholder.enter', { itm: $t('invoices.amount') })"
                        variant="outlined" :suffix="Devise"></v-text-field>
                    <span class="warningHint" id="WarningMsgAmount">{{ msg.Amount }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.tax') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row">
                    <v-select id="Tax" name="Tax" variant="outlined" v-model="Tax" persistent-placeholder
                        :placeholder="$t('placeholder.select', { itm: $t('invoices.tax') })" :items="TaxList"
                        item-title="taxPourcentage" item-value="taxPourcentage" hide-details :suffix="Tax? '%' : ''"
                        :readonly="IdCompany==null"> 
                        <template v-slot:item="{ props, item, index }">
                            <v-list-item v-bind="props" :id="index" :name="item.name" :title="item.value + '%'"
                                :subtitle="item.raw.taxName">
                            </v-list-item>
                        </template>
                    </v-select>
                    <span class="warningHint" id="WarningMsgTax">{{ msg.Tax }}</span>
                </div>
            </div>
            <div class="col-4 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.amountTTC') }}</v-card-title>
                </div>
                <div class="row">
                    <v-text-field v-model="AmountTTC" id="amountTTC" name="amountTTC" hide-details persistent-placeholder
                        :placeholder="$t('invoices.amountTTC')" variant="outlined" readonly :suffix="Tax? Devise : ''"></v-text-field>
                </div>
            </div>
            <div class="col-5 justify-content-start">
                <div class="row">
                    <v-card-title class="text-left">{{ $t('invoices.invoice') }}<span class="red">*</span></v-card-title>
                </div>
                <div class="row ml-0">
                    <uploadDocument @addDocument="setidentityDocument" :showUpload="true">
                        <template v-slot:DocumentsList>
                            <span v-if="identityDocument" style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start"
                                @click="downloadDocumentInvoices(identityDocument[0])"
                                :title="$t('actions.download') + ' ' + identityDocument[0].name">
                                {{ identityDocument[0].name }}
                            </span>
                        </template>
                    </uploadDocument>
                    <span class='warningHint' id="WarningMsgStatus">{{ msg.identityDocument }}</span>
                </div>
            </div>
            <div class="col-2"></div>
            <div class="col-5 justify-content-start">
                <div class="row ">
                <v-card-title class="text-left">{{ $t('purchaseOrder.AutresDocument') }}</v-card-title>
                </div>
                <div class="row ml-0">
                    <uploadMultiDocument v-on:addMultiDocument="setidentityDocuments" :showUpload="true">
                        <template v-slot:DocumentsList>
                            <div style="max-height: 60px; overflow-y:scroll;">
                                <div v-for="(fileName, index) in MultiFileList" :key="index">
                                    <div @mouseover="setHover(index)" @mouseout="setHover(null)"
                                        style="display: flex; position: relative;">
                                        <span style="color: blue; cursor: pointer; text-align: start; max-width: 95%;"
                                            @click="downloadMultiDocumentInvoices(fileName, index)"
                                            :title="$t('actions.download') + ' ' + fileName">{{ fileName }}</span>
                                        <v-icon v-show="isHovered == index"
                                            @click="showDeleteConfirmationDialog(fileName, index)" class="ml-2"
                                            style="color: red">mdi-close-circle-outline</v-icon>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </uploadMultiDocument>
                </div>
            </div>
        </div>
    </v-card>
    <dialogDelete v-if="selectedIndex !== null" v-model="showDeleteDialog" :yes="confirmDelete" :status="false"
        :title="selectedFileName" :loader="deleteLoader"></dialogDelete>
</template>
<script>
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import uploadMultiDocument from "@/genericComponent/uploadMultiDocument.vue"
import { CreateInvoice, GetCompanyFromInvoices, GetPurchaseOrderFromInvoices, isSupplier, isValidatedSupplier, GetTaxListByCmpanyId, GetDeviseFromPurchaseOrder, CanValideFactureByPurchaseOrder, InvoiceIsExistReference } from "@/api/index";
import deviseJson from "@/data/DevisesList.json"
import dateField from "@/genericComponent/date-field.vue";
import uploadDocument from "@/genericComponent/uploadDocument.vue"
export default {
    components: { genericBtnComponent, dateField, uploadDocument, uploadMultiDocument, dialogDelete },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.invoices');
    },
    data: () => ({
        ValidatedSupplier: false,
        Reference: null,
        Title: null,
        Amount: null,
        Deadline: null,
        Devise: null,
        Tax: null,
        IdCompany: null,
        CompanySearch: null,
        CompanyList: [],
        msg: [],
        TaxList: [],
        DeviseList: deviseJson,
        loader: false,
        identityDocument: null,
        identityDocuments: [],
        IdPurchaseOrder: null,
        PurchaseOrderSearch: null,
        PurchaseOrderList: [],
        isHovered: null,
        selectedIndex: null,
        selectedFileName: null
    }),
    created() {
        this.isValidatedSupplier();
        this.GetCompanyList();
        this.GetPurchaseOrderList();
    },
    watch: {
        CompanySearch(value) {
            if (this.CompanySearch != null && this.CompanySearch.length == 1) this.CompanySearch = value.trim();
            this.GetCompanyList();
        },
        PurchaseOrderSearch(value) {
            if (this.PurchaseOrderSearch != null && this.PurchaseOrderSearch.length == 1) this.PurchaseOrderSearch = value.trim();
            this.GetPurchaseOrderList();
        },
        IdCompany(value) {
            if (value != null) this.GetTaxListByCmpanyId(value);
            else this.Tax = null
        },
        IdPurchaseOrder(value) {
            if (value){
                this.GetDeviseFromPurchaseOrder(value)
            }
        }
    },
    methods: {
        confirmDelete() {
            if (this.selectedIndex !== null) {
                this.identityDocuments.splice(this.selectedIndex, 1);
                this.selectedIndex = null;
                this.selectedFileName = null;
                this.showDeleteDialog = false;
            }
        },
        showDeleteConfirmationDialog(filename, index) {
            this.showDeleteDialog = true;
            this.selectedFileName = filename;
            this.selectedIndex = index;
        },
        downloadMultiDocumentInvoices(fileName, index) {
            var reader = new FileReader();
            reader.readAsDataURL(this.identityDocuments[index]);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        },
        downloadDocumentInvoices(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = file.name;
                downloadLink.click();
            }
        },
        setHover(index) {
            this.isHovered = index;
        },
        setidentityDocument(value) {
            this.identityDocument = value
        },
        setidentityDocuments(value) {
            this.identityDocuments.push(...value);
        },
        isSupplier() {
            return isSupplier()
        },
        isValidatedSupplier() {
            isValidatedSupplier().then(({ data }) => {
                this.ValidatedSupplier = data
            })
        },
        GetCompanyList() {
            GetCompanyFromInvoices(this.CompanySearch)
                .then((res) => {
                    this.CompanyList = res.data;
                })
        },
        GetTaxListByCmpanyId(companyId) {
            GetTaxListByCmpanyId(companyId).then((res) => {
                this.TaxList = res.data;
            })
        },
        GetPurchaseOrderList() {
            GetPurchaseOrderFromInvoices(this.PurchaseOrderSearch)
                .then((res) => {
                    this.PurchaseOrderList = res.data;
                    this.PurchaseOrderList.map((item)=>{ item.title = item.reference + ' - ' + item.title})
                })
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        ActivePurchaseOrderInsufficientSnackBar() {
            this.getParent('MainLayout').ActivePurchaseOrderInsufficientSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        createInvoice() {
            CreateInvoice(
                this.Reference,
                this.Title,
                this.Amount,
                this.Tax,
                this.Devise,
                this.Deadline,
                this.IdCompany,
                this.IdPurchaseOrder,
                this.identityDocument,
                this.identityDocuments
            ).then(() => {
                this.ActiveSavedSnackBar();
            }).catch(() => {
                this.ActiveWarningSnackBar();
            }).finally(() => {
                this.loader = false;
                this.$router.push({ path: "/Invoices" });
            });
        },
        validateForm() {
            if (this.IdPurchaseOrder){
                CanValideFactureByPurchaseOrder(this.IdPurchaseOrder,this.AmountTTC).then((r)=>{
                    if(r.data){
                        this.createInvoice();
                    }else{
                        this.ActivePurchaseOrderInsufficientSnackBar();
                        this.loader = false;
                    }
                })
            } else {
                this.createInvoice();
            }
        },
        async IsExistReference() {
            const r = await InvoiceIsExistReference(null, this.Reference);
            return r.data;
        },
        async validateFormInvoice() {
            this.loader = true

            if (!this.Reference || this.Reference.length == 0) {
                this.msg["Reference"] = this.$t('invoices.referenceRequired');
            } else if(await this.IsExistReference()==true) {
                this.msg["Reference"] = this.$t('warningInput.exist', { itm: this.$t('invoices.reference') });
            } else {
                this.msg["Reference"] = "";
            }

            if (!this.Title || this.Title.length == 0) {
                this.msg["Title"] = this.$t('invoices.titleRequired');
            } else {
                this.msg["Title"] = "";
            }

            if (!this.IdCompany || this.IdCompany.length == 0) {
                this.msg["Company"] = this.$t('invoices.companyRequired');
            } else {
                this.msg["Company"] = "";
            }

            if (!this.Devise || this.Devise.length == 0) {
                this.msg["Devise"] = this.$t('invoices.currencyRequired');
            } else {
                this.msg["Devise"] = "";
            }

            if (!this.Amount || this.Amount.length == 0) {
                this.msg["Amount"] = this.$t('invoices.amountRequired');
            } else if (this.Amount <= 0) {
                this.msg["Amount"] = this.$t('warningInput.notValid', { itm: this.$t('invoices.amount') });
            } else {
                this.msg["Amount"] = "";
            }

            if (this.Tax==null || this.Tax.length == 0) {
                this.msg["Tax"] = this.$t('invoices.taxRequired');
            } else {
                this.msg["Tax"] = "";
            }

            if (!this.Deadline || this.Deadline.length == 0) {
                this.msg["Deadline"] = this.$t('invoices.deadlineRequired');
            } else if (this.Deadline < "1000-01-01") {
                this.msg["Deadline"] = this.$t('warningInput.wrong', { itm: this.$t('invoices.deadline') });
            } else {
                this.msg["Deadline"] = "";
            }
            if (this.identityDocument == null || this.identityDocument == "") {
                this.msg["identityDocument"] = this.$t('invoices.documentsTab.errors.identityDocument');
            } else {
                this.msg["identityDocument"] = "";
            }
            
            if (this.msg["Devise"] == "" && this.msg["Title"] == ""
                && this.msg["Amount"] == "" && this.msg["Company"] == ""
                && this.msg["Deadline"] == "" && this.msg["identityDocument"] == ""
                && this.msg["Reference"] == "" && this.msg["Tax"] == "") {
                this.validateForm();
            } else { this.loader = false }
        },
       
        GetDeviseFromPurchaseOrder(IdPurchaseOrder){
            GetDeviseFromPurchaseOrder(IdPurchaseOrder).then(r => {
                this.Devise = r.data;
            })
        }
    },
    computed: {
        AmountTTC() {
            if (this.Tax != null) {
                const amountTTC = this.Amount * (1 + (this.Tax / 100))
                let parts = amountTTC.toString().split('.');
                if (parts[1] && parts[1].length > 3) {
                    return amountTTC.toFixed(3).toString().replace(/\.?0+$/, '');
                } else {
                    return amountTTC;
                }
            } else {
                return null;
            }
        },
        MultiFileList() {
            let list = [];
            if (this.identityDocuments) {
                this.identityDocuments.forEach(file => {
                    list.push(file.name)
                });
            }
            return list
        }
    }
};
</script>
<style scoped>
.title {
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    padding-left: 0;
    margin-top: 0;
    padding-top: 0;
}

.v-autocomplete {
    margin-left: 12px;
}
</style>