<template>
    <div v-if="loader" style="height: 250px; line-height: 250px;">
        <v-progress-circular indeterminate :size="50"></v-progress-circular>
    </div>
    <div v-else class="row ma-4 mr-16 pr-16">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0" >{{ $t('configuration.company.brandName') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field required v-model="brandName"  :readonly="!validationButton" id="brandName" name="brandName" hide-details maxlength="30"
                    persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.brandName') })"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgBrandName"> {{ msg.brandName }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.companyfullname') }}<span class="red">*</span>
                    </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="companyFullName" :readonly="!validationButton" id="companyFullName" name="companyFullName"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.companyfullname') })"
                    variant="outlined" hide-details persistent-placeholder></v-text-field>
                <span class='warningHint' id="WarningMsgCompanyFullName"> {{ msg.companyFullName }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.address') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="address" :readonly="!validationButton" id="address" name="address"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.address') })" variant="outlined"
                    hide-details persistent-placeholder></v-text-field>
                <span class='warningHint' id="WarningMsgAddress"> {{ msg.address }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.city') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="city" :readonly="!validationButton" id="city" name="city"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.city') })" variant="outlined"
                    hide-details persistent-placeholder>
                </v-text-field>
                <span class='warningHint' id="WarningMsgCity"> {{ msg.city }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.postalCode') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="postalCode" :readonly="!validationButton"  id="postalCode" name="postalCode"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.postalCode') })" variant="outlined"
                    hide-details persistent-placeholder
                    @update:model-value="postalCode = postalCode.replace(/[^0-9.]/g, '');">
                </v-text-field>
                <span class='warningHint' id="WarningMsgPostalCode"> {{ msg.postalCode }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.country') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <select-country v-bind:countryProps="country" @getCountry="getCountryFromSelect" :readonly="!validationButton  || !isEditMode "></select-country>
                <span class='warningHint' id="WarningMsgCountry"> {{ msg.country }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.registrationID') }}<span class="red">*</span>
                    </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="registrationId" :readonly="!validationButton" id="registrationId" name="registrationId"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.registrationID') })"
                    variant="outlined" hide-details persistent-placeholder></v-text-field>
                <span class='warningHint' id="WarningMsgRegistrationId"> {{ msg.registrationId }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.vatID') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="vatId" :readonly="!validationButton" id="vatId" name="vatId" hide-details persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.vatID') })"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgVatId"> {{ msg.vatId }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.company.defaultmail') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="defaultEmail" :readonly="!validationButton" id="defaultEmail" name="defaultEmail"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.company.defaultmail') })" variant="outlined"
                    hide-details persistent-placeholder>
                </v-text-field>
                <span class='warningHint' id="WarningMsgDefaultEmail"> {{ msg.defaultEmail }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
        </div>
        <div class="col-6 justify-content-start">
            <uploadLogo @addLogo="setLogo" :readonly="!validationButton" :namelogo="item.logo != 'null' ? item.idCompany + '.' + item.logo : null" />
        </div>
    </div>
</template>
<script>
import SelectCountry from '../SelectCountry/SelectCountry.vue';
import uploadLogo from '@/genericComponent/uploadLogo.vue'
import { getCompanyByTokenId } from "@/api/index";
export default {
    props: {
        validationButton: Boolean,
        isEditMode: Boolean,
  },
    components: {
        SelectCountry, uploadLogo
    },
    data: () => ({
        loader: true,
        item: null,
        errorMessages: "",
        brandName: "",
        registrationId: "",
        address: "",
        postalCode: "",
        defaultEmail: "",
        companyFullName: "",
        country: null,
        city: "",
        vatId: "",
        logo: null,
        logoImage: null,
        msg: [],
        companiesData: {},
        
    }),
    created() {
        this.getCompanyByTokenId()
    },
    watch: {
        brandName(value) {
            this.brandName = value;
            this.companiesData.brandName = this.brandName;
            if (this.brandName == "")
                delete this.companiesData.brandName
            this.$emit('getData', this.companiesData)
        },
        companyFullName(value) {
            this.companyFullName = value;
            this.companiesData.companyFullName = this.companyFullName;
            if (this.companyFullName == "")
                delete this.companiesData.companyFullName
            this.$emit('getData', this.companiesData)
        },
        address(value) {
            this.address = value;
            this.companiesData.address = this.address;
            if (this.address == "")
                delete this.companiesData.address
            this.$emit('getData', this.companiesData)
        },
        postalCode(value) {
            this.postalCode = value;
            this.companiesData.postalCode = this.postalCode;
            if (this.postalCode == 0)
                delete this.companiesData.postalCode
            this.$emit('getData', this.companiesData)
        },
        registrationId(value) {
            this.registrationId = value;
            this.companiesData.idRegistration = this.registrationId;
            if (this.registrationId == "")
                delete this.companiesData.idRegistration
            this.$emit('getData', this.companiesData)
        },
        defaultEmail(value) {
            this.defaultEmail = value;
            this.companiesData.defaultEmail = this.defaultEmail;
            if (this.defaultEmail == "")
                delete this.companiesData.defaultEmail
            // eslint-disable-next-line
            if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.defaultEmail)) == false)
                delete this.companiesData.defaultEmail
            this.$emit('getData', this.companiesData)
        },
        city(value) {
            this.city = value;
            this.companiesData.city = this.city;
            if (this.city == "")
                delete this.companiesData.city
            this.$emit('getData', this.companiesData)
        },
        country(value) {
            this.country = value;
            this.companiesData.country = this.country;
            if (this.country == null || this.country == "")
                delete this.companiesData.country
            this.$emit('getData', this.companiesData)
        },
        vatId(value) {
            this.vatId = value;
            this.companiesData.idVat = this.vatId;
            if (this.vatId == "")
                delete this.companiesData.idVat
            this.$emit('getData', this.companiesData)
        },
    },
    methods: {
        setLogo(value) {
            this.logoImage = value
            this.$emit('setLogo', value)
        },
        getCountryFromSelect(value) {
            this.country = value;
        },
        getCompanyByTokenId: function () {
            getCompanyByTokenId()
                .then(response => {
                    this.item = response.data;
                    this.companiesData.idCompany = this.item.idCompany,
                        this.postalCode = this.item.postalCode;
                    this.companyFullName = this.item.companyFullName;
                    this.brandName = this.item.brandName;
                    this.address = this.item.address;
                    this.city = this.item.city;
                    this.defaultEmail = this.item.defaultEmail;
                    this.vatId = this.item.idVat;
                    this.registrationId = this.item.idRegistration;
                    this.country = this.item.country;
                    this.companiesData.logo = this.item.logo;
                    this.companiesData.status = this.item.status;
                    this.loader = false;
                })
        },
        ControleSaisir() {
            if (this.brandName == "") {
                this.msg['brandName'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.brandName') });
            } else {
                this.msg['brandName'] = '';
            }
            if (this.companyFullName == "") {
                this.msg['companyFullName'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.companyfullname') });
            } else {
                this.msg['companyFullName'] = '';
            }
            if (this.address == "") {
                this.msg['address'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.address') });
            } else {
                this.msg['address'] = '';
            }
            if (this.postalCode == 0) {
                this.msg['postalCode'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.postalCode') });
            } else {
                this.msg['postalCode'] = '';
            }
            if (this.registrationId == "" ||  this.registrationId==null) {
                this.msg['registrationId'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.registrationID') });
            } else {
                this.msg['registrationId'] = '';
            }
            if (this.defaultEmail == "") {
                this.msg['defaultEmail'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.defaultmail') });
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.defaultEmail)) {
                this.msg['defaultEmail'] = '';
            } else {
                this.msg['defaultEmail'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.company.defaultmail') });
            }
            if (this.city == "") {
                this.msg['city'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.city') });
            } else {
                this.msg['city'] = '';
            }
            if (this.country == "" || this.country == null) {
                this.msg['country'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.country') });
            } else {
                this.msg['country'] = '';
            }
            if (this.vatId == "" || this.vatId==null) {
                this.msg['vatId'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.company.vatID') });
            } else {
                this.msg['vatId'] = '';
            }
            if (
                this.msg["brandName"] == "" &&
                this.msg["companyFullName"] == "" &&
                this.msg["address"] == "" &&
                this.msg["postalCode"] == "" &&
                this.msg["registrationId"] == "" &&
                this.msg["defaultEmail"] == "" &&
                this.msg["city"] == "" &&
                this.msg["country"] == "" &&
                this.msg["vatId"] == ""
            ) {
                return true;
            } else {
                return false;
            }
        },
    }
};
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>