<template>
    <v-dialog v-model="dialog2" v-if="dialog2">
        <v-card width="800px" class="py-2" style="border-radius: 12px;">
            <v-form ref="form" lazy-validation>
                <h1>{{ $t('suppliers.usersTab.add') }} </h1>
                <div class="row ma-4">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.name') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="fullName" id="fullName" name="fullName" hide-details
                                persistent-placeholder required maxlength="30"
                                :placeholder="$t('suppliers.usersTab.placeholder.name')" variant="outlined">
                            </v-text-field>
                            <span class="warningHint" id="WarningMsgFullName">{{ msg.fullName }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.phone') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="phoneNumber" id="phoneNumber" name="phoneNumber" hide-details
                                persistent-placeholder required maxlength="15"
                                :placeholder="$t('suppliers.usersTab.placeholder.phone')" variant="outlined"
                                @update:model-value="phoneNumber = phoneNumber.replace(/[^0-9.]/g, '');">
                            </v-text-field>
                            <span class="warningHint" id="WarningMsgPhoneNumber">{{ msg.phoneNumber }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.email') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="email" id="email" name="email" hide-details maxlength="40"
                                persistent-placeholder :placeholder="$t('suppliers.usersTab.placeholder.email')"
                                variant="outlined"></v-text-field>
                            <span class='warningHint' id="WarningMsgEmail">{{ msg.email }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.role') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="role" name="role" variant="outlined" v-model="role" :items="itemsRole"
                                item-title="name" item-value="name" hide-details
                                :placeholder="$t('suppliers.usersTab.placeholder.role')">
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.title">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class='warningHint' id="WarningMsgRole">{{ msg.role }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.portal') }}<span class="red">*</span>
                                <v-tooltip  :text="$t('suppliers.usersTab.webAccessInfo')" location="right" max-width="350px">
                                    <template v-slot:activator="{ props }">
                                        <img class="ml-1" v-bind="props" :src="require('@/assets/infoBulle.svg')"/>
                                    </template>
                                </v-tooltip>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <div class="col-2 switchLabel"
                                @click="supplierPortal = supplierPortal == itemsPortal[0].value ? itemsPortal[1].value : itemsPortal[0].value">
                                <img v-if="supplierPortal == itemsPortal[0].value"
                                    :src="require('@/assets/Switch/SwitchOn.png')" style="width: 44px;" />
                                <img v-else :src="require('@/assets/Switch/SwitchOff.png')" style="width: 44px;" />
                            </div>
                            <div class="col-10 switchLabel">
                                {{ $t('suppliers.usersTab.webAccess') }}
                            </div>
                            <span v-if="msg.supplierPortal" class='warningHint' id="WarningMsgSupplierPortal">{{ msg.supplierPortal }}</span>
                        </div>
                    </div>
                </div>
                <card-actions :yes="AddSupplierUser" v-model:no="dialog2" width="300px"
                    :yesTitle="$t('suppliers.usersTab.add')" />
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import { addSupplierUser, getRolesBySupplier, ExistSupplierMail, isSupplier, idSupplier } from "@/api/index";
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    props: {
        reloadListUser: {}
    },
    components: {
        cardActions
    },
    data() {
        return {
            msg: [],
            idSupplier: null,
            dialog2: true,
            fullName: null,
            phoneNumber: null,
            email: null,
            supplierPortal: null,
            role: null,
            itemsRole: [],
            existMail: false,
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
        getRolesBySupplier(this.idSupplier).then((res) => { this.itemsRole = res.data.items; })
        this.supplierPortal = this.itemsPortal[0].value
    },
    watch: {
        dialog2(value) {
            this.dialog2 = value
            this.$emit('add-user-close-dialog')
            this.dialog2 = true
            this.fullName = null
            this.phoneNumber = null
            this.email = null
            this.supplierPortal = this.itemsPortal[0].value
            this.role = null
            this.msg = []
        },
        email() {
            this.existMail = null
            // eslint-disable-next-line
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                ExistSupplierMail(this.email).then((res) => {
                    this.existMail = res.data;
                })
            }
        },
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        AddSupplierUser() {
            if (!(this.fullName) || (this.fullName).length == 0) {
                this.msg['fullName'] = this.$t('suppliers.usersTab.errors.name');
            }
            else {
                this.fullName = (this.fullName.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.fullName))) {
                    this.msg['fullName'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else if (!(this.fullName.split(/\W+/).length >= 2)) {
                    this.msg['fullName'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else if (!(this.fullName.split(' ')[1].length >= 1)) {
                    this.msg['fullName'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else {
                    this.msg['fullName'] = '';
                }
            }

            if (!(this.phoneNumber) || (this.phoneNumber).length == 0) {
                this.msg['phoneNumber'] = this.$t('suppliers.usersTab.errors.phone');
            } else if (((this.phoneNumber).length < 8) || ((this.phoneNumber).length > 15)) {
                this.msg['phoneNumber'] = this.$t('suppliers.usersTab.errors.wrongNumber');
            } // eslint-disable-next-line
            else if (this.phoneNumber.match(/^[\+]?[0-9]{8,15}$/im)) {
                this.msg['phoneNumber'] = '';
            } else {
                this.msg['phoneNumber'] = this.$t('suppliers.usersTab.errors.wrongNumber');
            }

            if (!this.email || this.email.length == 0) {
                this.msg["email"] = this.$t('suppliers.usersTab.errors.email');
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
            ) {
                this.msg["email"] = "";
            } else {
                this.msg["email"] = this.$t('suppliers.usersTab.errors.wrongEmail');
            }

            if (!this.supplierPortal || this.supplierPortal.length == 0) {
                this.msg["supplierPortal"] = this.$t('suppliers.usersTab.errors.portal');
            } else {
                this.msg["supplierPortal"] = "";
            }
            if (!this.role || this.role.length == 0) {
                this.msg["role"] = this.$t('suppliers.usersTab.errors.role');
            } else {
                this.msg["role"] = "";
            }
            if (this.existMail) {
                this.msg["email"] = this.$t('warningInput.exist', { itm: this.$t('suppliers.usersTab.email') });
            } else if (
                this.msg["fullName"] == "" &&
                this.msg["phoneNumber"] == "" &&
                this.msg["email"] == "" &&
                this.msg["supplierPortal"] == "" &&
                this.msg["role"] == ""
            ) {
                this.$emit('add-user-close-dialog');
                addSupplierUser(this.fullName, this.phoneNumber, this.email, this.supplierPortal, this.role, this.idSupplier)
                    .then(() => {
                        this.ActiveSavedSnackBar();
                        this.reloadListUser();
                    }).catch(() => {
                        this.ActiveWarningSnackBar();
                    });
                setTimeout(() => {
                    this.fullName = null;
                    this.phoneNumber = null;
                    this.email = null;
                    this.supplierPortal = this.itemsPortal[0].value;
                    this.role = null;
                }, 2000);
            }
        }
    },
    computed: {
        itemsPortal() {
            return [
                { name: this.$t('suppliers.usersTab.portalType.WebSite'), value: "Website" },
                { name: this.$t('suppliers.usersTab.portalType.physical'), value: "Phisical" },
            ]
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-dialog .v-card {
    border-radius: 12px;
    background-color: #fff;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style> 