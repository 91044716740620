<template>
    <div class="row ma-4 mr-16 pr-16">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.user.Fullname') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="userName" hide-details persistent-placeholder maxlength="30"
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.Fullname') })" variant="outlined"
                    id="userName" name="userName"></v-text-field>
                <span class="warningHint" id="WarningMsgUserName">{{ msg.userName }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.user.email') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field maxlength="40" v-model="email" hide-details persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.email') })" variant="outlined"
                    id="email" name="email"></v-text-field>
                <span class="warningHint" id="WarningMsgEmail">{{ msg.email }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.user.regionCoverage') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="Regioncoverage" hide-details persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.user.regionCoverage') })" variant="outlined"
                    disabled id="Regioncoverage" name="Regioncoverage"></v-text-field>
                <span class="warningHint" id="WarningMsgRegionCoverage">{{ msg.regionCoverage }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.user.department') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="department" id="department" name="department" hide-details persistent-placeholder
                    :placeholder="$t('placeholder.select', { itm: $t('configuration.user.department') })" variant="outlined"
                    disabled></v-text-field>
                <span class="warningHint" id="WarningMsgDepartement">{{ msg.departement }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { ExistCompanyEmail } from '@/api';
export default {
    props: ['LocationName', 'DepartmentName'],
    data() {
        return {
            existMail: null,
            validForm: null,
            id: "",
            email: "",
            department: this.DepartmentName,
            userName: "",
            Regioncoverage: this.LocationName,
            msg: [],
            UserData: {
                idCompany: "",
                id: "",
                userName: "",
                email: "",
                role: "admin",
                DepartmentName: this.DepartmentName,
                LocationCoverage: this.LocationName,
            }
        }
    },
    methods: {
        validateForm() {
            this.id = this.generateUUID();
            if (!(this.userName) || (this.userName).length == 0) {
                this.msg['userName'] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.Fullname') });
            }
            else {
                this.userName = (this.userName.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.userName))) {
                    this.msg['userName'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else if (!(this.userName.split(/\W+/).length >= 2)) {
                    this.msg['userName'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else if (!(this.userName.split(' ')[1].length >= 1)) {
                    this.msg['userName'] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.Fullname') });
                }
                else {
                    this.msg['userName'] = '';
                }
            }
            if (!this.email || this.email.length == 0) {
                this.msg["email"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.email') });
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.msg['email'] = "";
            } else {
                this.msg["email"] = this.$t('warningInput.wrong', { itm: this.$t('configuration.user.email') });
            }
            if (!this.id || this.id.length == 0) {
                this.msg["id"] = "ID is required";
            } else if (this.id.length != 36) {
                this.msg["id"] = "ID is invalid";
            } else {
                this.msg["id"] = "";
            }
            if (!this.department || this.department.length == 0 || this.department == "Select departement") {
                this.msg["departement"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.department') });
            } else {
                this.msg["departement"] = "";
            }
            if (this.regionCoverage == "") {
                this.msg["regionCoverage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.user.regionCoverage') });
            } else {
                this.msg["regionCoverage"] = "";
            }

            if (this.existMail) {
                this.msg["email"] = this.$t('warningInput.exist', { itm: this.$t('configuration.user.email') });
            } else if (this.msg["email"] == ""
                && this.msg["id"] == ""
                && this.msg["userName"] == ""
                && this.msg["departement"] == ""
                && this.msg["regionCoverage"] == ""
            ) {
                return true;
            } else {
                return false;
            }
        },
        createNewUUID() {
            this.UserData.id = this.generateUUID();
        },
        generateUUID() { 
            var d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
                d += performance.now(); 
            }
            var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return newGuid;
        },
    },
    watch: {
        id(value) {
            this.id = value;
            this.UserData.id = this.id;
            if (this.id == "")
                delete this.UserData.id
            this.$emit('getData', this.UserData)
        },
        userName(value) {
            this.userName = value;
            this.UserData.userName = this.userName;
            if (this.userName == "")
                delete this.UserData.userName
            this.$emit('getData', this.UserData)
        },
        email(value) {
            this.email = value;
            this.existMail=null
            // eslint-disable-next-line
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                ExistCompanyEmail(this.email).then((res) => {
                    this.existMail = res.data;
                })
            }
            this.UserData.email = this.email;
            if (this.email == "")
                delete this.UserData.email
            this.$emit('getData', this.UserData)
        },
    },
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5B6D8E;
    margin-left: 12px;
    margin-bottom: 5px;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
</style>