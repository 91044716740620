<template>
    <div class="row ma-4 mr-16 pr-16">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.department.departmentName') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="DepartmentName" maxlength="30" id="DepartmentName" name="DepartmentName" hide-details
                    persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.department.departmentName') })"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgDepartmentName"> {{ msg.DepartmentName }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('configuration.department.locationCoverage') }}<span class="red">*</span>
                </v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="LocationCoverage" id="LocationCoverage" name="LocationCoverage" hide-details
                    persistent-placeholder
                    :placeholder="$t('placeholder.enter', { itm: $t('configuration.department.locationCoverage') })"
                    variant="outlined" disabled></v-text-field>
                <span class='warningHint' id="WarningMsgLocationCoverage"> {{ msg.LocationCoverage }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['LocationName'],
    data() {
        return {
            DepartmentName: "",
            LocationCoverage: this.LocationName,
            msg: [],
            DepartmentData: {
                DepartmentName: "",
                LocationCoverage: this.LocationName,
            }
        }
    },
    watch: {
        DepartmentName(value) {
            this.DepartmentName = value;
            this.DepartmentData.DepartmentName = this.DepartmentName;
            if (this.DepartmentName == "")
                delete this.DepartmentData.DepartmentName
            this.$emit('getData', this.DepartmentData)
        },
        LocationCoverage(value) {
            this.LocationCoverage = value;
            this.DepartmentData.LocationCoverage = this.LocationCoverage;
            if (this.LocationCoverage == "")
                delete this.DepartmentData.LocationCoverage
            this.$emit('getData', this.DepartmentData)
        },
    },
    methods: {
        validateForm() {
            this.msg["DepartmentName"] = "";
            this.msg["LocationCoverage"] = "";
            if (!this.DepartmentName || this.DepartmentName.length == 0) {
                this.msg["DepartmentName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.department.departmentName') });
            } else {
                this.msg["DepartmentName"] = "";
            }

            if (this.LocationCoverage == null) {
                this.msg["LocationCoverage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.department.locationCoverage') });
            } else {
                this.msg["LocationCoverage"] = "";
            }

            if (
                this.msg["DepartmentName"] == "" &&
                this.msg["LocationCoverage"] == ""
            ) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>
<style scoped>
.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5B6D8E;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>