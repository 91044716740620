   
<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>{{ $t('suppliers.edit.supplier') }}</v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent v-if="tab == 'option-1'" :disabled="!canEditGeneralInformation"
                    :ClickFun="SaveAndContinue" svg="1" :Title="$t('suppliers.edit.save')" />
                <genericBtnComponent v-if="tab == 'option-2'" @click="AddUsers = true" :disabled="!validationWriteButton" v-bind:idSupplier="idSupplier"
                    svg="2" :Title="$t('suppliers.edit.user')" />
                <genericBtnComponent v-if="tab == 'option-3'" @click="AddDocument = true" :disabled="!validationWriteButton" v-bind:idSupplier="idSupplier"
                    svg="2" :Title="$t('suppliers.edit.document')" />
                <genericBtnComponent v-if="tab == 'option-4'" @click="AddAlerts = true"  :disabled="!validationWriteButton" v-bind:idSupplier="idSupplier"
                    svg="2" :Title="$t('suppliers.edit.alert')" :class="{ 'disabled': isDisabled }" />
                <genericBtnComponent v-if="tab == 'option-5'" @click="AddBankAccount = true"  :disabled="!validationWriteButton" v-bind:idSupplier="idSupplier"
                    svg="2" :Title="$t('suppliers.edit.bankAccount')" />
            </div>
        </div>
    </div>
    <v-card flat>
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow fixed-tabs>
            <v-tab class="slider" value="option-1" :style="NotActive" centered v-model="general">
                {{ $t('suppliers.general') }}</v-tab>
            <v-tab class="slider" value="option-2" :style="NotActive"> {{ $t('suppliers.users') }}</v-tab>
            <v-tab class="slider" value="option-3" :style="NotActive"> {{ $t('suppliers.documents') }}</v-tab>
            <v-tab class="slider" value="option-4" :style="NotActive"> {{ $t('suppliers.alerts') }}</v-tab>
            <v-tab class="slider" value="option-5" :style="NotActive"> {{ $t('suppliers.bankAccounts') }}</v-tab>
            <v-tab class="slider" value="option-6" :style="NotActive"> {{ $t('suppliers.validation') }}</v-tab>
        </v-tabs>
        <v-divider class="ma-0"></v-divider>
        <v-window v-model="tab" :key="tab">
            <v-window-item value="option-1">
                <supplier-general-information-tab @getData="getDataFromEditSupplier" ref="generalInformationListRef" />
            </v-window-item>
            <v-window-item value="option-2">
                <supplier-users-tab ref="UserListRef" v-bind:idSupplier="idSupplier" :validationButton="validationButton" />
            </v-window-item>
            <v-window-item value="option-3">
                <supplier-documents-tab ref="DocumentsListRef" v-bind:idSupplier="idSupplier" :validationButton="validationButton" />
            </v-window-item>
            <v-window-item value="option-4">
                <supplier-alerts-tab ref="AlertListRef" v-bind:idSupplier="idSupplier" :validationButton="validationButton" />
            </v-window-item>
            <v-window-item value="option-5">
                <supplier-Bank-Account-tab ref="BankAccountListRef" v-bind:idSupplier="idSupplier" :validationButton="validationButton" />
            </v-window-item>
            <v-window-item value="option-6">
                <supplier-Validation-tab ref="ValidationRef" v-bind:idSupplier="idSupplier" :validationButton="validationButton"
                    @getStatusSupplier="setStatusSupplier" />
            </v-window-item>
        </v-window>
    </v-card>
    <AddDocument v-model="AddDocument" @add-document-close-dialog="AddDocument = false"
        :reloadListDocuments="reloadListDocuments" />
    <AddUsers v-model="AddUsers" @add-user-close-dialog="AddUsers = false" :reloadListUser="reloadListUser" />
    <AddBankAccount v-model="AddBankAccount" @add-bank-account-close-dialog="AddBankAccount = false"
        :reloadListBankAccount="reloadListBankAccount" />
</template>
<script>
import SupplierDocumentsTab from "./SupplierDocumentsTab.vue";
import SupplierAlertsTab from "./SupplierAlertsTab.vue";
import supplierBankAccountTab from "./SupplierBankAccountTab.vue";
import supplierValidationTab from "./SupplierValidationTab.vue";
import SupplierGeneralInformationTab from './SupplierGeneralInformationTab.vue';
import SupplierUsersTab from './SupplierUsersTab.vue';
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue";
import AddUsers from "../SuppliersEdit/AddUsers.vue";
import AddBankAccount from "../SuppliersEdit/AddBankAccount.vue";
import { editSupplier, isSupplier, idSupplier, getSupplierById, GetStatusSuppliers,CheckPermissionByUser } from '@/api/index';
import AddDocument from './AddDocument.vue';
import { status } from "@/enums/Status";
export default {
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.suppliers');
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
        this.getStatusSupplierById();
        this.checkSupplierPermission();
        this.checkSupplierWritePermission();
    },
    name: "NewSupplier",
    components: {
        SupplierDocumentsTab,
        SupplierAlertsTab,
        SupplierGeneralInformationTab,
        SupplierUsersTab,
        supplierBankAccountTab,
        supplierValidationTab,
        genericBtnComponent,
        AddDocument,
        AddUsers,
        AddBankAccount
    },
    data: () => ({
        UserListKey: 0,
        idSupplier: null,
        tab: "option-1",
        general: true,
        step: 1,
        AddUser: false,
        AddDocument: false,
        UsersTab: false,
        DocumentsTab: false,
        AddUsers: false,
        AddBankAccount: false,
        validationButton: false,
        validationWriteButton:false,
        NotActive: {
            color: "#667280",
            fontSize: "14px",
            font: "inter",
        },
        Active: {
            color: "#00CBA0",
            fontSize: "14px",
            font: "inter",
        },
        dataFromEditSupplier: null,
        canEditGeneralInformation: null,
        isDisabled: {
            type: Boolean,
            default: false
        },
        Status: null,
        StatusLoader: null
    }),
    created() {
        this.GetStatusSuppliers();
    },
    methods: {
        GetStatusSuppliers() {
            GetStatusSuppliers().then(response => {
                localStorage.setItem(status.created + this.$i18n.availableLocales[0], response.data.created.en);
                localStorage.setItem(status.created + this.$i18n.availableLocales[1], response.data.created.fr);
                localStorage.setItem(status.validatedClient1 + this.$i18n.availableLocales[0], response.data.validatedClient1.en);
                localStorage.setItem(status.validatedClient1 + this.$i18n.availableLocales[1], response.data.validatedClient1.fr);
                localStorage.setItem(status.validatedBySupplier + this.$i18n.availableLocales[0], response.data.validatedBySupplier.en);
                localStorage.setItem(status.validatedBySupplier + this.$i18n.availableLocales[1], response.data.validatedBySupplier.fr);
                localStorage.setItem(status.validatedByClient2 + this.$i18n.availableLocales[0], response.data.validatedByClient2.en);
                localStorage.setItem(status.validatedByClient2 + this.$i18n.availableLocales[1], response.data.validatedByClient2.fr);
            })
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        checkSupplierWritePermission: function () {
            CheckPermissionByUser("Optima.Suppliers.Write")
                .then((response) => {
                    this.validationWriteButton = response.data;
                }) 
                .catch(() => {
                    this.validationWriteButton = false;
                });
        },
        checkSupplierPermission: function () {
            CheckPermissionByUser("Optima.Suppliers.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        SaveAndContinue() {
            if (this.tab == "option-1") {
                this.putGeneralInformation();
            } else if (this.tab == "2") {
                this.postLocationTab();
            } else if (this.tab == "3") {
                this.postDepartmentTab();
            } else if (this.tab == "4") {
                this.postUserTab();
            } else if (this.tab == "5") {
                this.postTaxeTab();
            }
        },
        getDataFromEditSupplier(value) {
            this.dataFromEditSupplier = value;
            if (this.dataFromEditSupplier != null) {
                this.canEditGeneralInformation = this.dataFromEditSupplier.canEdit;
            }
        },
        putGeneralInformation: function () {
            if (this.$refs.generalInformationListRef.ControleSaisir()) {
                editSupplier(this.idSupplier, this.dataFromEditSupplier)
                    .then(() => {
                        this.ActiveSavedSnackBar()
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar()
                    });
            }
        },
        reloadListAlerts() {
            this.$refs.AlertListRef.getAlertsList()
        },
        reloadListDocuments() {
            this.$refs.DocumentsListRef.GetDocumentslist()
        },
        reloadListUser() {
            this.$refs.UserListRef.getUserlist()
        },
        reloadListBankAccount() {
            this.$refs.BankAccountListRef.getBankAccountList()
        },
        getStatusSupplierById: function () {
            this.StatusLoader = true
            getSupplierById(this.idSupplier)
                .then(({ data }) => {
                    this.Status = data.validationStatus
                }).finally(() => {
                    this.StatusLoader = false;
                });
        },
        setStatusSupplier: function () {
            getSupplierById(this.idSupplier)
                .then(({ data }) => {
                    this.Status = data.validationStatus
                })
        },
    },
    computed: {
        statusText() {
            switch (this.Status) {
                case 1: return localStorage.getItem(status.created + this.$i18n.locale);
                case 2: return localStorage.getItem(status.validatedClient1 + this.$i18n.locale);
                case 3: return localStorage.getItem(status.validatedBySupplier + this.$i18n.locale);
                case 4: return localStorage.getItem(status.validatedByClient2 + this.$i18n.locale);
                default: return localStorage.getItem(status.created + this.$i18n.locale);
            }
        }
    }
}
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.text-basil {
    color: #114ef7 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: inter;
}

.slider {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    border-bottom: "2px solid black";
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>