<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['15%', '15%', '18%', '20%']" :GetFunction="getUserlist">
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('suppliers.usersTab.headers.name')" :Filter="FieldName" :getList="getUserlist"
                @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('suppliers.usersTab.headers.mail')" :Filter="FieldEmail" :getList="getUserlist"
                @cancel="FieldEmail = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldEmail" id="FieldEmail" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('suppliers.usersTab.headers.phone')" :Filter="FieldPhoneNumber" :getList="getUserlist"
                @cancel="FieldPhoneNumber = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldPhoneNumber" id="FieldPhoneNumber" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="this.$t('suppliers.usersTab.headers.portal')" :Filter="FieldSupplierPortal"
                :getList="getUserlist" @cancel="FieldSupplierPortal = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldSupplierPortal" inline style="margin-top:6px ;">
                        <v-radio :label="itemsPortal[0].name" :value="itemsPortal[0].value" id="Website"
                            name="Website"></v-radio>
                        <v-radio :label="itemsPortal[1].name" :value="itemsPortal[1].value" id="Phisical"
                            name="Phisical"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:item0="{ item }">{{ item.fullName }}</template>
        <template v-slot:item1="{ item }">{{ item.email }}</template>
        <template v-slot:item2="{ item }">{{ item.phoneNumber }}</template>
        <template v-slot:item3="{ item }">{{ item.supplierPortal == 'Website' ?
            this.$t('suppliers.usersTab.portalType.WebSite') : this.$t('suppliers.usersTab.portalType.physical')
        }}</template>
        <template v-slot:edit="{ item, index }">
            <supplier-user-edit-menu :Id="index" :Supplier="item" :getUserlist="getUserlist" />
        </template>
    </gridComponent>
</template> 
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import SupplierUserEditMenu from "./SupplierUserEditMenu.vue";
import { getUserlist, isSupplier, idSupplier } from "@/api/index"
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
export default {
    name: "SupplierUsersTab",
    components: { SupplierUserEditMenu, gridComponent, ColumnFilter },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            idSupplier: null,
            itemsData: {},
            items: [],
            search: null,
            FieldName: null,
            FieldEmail: null,
            FieldPhoneNumber: null,
            FieldRole: null,
            FieldSupplierPortal: null,
            dialogCompose: false,
            SortedBy: "",
            SortingType: ""
        };
    },
    created() {
        this.idSupplier = isSupplier() ? idSupplier() : this.$route.params.id;
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.getUserlist();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getUserlist: function (page) {
            if(page){ this.page = page }
            getUserlist(this.page, this.PageSize, this.idSupplier, this.FieldName == null ? this.search : this.FieldName, this.FieldEmail, this.FieldPhoneNumber, this.FieldRole, this.FieldSupplierPortal, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            })
        }
    },
    computed: {
        headers() {
            return [
                this.$t('suppliers.usersTab.headers.name'),
                this.$t('suppliers.usersTab.headers.mail'),
                this.$t('suppliers.usersTab.headers.phone'),
                this.$t('suppliers.usersTab.headers.portal')
            ]
        },
        itemsPortal() {
            return [
                { name: this.$t('suppliers.usersTab.portalType.WebSite'), value: "Website" },
                { name: this.$t('suppliers.usersTab.portalType.physical'), value: "Phisical" },
            ]
        },
    }
};
</script>