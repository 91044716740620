<template charset="utf-8">
    <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-window v-model="step" disabled>
                        <v-window-item :value="1">
                            <svg class="d-flex mb-5" width="88" height="88" viewBox="0 0 88 88" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="44" cy="44" r="44" fill="#3BBAFF" fill-opacity="0.12" />
                                <path
                                    d="M49.5 62.3332C48.7483 62.3332 48.125 61.7098 48.125 60.9582V27.0415C48.125 26.2898 48.7483 25.6665 49.5 25.6665C50.2517 25.6665 50.875 26.2898 50.875 27.0415V60.9582C50.875 61.7098 50.2517 62.3332 49.5 62.3332Z"
                                    fill="#3BBAFF" />
                                <path
                                    d="M55 58.6668H49.5V29.3335H55C59.0517 29.3335 62.3333 32.6152 62.3333 36.6668V51.3335C62.3333 55.3852 59.0517 58.6668 55 58.6668Z"
                                    fill="#3BBAFF" />
                                <path
                                    d="M33.0003 29.3335C28.9487 29.3335 25.667 32.6152 25.667 36.6668V51.3335C25.667 55.3852 28.9487 58.6668 33.0003 58.6668H42.167C43.1753 58.6668 44.0003 57.8418 44.0003 56.8335V31.1668C44.0003 30.1585 43.1753 29.3335 42.167 29.3335H33.0003ZM36.2087 50.4168C36.2087 51.1685 35.5853 51.7918 34.8337 51.7918C34.082 51.7918 33.4587 51.1685 33.4587 50.4168V37.5835C33.4587 36.8318 34.082 36.2085 34.8337 36.2085C35.5853 36.2085 36.2087 36.8318 36.2087 37.5835V50.4168Z"
                                    fill="#3BBAFF" />
                            </svg>
                            <span class="loginText">{{ $t('auth.reset') }}</span>
                            <span class="welcomeText"> {{ $t('auth.mailRecive') }}</span>
                            <br>
                            <v-text-field v-model="password" id="password" name="password" :label="$t('auth.password')"
                                :type="showPass ? 'text' : 'password'" variant="outlined" single-line full-width
                                hide-details>
                                <template v-slot:prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                                            fill="#C5CDD4" />
                                        <path
                                            d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                                <template #append-inner>
                                    <v-btn icon variant="plain" @click="showPass = !showPass" id="showPass" name="showPass">
                                        <svg v-if="showPass" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                        <svg v-if="!showPass" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <br>
                            <v-text-field v-model="password2" id="password2" name="password2" :label="$t('auth.password')"
                                :type="showPass2 ? 'text' : 'password'" variant="outlined" single-line full-width
                                hide-details>
                                <template v-slot:progress>
                                    <v-progress-linear :value="progress" :color="color" absolute full-width
                                        height="7"></v-progress-linear>
                                </template>
                                <template v-slot:prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                                            fill="#C5CDD4" />
                                        <path
                                            d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                                <template #append-inner>
                                    <v-btn icon variant="plain" @click="showPass2 = !showPass2" id="showPass2"
                                        name="showPass2">
                                        <svg v-if="showPass2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                        <svg v-if="!showPass2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <br>
                            <v-row justify="center" align="center" style="padding-right: 12px; padding-left: 12px;">
                                <svg width="580" height="4" viewBox="0 0 580 4" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 0 ? Color : '#F3F3F3'" />
                                    <rect x="120" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 1 ? Color : '#F3F3F3'" />
                                    <rect x="240" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 2 ? Color : '#F3F3F3'" />
                                    <rect x="360" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 3 ? Color : '#F3F3F3'" />
                                    <rect x="480" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 4 ? Color : '#F3F3F3'" />
                                </svg>
                            </v-row>
                            <password-meter style="visibility: hidden;" @score="onScore"
                                :password="password"></password-meter>
                            <span class="d-flex PasswordHint" v-bind:style="{ color: Color }"
                                v-if="password && !passwordIsNotIdentique"> {{ strengthTranslate }} </span>
                            <span class='d-flex PasswordHint' style="color:#f95e68" v-else-if="passwordIsNotIdentique">{{
                                msg.passwordIdentique }}</span>
                            <span class='d-block PasswordHint' v-else>&nbsp;</span>
                        </v-window-item>
                        <v-window-item :value="2">
                            <svg class="d-flex mb-5" width="88" height="88" viewBox="0 0 88 88" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="44" cy="44" r="44" fill="#3BBAFF" fill-opacity="0.12" />
                                <path
                                    d="M55.9901 29.6461L45.9068 25.8694C44.8618 25.4844 43.1568 25.4844 42.1118 25.8694L32.0284 29.6461C30.0851 30.3794 28.5084 32.6528 28.5084 34.7244V49.5744C28.5084 51.0594 29.4801 53.0211 30.6718 53.9011L40.7551 61.4361C42.5334 62.7744 45.4484 62.7744 47.2268 61.4361L57.3101 53.9011C58.5018 53.0028 59.4734 51.0594 59.4734 49.5744V34.7244C59.4918 32.6528 57.9151 30.3794 55.9901 29.6461ZM45.3751 45.5961V50.4178C45.3751 51.1694 44.7518 51.7928 44.0001 51.7928C43.2484 51.7928 42.6251 51.1694 42.6251 50.4178V45.5961C40.7734 45.0094 39.4168 43.2861 39.4168 41.2511C39.4168 38.7211 41.4701 36.6678 44.0001 36.6678C46.5301 36.6678 48.5834 38.7211 48.5834 41.2511C48.5834 43.3044 47.2268 45.0094 45.3751 45.5961Z"
                                    fill="#3BBAFF" />
                            </svg>
                            <span class="loginText">{{ $t('auth.resetSuccess') }}</span>
                            <span class="forgetmailText" style="margin-top: 22px;">
                                {{ $t('auth.successReset') }}
                            </span>
                            <br>
                        </v-window-item>
                    </v-window>
                </v-form>
                <v-btn block size="x-large" dark color="#114EF7" rounded="lg" text-color="white" id="loginBTN"
                    name="loginBTN" style="margin-top: 26px; background-color: #114EF7;" @click="validateForm()"
                    :disabled="score < 3">
                    <span class="buttonText" v-if="step == 2">{{ $t('auth.backLogin').toUpperCase() }}</span>
                    <span class="buttonText" text-color="white" v-if="step == 1"> {{ $t('auth.login').toUpperCase() }} </span></v-btn>
                <br>
                <v-row align="justify">
                    <v-col align="justify" v-if="step == 1">
                        <router-link class="backText" to="/" id="ToSignIn" name="ToSignIn">
                            <v-icon>mdi-chevron-left</v-icon> {{ $t('auth.back') }}
                        </router-link>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent, ref } from 'vue';
import PasswordMeter from 'vue-simple-password-meter';
import { resetPassword } from '@/api/index';
export default defineComponent({
    name: "ResetPassword",
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.resetPassword');
    },
    components: { PasswordMeter },
    setup() {
        const password2 = ref("");
        const score = ref(null);
        const strength = ref(null);
        const onScore = (payload) => {
            score.value = payload.score;
            strength.value = payload.strength + " password";
        };
        return {
            password2,
            onScore,
            score,
            strength,
        };
    },
    data: () => ({
        ResetPasswordKey: "",
        step: 1,
        valid: true,
        password: null,
        passwordIsNotIdentique: null,
        showPass: false,
        showPass2: false,
        msg: [],
    }),
    watch: {
        password(value) {
            this.password = value;
            if (this.password2 == "") {
                this.passwordIsNotIdentique = false;
            } else if (this.password == this.password2) {
                this.passwordIsNotIdentique = false;
            } else {
                this.msg['passwordIdentique'] = this.$t('auth.notMatch');
                this.passwordIsNotIdentique = true;
            }
            this.passwordIsNotIdentique ? this.msg['passwordIdentique'] = this.$t('auth.notMatch') : this.msg['passwordIdentique'] = this.strength
        },
        password2(value) {
            this.password2 = value;
            if (this.password2 == "") {
                this.passwordIsNotIdentique = false;
            } else if (this.password == this.password2) {
                this.passwordIsNotIdentique = false;
            } else {
                this.msg['passwordIdentique'] = this.$t('auth.notMatch');
                this.passwordIsNotIdentique = true;
            }
            this.passwordIsNotIdentique ? this.msg['passwordIdentique'] = this.$t('auth.notMatch') : this.msg['passwordIdentique'] = this.strength
        }
    },
    methods: {
        validateForm() {
            this.ResetPasswordKey = this.$route.query.key
            if (!(this.password) || ((this.password).length == 0) || !(this.password2) || ((this.password2).length == 0)) {
                this.msg['passwordIdentique'] = this.$t('auth.passwordRequired');
                this.passwordIsNotIdentique = true;
            }
            else if (this.password == this.password2) {
                this.msg['passwordIdentique'] = '';
                this.passwordIsNotIdentique = false;
            }
            else {
                this.msg['passwordIdentique'] = this.$t('auth.notMatch');
                this.passwordIsNotIdentique = true;
            }
            if (this.step >= 2) {
                this.$router.push({ name: 'login' })
            } else if (!this.passwordIsNotIdentique && this.score >= 3) {
                resetPassword(this.ResetPasswordKey, this.password)
                    .then(response => {
                        if (response.status == 200)
                            this.step = 2;
                    })
            }
        }
    },
    computed: {
        strengthTranslate() {
            switch (this.strength) {
                case 'risky password': return this.$t('signUp.passwordStrength.risky')
                case 'guessable password': return this.$t('signUp.passwordStrength.guessable')
                case 'weak password': return this.$t('signUp.passwordStrength.weak')
                case 'safe password': return this.$t('signUp.passwordStrength.safe')
                case 'secure password': return this.$t('signUp.passwordStrength.secure')
                default: return this.$t('signUp.passwordStrength.risky')
            }
        },
        Color() {
            switch (this.score) {
                case 0: return '#f95e68'
                case 1: return '#fb964d'
                case 2: return '#fdd244'
                case 3: return '#b0dc53'
                case 4: return '#35cc62'
                default: return '#F3F3F3'
            }
        },
    },
})
</script>