<template>
    <actions-menu dialogEdit="SuppliersEdit" :dialogEditUrl="supplierId" v-model:dialogDelete="dialog" :Id="Id" />
    <dialogDelete :title="$t('suppliers.supplier')" :yes="DeleteSuppliers" v-model="dialog" />
</template>
<script>
import actionsMenu from "@/genericComponent/actionsMenu.vue";
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import { DeleteSuppliers } from '@/api/index';
export default {
    name: "SupplierItemMenu",
    components: { actionsMenu, dialogDelete },
    props: {
        supplierId: {
            type: String,
            required: true,
        },
        GetSuppliersAfterDeleted: {},
        Id: {}
    },
    methods: {
        DeleteSuppliers() {
            this.dialog = false;
            DeleteSuppliers(this.supplierId)
                .then(() => {
                    this.GetSuppliersAfterDeleted();
                    this.ActiveDeletedSnackBar();
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                })
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        }
    },
    data() {
        return {
            dialog: false,
        };
    },
};
</script>