<template>
    <actions-menu v-model:dialogEdit="editRequestDialog" v-model:dialogDelete="dialog" :Id="Id" />
    <v-dialog v-model="editRequestDialog" v-if="editRequestDialog">
        <v-card class="v--card-position">
            <v-form ref="form" lazy-validation>
                <h1>{{ $t('actions.edit', { itm: $t('requestForProposal.requestForProposal') }) }}</h1>
                <div class="row ma-4">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('requestForProposal.title') }}<span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.title" id="title" name="title" maxlength="30" hide-details
                                persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('requestForProposal.title') })"
                                variant="outlined"></v-text-field>
                            <span class="warningHint" id="WarningMsgTitle">{{ msg.title }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('requestForProposal.submissions') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.submissions" id="submissions" name="submissions" maxlength="3"
                                type="number" max="7" min="0" hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('requestForProposal.submissions') })"
                                variant="outlined"></v-text-field>
                            <span class="warningHint" id="WarningMsgSubmission">{{ msg.submission }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('requestForProposal.categories') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="Category" name="Category" ref="ChooseRole" v-model="editData.category"
                                hide-details
                                :placeholder="$t('placeholder.select', { itm: $t('requestForProposal.categories') })"
                                variant="outlined" persistent-placeholder :items="ChoseCategory">
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.title">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class="warningHint" id="WarningMsgCategory">{{ msg.category }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('requestForProposal.deadline') }}<span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <dateField v-model="editData.deadline" />
                            <span class="warningHint" id="WarningMsgDeadline">{{ msg.Deadline }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">
                                {{ $t('requestForProposal.status') }}
                                <span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-radio-group v-model="editData.status" inline>
                                <v-radio style="margin-top:10px ;" :label="isOpen" value="Open" id="Open"
                                    name="Open"></v-radio>
                                <v-radio style="margin-top:10px ;margin-left: 30px" :label="isClosed" value="Closed"
                                    id="Closed" name="Closed"></v-radio>
                            </v-radio-group>
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateFormEditRequest" v-model:no="editRequestDialog" width="300px"
                    :yesTitle="$t('actions.save')" />
            </v-form>
        </v-card>
    </v-dialog>
    <dialogDelete :title="$t('requestForProposal.requestForProposal')" :yes="deleteRequest" v-model="dialog" />
</template>
<script>
import { deleteRequest, updateRequest } from "@/api/index";
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import cardActions from "@/genericComponent/cardActions.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: { actionsMenu, cardActions, dialogDelete, dateField },
    props: ["idRequestForProposal", "getRequestListAfterDelete", "getRequestList", "Id"],
    data() {
        return {
            dialog: false,
            editRequestDialog: false,
            ConfigurationList: true,
            currency: "Select currency",
            ChoseCategory: ["Services"],
            editData: {
                id: "",
                category: "",
                deadline: "",
                status: 0,
                submissions: "",
                title: "",
            },
            msg: [],
            listIds: [],
        };
    },
    created() {
        this.editData = JSON.parse(JSON.stringify(this.getRequestList));
        this.editData.deadline = this.editData.deadline.split('T')[0];
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        deleteRequest() {
            this.dialog = false;
            deleteRequest(this.idRequestForProposal).then(() => {
                this.ActiveDeletedSnackBar()
                this.getRequestListAfterDelete();
            }).catch(() => {
                this.ActiveWarningSnackBar()
            });
        },
        validateFormEdit() {
            let objToUpdate = {
                id: this.editData.id,
                title: this.editData.title,
                status: this.editData.status,
                deadline: this.editData.deadline,
                submissions: this.editData.submissions,
                category: this.editData.category
            };
            switch (this.editData.category) {
                case "Services":
                    objToUpdate.category = 0;
                    break;
                default:
                    objToUpdate.category = 0;
                    break;
            }
            switch (this.editData.status) {
                case "Open":
                    objToUpdate.status = 0;
                    break;
                case "Closed":
                    objToUpdate.status = 1;
                    break;
                default: objToUpdate.status = 0;
                    break;
            }
            updateRequest(this.idRequestForProposal, objToUpdate)
                .then((res) => {
                    if (res.status == 200) {
                        this.ActiveSavedSnackBar();
                        this.editRequestDialog = false;
                        this.getRequestListAfterDelete();
                    } else {
                        this.editRequestDialog = true;
                    }
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        validateFormEditRequest() {
            if (!this.editData.title || this.editData.title.length == 0) {
                this.msg["title"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.title') });
            } else {
                this.msg["title"] = "";
            }
            if (!this.editData.deadline || this.editData.deadline.length == 0) {
                this.msg["Deadline"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.deadline') });
            } else if (this.editData.deadline < "1000-01-01") {
                this.msg["Deadline"] = this.$t('warningInput.wrong', { itm: this.$t('requestForProposal.deadline') });
            } else {
                this.msg["Deadline"] = "";
            }
            if (!this.editData.category || this.editData.category == 0) {
                this.msg["category"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.categories') });
            } else {
                this.msg["category"] = "";
            }
            if ((this.editData.submissions + "").length == 0) {
                this.msg["submission"] = this.$t('warningInput.isrequired', { itm: this.$t('requestForProposal.submissions') });
            } else if (this.editData.submissions < 0 || this.editData.submissions > 7) {
                this.msg["submission"] = this.$t('warningInput.positive&under7', { itm: this.$t('requestForProposal.submissions') });
            } else {
                this.msg["submission"] = "";
            }
            if (this.msg["title"] == "" && this.msg["category"] == "" && this.msg["submission"] == "" && this.msg["Deadline"] == "") {
                this.validateFormEdit();
                this.editUserDialog = false;
            }
        },
    },
    watch: {
        getRequestList() {
            this.editData = JSON.parse(JSON.stringify(this.getRequestList));
            this.editData.deadline = this.editData.deadline.split('T')[0];
        },
        editRequestDialog() {
            this.editData = JSON.parse(JSON.stringify(this.getRequestList));
            this.editData.deadline = this.editData.deadline.split('T')[0];
            this.msg = []
        }
    },
    computed: {
        isClosed() {
            return localStorage.getItem(status.Closed + this.$i18n.locale);
        },
        isOpen() {
            return localStorage.getItem(status.Open + this.$i18n.locale);
        },
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    margin-top: 25px;
    text-align: center;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5b6d8e;
    margin-left: 12px;
    padding-left: 0;
}

.v--card-position {
    width: 1054px;
    height: 520px;
    position: relative;
    margin: 0 auto;
}
</style>