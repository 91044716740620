<template>
    <v-btn icon variant="text" @click="dialogCompose = true"
        style="border-radius: 0; width: min-content; height: min-content; color: transparent;" v-bind="props"
        :id="'Filter-' + title.split(' ').join('')" ref="FilterButton">
        {{ index }}
        <svg class="ml-1" width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.12193 3.12194C0.737128 5.50675 0.737128 9.37327 3.12193 11.7581C5.50673 14.1429 9.37326 14.1429 11.7581 11.7581C14.1429 9.37327 14.1429 5.50675 11.7581 3.12194C9.37326 0.737143 5.50673 0.737143 3.12193 3.12194ZM4.06474 4.06475C5.92884 2.20065 8.95115 2.20065 10.8153 4.06475C12.6794 5.92886 12.6794 8.95116 10.8153 10.8153C8.95115 12.6794 5.92884 12.6794 4.06474 10.8153C2.20064 8.95116 2.20064 5.92886 4.06474 4.06475Z" fill="#92929D"/>
            <path d="M14.7296 13.5217L11.9766 10.7687C11.348 10.1401 10.4052 11.0829 11.0338 11.7115L13.7867 14.4645C14.4153 15.093 15.3581 14.1502 14.7296 13.5217Z" fill="#92929D"/>
        </svg>
    </v-btn>
    <v-dialog v-model="dialogCompose" max-width="500" width="max-content" >
        <v-card :retain-focus="false" min-width="350" class="pa-4" style="background-color:white; border-radius: 12px; overflow-y: hidden;">
            <h1>{{$t('ColumnFilter.searchBy', { itm: title })}}</h1>
            <div class="px-2 py-4">
                <slot name="input" />
            </div>
            <card-actions :yes="onFilter" v-model:no="dialogCompose" :noFun="onCancel" width="160px"
                :yesTitle="$t('ColumnFilter.search')" />
        </v-card>
    </v-dialog>
</template>
<script>
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    props: {
        title: {},
        Filter: {},
        getList: {},
        index:{}
    },
    components: {
        cardActions
    },
    data() {
        return {
            dialogCompose: false,
        };
    },
    methods: {
        onFilter: function () {
            this.getList(1);
            this.dialogCompose = false
        },
        onCancel: function () {
            this.$emit('cancel');
            this.getList();
            this.dialogCompose = false
        },
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: start;
}
</style>