<template>
    <v-app class="overflow-y-hidden" style="overflow-y: hidden;">
        <div v-if="loader" style="height: 250px; line-height: 250px;">
            <v-progress-circular indeterminate size="50"></v-progress-circular>
        </div>
        <div v-else v-for="menu in MenuList" :key="menu">
            <v-list-item :to="menu.route" :id="menu.name" :name="menu.name">
                <span v-if="$i18n.locale == 'fr'" class="ml-3">
                    {{ menu.displayName }}
                </span>
                <span v-else class="ml-3">
                    {{ menu.displayNameEn }}
                </span>
                <template v-slot:prepend>
                    <img class="svgimage" :src="require('@/assets/MenuIcon/' + menu.icon + '.svg')" />
                </template>
            </v-list-item>
        </div>
    </v-app>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { GetMenuById } from "@/api/index";
export default {
    setup() {
        const { t } = useI18n();
        return {
            t,
        };
    },
    data: () => ({
        MenuList: [],
        loader: false,
    }),
    created() {
        this.getMenuById();
    },
    methods: {
        getMenuById() {
            this.loader = true
            GetMenuById(localStorage.UserId)
                .then((res) => {
                    this.MenuList = res.data.items;
                }).finally(() => {
                    this.loader = false
                })
        },
    }
}
</script>
<style scoped>
.v-list-item {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #080C3E;
    stroke: #114EF7;
    height: 56px;
    line-height: 18px;
    text-align: start;
}

.v-list-item--active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #114EF7;
    border: #114EF7;
    border-left-style: solid;
    border-width: 4px;
    background-color: #F0F5FF;
}

.svgimage {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.v-list-item--active .svgimage {
    filter: invert(16%) sepia(88%) saturate(5006%) hue-rotate(229deg) brightness(101%) contrast(94%);
}
</style>