<template>
    <div class="row">
        <div class="col justify-content-center" style="max-width: 150px;">
            <v-img v-if="url" aspect-ratio="1" width="200" :src="url" class="nxmlogo" id="urlImage"
                @loadstart="isLoaded = false" @load="isLoaded = true" lazy-src="@\\assets\\upload.png"></v-img>
            <v-img v-else-if="namelogo" aspect-ratio="1" width="200" :src="getLogoBase64" class="nxmlogo"
                lazy-src="@\\assets\\upload.png"></v-img>
            <v-img v-else-if="!url && !namelogo" aspect-ratio="1" width="200" src="@\\assets\\upload.png"
                class="nxmlogo"></v-img>
        </div>
        <div class="col justify-content-center" style="width: auto;" v-if="!readonly">
            <div class="row" style="padding-left: 12px; padding-bottom: 8px;">
                <div class="upload" @click="chooseFiles()" style="cursor: pointer; width: max-content">
                    <svg class="pr-1 pb-1" width="16" height="16" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.53 13.53C11.38 13.68 11.19 13.75 11 13.75C10.81 13.75 10.62 13.68 10.47 13.53L9.75 12.81V17C9.75 17.41 9.41 17.75 9 17.75C8.59 17.75 8.25 17.41 8.25 17V12.81L7.53 13.53C7.24 13.82 6.76 13.82 6.47 13.53C6.18 13.24 6.18 12.76 6.47 12.47L8.47 10.47C8.54 10.41 8.61 10.36 8.69 10.32C8.71 10.31 8.74 10.3 8.76 10.29C8.82 10.27 8.88 10.26 8.95 10.25C8.98 10.25 9 10.25 9.03 10.25C9.11 10.25 9.19 10.27 9.27 10.3C9.28 10.3 9.28 10.3 9.29 10.3C9.37 10.33 9.45 10.39 9.51 10.45C9.52 10.46 9.53 10.46 9.53 10.47L11.53 12.47C11.82 12.76 11.82 13.24 11.53 13.53Z"
                            fill="#ffffff" />
                        <path
                            d="M17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048Z"
                            fill="#ffffff" />
                    </svg>
                    <button class="load text-center">
                        {{ $t('configuration.logo.uploadLogo') }}
                    </button>
                </div>
                <v-file-input v-model="logo" accept="image/jpeg, image/png, image/svg+xml" id="fileUpload"
                    style="visibility: hidden; display: none;"></v-file-input>
            </div>
            <div class="row">
                <div class="uploadText">
                    {{ $t('configuration.logo.logoMSG') }}
                </div>
            </div>
            <div v-if="afficheSucces" class="updateSuccess"
                :style="afficheTrue ? 'background-color: #E8FFF1;' : 'background-color: #F25C5C;'">
                <v-card-text class="text-center">
                    <p v-if="this.afficheTrue">{{ $t('configuration.logo.uploadSucces') }}</p>
                    <p v-else-if="!isLoaded">{{ $t('configuration.logo.warningType') }}</p>
                    <p v-else-if="!(this.logo[0].size / 1024 / 1024 < 5)">{{ $t('configuration.logo.warningSize') }}</p>
                </v-card-text>
            </div>
            <div v-else style="height: 40px;">
            </div>
        </div>
    </div>
    <v-snackbar v-model="afficheSucces" :timeout="2000" color="transparent" style="opacity: 0;">
    </v-snackbar>
</template>
<script>
import { getlogoCompany } from '@/api/index'
export default {
    props: {
        namelogo: {},
        readonly: {
            default: false,
            type: Boolean
        },
        API: {
            default: 0,
            type: Number
        }
    },
    data: () => ({
        snackbar: true,
        logo: null,
        urlimage: null,
        afficheSucces: false,
        afficheTrue: true,
        fileTypes: [
            "image/jpeg",
            "image/png",
            "image/svg+xml",
        ],
        isLoaded: false,
        getLogoBase64: null
    }),
    mounted() {
        this.getlogo()
    },
    computed: {
        url() {
            if ((this.logo && this.logo.length == 0) || !this.logo || !this.fileTypes.includes(this.logo[0].type) || !(this.logo[0].size / 1024 / 1024 < 5)) return;
            return URL.createObjectURL(this.logo[0]);
        }
    },
    watch: {
        logo() {
            if (this.logo.length > 0) {
                setTimeout(() => {
                    this.afficheSucces = true
                    if (this.fileTypes.includes(this.logo[0].type) && this.logo[0].size / 1024 / 1024 < 5 && this.isLoaded) {
                        this.$emit('addLogo', this.logo)
                        this.afficheTrue = true
                    } else {
                        this.logo.shift()
                        this.afficheTrue = false
                    }
                    setTimeout(() => {
                    }, 2000);
                }, 100);
            }
        }
    },
    methods: {
        getlogo() {
            if (this.namelogo != null) {
                getlogoCompany(this.API, this.namelogo).then((r) => {
                    var extension = (this.namelogo.split('.'))[1]
                    if (extension == 'svg') extension = 'svg+xml'
                    this.getLogoBase64 = 'data:image/' + extension + ';base64,' + btoa(
                        new Uint8Array(r.data)
                            .reduce((data, byte) => data + String.fromCharCode(byte), ''));
                })
            }
        },
        chooseFiles: function () {
            document.getElementById("fileUpload").click()
        },
    }
};
</script>
<style scoped>
.uploadText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: start;
    color: #939EAA;
}

.load {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 34px;
    color: #fff;
}

.upload {
    background-color: #3bbaff;
    width: 144px;
    height: 35px;
    border-radius: 6px;
    text-align: center;
}

.updateSuccess {
    height: 40px;
    border-radius: 6px;
    font-family: 'Inter';
}

.updateSuccess .v-card-text {
    color: #24c581;
    font-weight: 600;
    font-size: 12px;
    line-height: 50%;
}

.nxmlogo {
    width: 85pxx;
    height: 85px;
}

.logo-img {
    background-image: url("@/assets/upload.png");
    width: 85px;
    height: 71px;
    background-size: contain;
}
</style>