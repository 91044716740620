<template>
    <actions-menu v-model:dialogEdit="EditDialog" v-model:dialogDelete="DeleteDialog" :Id="Id" />
    <dialogDelete :title="$t('suppliers.documentsTab.document')" :yes="deleteDocumentSupplier" v-model="DeleteDialog" />
    <v-dialog v-model="EditDialog" v-if="EditDialog">
        <v-card width="950px" class="py-2" style="border-radius: 12px;">
            <h1 class="text-center dialog-title"> {{ $t('suppliers.documentsTab.edit') }}</h1>
            <form>
                <div class="row ma-4 mt-2 mb-2">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.documentsTab.name') }}<span
                                    class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="NameEdit" id="NameEdit" name="NameEdit" :readonly="!validationButton" maxlength="30" hide-details
                                persistent-placeholder required :placeholder="$t('suppliers.documentsTab.placeholder.name')"
                                variant="outlined"></v-text-field>
                            <span class='warningHint' id="WarningMsgName">{{ msg.NameEdit }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('suppliers.documentsTab.date') }}<span
                                    class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <dateField v-model="expiryDateEdit" :minDate="new Date()" :readonly="!validationButton" />
                            <span class="warningHint" id="WarningMsgExpiryDate">
                                {{ msg.expiryDateEdit }}
                            </span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row mb-4">
                        </div>
                        <div class="row ml-0">
                            <uploadDocument @addDocument="setidentityDocument" :fnDownload="DownloadDocumentSupplier" :readonly="!validationButton"
                                :showDownload="showDownload" :showUpload="true">
                                <template v-slot:DocumentsList>
                                    <span style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start"
                                        @click="DownloadDocumentSupplier()"
                                        :title="$t('actions.download') + ' ' + ( identityDocument ? identityDocument[0].name : document.name + '.' + document.type)">
                                        {{ identityDocument ? identityDocument[0].name : document.name + '.' + document.type  }}
                                    </span>
                                </template>
                            </uploadDocument>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                        </div>
                        <div class="row">
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateFormEdit" v-model:no="EditDialog" :yesDisable="!validationButton" width="300px"
                    :yesTitle="$t('suppliers.saveChanges')" />
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
import cardActions from "@/genericComponent/cardActions.vue"
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import { downloadDocumentSupplier, editSupplierDocument, deleteDocumentSupplier,CheckPermissionByUser } from "@/api/index";
import dateField from "@/genericComponent/date-field.vue";
import uploadDocument from "@/genericComponent/uploadDocument.vue"
import { status } from "@/enums/Status";
export default {
    mounted() {
        this.checkSupplierPermission();
    },
    components: { actionsMenu, dialogDelete, cardActions, dateField, uploadDocument },
    name: "SupplierDocumentEditMenu",
    props: {
        getDocumentslist: {},
        document: {
            type: Object,
            required: true,
        },
        Id: {}
    },
    data() {
        return {
            DeleteDialog: false,
            msg: [],
            EditDialog: false,
            NameEdit: this.document.name,
            identityDocument: null,
            expiryDateEdit: this.document.expiryDate.split('T')[0],
            statusEdit: this.document.status,
            idDocument: this.document.idDocument,
            showDownload: false,
            validationButton:false
        };
    },
    watch: {
        EditDialog() {
            this.NameEdit = this.document.name
            this.expiryDateEdit = this.document.expiryDate.split('T')[0]
            this.statusEdit = this.document.status
            this.idDocument = this.document.idDocument
            this.type = this.document.type
            this.identityDocument = null;
            this.msg = [];
            this.showDownload = true
        }
    },
    methods: {
        DownloadDocumentSupplier() {
            const file = this.identityDocument ? this.identityDocument[0] : null;
            if (file != null) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const linkSource = `${base64data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = file.name;
                    downloadLink.click();
                }
            } else {
                let fileName = this.document.idDocument + '.' + this.document.type
                downloadDocumentSupplier(fileName)
                    .then(response => {
                        const linkSource = `data:application/pdf;base64,${response.data}`;
                        const downloadLink = document.createElement("a");
                        const fileName = this.document.name + '.' + this.document.type;
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    });
            }
        },
        setidentityDocument(value) {
            this.identityDocument = value
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        deleteDocumentSupplier() {
            this.DeleteDialog = false;
            deleteDocumentSupplier(this.idDocument)
                .then(() => {
                    this.getDocumentslist();
                    this.ActiveDeletedSnackBar();
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        checkSupplierPermission: function () {
            CheckPermissionByUser("Optima.Suppliers.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        editSupplierDocument() {
            editSupplierDocument(this.idDocument, {
                idDocument: this.idDocument,
                name: this.NameEdit,
                expiryDate: this.expiryDateEdit,
                status: this.statusEdit,
                identityDocument: this.identityDocument,
                type: this.type
            })  
                .then(() => {
                    if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    this.btnloader = false;
                    return;
                }
                    this.getDocumentslist();
                    this.ActiveSavedSnackBar()
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                });
        },
        validateFormEdit() {
            if (!this.NameEdit || this.NameEdit.length == 0) {
                this.msg["NameEdit"] = this.$t('suppliers.documentsTab.errors.name');
            } else {
                this.msg["NameEdit"] = "";
            }

            if (!this.expiryDateEdit || this.expiryDateEdit.length == 0) {
                this.msg["expiryDateEdit"] = this.$t('suppliers.documentsTab.errors.date');
            } else if (this.expiryDateEdit < "1000-01-01") {
                this.msg["expiryDateEdit"] = this.$t('warningInput.wrong', { itm: this.$t('suppliers.documentsTab.date') });
            } else {
                this.msg["expiryDateEdit"] = "";
            }

            if (
                this.msg["NameEdit"] == "" &&
                this.msg["expiryDateEdit"] == ""
            ) {
                this.editSupplierDocument();
                this.EditDialog = false;
            }
        },
    },
    computed: {
        itemsStatus() {
            return [
                { 'name': localStorage.getItem(status.Active + this.$i18n.locale), 'value': 0 },
                { 'name': localStorage.getItem(status.Inactive + this.$i18n.locale), 'value': 1 }
            ]
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-card-text {
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}

.dialog-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}
</style>