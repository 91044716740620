<template>
    <div class="row ma-4 mt-2 mb-2">
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.name') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="fullName" id="fullName" name="fullName" hide-details persistent-placeholder required
                    maxlength="30" :placeholder="$t('suppliers.usersTab.placeholder.name')"
                    variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgFullName">{{ msg.fullName }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.email') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="email" id="email" name="email" hide-details maxlength="40" persistent-placeholder
                    :placeholder="$t('suppliers.usersTab.placeholder.email')" variant="outlined"></v-text-field>
                <span class='warningHint' id="WarningMsgEmail">{{ msg.email }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.phone') }}<span class="red">*</span></v-card-title>
            </div>
            <div class="row">
                <v-text-field v-model="phoneNumber" id="phoneNumber" name="phoneNumber" hide-details maxlength="15"
                    persistent-placeholder @update:model-value="phoneNumber = phoneNumber.replace(/[^0-9.]/g, '');"
                    :placeholder="$t('suppliers.usersTab.placeholder.phone')" variant="outlined">
                    <template v-slot:prepend-inner>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.24 2.5H7.76C5 2.5 4 3.5 4 6.31V18.69C4 21.5 5 22.5 7.76 22.5H16.23C19 22.5 20 21.5 20 18.69V6.31C20 3.5 19 2.5 16.24 2.5Z"
                                fill="#C5CDD4" />
                            <path
                                d="M14 6.75H10C9.59 6.75 9.25 6.41 9.25 6C9.25 5.59 9.59 5.25 10 5.25H14C14.41 5.25 14.75 5.59 14.75 6C14.75 6.41 14.41 6.75 14 6.75Z"
                                fill="white" />
                            <path
                                d="M12 19.8008C12.9665 19.8008 13.75 19.0173 13.75 18.0508C13.75 17.0843 12.9665 16.3008 12 16.3008C11.0335 16.3008 10.25 17.0843 10.25 18.0508C10.25 19.0173 11.0335 19.8008 12 19.8008Z"
                                fill="white" />
                        </svg>
                    </template>
                </v-text-field>
                <span class='warningHint' id="WarningMsgPhoneNumber">{{ msg.phoneNumber }}</span>
            </div>
        </div>
        <div class="col-6 justify-content-start">
            <div class="row">
                <v-card-title class="text-left ma-0">{{ $t('suppliers.usersTab.portal') }}<span class="red">*</span>
                    <v-tooltip  :text="$t('suppliers.usersTab.webAccessInfo')" location="bottom" max-width="350px">
                        <template v-slot:activator="{ props }">
                            <img class="ml-1" v-bind="props" :src="require('@/assets/infoBulle.svg')"/>
                        </template>
                    </v-tooltip>
                </v-card-title>
            </div>
            <div class="row">
                <div class="col-2 switchLabel"
                    @click="supplierPortal = supplierPortal == itemsPortal[0].value ? itemsPortal[1].value : itemsPortal[0].value">
                    <img v-if="supplierPortal == itemsPortal[0].value" :src="require('@/assets/Switch/SwitchOn.png')"
                        style="width: 44px;" />
                    <img v-else :src="require('@/assets/Switch/SwitchOff.png')" style="width: 44px;" />
                </div>
                <div class="col-10 switchLabel text-left">
                    {{ $t('suppliers.usersTab.webAccess') }}
                </div>
                <span class='warningHint' id="WarningMsgSupplierPortal">{{ msg.supplierPortal }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { ExistSupplierMail } from '@/api';
export default {
    props: {
        idSupplier: {},
    },
    data() {
        return {
            existMail: null,
            supplierPortal: null,
            phoneNumber: "",
            email: "",
            fullName: "",
            msg: [],
            userData: {}
        }
    },
    created() {
        this.supplierPortal = this.itemsPortal[0].value
    },
    watch: {
        fullName(value) {
            this.fullName = value;
            this.userData.fullName = this.fullName;
            if (this.fullName == "")
                delete this.userData.fullName
            this.$emit('getData', this.userData)
        },
        supplierPortal(value) {
            this.supplierPortal = value;
            this.userData.supplierPortal = this.supplierPortal;
            if (this.supplierPortal == null)
                delete this.userData.supplierPortal
            this.$emit('getData', this.userData)
        },
        phoneNumber(value) {
            this.phoneNumber = value;
            this.userData.phoneNumber = this.phoneNumber;
            if (this.phoneNumber == "")
                delete this.userData.phoneNumber
            this.$emit('getData', this.userData)
        },
        email(value) {
            this.email = value;
            this.existMail = null
            // eslint-disable-next-line
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                ExistSupplierMail(this.email).then((res) => {
                    this.existMail = res.data;
                })
            }
            this.userData.email = this.email;
            if (this.email == "")
                delete this.userData.email
            this.$emit('getData', this.userData)
        },
    },
    methods: {
        validateForm() {
            if (!(this.fullName) || (this.fullName).length == 0) {
                this.msg['fullName'] = this.$t('suppliers.usersTab.errors.name');
            }
            else {
                this.fullName = (this.fullName.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.fullName))) {
                    this.msg['fullName'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else if (!(this.fullName.split(/\W+/).length >= 2)) {
                    this.msg['fullName'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else if (!(this.fullName.split(' ')[1].length >= 1)) {
                    this.msg['fullName'] = this.$t('suppliers.usersTab.errors.wrongName');
                }
                else {
                    this.msg['fullName'] = '';
                }
            }
            if (!this.supplierPortal || this.supplierPortal.length == 0) {
                this.msg["supplierPortal"] = this.$t('suppliers.usersTab.errors.portal');
            } else {
                this.msg["supplierPortal"] = "";
            }
            if (!(this.phoneNumber) || (this.phoneNumber).length == 0) {
                this.msg['phoneNumber'] = this.$t('suppliers.usersTab.errors.phone');
            } else if (((this.phoneNumber).length < 8) || ((this.phoneNumber).length > 15)) {
                this.msg['phoneNumber'] = this.$t('suppliers.usersTab.errors.wrongNumber');
            } // eslint-disable-next-line
            else if (this.phoneNumber.match(/^[\+]?[0-9]{8,15}$/im)) {
                this.msg['phoneNumber'] = '';
            } else {
                this.msg['phoneNumber'] = this.$t('suppliers.usersTab.errors.wrongNumber');
            }
            if (!this.email || this.email.length == 0) {
                this.msg["email"] = this.$t('suppliers.usersTab.errors.email');
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
            ) {
                this.msg["email"] = "";
            } else {
                this.msg["email"] = this.$t('suppliers.usersTab.errors.wrongEmail');
            }

            if (this.existMail) {
                this.msg["email"] = this.$t('warningInput.exist', { itm: this.$t('suppliers.usersTab.email') });
            } else if (
                this.msg["email"] == "" &&
                this.msg["phoneNumber"] == "" &&
                this.msg["supplierPortal"] == "" &&
                this.msg["fullName"] == ""
            ) {
                return true;
            }
        },
    },
    computed: {
        itemsPortal() {
            return [
                { name: this.$t('suppliers.usersTab.portalType.WebSite'), value: "Website" },
                { name: this.$t('suppliers.usersTab.portalType.physical'), value: "Phisical" },
            ]
        }
    }
}
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    margin-top: 25px;
    text-align: center;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>