<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['15%', '15%', '10%', '10%', '10%', '10%', '10%']" :GetFunction="getLocationList">
        <template v-slot:item0="{ item }">{{ item.locationName }}</template>
        <template v-slot:item1="{ item }">{{ item.address.substr(0, 15) }}</template>
        <template v-slot:item2="{ item }">{{ item.city }}</template>
        <template v-slot:item3="{ item }">{{ item.postalCode }}</template>
        <template v-slot:item4="{ item }">
            <flag-country :countryProps=item.country :key="item.country"></flag-country>
        </template>
        <template v-slot:item5="{ item }">{{ item.currency }}</template>
        <template v-slot:item6="{ item }">
            <StatusButton :title="statut(item)" />
        </template>
        <template v-slot:edit="{ item, index }">
            <EditLocation :Id="index" v-bind:getLocationListAfterUpdate="getLocationList" :location="item" />
        </template>
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('configuration.location.locationName')" :Filter="LocationFilters.LocationName"
                :getList="getLocationList" @cancel="delete LocationFilters.LocationName">
                <template v-slot:input>
                    <v-text-field v-model="LocationFilters.LocationName"  id="FieldLocationName" variant="outlined"
                        maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('configuration.location.address')" :Filter="LocationFilters.Address"
                :getList="getLocationList" @cancel="delete LocationFilters.Address">
                <template v-slot:input>
                    <v-text-field v-model="LocationFilters.Address" id="FieldAddress" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('configuration.location.city')" :Filter="LocationFilters.City"
                :getList="getLocationList" @cancel="delete LocationFilters.City">
                <template v-slot:input>
                    <v-text-field v-model="LocationFilters.City" id="FieldCity" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('configuration.location.postalCode')" :Filter="LocationFilters.PostalCode"
                :getList="getLocationList" @cancel="delete LocationFilters.PostalCode">
                <template v-slot:input>
                    <v-text-field v-model="LocationFilters.PostalCode" id="FieldPostalCode" variant="outlined"
                        maxlength="33" hide-details
                        @update:model-value="LocationFilters.PostalCode = LocationFilters.PostalCode.replace(/[^0-9.]/g, '');"></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter4>
            <ColumnFilter :title="$t('configuration.location.country')" :Filter="LocationFilters.Country"
                :getList="getLocationList" @cancel="delete LocationFilters.Country">
                <template v-slot:input>
                    <v-text-field v-model="LocationFilters.Country" id="FieldCountry" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter5>
            <ColumnFilter :title="$t('configuration.location.currency')" :Filter="LocationFilters.Currency"
                :getList="getLocationList" @cancel="delete LocationFilters.Currency">
                <template v-slot:input>
                    <v-text-field v-model="LocationFilters.Currency" id="FieldCurrency" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter6>
            <ColumnFilter :title="$t('configuration.location.status')" :Filter="LocationFilters.Status"
                :getList="getLocationList" @cancel="delete LocationFilters.Status, delete LocationFilters.withStatus">
                <template v-slot:input>
                    <v-radio-group v-model="LocationFilters.Status" inline style="margin-top:6px;">
                        <v-radio :label="isActive" :value=true id="Active" name="Active"></v-radio>
                        <v-radio :label="isInactive" :value=false id="In Active" name="In Active"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
    </gridComponent>
</template>
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import { getLocation, GetStatusLocations } from "@/api/index";
import StatusButton from "@/genericComponent/StatusButton.vue";
import EditLocation from "../companiesEdit/EditLocation.vue";
import FlagCountry from "../SelectCountry/FlagCountry.vue";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import { status } from "@/enums/Status";
export default {
    components: { gridComponent, EditLocation, FlagCountry, StatusButton, ColumnFilter },
    props: {
        idCompany: String,
    },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            items: null,
            itemsData: {},
            SortedBy: "",
            SortingType: "ASC",
            LocationFilters: {},
        };
    },
    created() {
        this.GetStatusLocations();
    },
    mounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.LocationFilters.locationName = event;
            this.getLocationList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        getLocationList: function (page) {
            if(page){ this.page = page }
            getLocation(this.page, this.PageSize, this.LocationFilters, this.SortedBy, this.SortingType)
                .then(response => {
                    this.items = response.data.items;
                    this.itemsData.numberOfPage = response.data.totalPages;
                    this.itemsData.totalItemsCount = response.data.totalCount;
                    this.itemsData.pageNumber = response.data.pageNumber;
                    this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                    this.itemsData.hasNextPage = response.data.hasNextPage
                    this.loader = false;
                })
        },
        GetStatusLocations() {
            GetStatusLocations().then(response => {
                localStorage.setItem(status.Active + this.$i18n.availableLocales[0], response.data.Active.en);
                localStorage.setItem(status.Active + this.$i18n.availableLocales[1], response.data.Active.fr);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[0], response.data.Inactive.en);
                localStorage.setItem(status.Inactive + this.$i18n.availableLocales[1], response.data.Inactive.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => item.status ? localStorage.getItem(status.Active + this.$i18n.locale) : localStorage.getItem(status.Inactive + this.$i18n.locale)
        },
        headers() {
            return [this.$t('configuration.location.locationName'),
            this.$t('configuration.location.address'),
            this.$t('configuration.location.city'),
            this.$t('configuration.location.postalCode'),
            this.$t('configuration.location.country'),
            this.$t('configuration.location.currency'),
            this.$t('configuration.location.status')
            ]
        },
        isActive() {
            return localStorage.getItem(status.Active + this.$i18n.locale);
        },
        isInactive() {
            return localStorage.getItem(status.Inactive + this.$i18n.locale);
        },
    }
};
</script>