<template>
  <v-menu transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn icon="mdi-translate" v-bind="props"></v-btn>
    </template>
    <v-list rounded="4" style="width: 200px; margin-top: 3px; padding: 10px 0;">
      <div v-for="locale, index in SUPPORTED_LOCALES" :key="`locale-${locale}`">
        <v-divider v-if="index != 0" thickness="2" class="border-opacity-25" style="margin: 2px 0;"></v-divider>
        <v-list-item :value="locale" @click="changeLanguage(locale)">
          <v-list-item-title> {{ $t("language." + locale) }}</v-list-item-title>
          <template v-slot:prepend>
            <img :src="getFlag(locale)" class="country-flag mx-2" />
          </template>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>
<script>
import { useI18n } from "vue-i18n";
import { SUPPORTED_LOCALES } from "@/i18n";
import i18n from "@/i18n"
export default {
  setup() {
    const {
      locale,
    } = useI18n();
    return {
      locale,
      SUPPORTED_LOCALES
    };
  },
  methods: {
    changeLanguage(value) {
      localStorage.setItem("UserLang", value);
      this.locale = value
      i18n.global.locale.value = value
      document.querySelector("html").setAttribute("lang", value)
    },
    getFlag(flagName) {
      try {
        return require(`../assets/LanguageFlags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('../assets/LanguageFlags/undefined.svg')
      }
    },
  }
};
</script>
<style scoped>
v-select {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.country-flag {
  height: 16px;
  width: 16px;
  object-fit: cover;
  border-radius: 50%;
}
</style>