<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>{{ $t('configuration.add') }}</v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :loader="buttonLoader" :ClickFun="SaveAndContinue" svg="1"
                    :Title="$t('configuration.save')" />
            </div>
        </div>
        <v-tabs style="background-color: #fff; border-radius: 6px 6px 0px 0px;" v-model="tab" grow touchless>
            <v-tab class="slider" value="1"
                :style="[parseInt(tab) > 1 ? Active : [parseInt(tab) == 1 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '1'">mdi-check-circle</v-icon>
                {{ $t('configuration.generalInformation') }}
            </v-tab>
            <v-tab class="slider" value="2"
                :style="[parseInt(tab) > 2 ? Active : [parseInt(tab) == 2 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '2'">
                    mdi-check-circle
                </v-icon>
                {{ $t('configuration.locations') }}
            </v-tab>
            <v-tab class="slider" value="3"
                :style="[parseInt(tab) > 3 ? Active : [parseInt(tab) == 3 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '3'">
                    mdi-check-circle
                </v-icon>
                {{ $t('configuration.departments') }}
            </v-tab>
            <v-tab class="slider" value="4"
                :style="[parseInt(tab) > 4 ? Active : [parseInt(tab) == 4 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '4'">
                    mdi-check-circle
                </v-icon>
                {{ $t('configuration.users') }}
            </v-tab>
            <v-tab class="slider" value="5"
                :style="[parseInt(tab) > 5 ? Active : [parseInt(tab) == 5 ? 'color:blue' : NotActive]]">
                <v-icon v-if="tab > '5'">
                    mdi-check-circle
                </v-icon>
                {{ $t('configuration.taxes') }}
            </v-tab>
        </v-tabs>
        <v-divider class="ma-0"></v-divider>
        <v-window v-model="tab" :key="item">
            <v-window-item value="1">
                <general-information @getData="getDataFromCompanies" ref="generalInformationRef" @setLogo="setLogo" />
            </v-window-item>
            <v-window-item value="2">
                <locations-tab @getData="getDataFromLocationTab" ref="LocationTabRef" />
            </v-window-item>
            <v-window-item value="3">
                <departments-tab @getData="getDataFromDepartmentTab" :LocationName="LocationName" ref="DepartmentTabRef" />
            </v-window-item>
            <v-window-item value="4">
                <user-tab @getData="getDataFromUserTab" @postUserTab="postUserTab" :DepartmentName="DepartmentName"
                    :LocationName="LocationName" ref="UserTabRef" />
            </v-window-item>
            <v-window-item value="5">
                <taxes-tab @getData="getDataFromTaxeTab" ref="TaxTabRef" />
            </v-window-item>
        </v-window>
    </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import { addCompany, addLocation, AddDepartement, addUser, AddTaxe, addlogoCompany } from "@/api/index";
import DepartmentsTab from "./DepartmentsTab.vue";
import GeneralInformation from "./GeneralInformation.vue";
import LocationsTab from "./LocationsTab.vue";
import TaxesTab from "./TaxesTab.vue";
import UserTab from "./UserTab.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default defineComponent({
    components: {
        GeneralInformation,
        LocationsTab,
        DepartmentsTab,
        UserTab,
        TaxesTab,
        genericBtnComponent
    },
    data: () => ({
        buttonLoader: false,
        tab: "1",
        NotActive: {
            disabled: true,
            color: "#667280",
            fontSize: "14px",
            font: "inter",
            width: "41px",
            "pointer-events": "none",
        },
        Active: {
            color: "#00CBA0",
            fontSize: "14px",
            font: "inter",
            width: "41px",
            "border-bottom": "2px solid #00CBA0",
            "pointer-events": "none",
        },
        dataFromCompanies: {},
        dataFromLocationTab: {},
        dataFromDepartmentTab: {},
        dataFromUserTab: {},
        dataFromTaxeTab: {},
        IdCompany: "",
        idLocation: "",
        LocationName: "",
        idDepartment: "",
        DepartmentName: "",
        logoImage: null,
    }),
    watch: {
        tab() {
            this.buttonLoader = false;
        }
    },
    methods: {
        SaveAndContinue() {
            this.buttonLoader = true;
            if (this.tab == "1") {
                this.postGeneralInformation();
            } else if (this.tab == "2") {
                this.postLocationTab();
            } else if (this.tab == "3") {
                this.postDepartmentTab();
            } else if (this.tab == "4") {
                this.postUserTab();
            } else if (this.tab == "5") {
                this.postTaxeTab();
            }
        },
        getDataFromCompanies(value) {
            this.dataFromCompanies = value;
        },
        setLogo(value) {
            this.logoImage = value
        },
        postGeneralInformation: function () {
            if (this.$refs.generalInformationRef.ControleSaisir()) {
                this.buttonLoader = true
                if (this.logoImage) {
                    this.dataFromCompanies.logo = (this.logoImage[0].type).split('/')[1] == 'svg+xml' ? 'svg' : (this.logoImage[0].type).split('/')[1]
                    addCompany(this.dataFromCompanies)
                        .then(response => {
                            this.IdCompany = response.data
                            addlogoCompany(this.IdCompany, this.logoImage).then((r) => {
                                this.dataFromCompanies.logo = r.data
                            }).finally(() => {
                                this.ActiveSavedSnackBar();
                                this.buttonLoader = false
                                this.tab = "2";
                            });
                        })
                        .catch(() => {
                            this.ActiveWarningSnackBar();
                            this.buttonLoader = false
                        })
                } else {
                    addCompany(this.dataFromCompanies)
                        .then(response => {
                            this.IdCompany = response.data
                            if (response.status == 200)
                                this.ActiveSavedSnackBar();
                            this.tab = "2";
                        })
                        .catch(() => {
                            this.ActiveWarningSnackBar();
                        }).finally(() => { this.buttonLoader = false });
                }
            } else { this.buttonLoader = false }
        },
        getDataFromLocationTab(value) {
            this.dataFromLocationTab = value;
        },
        postLocationTab: function () {
            if (this.$refs.LocationTabRef.validateForm()) {
                this.dataFromLocationTab.IdCompany = this.IdCompany
                this.LocationName = this.dataFromLocationTab.locationName
                addLocation(
                    this.dataFromLocationTab.IdCompany,
                    this.dataFromLocationTab.locationName,
                    this.dataFromLocationTab.address,
                    this.dataFromLocationTab.postalCode,
                    this.dataFromLocationTab.currency,
                    this.dataFromLocationTab.city,
                    this.dataFromLocationTab.country
                )
                    .then(response => {
                        this.idLocation = response.data
                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.tab = "3";
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        getDataFromDepartmentTab(value) {
            this.dataFromDepartmentTab = value;
        },
        postDepartmentTab: function () {
            if (this.$refs.DepartmentTabRef.validateForm()) {
                this.dataFromDepartmentTab.idLocation = this.idLocation
                this.DepartmentName = this.dataFromDepartmentTab.DepartmentName
                AddDepartement(
                    this.dataFromDepartmentTab.DepartmentName,
                    this.dataFromDepartmentTab.idLocation,
                    this.IdCompany,
                )
                    .then((response) => {
                        this.idDepartment = response.data
                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.tab = "4";
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        getDataFromUserTab(value) {
            this.dataFromUserTab = value;
        },
        postUserTab: function () {
            if (this.$refs.UserTabRef.validateForm()) {
                this.dataFromUserTab.idCompany = this.IdCompany
                this.dataFromUserTab.regionCoverage = this.idLocation
                this.dataFromUserTab.idDepartment = this.idDepartment
                this.dataFromUserTab.id = this.$refs.UserTabRef.generateUUID();

                addUser(
                    this.dataFromUserTab.idCompany,
                    this.dataFromUserTab.id,
                    this.dataFromUserTab.userName,
                    this.dataFromUserTab.email,
                    this.dataFromUserTab.regionCoverage,
                    this.dataFromUserTab.idDepartment,
                    this.dataFromUserTab.role,
                )
                    .then((response) => {
                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.tab = "5";
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                        this.buttonLoader = false;
                    });
            } else {
                this.buttonLoader = false;
            }
        },
        getDataFromTaxeTab(value) {
            this.dataFromTaxeTab = value;
        },
        postTaxeTab: function () {
            if (this.$refs.TaxTabRef.validateForm()) {
                this.dataFromTaxeTab.idCompany = this.IdCompany
                AddTaxe(
                    this.dataFromTaxeTab.TaxName,
                    this.dataFromTaxeTab.TaxPercentage,
                    this.dataFromTaxeTab.idCompany,
                )
                    .then((response) => {
                        if (response.status == 200)
                            this.ActiveSavedSnackBar();
                        this.$router.push({ name: 'Configuration' });
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                        this.buttonLoader = false;
                    });
            } else {
                this.buttonLoader = false;
            }
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
    }
});
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.btn {
    display: flex;
}

.Save {
    color: #fff;
    height: 45px;
    font-family: inter;
    font-weight: 600;
}

.iconSave {
    width: 20px;
    height: 15px;
    margin-right: 5px;
}

.slider {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    border-bottom: "2px solid black";
}
</style>
