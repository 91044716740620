<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('routes.purchasesOrder') }}
                </v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent v-if="isClient()" :ClickFun="navigateToNewPurchaseOrder" svg="2"
                    :Title="$t('purchaseOrder.create')" />
            </div>
        </div>
        <v-card elevation="0">
            <PurchaseOrderList />
        </v-card>
    </div>
</template>
<script>
import { isClient, isSupplier } from "@/api/index";
import PurchaseOrderList from "../PurchaseOrder/PurchaseOrderList.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    components: {
        PurchaseOrderList,
        genericBtnComponent
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.purchasesOrder');
    },
    methods: {
        navigateToNewPurchaseOrder() {
            this.$router.push({ path: '/NewPurchaseOrder' });
        },
        isClient() {
            return isClient()
        },
        isSupplier() {
            return isSupplier()
        }
    }
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>