export const status = {
    Active: 'Active',
    Inactive: 'Inactive',
    Submitted: 'Submitted',
    Validated: 'Validated',
    Paid: 'Paid',
    Refused: 'Refused',
    Completed: 'Completed',
    Draft: 'Draft',
    Initialized: 'Initialized',
    Pending: 'Pending',
    Error: 'Error',
    Closed: 'Closed',
    Open: 'Open',
    Display: 'Display',
    Unavailable: 'Unavailable',
    created: 'created',
    validatedClient1:'validatedClient1',
    validatedBySupplier:'validatedBySupplier',
    validatedByClient2:'validatedByClient2'
}