<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('actions.edit', { itm: $t('configuration.company.company') }) }}
                </v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end" @click="save = !save">
                <genericBtnComponent v-if="tab == 'option-1'" :ClickFun="putGeneralInformation" svg="1"
                    :Title="$t('configuration.company.save')" :disabled="!validationButton || btnloader"
                    :loader="btnloader" />
                <genericBtnComponent v-if="tab == 'option-2'" @click="AddLocation = true" svg="1"
                    :Title="$t('configuration.location.add')" :disabled="!validationWriteButton || btnloader"/>
                <genericBtnComponent v-if="tab == 'option-3'" @click="AddDepartment = true" v-bind:idCompany="idCompany"
                    svg="1" :Title="$t('configuration.department.add')" :disabled="!validationWriteButton || btnloader"/>
                <genericBtnComponent v-if="tab == 'option-4'" @click="AddUser = true" svg="1"
                    :Title="$t('configuration.user.add')" :disabled="!validationWriteButton || btnloader"/>
                <genericBtnComponent v-if="tab == 'option-5'" @click="AddTaxes = true" svg="1"
                    :Title="$t('configuration.tax.add')" :disabled="!validationWriteButton || btnloader" />
            </div>
        </div>
        <v-card flat>
            <v-tabs v-model="tab" background-color="transparent" color="basil" grow fixed-tabs>
                <v-tab class="slider" value="option-1" :style="NotActive" centered v-model="general">
                    {{ $t('configuration.generalInformation') }}
                </v-tab>
                <v-tab class="slider" value="option-2" :style="NotActive" v-model="location">
                    {{ $t('configuration.locations') }}
                </v-tab>
                <v-tab class="slider" value="option-3" :style="NotActive">
                    {{ $t('configuration.departments') }}
                </v-tab>
                <v-tab class="slider" value="option-4" :style="NotActive">
                    {{ $t('configuration.users') }}
                </v-tab>
                <v-tab class="slider" value="option-5" :style="NotActive">
                    {{ $t('configuration.taxes') }}
                </v-tab>
            </v-tabs>
            <v-divider class="ma-0"></v-divider>
            <v-window v-model="tab" :key="tab">
                <v-window-item value="option-1">
                    <general-info-list @getData="getDataFromEditCompanies" ref="generalInformationListRef"
                        @setLogo="setLogo" :isEditMode="true" :validationButton="validationButton" />
                </v-window-item>
                <v-window-item value="option-2">
                    <location-list v-bind:idCompany="idCompany" :validationButton="validationButton" ref="LocationsListRef" />
                </v-window-item>
                <v-window-item value="option-3">
                    <departments-list v-bind:idCompany="idCompany" :validationButton="validationButton" ref="DepartementsListRef" />
                </v-window-item>
                <v-window-item value="option-4">
                    <users-list ref="UserListRef" :validationButton="validationButton"  :key='UserListKey' />
                </v-window-item>
                <v-window-item value="option-5">
                    <TaxesList v-bind:idCompany="idCompany" :validationButton="validationButton"  ref="TaxesListRef" />
                </v-window-item>
            </v-window>
        </v-card>
        <AddUsers v-model="AddUser" @add-user-close-dialog="AddUser = false" @reloadListUser="reloadListUser"
            ref="AddUsersRef" />
        <AddTaxes v-model="AddTaxes" @add-taxes-close-dialog="AddTaxes = false" @reloadListTaxes="reloadListTaxes" />
        <AddDepartment v-model="AddDepartment" @add-departement-close-dialog="AddDepartment = false"
            @reloadListDepartements="reloadListDepartements" />
        <AddLocation v-model="AddLocation" @add-location-close-dialog="AddLocation = false"
            @reloadListLocation="reloadListLocation" />
    </div>
</template>
<script>
import GeneralInfoList from './GeneralInfoList.vue';
import LocationList from './LocationList.vue';
import DepartmentsList from './DepartmentsList.vue';
import UsersList from './UsersList.vue';
import TaxesList from './TaxesList.vue';
import AddUsers from '../companiesAdd/AddUsers.vue';
import AddDepartment from '../companiesAdd/AddDepartment.vue';
import AddTaxes from '../companiesAdd/AddTaxes.vue';
import AddLocation from '../companiesAdd/AddLocation.vue';
import { editCompany, CheckPermissionByUser, addlogoCompany } from '@/api/index';
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.configuration');
        this.idCompany = this.$route.params.id;
        this.checkCompanyPermission();
        this.checkCompanyWritePermission();
    },
    components: {
        LocationList,
        GeneralInfoList,
        DepartmentsList,
        UsersList,
        TaxesList,
        AddUsers,
        AddDepartment,
        AddTaxes,
        AddLocation,
        genericBtnComponent
    },
    data: () => ({
        btnloader: false,
        UserListKey: 0,
        idCompany: null,
        location: '',
        general: true,
        tab: "option-1",
        step: 1,
        AddUser: false,
        AddLocation: false,
        AddDepartment: false,
        AddTaxes: false,
        validationButton: false,
        validationWriteButton:false,
        NotActive: {
            color: "#667280",
            fontSize: "14px",
            font: "inter",
        },
        Active: {
            color: "#00CBA0",
            fontSize: "14px",
            font: "inter",
        },
        dataFromEditCompanies: null,
        logoImage: null,
    }),
    watch: {
        AddUser(val) {
            this.AddUser = val;
            if (this.AddUser == true) {
                this.$refs.AddUsersRef.getDepartmentList();
                this.$refs.AddUsersRef.getRolesByCompany();
            }
        }
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        reloadListLocation() {
            this.$refs.LocationsListRef.getLocationList()
        },
        reloadListTaxes() {
            this.$refs.TaxesListRef.getTaxesList()
        },
        reloadListDepartements() {
            this.$refs.DepartementsListRef.getDepartmentList()
        },
        reloadListUser() {
            this.$refs.UserListRef.getUsersList()
        },
        getDataFromEditCompanies(value) {
            this.dataFromEditCompanies = value;
        },
        setLogo(value) {
            this.logoImage = value
        },

        checkCompanyWritePermission: function () {
            CheckPermissionByUser("Optima.Company.Write")
                .then((response) => {
                    this.validationWriteButton = response.data;
                }) 
                .catch(() => {
                    this.validationWriteButton = false;
                });
        },
        checkCompanyPermission: function () {
            CheckPermissionByUser("Optima.Company.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        putGeneralInformation: function () {
            if (this.$refs.generalInformationListRef.ControleSaisir()) {
                this.btnloader = true
                if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    this.btnloader = false;
                    return;
                }
                if (this.logoImage) {
                    return addlogoCompany(this.idCompany, this.logoImage);
                } else {
                    editCompany(this.$route.params.id, this.dataFromEditCompanies)
                        .then(() => {
                            this.ActiveSavedSnackBar()
                        })
                        .catch(() => {
                            this.ActiveWarningSnackBar()
                        }).finally(() => { this.btnloader = false });
                }
            }
        }
    }
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}

.text-basil {
    color: #114ef7 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: inter;
}

.slider {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    border-bottom: "2px solid black";
}
</style>