<template>
    <actions-menu v-model:dialogEdit="editRequestDialog" v-model:dialogDelete="dialog" :Id="Id"
        v-model:dialogRetry="Retrydialog" :ShowDialogRetry="editData.status == '2' ? true : false" />
    <dialogDelete :title="$t('purchaseRequest.purchaseRequest')" :yes="deleteRequest" v-model="dialog" />
    <dialogDelete DialogType="Retry" :title="$t('purchaseRequest.purchaseRequest')" :yes="RetryStatutRequest"
        v-model="Retrydialog" />
    <v-dialog v-model="editRequestDialog" v-if="editRequestDialog">
        <v-card width="800px" height="fit-content">
            <v-form ref="form" lazy-validation>
                <div class="row ma-4">
                    <h1>{{ $t('actions.edit', { itm: $t('purchaseRequest.purchaseRequest') }) }}</h1>
                </div>
                <div class="row ma-4">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('purchaseRequest.title') }}<span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.title" id="title" name="title" maxlength="30" hide-details
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseRequest.title') })" variant="outlined">
                            </v-text-field>
                            <span class="warningHint" id="WarningMsgTitle">{{ msg.title }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('purchaseRequest.requester') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-autocomplete id="Requester" name="Requester" v-model="editData.userId" hide-details
                                :placeholder="$t('placeholder.select', { itm: $t('purchaseRequest.requester') })"
                                variant="outlined" persistent-placeholder :items="RequesterList" item-title="fullName"
                                item-value="id" single-line>
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.fullName">
                                        {{ item.fullName }}
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <span class="warningHint" id="WarningMsgRequester">{{ msg.Requester }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('purchaseRequest.deadline') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <dateField v-model="editData.deadline" />
                            <span class="warningHint" id="WarningMsgDeadline">{{ msg.deadline }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('purchaseRequest.status') }}<span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-radio-group v-model="Status" inline style="margin-top:6px ;margin-bottom:6px" hide-details>
                                <v-radio :label="isCompleted" value="0" id="Completed" name="Completed"></v-radio>
                                <v-radio :label="isPending" value="1" id="Pending" name="Pending"></v-radio>
                                <v-radio :label="isError" value="2" id="Error" name="Error"></v-radio>
                            </v-radio-group>
                        </div>
                    </div>
                </div>
                <card-actions class="mb-4" :yes="validateFormEditRequest" v-model:no="editRequestDialog" width="300px"
                    :yesTitle="$t('actions.save')" />
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
import dateField from "@/genericComponent/date-field.vue";
import { EditPurchaseRequest, DeletePurchaseRequest } from '@/api/index'
import { status } from "@/enums/Status";
export default {
    components: { actionsMenu, dialogDelete, cardActions, dateField },
    props: ["idPurchaseRequest", "getPurchaseRequestListAfterDelete", "getPurchaseRequest", "RequesterList", "Id"],
    mounted() {
        this.editData.title = this.getPurchaseRequest.title;
        this.editData.deadline = this.getPurchaseRequest.deadline.split('T')[0];
        this.editData.idPurchaseRequest = this.getPurchaseRequest.idPurchaseRequest;
        this.editData.status = this.getPurchaseRequest.status + "";
        if (this.getPurchaseRequest.users && !this.getPurchaseRequest.users.isDeleted) {
            this.editData.userId = this.getPurchaseRequest.userId
        } else {
            this.editData.userId = null
        }
    },
    watch: {
        editRequestDialog() {
            this.editData.title = this.getPurchaseRequest.title;
            this.editData.deadline = this.getPurchaseRequest.deadline.split('T')[0];
            this.editData.idPurchaseRequest = this.getPurchaseRequest.idPurchaseRequest;
            this.editData.status = this.getPurchaseRequest.status + "";
            if (this.getPurchaseRequest.users && !this.getPurchaseRequest.users.isDeleted) {
                this.editData.userId = this.getPurchaseRequest.userId
            } else {
                this.editData.userId = null
            }
            this.msg = []
        }
    },
    data() {
        return {
            dialog: false,
            editRequestDialog: false,
            Retrydialog: false,
            editData: {
                idPurchaseRequest: null,
                deadline: null,
                status: null,
                title: null,
                userId: null
            },
            msg: [],
        };
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        RetryStatutRequest() {
            EditPurchaseRequest(this.idPurchaseRequest, {
                "idPurchaseRequest": this.getPurchaseRequest.idPurchaseRequest,
                "deadline": this.getPurchaseRequest.deadline.split('T')[0],
                "status": 1,
                "title": this.getPurchaseRequest.title,
                "userId": this.getPurchaseRequest.userId
            })
                .then((res) => {
                    if (res.status == 200) {
                        this.editRequestDialog = false;
                    } else {
                        this.editRequestDialog = true;
                    }
                    this.ActiveSavedSnackBar();
                    this.getPurchaseRequestListAfterDelete();
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                })
        },
        deleteRequest() {
            this.dialog = false;
            DeletePurchaseRequest(this.idPurchaseRequest)
                .then(() => {
                    this.ActiveDeletedSnackBar();
                    this.getPurchaseRequestListAfterDelete();
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        validateFormEdit() {
            this.editData.status = parseInt(this.editData.status)
            EditPurchaseRequest(this.idPurchaseRequest, this.editData)
                .then((res) => {
                    if (res.status == 200) {
                        this.editRequestDialog = false;
                    } else {
                        this.editRequestDialog = true;
                    }
                    this.ActiveSavedSnackBar();
                    this.getPurchaseRequestListAfterDelete();
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                })
            this.editData.status = this.editData.status + ""
        },
        validateFormEditRequest() {
            if (!this.editData.title || this.editData.title.length == 0) {
                this.msg["title"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseRequest.title') });
            } else {
                this.msg["title"] = "";
            }
            if (!this.editData.deadline || this.editData.deadline.length == 0) {
                this.msg["deadline"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseRequest.deadline') });
            } else if (this.editData.deadline < "1000-01-01") {
                this.msg["deadline"] = this.$t('warningInput.wrong', { itm: this.$t('purchaseRequest.deadline') });
            } else {
                this.msg["deadline"] = "";
            }
            if (!this.editData.userId) {
                this.msg["Requester"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseRequest.requester') });
            } else {
                this.msg["Requester"] = "";
            }
            if (this.msg["title"] == "" && this.msg["deadline"] == "" && this.msg["Requester"] == "") {
                this.validateFormEdit();
                this.editUserDialog = false;
            }
        },
    },
    computed: {
        isCompleted() {
        return localStorage.getItem(status.Completed + this.$i18n.locale);
        },
        isPending() {
        return localStorage.getItem(status.Pending + this.$i18n.locale);
        },
        isError() {
        return localStorage.getItem(status.Error + this.$i18n.locale);
        }
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 5px;
    line-height: 27px;
}
</style>