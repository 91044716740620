<template>
    <span v-if="PageLoader">
        <br><br><br><br><br>
        <v-progress-circular indeterminate :size="50"></v-progress-circular>
        <br><br><br><br><br>
    </span>
    <div v-else>
        <div class="row ma-4 justify-content-center">
            <span v-if="activeUsersItems.length > 0" class="selectHint">{{ activeUsersItems.length }} {{ $t('permissions.usersSelected') }}</span>
        </div>
        <div class="row ma-4">
            <div class="col-6 justify-content-start">
                <v-card>
                    <v-text-field v-model="name" id="Name" name="Name" maxlength="30" hide-details
                        persistent-placeholder></v-text-field>
                    <v-list two-line subheader class=" justify-content-start">
                        <span v-if="usersItems.length == 0">{{ $t('permissions.noUserAvailable') }}</span>
                        <v-list-item v-for="(item) in usersItems" @click="toggleActiveUser(item)" :key="item.title">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.fullName"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
            <div class="col-6 justify-content-start">
                <v-card v-if="activeUsersItems.length > 0">
                    <v-list two-line subheader style="max-height:60vh;">
                        <v-list-item v-for="(item, index) in activeUsersItems" @mouseover="hover = index"
                            @mouseleave="hover = null" @click="toggleInActiveUser(item)" :key="item">
                            <v-list-item-content>
                                <v-list-item-title v-text="item.fullName"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.email"></v-list-item-subtitle>
                            </v-list-item-content>
                            <template v-slot:append>
                                <v-icon :style="hover == index ? hideCloseIcon : showCloseIcon">mdi-close-circle</v-icon>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script>
import { GetUsersByOrganisationId } from "@/api/index";
export default {
    props: {
        Mode: {
            type: String,
            default: "Add"
        },
        RoleProps: {
            default: null
        },
    },
    data() {
        return {
            activeUsersItems: [],
            usersItems: [],
            name: "",
            hover: false,
            showCloseIcon: {
                display: "none",
            },
            hideCloseIcon: {
                display: "block",
            },
            PageLoader: null
        };
    },
    created() {
        this.PageLoader = true
        this.GetUsersByOrganisationId()
    },
    watch: {
        name() {
            this.GetUsersByOrganisationId();
        },
        activeUsersItems(value) {
            this.$emit('getData', value.map((obj) => obj.id))
        },
    },
    methods: {
        validateForm() {
            this.$emit('getData', this.activeUsersItems.map((obj) => obj.id))
            return true
        },
        GetUsersByOrganisationId: function () {
            GetUsersByOrganisationId(this.name, this.RoleProps.id).then((response) => {
                this.usersItems = response.data;
            }).finally(() => {
                let posToDelete = []
                this.usersItems.map((userid) => {
                    var user = this.activeUsersItems.find((user) => user.id === userid.id);
                    if (user != null) {
                        let pos = this.usersItems.findIndex(users => users.id === user.id);
                        if (pos > -1) { posToDelete.push(pos) }
                    }
                })
                const sortedposToDelete = posToDelete.slice().sort((a, b) => b - a);
                sortedposToDelete.map((p) => {
                    this.usersItems.splice(p, 1)
                })
                if (this.RoleProps && this.PageLoader) {
                    this.$emit('getListUserByCompany', this.usersItems.map((obj) => obj.id))
                    this.RoleProps.users.map((userid) => {
                        var user = this.usersItems.find((user) => user.id === userid.id);
                        if (user != null) {
                            if (this.activeUsersItems.indexOf(user) == -1)
                                this.toggleActiveUser(user)
                        }
                    })
                }
                this.PageLoader = false
            })
        },
        toggleActiveUser(idx) {
            this.activeUsersItems.push(idx)
            this.usersItems.splice(this.usersItems.indexOf(idx), 1)
        },
        toggleInActiveUser(idx) {
            let pos = this.activeUsersItems.indexOf(idx)
            this.activeUsersItems.splice(pos, 1)
            this.usersItems.push(idx)
        },
    }
};
</script>
<style scoped>
.v-list-item {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #080C3E;
    stroke: #114EF7;
    height: 56px;
    line-height: 18px;
    text-align: start;
}

.selectHint {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #5b6d8e;
    text-align: center;
}

.active {
    display: none
}

.v-list {
    max-height: 50vh;
    overflow-y: auto
}
</style>