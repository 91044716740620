<template>
    <tfoot height="56px" v-if="this.itemsData.totalItemsCount > 0">
        <td colspan="2" class="pl-8 itemsText">
            <span v-if="!$props['prependText']">
                {{ countItemMsg }}
            </span>
            <span v-else>{{ prependText }}</span>
        </td>
        <td colspan="6">
            <div style="display:flex">
                <v-pagination v-model="page_" density="comfortable" style="right: 0%;" :length="itemsData.numberOfPage"
                    total-visible="6" color="#7D8DA7" active-color="#394EFE" variant="plain">
                    <template v-slot:next>
                        <button type="button" @click="page_ = page_ + 1" id="nextPage" name="nextPage"
                            :class="itemsData.hasNextPage ? '' : 'v-btn--disabled'" :disabled="!itemsData.hasNextPage"
                            class="v-btn v-btn--icon v-theme--white v-btn--density-comfortable v-btn--size-default v-btn--variant-plain"
                            aria-label="Next page" :aria-disabled="!itemsData.hasNextPage"
                            style="color: rgb(125, 141, 167); caret-color: rgb(125, 141, 167);">
                            <span class="v-btn__overlay"></span><span class="v-btn__underlay"></span><span
                                class="v-btn__content" data-no-activator=""><i
                                    class="mdi-chevron-right mdi v-icon notranslate v-theme--white v-icon--size-default"
                                    aria-hidden="true"></i></span>
                        </button>
                    </template>
                    <template v-slot:prev>
                        <button type="button" @click="page_ = page_ - 1" id="prevPage" name="prevPage"
                            :class="itemsData.hasPreviousPage ? '' : 'v-btn--disabled'"
                            :disabled="!itemsData.hasPreviousPage"
                            class="v-btn v-btn--icon v-theme--white v-btn--density-comfortable v-btn--size-default v-btn--variant-plain"
                            aria-label="Next page" :aria-disabled="!itemsData.hasPreviousPage"
                            style="color: rgb(125, 141, 167); caret-color: rgb(125, 141, 167);">
                            <span class="v-btn__overlay"></span><span class="v-btn__underlay"></span><span
                                class="v-btn__content" data-no-activator=""><i
                                    class="mdi-chevron-left mdi v-icon notranslate v-theme--white v-icon--size-default"
                                    aria-hidden="true"></i></span>
                        </button>
                    </template>
                </v-pagination>
                <v-sheet elevation="0" height="38px" width="70px" class="mr-8 mt-1"
                    style="margin: '4.8px'; border: 1px solid #E2E2EA; border-radius: 8px;">
                    <v-text-field type="number" hide-details class="inputPaginations pr-4 mr-3" single-line
                        density="compact" step="1" min="1" max="99" variant="plain" v-model.number="pageSize_" id="pageSize"
                        name="pageSize" style=" border-color: blue; margin-top: -4px" maxlength="2" length="2" >
    
                        <template #append-inner>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path @click="pageSize_ = pageSize_ - 1" id="pageSizeDown" name="pageSizeDown"
                                    style="cursor:pointer" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M15.5 15C15.912 15 16.1472 15.4704 15.9 15.8L12.4 20.4667C12.2 20.7333 11.8 20.7333 11.6 20.4667L8.1 15.8C7.85279 15.4704 8.08798 15 8.5 15L15.5 15Z"
                                    fill="#7D8DA7" />
                                <path @click="pageSize_ = pageSize_ + 1" id="pageSizeUp" name="pageSizeUp"
                                    style="cursor:pointer" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M15.5 9C15.912 9 16.1472 8.52962 15.9 8.2L12.4 3.53333C12.2 3.26667 11.8 3.26667 11.6 3.53333L8.1 8.2C7.85279 8.52962 8.08798 9 8.5 9L15.5 9Z"
                                    fill="#7D8DA7" />
                            </svg>
                        </template>
                    </v-text-field>
                </v-sheet>
            </div>
        </td>
    </tfoot>
</template>
<script>
export default {
    props: {
        page: {},
        PageSize: {},
        itemsData: {},
        prependText: {}
    },
    data() {
        return {
            pageSize_: this.PageSize,
            page_: this.page,
        }
    },
    watch: {
        page_(value) {
            this.page_ = value;
            this.$parent.page = this.page_;
            this.$emit("pageEventChanged", { page: this.page_, PageSize: this.pageSize_ });
        },
        pageSize_(value) {
            this.pageSize_ = value;
            if (this.pageSize_ > 99) {
                this.pageSize_ = 99
            }
            if (this.pageSize_ < 1) {
                this.pageSize_ = 10
            }
            this.$parent.pageSize = this.pageSize_;
            this.page_ = 1;
            this.$parent.page = this.page_;
            this.$emit("pageEventChanged", { page: this.page_, PageSize: this.pageSize_ });
        },
    },
    computed: {
        countItemMsg() {
            let msg = (this.pageSize_ * (this.itemsData.pageNumber - 1) + 1) + " - ";
            let msg2 = this.pageSize_ * this.itemsData.pageNumber > this.itemsData.totalItemsCount ? this.itemsData.totalItemsCount : this.pageSize_ * this.itemsData.pageNumber;
            return this.$t('countItemMsg', { itm: msg + msg2, count: this.itemsData.totalItemsCount })
        }
    }
}
</script>
<style scoped>
.itemsText {
    text-align: left;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #44444F;
}

.v-input {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #7D8DA7;
    display: grid;
    flex: 1 1 auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    align-items: flex-start;
    width: 81px;
}
</style>
<style>
.v-pagination__prev,
.v-pagination__next {
    border: 1px solid #E2E2EA;
    border-radius: 8px;
}

.v-pagination__item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #7D8DA7;
}

.inputPaginations input[type='number'] {
    text-align: right;
    margin-right: 5px;
}

.inputPaginations input::-webkit-outer-spin-button,
.inputPaginations input::-webkit-inner-spin-button {
    display: none;
}
</style>