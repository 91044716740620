<template>
    <actions-menu dialogEdit="RoleEdit" :dialogEditUrl="RoleId" v-model:dialogDelete="dialog" :Id="Id"
        v-model:dialogReset="Resetdialog" ShowDialogReset="true" />
    <dialogDelete :title="$t('permissions.Role')" :yes="DeleteRole" v-model="dialog" />
    <dialogDelete DialogType="Reset" :title="$t('permissions.Role')" :yes="ResetRequest" v-model="Resetdialog" />
</template>
<script>
import { deleteRole, ResetRole } from "@/api/index";
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
export default {
    components: { actionsMenu, dialogDelete },
    props: {
        RoleId: {
            type: String,
            required: true,
        },
        getRole: {},
        getRoleListAfterDelete: {
            type: Function
        },
        Id: {}
    },
    data() {
        return {
            dialog: false,
            Resetdialog: false,
            msg: [],
        };
    },
    methods: {
        DeleteRole: function () {
            this.dialog = false
            deleteRole(this.getRole.id)
                .then(() => {
                    this.ActiveDeletedSnackBar();
                    this.getRoleListAfterDelete();

                }).catch((e) => {
                    if(e.response.data.errors[0].code == "Authentication.CannotDeleted"){
                        this.ActiveWarningDeleteRoleSnackBar()
                    }else{
                        this.ActiveWarningSnackBar()
                    }
                });
        },
        ResetRequest() {
            this.Resetdialog = false;
            ResetRole(this.RoleId)
                .then(() => {
                    this.ActiveSavedSnackBar();
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                })
        },
        ActiveWarningDeleteRoleSnackBar() {
            this.getParent('MainLayout').ActiveWarningDeleteRoleSnackBar();
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
    },
};
</script>