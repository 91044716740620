<template>
    <actions-menu v-model:dialogEdit="dialog2" v-model:dialogDelete="dialog" :Id="Id" />
    <dialogDelete :title="$t('configuration.tax.tax')" :yes="deleteTaxe" v-model="dialog" />
    <v-dialog v-model="dialog2" v-if="dialog2">
        <v-card width="800px" class="py-6">
            <form @submit.prevent="updateTaxe">
                <h1>{{ $t('actions.edit', { itm: $t('configuration.tax.tax') }) }}</h1>
                <div class="row ma-4">
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.tax.taxName') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="TaxName" id="TaxName" name="TaxName" hide-details maxlength="30" :readonly="!validationButton"
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.tax.taxName') })"
                                variant="outlined"></v-text-field>
                            <span class="warningHint" id="WarningMsgTaxName"> {{ msg.TaxName }}</span>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left ma-0">{{ $t('configuration.tax.taxPercentage') }}<span class="red">*</span>
                                </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field maxlength="9" v-model="TaxPercentage" id="TaxPercentage" name="TaxPercentage" :readonly="!validationButton"
                                hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('configuration.tax.taxPercentage') })"
                                variant="outlined"></v-text-field>
                            <span class="warningHint" id="WarningMsgTaxPercentage"> {{ msg.TaxPercentage }}</span>
                        </div>
                    </div>
                </div>
                <card-actions :yes="updateTaxe" v-model:no="dialog2" :yesDisable="!validationButton" width="300px" :yesTitle="$t('actions.save')" />
            </form>
        </v-card>
    </v-dialog>
</template>
<script>
import { editTaxe, deleteTaxe,CheckPermissionByUser } from '@/api/index';
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    mounted() {
       this.checkEditTaxePermission();
    },
    components: { actionsMenu, dialogDelete, cardActions },
    props: ['taxe', 'getTaxesListAfterUpdate', "Id"],
    data() {
        return {
            dialog: false,
            dialog2: false,
            dialog3: false,
            TaxName: this.taxe.taxName,
            TaxPercentage: this.taxe.taxPourcentage,
            Updatedtaxe: null,
            msg: [],
            validationButton:false
        };
    },
    watch: {
        dialog2() {
            this.TaxName = this.taxe.taxName,
                this.TaxPercentage = this.taxe.taxPourcentage,
                this.Updatedtaxe = null,
                this.msg = []
        },
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        checkEditTaxePermission: function () {
            CheckPermissionByUser("Optima.Company.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        updateTaxe() {
            if (!this.TaxName || this.TaxName.length == 0) {
                this.msg["TaxName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.tax.taxName') });
            } else {
                this.msg["TaxName"] = "";
            }

            if (!this.TaxPercentage || this.TaxPercentage.length == 0) {
                this.msg["TaxPercentage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.tax.taxPercentage') });
            } else {
                this.msg["TaxPercentage"] = "";
            }
            if (
                this.msg["TaxName"] == "" &&
                this.msg["TaxPercentage"] == ""
            ) {
                if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    this.btnloader = false;
                    return;
                }
                this.Updatedtaxe = {
                    idTax: this.taxe.idTax,
                    taxName: this.TaxName,
                    taxPourcentage: parseInt(this.TaxPercentage),
                    status: this.taxe.status,
                    idCompany: this.$route.params.id
                };
                editTaxe(this.taxe.idTax, this.Updatedtaxe).then(() => {
                    this.dialog2 = false;
                    this.ActiveSavedSnackBar();
                    this.getTaxesListAfterUpdate();
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                })
            }
        },
        deleteTaxe() {
            this.dialog = false;
            deleteTaxe(this.taxe.idTax).then(() => {
                this.getTaxesListAfterUpdate();
                this.ActiveDeletedSnackBar();
            }).catch(() => {
                this.ActiveWarningSnackBar();
            })
        },
        reverseStatutTaxe() {
            const taxe = {
                idTax: this.taxe.idTax,
                taxName: this.taxe.taxName,
                taxPourcentage: this.taxe.taxPourcentage,
                status: !this.taxe.status,
                idCompany: this.taxe.idCompany
            }
            editTaxe(this.taxe.idTax, taxe).then(() => {
                this.dialog3 = false;
                this.ActiveSavedSnackBar();
            })
        },
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
    margin-top: -20px;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>