<template>
    <actions-menu v-model:PDFExport="generatePDFbutton" v-model:dialogEdit="editRequestDialog" v-model:dialogDelete="dialog"
        v-model:dialogValidation="dialogValidation" v-model:dialogRefuse="dialogCommentRefuse"
        v-model:dialogConsult="editRequestDialog" :Id="Id" :ShowDialogEdit="isEditMode" :ShowDialogDelete="isEditMode"
        :ShowDialogValidation="getPurchaseOrder.displayValidateButton"
        :ShowDialogRefuse="getPurchaseOrder.displayValidateButton" :ShowDialogConsult="!isEditMode" :ShowExport="true" />
    <dialogDelete :title="$t('purchaseOrder.purchaseOrder')" :yes="deleteRequest" v-model="dialog" />
    <v-dialog v-model="editRequestDialog">
        <v-card width="1000px" class="py-2" style="border-radius: 12px;">
            <v-form ref="form" lazy-validation>
                <div class="row ma-4">
                    <h1>{{ isEditMode ? $t('actions.edit', { itm: $t('purchaseOrder.purchaseOrder') })
                        : $t('actions.consult', { itm: $t('purchaseOrder.purchaseOrder') }) }}</h1>
                </div>
                <div class="row ma-4">
                    <div class="col-3 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.reference') }}
                                <span class="red">*</span>
                            </v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.reference" id="reference" name="reference" maxlength="30"
                                hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.reference') })"
                                variant="outlined" class="rounded-xl" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgReference">{{ msg.reference }}</span>
                        </div>
                    </div>
                    <div class="col-3 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.title') }}
                                <span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.title" id="title" name="title" maxlength="30" hide-details
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.title') })"
                                variant="outlined" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgTitle">{{ msg.title }}</span>
                        </div>
                    </div>
                    <div class="col-3 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.expirationDate') }}
                                <span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <dateField v-model="editData.expirationDate" :minDate="new Date()" id="expirationDate"
                                name="expirationDate" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''" />
                        </div>
                    </div>
                    <div class="col-3 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.devise') }}
                                <span class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-autocomplete id="Devise" name="Devise" variant="outlined" v-model="editData.devise"
                                persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('purchaseOrder.devise') })"
                                :items="DeviseList" item-title="name" item-value="symbol" hide-details
                                :readonly="!isEditMode" :class="!isEditMode ? 'readonly-field' : ''">
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" variant="text" :id="index" :name="item.name">
                                        {{ item.name }}
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                            <span class="warningHint" id="WarningMsgDevise">{{ msg.devise }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.amountHT') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.threshold" id="AmountHT" name="AmountHT" maxlength="9"
                                type="number" hide-details persistent-placeholder :suffix="editData.devise"
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.amountHT') })"
                                variant="outlined" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgThreshold">{{ msg.amountHT }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.tax') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-select id="tax" name="tax" variant="outlined" v-model="editData.taxe" persistent-placeholder
                                :placeholder="$t('placeholder.select', { itm: $t('purchaseOrder.tax') })" :items="TaxList"
                                item-title="taxPourcentage" item-value="taxPourcentage" hide-details
                                :suffix="editData.taxe ? '%' : ''" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''">
                                <template v-slot:item="{ props, item, index }">
                                    <v-list-item v-bind="props" :id="index" :name="item.name" :title="item.value + '%'"
                                        :subtitle="item.raw.taxName">
                                    </v-list-item>
                                </template>
                            </v-select>
                            <span class="warningHint" id="WarningMsgTax">{{ msg.tax }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.quantity') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="editData.quantity" id="quantité" name="quantité" maxlength="9"
                                type="number" hide-details persistent-placeholder :min="1"
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.quantity') })"
                                variant="outlined" :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"></v-text-field>
                            <span class="warningHint" id="WarningMsgThreshold">{{ msg.quantity }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.montantHT') }}</v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="MTHTVA" id="MTHTVA" name="MTHTVA" maxlength="9" type="text" hide-details
                                persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.montantHT') })" readonly
                                :class="!isEditMode ? 'readonly-field' : ''" variant="outlined"
                                :suffix="editData.devise"></v-text-field>
                        </div>
                        <span class="warningHint" id="WarningMsgAmountHT">{{ msg.AmountHT }}</span>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.montantTotal') }}</v-card-title>
                        </div>
                        <div class="row">
                            <v-text-field v-model="MTTotal" id="MTTotal" name="MTTotal" maxlength="9" type="text"
                                hide-details persistent-placeholder
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.montantTotal') })"
                                variant="outlined" readonly :class="!isEditMode ? 'readonly-field' : ''"
                                :suffix="editData.devise"></v-text-field>
                            <span class="warningHint" id="WarningMsgThreshold">{{ msg.montantTotal }}</span>
                        </div>
                    </div>
                    <div class="col-4 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.paymentMethod') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-select v-model="editData.paymentMethod" :items="selectedPaymentMethod" name="paymentMethods"
                                maxlength="30" hide-details persistent-placeholder :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.paymentMethod') })"
                                variant="outlined" class="rounded-xl"></v-select>
                            <span class="warningHint" id="WarningMsgTitle">{{ msg.paymentMethod }}</span>
                        </div>
                    </div>
                    <div class="col-12 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.description') }}<span
                                    class="red">*</span></v-card-title>
                        </div>
                        <div class="row">
                            <v-textarea v-model="editData.description" id="description" name="description" hide-details
                                persistent-placeholder single-line auto-grow :readonly="!isEditMode"
                                :class="!isEditMode ? 'readonly-field' : ''"
                                :placeholder="$t('placeholder.enter', { itm: $t('purchaseOrder.description') })"
                                variant="outlined" class="rounded-xl"></v-textarea>
                            <span class="warningHint" id="WarningMsgTitle">{{ msg.description }}</span>
                        </div>
                    </div>
                    <div class="col-12 justify-content-center">
                        <span style="float:left;">
                            <v-card-title class="ma-0 pa-0">{{ $t('purchaseOrder.consumption') }}</v-card-title>
                            <span class="d-flex justify-content-start textConsumptionThreshold">{{
                                getPurchaseOrder.consumption }}
                                {{ getPurchaseOrder.devise }}</span>
                        </span>
                        <span style="float:right;">
                            <v-card-title class="ma-0 pa-0">{{ $t('purchaseOrder.threshold') }}</v-card-title>
                            <span class="d-flex justify-content-end textConsumptionThreshold">{{ MTTotal }}
                                {{ getPurchaseOrder.devise }}</span>
                        </span>
                        <v-progress-linear rounded rounded-bar :color="GetProgressColor(consumptionPercentage)"
                            :model-value="consumptionPercentage" height="18">
                            <template v-slot:default="{ value }"><strong>{{ Math.ceil(value) }}%</strong></template>
                        </v-progress-linear>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.purchaseOrder') }}</v-card-title>
                        </div>
                        <div class="row mx-1">
                            <uploadDocument @addDocument="setidentityDocument" :fnDownload="DownloadDocumentPurchaseOrder"
                                :showDownload="showDownload" :showUpload="isEditMode ? true : false" :has-file="true">
                                <template v-slot:DocumentsList>
                                    <div @mouseover="setFileHover(isEditMode ? true : null)" @mouseout="setFileHover(null)"
                                        style="display: flex; position: relative;">
                                        <span style="color: blue; cursor: pointer; display: flex; text-align: start"
                                            @click="DownloadDocumentPurchaseOrder()"
                                            :title="$t('actions.download') + ' ' + (editData.file ? editData.file.name : (editData.type ? editData.title + '.' + editData.type : ''))">
                                            {{ editData.file ? editData.file.name : (editData.type ? editData.title + "." +
                                                editData.type : '') }}
                                        </span>
                                        <v-icon v-show="isFileHovered" @click="showDeleteConfirmationFileDialog()"
                                            class="ml-2" style="color: red">mdi-close-circle-outline</v-icon>
                                    </div>
                                </template>
                            </uploadDocument>
                        </div>
                    </div>
                    <div class="col-6 justify-content-start">
                        <div class="row">
                            <v-card-title class="text-left">{{ $t('purchaseOrder.AutresDocument') }}</v-card-title>
                        </div>
                        <div class="row mx-1">
                            <uploadMultiDocument v-on:addMultiDocument="setidentityDocuments"
                                :fnDownload="DownloadallMultiDocument" :showDownload="showDownload" :is-update="isUpdate"
                                :showUpload="isEditMode ? true : false" :has-file="true">
                                <template v-slot:DocumentsList>
                                    <div style="max-height: 60px; overflow-y:scroll;">
                                        <div v-for="(fileName, index) in MultiFileList" :key="index">
                                            <div @mouseover="setHover(index)" @mouseout="setHover(null)"
                                                style="display: flex; position: relative;">
                                                <span
                                                    style="color: blue; cursor: pointer; text-align: start; max-width: 95%;"
                                                    @click="DownloadMultiDocumentPurchaseOrder(fileName, index)"
                                                    :title="$t('actions.download') + ' ' + fileName">{{ fileName }}</span>
                                                <br v-if="index < this.editData.uriFiles.split(',').length - 1">
                                                <v-icon v-show="isHovered == index"
                                                    @click="showDeleteConfirmationDialog(fileName, index)" class="ml-2"
                                                    style="color: red">mdi-close-circle-outline</v-icon>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </uploadMultiDocument>
                        </div>
                    </div>
                </div>
                <card-actions :yes="validateFormEditRequest" v-model:no="editRequestDialog" :loader="loader"
                    :yesDisable="loader ? true : !isEditMode ? true : false" width="300px" :yesTitle="$t('actions.save')" />
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCommentRefuse" v-if="dialogCommentRefuse">
        <v-card width="max-content" height="max-content" class="px-8 py-4">
            <div class="row justify-content-center">
                <v-card-title class="text-left">{{ $t('purchaseOrder.validationTab.commentaire') }} : <span
                        class="red">*</span>
                </v-card-title>
            </div>
            <div class="row justify-content-center">
                <v-textarea id="Comment" v-model="commentaire" variant="outlined" counter
                    :label="$t('purchaseOrder.validationTab.commentaireLabel')" single-line auto-grow hide-details
                    maxlength="500"></v-textarea>
                <span class="warningHint" id="WarningMsgComment">{{ msg.comment }}</span>
            </div>
            <div class="row justify-content-center">
                <card-actions :yes="checkComment" v-model:no="dialogCommentRefuse" width="200px"
                    :yesTitle="$t('purchaseOrder.validationTab.refuseBtn')" :YesColor="'#F25C5C'" />
            </div>
        </v-card>
    </v-dialog>
    <dialogDelete v-if="selectedIndex !== null" v-model="showDeleteDialog" :yes="confirmDelete" :status="false"
        :title="selectedFileName" v-model:no="showDeleteDialog"></dialogDelete>
    <dialogDelete v-model="showDeleteFileDialog" :yes="confirmFileDelete" :status="false"
        :title="editData.file ? editData.file.name : (editData.type ? editData.title + '.' + editData.type : '')" v-model:no="showDeleteFileDialog"></dialogDelete>
    <dialogDelete :loader="buttonLoader" DialogType="Validation" :title="$t('purchaseOrder.validationTab.validationStatus')"
        :yes="ValidatePurchaseOrder" v-model="dialogValidation" />
    <dialogDelete :loader="buttonLoader" DialogType="Refuse" :title="$t('purchaseOrder.validationTab.validationStatus')"
        :yes="RefusePurchaseOrder" v-model="dialogRefuse" />
</template>
<script>
import { downloadDocumentPurchaseOrder, DownloadPurchaseOrderPdf, downloadMultiDocumentPurchaseOrder, DeletePurchaseOrder, EditPurchaseOrder, GetTaxListByCmpanyId, PurchaseOrderIsExistReference, isClient, idClient, ValidatePurchaseOrder, RefusePurchaseOrder } from "@/api/index";
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import deviseJson from "@/data/DevisesList.json"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import cardActions from "@/genericComponent/cardActions.vue"
import uploadDocument from "@/genericComponent/uploadDocument.vue"
import dateField from "@/genericComponent/date-field.vue";
import uploadMultiDocument from "@/genericComponent/uploadMultiDocument.vue"

export default {
    components: { actionsMenu, dialogDelete, cardActions, uploadDocument, uploadMultiDocument, dateField, },
    props: ["idPurchaseOrder", "getPurchaseOrderListAfterDelete", "getPurchaseOrder", "SupplierList", "Id"], isUpdate: true,
    mounted() {
        this.editData.reference = this.getPurchaseOrder.reference;
        this.editData.idPurchaseOrder = this.getPurchaseOrder.idPurchaseOrder;
        this.editData.title = this.getPurchaseOrder.title;
        this.editData.threshold = this.getPurchaseOrder.threshold;
        this.editData.devise = this.getPurchaseOrder.devise;
        this.editData.type = this.getPurchaseOrder.type;
        this.editData.expirationDate = this.getPurchaseOrder.expirationDate;
        this.editData.taxe = this.getPurchaseOrder.taxe;
        this.editData.quantity = this.getPurchaseOrder.quantity;
        this.editData.paymentMethod = this.getPurchaseOrder.paymentMethod;
        this.editData.file = this.identityDocument ? this.identityDocument[0] : null;
        if (this.identityDocuments && this.identityDocuments.length > 0) {
            this.editData.filesToAdd = this.identityDocuments;
        } else {
            this.editData.filesToAdd = null;
        }
        this.editData.description = this.getPurchaseOrder.description
        this.editData.uriFiles = this.getPurchaseOrder.uriFiles
    },
    data() {
        return {
            dialog: false,
            editRequestDialog: false,
            generatePDFbutton: false,
            dialogCommentRefuse: false,
            identityDocument: null,
            identityDocuments: [],
            TaxList: [],
            selectedIndex: null,
            selectedFileName: null,
            isHovered: null,
            showDeleteDialog: null,
            uploadedFile: false,
            isUpdate: false,
            loader: false,
            editData: {
                paymentMethod: null,
                quantity: "",
                description: "",
                reference: "",
                idPurchaseOrder: "",
                title: "",
                threshold: 0,
                devise: "",
                type: null,
                file: null,
                expirationDate: null,
                taxe: null,
                uriFiles: [],
                filesToAdd: [],
                filesToDelete: []
            },
            msg: [],
            DeviseList: deviseJson,
            commentaire: null,
            dialogValidation: false,
            dialogRefuse: false,
            buttonLoader: false,
            showDownload: false,
            showDeleteFileDialog: null,
            isFileHovered: null,
        };
    },
    watch: {
        editRequestDialog(value) {
            if (value) {
                this.GetTaxListByCmpanyId(idClient());
                this.editData.idPurchaseOrder = this.getPurchaseOrder.idPurchaseOrder;
                this.editData.reference = this.getPurchaseOrder.reference;
                this.editData.title = this.getPurchaseOrder.title;
                this.editData.threshold = this.getPurchaseOrder.threshold;
                this.editData.devise = this.getPurchaseOrder.devise;
                this.editData.type = this.getPurchaseOrder.type;
                this.editData.expirationDate = this.getPurchaseOrder.expirationDate;
                this.editData.taxe = this.getPurchaseOrder.taxe;
                this.editData.description = this.getPurchaseOrder.description;
                this.editData.quantity = this.getPurchaseOrder.quantity;
                this.editData.paymentMethod = this.getPurchaseOrder.paymentMethod;
                this.editData.uriFiles = this.getPurchaseOrder.uriFiles
                this.identityDocument = null,
                this.identityDocuments = [],
                this.editData.file = this.identityDocument ? this.identityDocument[0] : null;
                if (this.identityDocuments && this.identityDocuments.length > 0) {
                    this.editData.filesToAdd = this.identityDocuments;
                } else {
                    this.editData.filesToAdd = null;
                }
                this.msg = [];
                if (this.editData.type !== undefined && this.editData.type !== null && this.editData.type !== "") {
                    this.showDownload = true;
                } else {
                    this.showDownload = false;
                }
            }
        },
        dialogCommentRefuse(value) {
            if (value == false) {
                this.msg['comment'] = ""
                this.commentaire = null
            }
        },
        generatePDFbutton(value) {
            if (value == true) {
                this.generatePDF();
                this.generatePDFbutton = false;
            }
        }
    },
    methods: {
        setFileHover(isHover){
            this.isFileHovered = isHover
        },
        confirmFileDelete(){
            delete this.editData.file,
            delete this.editData.type,
            this.showDeleteFileDialog = false;
        },
        showDeleteConfirmationFileDialog(){
            this.showDeleteFileDialog = true;
        },
        async generatePDF() {
            try {
                const responsePurchaseOrder = await DownloadPurchaseOrderPdf(this.idPurchaseOrder);
                const url = window.URL.createObjectURL(new Blob([responsePurchaseOrder.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'purchase_order.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Erreur lors du téléchargement du fichier PDF:', error);
            }
        },
        getFileName(list) {
            return list.map(fileName => fileName.replace(/_(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})\./, '.'));
        },
        showDeleteConfirmationDialog(filename, index) {
            this.showDeleteDialog = true;
            this.selectedFileName = filename;
            this.selectedIndex = index;
        },
        confirmDelete() {
            if (this.selectedIndex !== null) {
                this.removeFile(this.selectedIndex);
                this.selectedIndex = null;
                this.selectedFileName = null;
                this.showDeleteDialog = false;
            }
        },
        setHover(index) {
            this.isHovered = index;
        },
        removeFile(fileToRemove) {
            let arr = this.editData.uriFiles.split(',');
            if (fileToRemove >= arr.length) {
                this.editData.filesToAdd.splice(fileToRemove - arr.length, 1);
            } else {
                this.editData.filesToDelete.push(arr[fileToRemove]);
                arr.splice(fileToRemove, 1)
            }
            this.editData.uriFiles = arr.join(',');
            this.uploadedFile = false;
        },
        GetProgressColor(A) {
            switch (true) {
                case A === 100:
                    return "#F25C5C";
                case A >= 50:
                    return "#FF8934";
                case A >= 10:
                    return "#3BBAFF";
                default:
                    return "#00CBA0";
            }
        },
        GetTaxListByCmpanyId(companyId) {
            GetTaxListByCmpanyId(companyId).then((res) => {
                this.TaxList = res.data;
            })
        },
        downloadDocumentInvoices(file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                var base64data = reader.result;
                const linkSource = `${base64data}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = file.name;
                downloadLink.click();
            }
        },
        DownloadallMultiDocument() {
            this.MultiFileList.forEach((fileName, index) => {
                this.DownloadMultiDocumentPurchaseOrder(fileName, index)
            });
        },
        DownloadMultiDocumentPurchaseOrder(fileName, index) {
            let arr = this.editData.uriFiles.split(',');
            if (index >= arr.length) {
                var reader = new FileReader();
                reader.readAsDataURL(this.editData.filesToAdd[index - arr.length]);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const linkSource = `${base64data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }
            } else {
                downloadMultiDocumentPurchaseOrder(arr[index])
                    .then(response => {
                        const linkSource = `data:application/pdf;base64,${response.data}`;
                        const downloadLink = document.createElement("a");
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    })
            }
        },
        DownloadDocumentPurchaseOrder() {
            const file = this.identityDocument ? this.identityDocument[0] : null
            if (file != null) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const linkSource = `${base64data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = file.name;
                    downloadLink.click();
                }
            } else {
                let fileName = this.getPurchaseOrder.idPurchaseOrder + '.' + this.getPurchaseOrder.type
                downloadDocumentPurchaseOrder(fileName)
                    .then(response => {
                        const linkSource = `data:application/pdf;base64,${response.data}`;
                        const downloadLink = document.createElement("a");
                        const fileName = this.getPurchaseOrder.title + '.' + this.getPurchaseOrder.type;
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    })
            }
        },
        setidentityDocument(value) {
            this.identityDocument = value
            this.editData.file = this.identityDocument ? this.identityDocument[0] : null;
        },
        setidentityDocuments(value) {
            this.identityDocuments.push(...value);
            this.editData.filesToAdd = this.identityDocuments;
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        deleteRequest() {
            this.dialog = false
            DeletePurchaseOrder(this.idPurchaseOrder)
                .then(() => {
                    this.ActiveDeletedSnackBar();
                    this.getPurchaseOrderListAfterDelete();
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        validateFormEdit() {
            this.loader = true;
            EditPurchaseOrder(this.idPurchaseOrder, this.editData).then((res) => {
                if (res.status == 200) {
                    this.editRequestDialog = false;
                    this.getPurchaseOrderListAfterDelete();
                } else {
                    this.editRequestDialog = true;
                }
                this.ActiveSavedSnackBar();
            }).catch(() => {
                this.ActiveWarningSnackBar();
            }).finally(() => {
                this.loader = false;
            })
        },
        async IsExistReference() {
            const r = await PurchaseOrderIsExistReference(this.idPurchaseOrder, this.editData.reference);
            return r.data;
        },
        async validateFormEditRequest() {
            if (!this.editData.reference || this.editData.reference.length == 0) {
                this.msg["reference"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.reference') });
            } else if (await this.IsExistReference() == true) {
                this.msg["reference"] = this.$t('warningInput.exist', { itm: this.$t('purchaseOrder.reference') });
            } else {
                this.msg["reference"] = "";
            }
            if (!this.editData.title || this.editData.title.length == 0) {
                this.msg["title"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.title') });
            } else {
                this.msg["title"] = "";
            }
            if (!this.editData.threshold || this.editData.threshold.length == 0) {
                this.msg["amountHT"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.amountHT') });
            } else if (this.editData.threshold <= 0) {
                this.msg["amountHT"] = this.$t('warningInput.notValid', { itm: this.$t('purchaseOrder.amountHT') });
            } else {
                this.msg["amountHT"] = "";
            }
            if (this.editData.taxe == null || this.editData.taxe.length == 0) {
                this.msg["tax"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.tax') });
            } else {
                this.msg["tax"] = "";
            }
            if (!this.editData.expirationDate || this.editData.expirationDate.length == 0) {
                this.msg["expirationDate"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.expirationDate') });
            } else {
                this.msg["expirationDate"] = "";
            }
            if (!this.editData.quantity || this.editData.quantity.length == 0) {
                this.msg["quantity"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.quantity') });
            } else if (this.editData.quantity <= 0) {
                this.msg["quantity"] = this.$t('warningInput.notValid', { itm: this.$t('purchaseOrder.quantity') });
            } else {
                this.msg["quantity"] = "";
            }
            if (!this.editData.paymentMethod || this.editData.paymentMethod.length == 0) {
                this.msg["paymentMethod"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.paymentMethod') });
            } else {
                this.msg["paymentMethod"] = "";
            }
            if (!this.editData.description || this.editData.description.length == 0) {
                this.msg["description"] = this.$t('warningInput.isrequired', { itm: this.$t('purchaseOrder.description') });
            } else {
                this.msg["description"] = "";
            }


            if (
                this.msg["title"] == "" &&
                this.msg["reference"] == "" &&
                this.msg["tax"] == "" &&
                this.msg["expirationDate"] == "" &&
                this.msg["quantity"] == "" &&
                this.msg["paymentMethod"] == "" &&
                this.msg["description"] == ""

            ) {
                this.validateFormEdit();
            }
        },
        ValidatePurchaseOrder: function () {
            this.buttonLoader = true
            ValidatePurchaseOrder(this.getPurchaseOrder.idPurchaseOrder).then(() => {
                this.ActiveSavedSnackBar()
            }).finally(() => {
                this.dialogValidation = false;
                this.getPurchaseOrderListAfterDelete();
            });
        },
        checkComment: function () {
            if (this.commentaire == null || this.commentaire.length == 0) {
                this.msg['comment'] = this.$t('warningInput.isrequired', { itm: this.$t('suppliers.validationTab.commentaire') });
            } else {
                this.dialogRefuse = true
            }
        },
        RefusePurchaseOrder: function () {
            this.buttonLoader = true
            RefusePurchaseOrder(this.getPurchaseOrder.idPurchaseOrder, this.commentaire).then(() => {
                this.ActiveSavedSnackBar()
            }).finally(() => {
                this.dialogRefuse = false;
                this.dialogCommentRefuse = false;
                this.getPurchaseOrderListAfterDelete();
            })
        },
    },
    computed: {
        selectedPaymentMethod() {
            return [
                { 'value': 'Chèque bancaire', 'title': this.$t('purchaseOrder.paymentMethodChoise.BankCheck') },
                { 'value': 'Virement', 'title': this.$t('purchaseOrder.paymentMethodChoise.Transfer') },
                { 'value': 'Prélèvement', 'title': this.$t('purchaseOrder.paymentMethodChoise.DirectDebit') }
            ]
        },
        statusText() {
            switch (this.getPurchaseOrder.status) {
                case 0: return this.$t('purchaseOrder.validationTab.status.ValidatedByClient');
                case 1: return this.$t('purchaseOrder.validationTab.status.ValidatedBySupplier');
                case 2: return this.$t('purchaseOrder.validationTab.status.RefusedBySupplier');
                default: return this.$t('purchaseOrder.validationTab.status.ValidatedByClient');
            }
        },
        isEditMode() {
            return isClient() && [0, 2].includes(this.getPurchaseOrder.status);
        },
        idClient() {
            return idClient()
        },
        MTHTVA() {
            let mtHTVA = parseFloat(this.editData.quantity) * parseFloat(this.editData.threshold);
            let parts = mtHTVA.toString().split('.');
            if (parts[1] && parts[1].length > 3) {
                return mtHTVA.toFixed(3).toString().replace(/\.?0+$/, '');
            } else {
                return mtHTVA;
            }
        },
        MTTotal() {
            let amountTTC = (this.editData.threshold * (1 + (this.editData.taxe / 100)))
            let mtTotal = parseFloat(this.editData.quantity) * amountTTC;
            let parts = mtTotal.toString().split('.');
            if (parts[1] && parts[1].length > 3) {
                return mtTotal.toFixed(3).toString().replace(/\.?0+$/, '');
            } else {
                return mtTotal;
            }
        },
        consumptionPercentage() {
            return (this.getPurchaseOrder.consumption / this.MTTotal) * 100
        },
        MultiFileList() {
            let list = this.getFileName(this.editData.uriFiles.split(','));
            if (this.editData.filesToAdd) {
                this.editData.filesToAdd.forEach(file => {
                    list.push(file.name)
                });
            }
            return list
        }
    }
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-card-text {
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #5b6d8e;
}

.textConsumptionThreshold {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    color: #080C3E;
}</style>