<template>
    <div>
        <div elevation="0" color="transparent" flat>
            <div class="row mb-8">
                <div class="col-6 d-flex justify-content-start">
                    <v-card-title>
                        {{ $t('routes.suppliers') }}
                    </v-card-title>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <genericBtnComponent :ClickFun="navigateToNewSuppliers" svg="2" :Title="$t('suppliers.add')">
                    </genericBtnComponent>
                </div>
            </div>
        </div>
        <v-card elevation="0">
            <suppliers-list />
        </v-card>
    </div>
</template>
<script>
import SuppliersList from "./SuppliersList/SuppliersList.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    components: {
        SuppliersList,
        genericBtnComponent
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.suppliers');
    },
    data: () => ({
    }),
    methods: {
        navigateToNewSuppliers() {
            this.$router.push({ name: 'NewSupplier' });
        },
    }
};
</script>
<style scoped>
.v-card-title {
    font-size: 14px;
    font-family: inter;
    font-weight: 600;
}

.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>