<template>
    <div>
        Users
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {};
    },
    mounted() {
    },
    unmounted() {
    },
    methods: {

    },
    computed: {
    }
};
</script>