<template>
    <actions-menu v-model:dialogEdit="EditDialog" v-model:dialogDelete="DeleteDialog" :Id="Id" />
    <dialogDelete :title="$t('suppliers.bankAccountsTab.bankAccount')" :yes="DeleteSupplierBankAccount"
        v-model="DeleteDialog" />
    <v-dialog v-model="EditDialog" v-if="EditDialog">
        <v-card width="1000px" class="py-2" style="border-radius: 12px;">
            <h1 class="text-center dialog-title"> {{ $t('suppliers.bankAccountsTab.edit') }}</h1>                    
            <div class="row mx-4">
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.iban') }}
                            <v-tooltip text="Tooltip" location="end">
                                <template v-slot:activator="{ props }">
                                    <v-icon  class="ml-2" id="tooltip-target-1" v-bind="props">mdi-information</v-icon>
                                </template>
                                <b-tooltip class="tooltip-inner" target="tooltip-target-1" triggers="hover">
                                    {{ $t('suppliers.bankAccountsTab.Toolipiban') }}<b>FR763000100794123456789XXXX</b> 
                                </b-tooltip>
                            </v-tooltip>
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="iban" id="iban" name="iban" maxlength="40" :readonly="!validationButton" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.iban')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.iban }}</span>
                    </div>
                </div>                                       

                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.bic') }}
                                <v-tooltip text="Tooltip" location="end">
                                <template v-slot:activator="{ props }">
                                    <v-icon  class="ml-2" id="tooltip-target-1" v-bind="props">mdi-information</v-icon>
                                </template>
                                <b-tooltip class="tooltip-inner" target="tooltip-target-1" triggers="hover">
                                    {{ $t('suppliers.bankAccountsTab.Toolipbic') }}<b>CEPAXXXX</b>                         
                                </b-tooltip>
                            </v-tooltip>
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="bic" id="bic" name="bic" maxlength="11" :readonly="!validationButton" hide-details persistent-placeholder
                            :placeholder="$t('suppliers.bankAccountsTab.placeholder.bic')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.bic }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.bankName') }}
                        </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="bankName" id="bankName" name="bankName" maxlength="30" :readonly="!validationButton" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.bankName')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.bankName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.accountHolderName') }}</v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="accountHolderName" id="accountHolderName" :readonly="!validationButton" name="accountHolderName"
                            maxlength="30" hide-details persistent-placeholder
                            :placeholder="$t('suppliers.bankAccountsTab.placeholder.accountHolderName')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.accountHolderName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.agencyName') }}</v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="agencyName" id="agencyName" name="agencyName" :readonly="!validationButton" maxlength="30" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.agencyName')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.agencyName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.bankAdresse')
                        }}</v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="bankAdresse" id="bankAdresse" name="bankAdresse" :readonly="!validationButton" maxlength="100" hide-details
                            persistent-placeholder :placeholder="$t('suppliers.bankAccountsTab.placeholder.bankAdresse')"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgName"> {{ msg.bankAdresse }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.country') }}</v-card-title>
                    </div>
                    <div class="row">
                        <select-country :placeholder="$t('suppliers.generalInformation.placeholder.country')"
                            v-bind:countryProps="country" :readonly="!validationButton" @getCountry="getCountryFromSelect"></select-country>
                        <span class='warningHint' id="WarningMsgCountry"> {{ msg.country }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('suppliers.bankAccountsTab.identityDocument')
                        }}<span class="red">*</span></v-card-title>
                    </div>
                    <div class="row ml-0">
                        <uploadDocument @addDocument="setidentityDocument" :fnDownload="DownloadDocument"
                            :showDownload="identityDocument" :showUpload="true" :readonly="!validationButton" :hasFile="identityDocument"
                            :fileTypes="['application/pdf']" sub-title="suppliers.identityDocument.FileMSG"
                            sub-title2="suppliers.identityDocument.FileMSG2">
                            <template v-slot:DocumentsList>
                                <span style="color: blue; cursor: pointer; margin-left: 14px; display: flex; text-align: start" @click="DownloadDocument()"
                                    :title="$t('actions.download') + ' ' + (identityDocumentPDF ? identityDocumentPDF[0].name : bankName + ' - ' + accountHolderName + '.pdf')">
                                    {{ identityDocumentPDF ? identityDocumentPDF[0].name : bankName + ' - ' + accountHolderName + '.pdf'  }}
                                </span>
                            </template>
                        </uploadDocument>
                        <span class='warningHint' id="WarningMsgDocument">{{ msg.identityDocument }}</span>
                    </div>
                </div>
            </div>
            <card-actions :yes="validateFormEdit" v-model:no="EditDialog" :yesDisable="!validationButton"  width="300px"
                :yesTitle="$t('suppliers.saveChanges')" />
        </v-card>
    </v-dialog>
</template>
<script>
import cardActions from "@/genericComponent/cardActions.vue"
import actionsMenu from "@/genericComponent/actionsMenu.vue"
import dialogDelete from "@/genericComponent/dialogDelete.vue"
import uploadDocument from "@/genericComponent/uploadDocument.vue"
import SelectCountry from '../../SelectCountry/SelectCountry.vue';
import { editSupplierBankAccount, DeleteSupplierBankAccount, getIdentityDocument,CheckPermissionByUser} from "@/api/index";
import IBAN from 'iban';
export default {
    mounted () {
    this.checkSupplierPermission();
    },
    components: { actionsMenu, uploadDocument, dialogDelete, cardActions, SelectCountry },
    name: "SupplierBankAccountEditMenu",
    props: {
        getBankAccountList: {},
        Supplier: {
            type: Object,
            required: true,
        },
        Id: {},
    },
    data() {
        return {
            DeleteDialog: false,
            msg: [],
            EditDialog: false,
            bankName: this.Supplier.bankName,
            iban: this.Supplier.iban,
            bic: this.Supplier.bic,
            agencyName: this.Supplier.agencyName != "null" ? this.Supplier.agencyName : null,
            bankAdresse: this.Supplier.bankAdresse != "null" ? this.Supplier.bankAdresse : null,
            accountHolderName: this.Supplier.accountHolderName,
            identityDocument: this.Supplier.identityDocument,
            identityDocumentPDF: null,
            idBankAccount: this.Supplier.id,
            country: this.Supplier.country,
            validationButton:false
        };

    },
    watch: {
        EditDialog() {
            this.bankName = this.Supplier.bankName;
            this.iban = this.Supplier.iban;
            this.bic = this.Supplier.bic;
            this.agencyName = this.Supplier.agencyName != "null" ? this.Supplier.agencyName : null;
            this.bankAdresse = this.Supplier.bankAdresse != "null" ? this.Supplier.bankAdresse : null;
            this.accountHolderName = this.Supplier.accountHolderName;
            this.identityDocumentPDF = null;
            this.identityDocument = this.Supplier.identityDocument;
            this.idBankAccount = this.Supplier.id;
            this.msg = [];
            this.country = this.Supplier.country

        }
    },
    computed: {
        isValidIBAN() {
            return IBAN.isValid(this.iban);
        },
        isValidBIC() {
            const bicRegex = /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/;
            return bicRegex.test(this.bic);
        }
    },
    methods: {
        getCountryFromSelect(value) {
            this.country = value;
        },
        DownloadDocument() {
            const file = this.identityDocumentPDF ? this.identityDocumentPDF[0] : null;
            if (file != null) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const linkSource = `${base64data}`;
                    const downloadLink = document.createElement("a");
                    downloadLink.href = linkSource;
                    downloadLink.download = file.name;
                    downloadLink.click();
                }
            } else {
                getIdentityDocument(this.idBankAccount)
                    .then(response => {
                        const linkSource = `data:application/pdf;base64,${response.data}`;
                        const downloadLink = document.createElement("a");
                        const fileName = this.bankName + ' - ' + this.accountHolderName + '.pdf';
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();
                    });
            }
        },
        setidentityDocument(value) {
            this.identityDocumentPDF = value
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        DeleteSupplierBankAccount() {
            this.DeleteDialog = false;
            DeleteSupplierBankAccount(this.idBankAccount)
                .then(() => {
                    this.getBankAccountList();
                    this.ActiveDeletedSnackBar();
                }).catch(() => {
                    this.ActiveWarningSnackBar()
                });
        },
        checkSupplierPermission: function () {
            CheckPermissionByUser("Optima.Suppliers.readWrite")
                .then((response) => {
                    this.validationButton = response.data;
                }) 
                .catch(() => {
                    this.validationButton = false;
                });
        },
        EditSupplierBankAccount() {
            editSupplierBankAccount(this.idBankAccount, {
                idBankAccount: this.idBankAccount,
                iban: this.iban !== null ? this.iban : '',
                bic: this.bic !== null ? this.bic : '',
                bankName: this.bankName !== null ? this.bankName : '',
                agencyName: this.agencyName !== null ? this.agencyName : '',
                bankAdresse: this.bankAdresse !== null ? this.bankAdresse : '' ,
                accountHolderName: this.accountHolderName !== null ? this.accountHolderName : '' ,
                identityDocument: this.identityDocument ? true : this.identityDocumentPDF ? true : false,
                country: this.country !== null ? this.country : '',
                identityDocumentPDF: this.identityDocumentPDF
            })
                .then(() => {
                    if (!this.validationButton) {
                    this.ActiveWarningSnackBar();
                    this.btnloader = false;
                    return;
                }
                    this.getBankAccountList();
                    this.ActiveSavedSnackBar()
                })
                .catch(() => {
                    this.ActiveWarningSnackBar();
                });
        },
        validateFormEdit() {
            if (this.identityDocument == false){
                if (this.identityDocumentPDF == null || this.identityDocumentPDF == "") {
                this.msg["identityDocument"] = this.$t('suppliers.documentsTab.errors.identityDocument');
                } 
            } else {
                this.msg["identityDocument"] = "";
            }

            if (
                this.msg["identityDocument"] == ""
            ) {
                this.EditSupplierBankAccount();
                this.EditDialog = false;
            }
        },
    },
};
</script>
<style scoped>
h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-dialog .v-card {
    border-radius: 12px;
    background-color: #ffffff;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
}

.v-card-text {
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}

.dialog-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: black;  
    text-align: center;
    border-radius: 0.25rem;
}
</style>