<template>
    <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="12">
                <span class="loginText"> {{ $t('signUp.getStarted') }} </span>
                <span class="welcomeText" style="margin-top: 11px;"> {{ $t('signUp.createToContinue') }} </span>
                <br>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-window v-model="step" disabled>
                        <v-window-item :value="1">
                            <v-row style="flex-wrap: nowrap;" class=" mb-0">
                                <v-col class=" flex-grow-1 flex-shrink-0  justify-start title">
                                    <span>
                                        {{ currentTitle }}
                                    </span>
                                </v-col>
                                <v-col class=" flex-grow-0 flex-shrink-0 d-flex justify-end ">
                                    <v-avatar color="#00CBA0" style="width: 75px; height: 31px; border-radius: 23px">
                                        <span class="stepText">{{ step }}/2</span>
                                    </v-avatar>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="name" maxlength="30" id="name" name="name"
                                :label="$t('placeholder.enter', { itm: $t('signUp.fullName') })" variant="outlined"
                                single-line full-width hide-details required>
                                <template #prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                                            fill="#C5CDD4" />
                                        <path
                                            d="M12.0002 14.5C6.99016 14.5 2.91016 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17.0102 14.5 12.0002 14.5Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                            </v-text-field>
                            <span class='warningHint' id="nameErrorMsg"> {{ msg.name }}</span>
                            <v-text-field v-model="email" maxlength="40" id="email" name="email"
                                :label="$t('placeholder.enter', { itm: $t('signUp.email') })" variant="outlined" single-line
                                full-width hide-details required>
                                <template #prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17 3H7C4 3 2 4.5 2 8V15C2 18.5 4 20 7 20H10.57C11.16 20 11.64 19.48 11.56 18.89C11.46 18.18 11.48 17.44 11.63 16.68C12.16 14.08 14.3 12.01 16.92 11.58C18.23 11.37 19.48 11.55 20.58 12.03C21.25 12.32 22 11.86 22 11.13V8C22 4.5 20 3 17 3ZM17.47 8.59L14.34 11.09C13.68 11.62 12.84 11.88 12 11.88C11.16 11.88 10.31 11.62 9.66 11.09L6.53 8.59C6.21 8.33 6.16 7.86 6.41 7.53C6.67 7.21 7.14 7.15 7.46 7.41L10.59 9.91C11.35 10.52 12.64 10.52 13.4 9.91L16.53 7.41C16.85 7.15 17.33 7.2 17.58 7.53C17.84 7.86 17.79 8.33 17.47 8.59Z"
                                            fill="#C5CDD4" />
                                        <path
                                            d="M18 13C15.24 13 13 15.23 13 18C13 20.76 15.24 23 18 23C20.77 23 23 20.76 23 18C23 15.23 20.77 13 18 13ZM20.05 17.55L19.68 17.91L17.5 20.1C17.4 20.19 17.21 20.29 17.07 20.31L16.09 20.45C15.74 20.5 15.49 20.25 15.54 19.9L15.68 18.92C15.7 18.78 15.79 18.59 15.89 18.49L18.07 16.32L18.43 15.95C18.67 15.71 18.94 15.55 19.22 15.55C19.46 15.55 19.73 15.66 20.03 15.95C20.7 16.6 20.49 17.11 20.05 17.55Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                            </v-text-field>
                            <span class='warningHint' id="emailErrorMsg"> {{ msg.email }}</span>
                            <v-text-field v-model="phoneNumber" id="phoneNumber" name="phoneNumber" maxlength="15"
                                :label="$t('placeholder.enter', { itm: $t('signUp.phoneNumber') })" variant="outlined"
                                single-line full-width hide-details required>
                                <template #prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.24 2H7.76C5 2 4 3 4 5.81V18.19C4 21 5 22 7.76 22H16.23C19 22 20 21 20 18.19V5.81C20 3 19 2 16.24 2ZM12 19.3C11.04 19.3 10.25 18.51 10.25 17.55C10.25 16.59 11.04 15.8 12 15.8C12.96 15.8 13.75 16.59 13.75 17.55C13.75 18.51 12.96 19.3 12 19.3ZM14 6.25H10C9.59 6.25 9.25 5.91 9.25 5.5C9.25 5.09 9.59 4.75 10 4.75H14C14.41 4.75 14.75 5.09 14.75 5.5C14.75 5.91 14.41 6.25 14 6.25Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                            </v-text-field>
                            <span class='warningHint' id="phoneNumberErrorMsg">{{ msg.phoneNumber }}</span>
                            <v-text-field v-model="password" id="password" name="password"
                                :label="$t('placeholder.enter', { itm: $t('signUp.password') })"
                                :type="showPass ? 'text' : 'password'" variant="outlined" single-line full-width
                                hide-details>
                                <template #prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                                            fill="#C5CDD4" />
                                        <path
                                            d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                                <template #append-inner>
                                    <v-btn icon variant="plain" id="showPass" name="showPass" @click="showPass = !showPass">
                                        <svg v-if="showPass" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                        <svg v-if="!showPass" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <span class='warningHint' id="passwordErrorMsg">{{ msg.password }}</span>
                            <v-text-field v-model="password2" id="password2" name="password2"
                                :label="$t('placeholder.enter', { itm: $t('signUp.password') })"
                                :type="showPass2 ? 'text' : 'password'" variant="outlined" single-line full-width
                                hide-details>
                                <template v-slot:progress>
                                    <v-progress-linear :value="progress" :color="color" absolute full-width
                                        height="7"></v-progress-linear>
                                </template>
                                <template #prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                                            fill="#C5CDD4" />
                                        <path
                                            d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                                <template #append-inner>
                                    <v-btn icon variant="plain" id="showPass2" name="showPass2"
                                        @click="showPass2 = !showPass2">
                                        <svg v-if="showPass2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                        <svg v-if="!showPass2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
                                                fill="#C5CDD4" />
                                            <path
                                                d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
                                                fill="#C5CDD4" />
                                        </svg>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <br>
                            <v-row justify="center" align="center">
                                <svg width="580" height="4" viewBox="0 0 580 4" fill="none"
                                    style="padding-right: 12px; padding-left: 12px;" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 0 ? Color : '#F3F3F3'" />
                                    <rect x="120" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 1 ? Color : '#F3F3F3'" />
                                    <rect x="240" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 2 ? Color : '#F3F3F3'" />
                                    <rect x="360" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 3 ? Color : '#F3F3F3'" />
                                    <rect x="480" width="100" height="4" rx="2"
                                        :fill="passwordIsNotIdentique ? '#f95e68' : score >= 4 ? Color : '#F3F3F3'" />
                                </svg>
                            </v-row>
                            <password-meter style="visibility: hidden;" @score="onScore"
                                :password="password"></password-meter>
                            <span class="d-flex PasswordHint" v-bind:style="{ color: Color }"
                                v-if="password && !passwordIsNotIdentique"> {{ strengthTranslate }} </span>
                            <span class='d-flex PasswordHint' style="color:#f95e68" v-else-if="passwordIsNotIdentique">{{
                                msg.passwordIdentique }}</span>
                            <span class='d-block PasswordHint' v-else>&nbsp;</span>
                        </v-window-item>
                        <v-window-item :value="2">
                            <v-row class="d-flex flex-wrap mb-0" align="center">
                                <v-col class=" flex-grow-1 flex-shrink-0  justify-start title">
                                    <span>
                                        {{ currentTitle }}
                                    </span>
                                </v-col>
                                <v-col class=" flex-grow-0 flex-shrink-0 d-flex justify-end ">
                                    <v-avatar color="#00CBA0" style="width: 75px; height: 31px; border-radius: 23px">
                                        <span class="stepText">{{ step }}/2</span>
                                    </v-avatar>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="companyName" id="companyName" name="companyName" maxlength="30"
                                :label="$t('placeholder.enter', { itm: $t('signUp.companyName') })" variant="outlined"
                                single-line full-width hide-details required>
                                <template v-slot:prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M22 21.2488H21V9.97875C21 9.35875 20.72 8.77875 20.23 8.39875L19 7.43875L18.98 4.98875C18.98 4.43875 18.53 3.99875 17.98 3.99875H14.57L13.23 2.95875C12.51 2.38875 11.49 2.38875 10.77 2.95875L3.77 8.39875C3.28 8.77875 3 9.35875 3 9.96875L2.95 21.2488H2C1.59 21.2488 1.25 21.5888 1.25 21.9988C1.25 22.4088 1.59 22.7488 2 22.7488H22C22.41 22.7488 22.75 22.4088 22.75 21.9988C22.75 21.5888 22.41 21.2488 22 21.2488ZM6.5 12.7487V11.2487C6.5 10.6987 6.95 10.2487 7.5 10.2487H9.5C10.05 10.2487 10.5 10.6987 10.5 11.2487V12.7487C10.5 13.2987 10.05 13.7487 9.5 13.7487H7.5C6.95 13.7487 6.5 13.2987 6.5 12.7487ZM14.5 21.2488H9.5V18.4987C9.5 17.6687 10.17 16.9987 11 16.9987H13C13.83 16.9987 14.5 17.6687 14.5 18.4987V21.2488ZM17.5 12.7487C17.5 13.2987 17.05 13.7487 16.5 13.7487H14.5C13.95 13.7487 13.5 13.2987 13.5 12.7487V11.2487C13.5 10.6987 13.95 10.2487 14.5 10.2487H16.5C17.05 10.2487 17.5 10.6987 17.5 11.2487V12.7487Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                            </v-text-field>
                            <span class='warningHint' id="companyNameErrorMsg">{{ msg.companyName }}</span>
                            <select-country class="mt-2" v-bind:countryProps="country" id="SelectCountry"
                                name="SelectCountry" @getCountry="getCountryFromSelect"></select-country>
                            <span class='mt-3 warningHint' id="countryErrorMsg">{{ msg.country }}</span>
                            <v-row>
                                <v-col>
                                    <div class="pr-1" variant="plain">
                                        <v-text-field v-model="postalCode" id="postalCode" name="postalCode" maxlength="9"
                                            :label="$t('placeholder.enter', { itm: $t('signUp.postalCode') })"
                                            @update:model-value="postalCode = postalCode.replace(/[^0-9.]/g, '');"
                                            variant="outlined" single-line full-width hide-details required>
                                            <template v-slot:prepend-inner>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9.14844 7.48828C8.58844 7.48828 8.14844 7.93828 8.14844 8.48828C8.14844 9.03828 8.59844 9.48828 9.14844 9.48828C9.69844 9.48828 10.1484 9.03828 10.1484 8.48828C10.1484 7.93828 9.69844 7.48828 9.14844 7.48828Z"
                                                        fill="#C5CDD4" />
                                                    <path
                                                        d="M21.46 5.04C20.62 3.09 18.77 2 16.19 2H7.81C4.6 2 2 4.6 2 7.81V16.19C2 18.77 3.09 20.62 5.04 21.46C5.23 21.54 5.45 21.49 5.59 21.35L21.35 5.59C21.5 5.44 21.55 5.22 21.46 5.04ZM10.53 12.24C10.14 12.62 9.63 12.8 9.12 12.8C8.61 12.8 8.1 12.61 7.71 12.24C6.69 11.28 5.57 9.75 6 7.93C6.38 6.28 7.84 5.54 9.12 5.54C10.4 5.54 11.86 6.28 12.24 7.94C12.66 9.75 11.54 11.28 10.53 12.24Z"
                                                        fill="#C5CDD4" />
                                                    <path
                                                        d="M19.4689 20.5295C19.6889 20.7495 19.6589 21.1095 19.3889 21.2595C18.5089 21.7495 17.4389 21.9995 16.1889 21.9995H7.80892C7.51892 21.9995 7.39892 21.6595 7.59892 21.4595L13.6389 15.4195C13.8389 15.2195 14.1489 15.2195 14.3489 15.4195L19.4689 20.5295Z"
                                                        fill="#C5CDD4" />
                                                    <path
                                                        d="M22.0017 7.80892V16.1889C22.0017 17.4389 21.7517 18.5189 21.2617 19.3889C21.1117 19.6589 20.7517 19.6789 20.5317 19.4689L15.4117 14.3489C15.2117 14.1489 15.2117 13.8389 15.4117 13.6389L21.4517 7.59892C21.6617 7.39892 22.0017 7.51892 22.0017 7.80892Z"
                                                        fill="#C5CDD4" />
                                                </svg>
                                            </template>
                                        </v-text-field>
                                        <span class='warningHint' id="postalCodeErrorMsg">{{ msg.postalCode }}</span>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div class="pl-1" variant="plain">
                                        <v-text-field v-model="city" maxlength="20" id="city" name="city"
                                            :label="$t('placeholder.enter', { itm: $t('signUp.city') })" variant="outlined"
                                            single-line full-width hide-details required>
                                            <template v-slot:prepend-inner>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.3196 3H5.08961C3.09961 3 2.09961 4.01 2.09961 6.02V22H7.49961V18.25C7.49961 17.84 7.83961 17.5 8.24961 17.5C8.65961 17.5 8.99961 17.83 8.99961 18.25V22H14.2996V6.02C14.2996 4.01 13.3096 3 11.3196 3ZM10.7496 12.75H5.79961C5.38961 12.75 5.04961 12.41 5.04961 12C5.04961 11.59 5.38961 11.25 5.79961 11.25H10.7496C11.1596 11.25 11.4996 11.59 11.4996 12C11.4996 12.41 11.1596 12.75 10.7496 12.75ZM10.7496 9H5.79961C5.38961 9 5.04961 8.66 5.04961 8.25C5.04961 7.84 5.38961 7.5 5.79961 7.5H10.7496C11.1596 7.5 11.4996 7.84 11.4996 8.25C11.4996 8.66 11.1596 9 10.7496 9Z"
                                                        fill="#C5CDD4" />
                                                    <path
                                                        d="M23 21.2511H20.73V18.2511C21.68 17.9411 22.37 17.0511 22.37 16.0011V14.0011C22.37 12.6911 21.3 11.6211 19.99 11.6211C18.68 11.6211 17.61 12.6911 17.61 14.0011V16.0011C17.61 17.0411 18.29 17.9211 19.22 18.2411V21.2511H1C0.59 21.2511 0.25 21.5911 0.25 22.0011C0.25 22.4111 0.59 22.7511 1 22.7511H19.93C19.95 22.7511 19.96 22.7611 19.98 22.7611C20 22.7611 20.01 22.7511 20.03 22.7511H23C23.41 22.7511 23.75 22.4111 23.75 22.0011C23.75 21.5911 23.41 21.2511 23 21.2511Z"
                                                        fill="#C5CDD4" />
                                                </svg>
                                            </template>
                                        </v-text-field>
                                        <span class='warningHint' id="cityErrorMsg">{{ msg.city }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="address" id="address" maxlength="50" name="address"
                                :label="$t('placeholder.enter', { itm: $t('signUp.address') })" variant="outlined"
                                single-line full-width hide-details required>
                                <template v-slot:prepend-inner>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20.6211 8.45C19.5711 3.83 15.5411 1.75 12.0011 1.75C12.0011 1.75 12.0011 1.75 11.9911 1.75C8.46107 1.75 4.42107 3.82 3.37107 8.44C2.20107 13.6 5.36107 17.97 8.22107 20.72C9.28107 21.74 10.6411 22.25 12.0011 22.25C13.3611 22.25 14.7211 21.74 15.7711 20.72C18.6311 17.97 21.7911 13.61 20.6211 8.45ZM12.0011 13.46C10.2611 13.46 8.85107 12.05 8.85107 10.31C8.85107 8.57 10.2611 7.16 12.0011 7.16C13.7411 7.16 15.1511 8.57 15.1511 10.31C15.1511 12.05 13.7411 13.46 12.0011 13.46Z"
                                            fill="#C5CDD4" />
                                    </svg>
                                </template>
                            </v-text-field>
                            <span class='warningHint' id="adressErrorMsg">{{ msg.address }}</span>
                            <v-checkbox class="shrink mr-0 mt-0 mb-0 pb-0" v-model="agreeTerm" id="agreeTerm"
                                name="agreeTerm" :label="$t('signUp.agree')" color="#114EF7" hide-details></v-checkbox>
                            <span class="warningHint" id="agreeTermErrorMsg">{{ msg.agreeTerm }}</span>
                        </v-window-item>
                    </v-window>
                    <v-btn block size="x-large" rounded="lg" dark color="#114EF7" text-color="white" v-if="step == 1"
                        @click="validateFormStep1()" id="ContinueBTN" name="ContinueBTN" :disabled="loader">
                        <span v-if="loader" class="buttonText">
                            <v-progress-circular indeterminate :size="30"></v-progress-circular>
                        </span>
                        <span v-else class="buttonText"> {{ $t('signUp.Continue').toUpperCase() }} </span>
                    </v-btn>
                    <v-btn block size="x-large" rounded="lg" dark color="#114EF7" text-color="white" v-if="step == 2"
                        @click="validateFormStep2()" id="signInBTN" name="signInBTN" :disabled="loader">
                        <span v-if="loader" class="buttonText">
                            <v-progress-circular indeterminate :size="30"></v-progress-circular>
                        </span>
                        <span class="buttonText"> {{ $t('signUp.SignUp').toUpperCase() }} </span>
                    </v-btn>
                    <br>
                </v-form>
                <v-row>
                    <v-col align="justify">
                        <span class="linkText">
                            {{ $t('signUp.haveAccount?') }}
                            <router-link class="linkText" style="color:#114EF7; font-weight: bold;" to="/" id="toSignIn"
                                name="toSignIn">
                                {{ $t('signUp.SignIn') }}
                            </router-link>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { defineComponent, ref } from 'vue';
import PasswordMeter from 'vue-simple-password-meter';
import SelectCountry from '../SelectCountry/SelectCountry.vue';
import { ExistUserMail } from "@/api/index";
export default defineComponent({
    name: "SignUp",
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.SignUp')
    },
    components: { PasswordMeter, SelectCountry },
    setup() {
        const password2 = ref("");
        const score = ref(null);
        const strength = ref(null);
        const onScore = (payload) => {
            score.value = payload.score;
            strength.value = payload.strength + " password";
        };
        return {
            password2,
            onScore,
            score,
            strength,
        };
    },
    data: () => ({
        loader: false,
        valid: true,
        ExistMail: null,
        step: 1,
        name: '',
        email: '',
        phoneNumber: null,
        password: null,
        passwordIsNotIdentique: null,
        showPass: false,
        showPass2: false,
        companyName: null,
        postalCode: null,
        country: null,
        city: null,
        address: null,
        agreeTerm: false,
        msg: [],
        RegisterData: {
            user: "",
            company: ""
        },
        userInfo: {
            fullName: "",
            email: "",
            phoneNumber: "",
            password: "",
        },
        CompanyInfo: {
            "brandName": "this.companyName,",
            "companyFullName": "this.companyName,",
            "address": "this.address,",
            "city": "this.city,",
            "postalCode": "this.postalCode,",
            "country": "this.country,",
            "defaultEmail": "this.email",
            "logo": "null",
        }
    }),
    watch: {
        password(value) {
            this.password = value;
            if (this.password2 == "") {
                this.passwordIsNotIdentique = false;
            }
            else if (this.password == this.password2) {
                this.passwordIsNotIdentique = false;
            } else {
                this.msg['passwordIdentique'] = this.$t('signUp.passwordNoMatch');
                this.passwordIsNotIdentique = true;
            }
            this.passwordIsNotIdentique ? this.msg['passwordIdentique'] = this.$t('signUp.passwordNoMatch') : this.msg['passwordIdentique'] = this.strength
        },
        password2(value) {
            this.password2 = value;
            if (this.password2 == "") {
                this.passwordIsNotIdentique = false;
            }
            else if (this.password == this.password2) {
                this.passwordIsNotIdentique = false;
            } else {
                this.msg['passwordIdentique'] = this.$t('signUp.passwordNoMatch');
                this.passwordIsNotIdentique = true;
            }
            this.passwordIsNotIdentique ? this.msg['passwordIdentique'] = this.$t('signUp.passwordNoMatch') : this.msg['passwordIdentique'] = this.strength
            this.userInfo.password = this.password2;
        },
        name(value) {
            this.name = value;
            this.userInfo.fullName = this.name;
        },
        email(value) {
            this.email = value;
            this.userInfo.email = this.email;
            this.ExistMail = null;
        },
        phoneNumber(value) {
            this.phoneNumber = value;
            this.userInfo.phoneNumber = `+${value}`
        },
    },
    methods: {
        getCountryFromSelect(value) {
            this.country = value;
        },
        validateFormStep1() {
            this.loader = true;
            if (!(this.name) || (this.name).length == 0) {
                this.msg['name'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.fullName') });
            }
            else {
                this.name = (this.name.replace(/\s+/g, ' ').trim())
                // eslint-disable-next-line
                if (!(/^[a-z][a-z\s]*$/i.test(this.name))) {
                    this.msg['name'] = this.$t('warningInput.wrong', { itm: this.$t('signUp.fullName') });
                }
                else if (!(this.name.split(/\W+/).length >= 2)) {
                    this.msg['name'] = this.$t('warningInput.wrong', { itm: this.$t('signUp.fullName') });
                }
                else if (!(this.name.split(' ')[1].length >= 2)) {
                    this.msg['name'] = this.$t('warningInput.wrong', { itm: this.$t('signUp.fullName') });
                }
                else {
                    this.msg['name'] = '';
                }
            }

            if (!(this.email) || (this.email).length == 0) {
                this.msg['email'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.email') });
            }
            // eslint-disable-next-line
            else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.msg['email'] = '';
            }
            else {
                this.msg['email'] = this.$t('warningInput.wrong', { itm: this.$t('signUp.email') });
            }

            if (!(this.phoneNumber) || (this.phoneNumber).length == 0) {
                this.msg['phoneNumber'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.phoneNumber') });
            } else if (((this.phoneNumber).length < 8) || ((this.phoneNumber).length > 15)) {
                this.msg['phoneNumber'] = this.$t('warningInput.wrong', { itm: this.$t('signUp.phoneNumber') });
            } // eslint-disable-next-line
            else if (this.phoneNumber.match(/^[\+]?[0-9]{8,15}$/im)) {
                this.msg['phoneNumber'] = '';
            } else {
                this.msg['phoneNumber'] = this.$t('warningInput.wrong', { itm: this.$t('signUp.phoneNumber') });
            }

            if (!(this.password) || ((this.password).length == 0) || !(this.password2) || ((this.password2).length == 0)) {
                this.msg['passwordIdentique'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.password') });
                this.passwordIsNotIdentique = true;
            }
            else if (this.password == this.password2) {
                this.msg['passwordIdentique'] = '';
                this.passwordIsNotIdentique = false;
            }
            else {
                this.msg['passwordIdentique'] = this.$t('signUp.passwordNoMatch');
                this.passwordIsNotIdentique = true;
            }

            if (this.msg['email'] == '') {
                ExistUserMail(this.email)
                    .then((res) => {
                        this.ExistMail = res.data
                        if (this.ExistMail) {
                            this.msg['email'] = this.$t('warningInput.exist', { itm: this.$t('signUp.email') });
                        }
                        if (
                            this.ExistMail == false
                            && this.msg['email'] == ''
                            && this.msg['name'] == ''
                            && this.msg['phoneNumber'] == ''
                            && !this.passwordIsNotIdentique
                            && this.score >= 3
                        ) {
                            this.step = 2;
                        }
                        this.loader = false;
                    })
            } else {
                this.loader = false;
            }
        },
        async validateFormStep2() {
            this.loader = true;
            if (!(this.companyName) || (this.companyName).length == 0) {
                this.msg['companyName'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.companyName') });
            }
            else {
                this.msg['companyName'] = '';
            }

            if (this.country == null || this.country == "") {
                this.msg['country'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.country') });
            } else {
                this.msg['country'] = '';
            }

            if (!(this.postalCode) || (this.postalCode).length == 0) {
                this.msg['postalCode'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.postalCode') });
            }
            else {
                this.msg['postalCode'] = '';
            }

            if (!(this.address) || (this.address).length == 0) {
                this.msg['address'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.address') });
            }
            else {
                this.msg['address'] = '';
            }

            if (!(this.city) || (this.city).length == 0) {
                this.msg['city'] = this.$t('warningInput.isrequired', { itm: this.$t('signUp.city') });
            }
            else {
                this.msg['city'] = '';
            }

            if (!(this.agreeTerm)) {
                this.msg['agreeTerm'] = this.$t('signUp.mustAgree');
            } else {
                this.msg['agreeTerm'] = '';
            }

            if ((this.agreeTerm == true) && this.msg['companyName'] == '' && this.msg['country'] == '' && this.msg['postalCode'] == '' && this.msg['city'] == '' && this.msg['address'] == '') {
                this.CompanyInfo.brandName = this.companyName,
                    this.CompanyInfo.companyFullName = this.companyName,
                    this.CompanyInfo.address = this.address,
                    this.CompanyInfo.city = this.city,
                    this.CompanyInfo.postalCode = this.postalCode,
                    this.CompanyInfo.country = this.country,
                    this.CompanyInfo.defaultEmail = this.email,
                    this.CompanyInfo.logo = "null",
                    this.RegisterData.user = this.userInfo
                this.RegisterData.company = this.CompanyInfo
                try {
                    await this.$store.dispatch('signup', this.RegisterData);
                    this.$router.push({ name: 'VerifyEmail', params: { email: this.email } })
                } catch (e) {
                    this.msg['SignUp'] = ' ' + e;
                } finally {
                    this.loader = false;
                }
            } else { this.loader = false; }
        },
    },
    computed: {
        strengthTranslate() {
            switch (this.strength) {
                case 'risky password': return this.$t('signUp.passwordStrength.risky')
                case 'guessable password': return this.$t('signUp.passwordStrength.guessable')
                case 'weak password': return this.$t('signUp.passwordStrength.weak')
                case 'safe password': return this.$t('signUp.passwordStrength.safe')
                case 'secure password': return this.$t('signUp.passwordStrength.secure')
                default: return this.$t('signUp.passwordStrength.risky')
            }
        },
        currentTitle() {
            switch (this.step) {
                case 1: return this.$t('signUp.personalInformation')
                case 2: return this.$t('signUp.companyInformation')
                default: return this.$t('signUp.accountCreated')
            }
        },
        Color() {
            switch (this.score) {
                case 0: return '#f95e68'
                case 1: return '#fb964d'
                case 2: return '#fdd244'
                case 3: return '#b0dc53'
                case 4: return '#35cc62'
                default: return '#F3F3F3'
            }
        },
    },
})
</script>
<style scoped>
.title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #080C3E;
}

.stepText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}
</style>