<template>
    <v-dialog v-model="dialogAddTaxe">
        <v-card width="800px" class="py-6">
            <h1>{{ $t('configuration.tax.add') }}</h1>
            <div class="row ma-4">
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.tax.taxName') }} <span class="red">*</span></v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="TaxName" id="TaxName" name="TaxName" maxlength="30" hide-details
                            persistent-placeholder
                            :placeholder="$t('placeholder.enter', { itm: $t('configuration.tax.taxName') })"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgTaxName"> {{ msg.TaxName }}</span>
                    </div>
                </div>
                <div class="col-6 justify-content-start">
                    <div class="row">
                        <v-card-title class="text-left ma-0">{{ $t('configuration.tax.taxPercentage') }} <span class="red">*</span>
                            </v-card-title>
                    </div>
                    <div class="row">
                        <v-text-field v-model="TaxPercentage" maxlength="9"
                            @update:model-value="TaxPercentage = TaxPercentage.replace(/[^0-9.]/g, '');" id="TaxPercentage"
                            name="TaxPercentage" hide-details persistent-placeholder
                            :placeholder="$t('placeholder.enter', { itm: $t('configuration.tax.taxPercentage') })"
                            variant="outlined"></v-text-field>
                        <span class='warningHint' id="WarningMsgTaxPercentage"> {{ msg.TaxPercentage }}</span>
                    </div>
                </div>
            </div>
            <card-actions :yes="AddTaxe" v-model:no="dialogAddTaxe" width="300px" :yesTitle="$t('configuration.tax.add')" />
        </v-card>
    </v-dialog>
</template>
<script>
import { AddTaxe } from "@/api/index";
import cardActions from "@/genericComponent/cardActions.vue"
export default {
    components: { cardActions },
    data() {
        return {
            dialogAddTaxe: true,
            TaxName: null,
            TaxPercentage: null,
            msg: [],
        };
    },
    watch: {
        dialogAddTaxe(value) {
            this.dialogAddTaxe = value
            this.$emit('add-taxes-close-dialog')
            this.dialogAddTaxe = true
            this.TaxName = null
            this.TaxPercentage = null
            this.msg = []
        }
    },
    methods: {
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
        AddTaxe() {
            if (!this.TaxName || this.TaxName.length == 0) {
                this.msg["TaxName"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.tax.taxName') });
            } else {
                this.msg["TaxName"] = "";
            }

            if (!this.TaxPercentage || this.TaxPercentage.length == 0) {
                this.msg["TaxPercentage"] = this.$t('warningInput.isrequired', { itm: this.$t('configuration.tax.taxPercentage') });
            } else {
                this.msg["TaxPercentage"] = "";
            }

            if (
                this.msg["TaxName"] == "" &&
                this.msg["TaxPercentage"] == ""
            ) {
                this.$emit('add-taxes-close-dialog');
                AddTaxe(this.TaxName, this.TaxPercentage, this.$route.params.id).then(() => {
                    this.ActiveSavedSnackBar();
                    this.$emit("reloadListTaxes");
                }).catch(() => {
                    this.ActiveWarningSnackBar();
                });
                setTimeout(() => {
                    this.TaxName = null;
                    this.TaxPercentage = null;
                }, 2000);
            }
        }
    }
};
</script>
<style scoped>
.DeleteSup {
    font-family: inter;
    font-size: 20px;
    line-height: 15px;
    font-weight: 600;
    color: #0d0849;
    margin-left: 20px;
    margin-top: -10px;
    cursor: pointer;
}

h1 {
    font-family: inter;
    font-size: 18px;
    line-height: 29px;
    font-weight: 600;
    color: #0d0849;
    text-align: center;
}

.v-card-title {
    font-family: inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0d0849;
    margin-left: 40px;
    margin-top: -30px;
}

.v-card-text {
    margin-left: 40px;
    font-family: inter;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #768da9;
}

.v-card-title {
    font-family: inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #5b6d8e;
    margin-left: 12px;
    margin-bottom: 5px;
}
</style>