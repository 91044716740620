import { createRouter, createWebHistory } from "vue-router";
import AuthTemplate from "@/components/authentication/AuthTemplate";
import LoginAccount from "@/components/authentication/LoginAccount";
import ForgotPassword from "@/components/authentication/ForgotPassword";
import ResetPassword from "@/components/authentication/ResetPassword";
import DashboardLayout from "@/components/dashboard/DashboardLayout";
import MyDashboard from "@/components/dashboard/MyDashboard";
import companyView from "@/components/companyList/companyView";
import NewCompany from "@/components/company/NewCompany";
import SignUp from "@/components/signUp/SignUp";
import VerifyEmail from "@/components/signUp/VerifyEmail";

import ActivateAccount from "@/components/ActivateAccount/ActivateAccount"

import SuppliersView from "@/components/suppliers/SuppliersView";
import NewSupplier from "@/components/suppliers/SuppliersNew/NewSupplier";
import SupplierEdit from "@/components/suppliers/SuppliersEdit/SupplierEdit";
import SupplieView from "@/components/suppliers/SuppliersEdit/SupplierEdit";

import SupplierClient from "@/components/SupplierClient/SupplierClient";

import RequestForProposal from "@/components/RequestForProposal/RequestForProposalMenu";
import NewRequestForProposal from "@/components/RequestForProposal/NewRequestForProposal";

import PurchaseRequest from "@/components/PurchaseRequest/PurchaseRequestMenu";
import NewPurchaseRequest from "@/components/PurchaseRequest/NewPurchaseRequest";

import PurchaseOrder from "@/components/PurchaseOrder/PurchaseOrderMenu";
import NewPurchaseOrder from "@/components/PurchaseOrder/NewPurchaseOrder"

import Invoices from "@/components/Invoices/InvoicesMenu";
import NewInvoice from "@/components/Invoices/NewInvoice"

import Permissions from "@/components/Permissions/PermissionsView"
import NewRole from "@/components/Permissions/NewRole"
import RoleEdit from "@/components/Permissions/EditRole/EditRoleMenu"

const routes = [
    {
        path: "/",
        name: "Authentification",
        component: AuthTemplate,
        children: [
            {
                path: "/",
                name: "login",
                component: LoginAccount,
            },
            {
                path: "/ForgotPassword",
                name: "ForgotPassword",
                component: ForgotPassword,
            },
            {
                path: "/ResetPassword",
                name: "ResetPassword",
                component: ResetPassword,
            },
            {
                path: "/SignUp",
                name: "SignUp",
                component: SignUp,
            },
            {
                path: "/VerifyEmail/:email",
                name: "VerifyEmail",
                component: VerifyEmail,
            },
            {
                path: "/ActivateAccount",
                name: "ActivateAccount",
                component: ActivateAccount,
            },
        ],
    },
    {
        path: "/",
        name: "DashboardLayout",
        component: DashboardLayout,
        meta: {
            auth: true,
        },
        children: [
            {
                path: "/MyDashboard",
                name: "MyDashboard",
                component: MyDashboard,
            },
            {
                path: "/NewCompany",
                name: "NewCompany",
                component: NewCompany,
            },
            {
                path: "/Configuration",
                name: "Configuration",
                component: companyView,
            },
            {
                path: "/suppliers",
                name: "Suppliers",
                component: SuppliersView,
            },
            {
                path: "/supplier",
                name: "Supplier",
                component: SupplieView,
            },
            {
                path: "/suppliersadd",
                name: "NewSupplier",
                component: NewSupplier,
            },
            {
                path: "/suppliersEdit/:id",
                name: "SuppliersEdit",
                component: SupplierEdit,
            },
            {
                path: "/RequestForProposal",
                name: "RequestForProposal",
                component: RequestForProposal,
            },
            {
                path: "/NewRequestForProposal",
                name: "NewRequestForProposal",
                component: NewRequestForProposal,
            },
            {
                path: "/PurchaseOrder",
                name: "PurchaseOrder",
                component: PurchaseOrder,
            },
            {
                path: "/NewPurchaseOrder",
                name: "NewPurchaseOrder",
                component: NewPurchaseOrder,
            },
            {
                path: "/PurchaseRequest",
                name: "PurchaseRequest",
                component: PurchaseRequest,
            },
            {
                path: "/NewPurchaseRequest",
                name: "NewPurchaseRequest",
                component: NewPurchaseRequest,
            },
            {
                path: "/Invoices",
                name: "Invoices",
                component: Invoices,
            },
            {
                path: "/NewInvoice",
                name: "NewInvoice",
                component: NewInvoice,
            },
            {
                path: "/Permissions",
                name: "Permissions",
                component: Permissions,
            },
            {
                path: "/NewRole",
                name: "NewRole",
                component: NewRole,
            },
            {
                path: "/RoleEdit/:id",
                name: "RoleEdit",
                component: RoleEdit,
            },
            {
                path: "/Client",
                name: "Client",
                component: SupplierClient,
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    if (
        (!window.VUE_APP_ENABLE_CREATE_ACCOUNT && to.name === "SignUp")
    ) {
        next({ name: "login" });
    }

    const loggedIn = localStorage.getItem("accessToken");
    if (
        (loggedIn && to.name === "login") ||
        (loggedIn && to.name === "ForgotPassword") ||
        (loggedIn && to.name === "ResetPassword") ||
        (loggedIn && to.name === "SignUp") ||
        (loggedIn && to.name === "VerifyEmail")
    ) {
        next({ name: "PurchaseOrder" });
    }
    if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
        next({ name: "login" });
        return;
    }
    next();
});
export default router;