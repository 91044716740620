<template>
    <v-autocomplete id="SelectCountry" name="SelectCountry" variant="outlined"
        :placeholder="$t('placeholder.select', { itm: $t('configuration.company.country') })" persistent-placeholder
        v-model="country" v-model:search="countrysearch" :items="itemsCountry" item-title="name" item-value="name"
        hide-details single-line clearable>
        <template v-if="country" v-slot:prepend-inner="{}">
            <img :src="getFlag(getAlpha3(country))" class="country-flag" />
        </template>
        <template v-slot:item="{ props, item, index }">
            <v-list-item v-bind="props" variant="text" title="" :id="index" :name="item.title">
                <img :src="getFlag(item.raw.alpha3)" class="country-flag mr-2" /> {{ item.title }}
            </v-list-item>
        </template>
    </v-autocomplete>
</template>
<script>
import json from "@/data/country.json";
export default {
    props: ["countryProps"],
    data() {
        return {
            itemsCountry: json,
            country: this.countryProps,
            countrysearch: null,
        }
    },
    created() {
        if (this.country == "Select country") {
            this.country = { name: "Select country" }
            this.$emit("getCountry", "Select country");
        }
    },
    methods: {
        getAlpha3(country) {
            var results;
            results = this.itemsCountry.filter(function (entry) {
                return entry.name.indexOf(country) !== -1;
            });
            if (results[0]) {
                return results[0].alpha3;
            } else {
                return "undefined"
            }
        },
        getFlag(flagName) {
            try {
                return require(`@/assets/flags/${flagName.toLowerCase()}.svg`)
            } catch (e) {
                return require('@/assets/flags/undefined.svg')
            }
        },
    },
    watch: {
        country(value) {
            this.country = value;
            this.$emit("getCountry", this.country);
        }
    }
}
</script>