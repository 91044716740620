<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('routes.requestsForProposal') }}
                </v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :ClickFun="navigateToNewRequestForProposal" svg="2"
                    :Title="$t('requestForProposal.create')" />
            </div>
        </div>
        <div>
            <RequestForProposalList />
        </div>
    </div>
</template>
<script>
import RequestForProposalList from "../RequestForProposal/RequestForProposalList.vue";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
export default {
    components: {
        RequestForProposalList,
        genericBtnComponent
    },
    mounted() {
        document.title = window.VUE_APP_NAME + ' - ' + this.$t('Document.Title.requestsForProposal');
    },
    methods: {
        navigateToNewRequestForProposal() {
            this.$router.push({ path: '/NewRequestForProposal' });
        },
    }
};
</script>
<style scoped>
.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
</style>
