<template>
    <div>
        <div class="row mb-8">
            <div class="col-6 d-flex justify-content-start">
                <v-card-title>
                    {{ $t('permissions.AddNewRole') }}
                </v-card-title>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <genericBtnComponent :ClickFun="SaveAndContinue" svg="2" :Title="$t('permissions.SaveContinue')" />
            </div>
        </div>
        <v-card elevation="0">
            <v-tabs v-model="tab" grow touchless style="pointer-events: none">
                <v-tab class="slider" value="1" centered v-model="role"
                    :style="[parseInt(tab) > 1 ? Active : [parseInt(tab) == 1 ? 'color:blue' : NotActive]]">
                    <v-icon v-if="tab > '1'">mdi-check-circle</v-icon>
                    {{ $t('permissions.Roles') }}
                </v-tab>
                <v-tab class="slider" value="2" v-model="Permissions"
                    :style="[parseInt(tab) > 2 ? Active : [parseInt(tab) == 2 ? 'color:blue' : NotActive]]">
                    <v-icon v-if="tab > '2'">mdi-check-circle</v-icon>
                    {{ $t('permissions.Permissions') }}
                </v-tab>
                <v-tab class="slider" value="3" v-model="Users"
                    :style="[parseInt(tab) > 3 ? Active : [parseInt(tab) == 3 ? 'color:blue' : NotActive]]">
                    <v-icon v-if="tab > '3'">mdi-check-circle</v-icon>
                    {{ $t('permissions.Users') }}
                </v-tab>
                <v-tab class="slider" value="0">
                </v-tab>
                <v-tab class="slider" value="0">
                </v-tab>
            </v-tabs>
            <v-divider class="ma-0"></v-divider>
            <v-window v-model="tab" :key="tab">
                <v-window-item value="1">
                    <AddRole @getData="getDataFromRoles" ref="RoleRef" />
                </v-window-item>
                <v-window-item value="2">
                    <AddPermissions :RoleProps="RoleAfterPost" @getData="getDataFromPermissions" ref="PermissionsRef" />
                </v-window-item>
                <v-window-item value="3">
                    <AddUsers :RoleProps="RoleAfterPost" @getData="getDataFromUsers" ref="UsersRef" />
                </v-window-item>
            </v-window>
        </v-card>
    </div>
</template>
<script>
import { addRole, getRoleByName, addMultiPermissions, addMultiUsers } from "@/api";
import genericBtnComponent from "@/genericComponent/genericBtnComponent.vue"
import AddPermissions from "./NewRole/AddPermissions.vue";
import AddRole from "./NewRole/AddRole.vue";
import AddUsers from "./NewRole/AddUsers.vue";
export default {
    components: { genericBtnComponent, AddRole, AddPermissions, AddUsers },
    mounted() {
        document.title = window.VUE_APP_NAME +' - ' + this.$t('Document.Title.permissions');
    },
    data() {
        return {
            tab: "1",
            NotActive: {
                color: "#667280",
                fontSize: "14px",
                font: "inter",
            },
            Active: {
                color: "#00CBA0",
                fontSize: "14px",
                font: "inter",
            },
            RoleId: null,
            dataFromRoles: {},
            dataFromPermissions: null,
            dataFromUsers: null,
            RoleAfterPost: null,
        };
    },
    methods: {
        SaveAndContinue() {
            this.buttonLoader = true;
            if (this.tab == "1") {
                this.PostRole();
            } else if (this.tab == "2") {
                this.PostPermissions();
            } else if (this.tab == "3") {
                this.PostUsers();
            }
        },
        getRoleByName(name) {
            getRoleByName(name).then((response) => {
                this.RoleAfterPost = response.data.items[0]
                this.RoleId = response.data.items[0].id
            })
        },
        getDataFromRoles(value) {
            this.dataFromRoles = value;
        },
        PostRole: function () {
            if (this.$refs.RoleRef.validateForm()) {
                addRole(this.dataFromRoles).then((response) => {
                    if (response.status == 200) {
                        this.ActiveSavedSnackBar();
                        getRoleByName(this.dataFromRoles.Name).then((response) => {
                            this.RoleAfterPost = response.data.items[0]
                            this.RoleId = response.data.items[0].id
                        }).finally(() => {
                            this.tab = "2"
                        })
                    }
                })
                    .catch((e) => {
                        if (e.response.data.errors[0].code == "Authentication.DuplicateName") {
                            this.$refs.RoleRef.msg["Name"] = this.$t('warningInput.exist', { itm: this.$t('permissions.RoleName') })
                        } else {
                            this.ActiveWarningSnackBar();
                        }
                    }).finally(() => {
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        getDataFromPermissions(value) {
            this.dataFromPermissions = value;
        },
        PostPermissions: function () {
            if (this.$refs.PermissionsRef.validateForm()) {
                this.buttonLoader = true
                addMultiPermissions(this.RoleId, this.dataFromPermissions)
                    .then((response) => {
                        if (response.status == 200) {
                            this.ActiveSavedSnackBar();
                            this.tab = "3";
                        }
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        getDataFromUsers(value) {
            this.dataFromUsers = value;
        },
        PostUsers: function () {
            if (this.$refs.UsersRef.validateForm()) {
                this.buttonLoader = true
                addMultiUsers(this.RoleId, this.dataFromUsers)
                    .then((response) => {
                        if (response.status == 200) {
                            this.ActiveSavedSnackBar();
                            this.$router.push({ path: '/Permissions' });
                        }
                    })
                    .catch(() => {
                        this.ActiveWarningSnackBar();
                    }).finally(() => {
                        this.buttonLoader = false;
                    });
            }
            else {
                this.buttonLoader = false;
            }
        },
        ActiveDeletedSnackBar() {
            this.getParent('MainLayout').ActiveDeletedSnackBar();
        },
        ActiveWarningSnackBar() {
            this.getParent('MainLayout').ActiveWarningSnackBar();
        },
        ActiveSavedSnackBar() {
            this.getParent('MainLayout').ActiveSavedSnackBar();
        },
        getParent(name) {
            let p = this.$parent;
            while (typeof p !== 'undefined') {
                if (p.$options.name == name) {
                    return p;
                } else {
                    p = p.$parent;
                }
            }
            return false;
        },
    },
};
</script>
<style scoped>
.v-card-title {
    font-size: 14px;
    font-family: inter;
    font-weight: 600;
}

.v-card-title {
    color: #001e50;
    font-size: 18px;
    line-height: 25.5px;
    font-weight: 700;
    font-family: inter;
}
.bg-basil {
    background-color: #ffffff !important;
}

.text-basil {
    color: #114ef7 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: inter;
}

.btn {
    display: flex;
}

.Save {
    color: #fff;
    height: 45px;
    font-family: inter;
    font-weight: 600;
    display: inline-flex;
}

.icon {
    width: 10px;
    height: 10px;
}

.iconSave {
    width: 20px;
    height: 15px;
    margin-right: 5px;
}

.svg {
    margin-right: 8px;
    margin-top: 4px;
}

.slider {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    flex: content;
    border-bottom: "2px solid black";
}
</style>