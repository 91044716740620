<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers" :ColonneWidth="colonneWidth"
        :GetFunction="PurchaseOrderList">
        <template v-slot:[SlotsFilters.Filter0]>
            <ColumnFilter :title="$t('purchaseOrder.reference')" :Filter="FieldReference" :getList="PurchaseOrderList"
                @cancel="FieldReference = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldReference" id="FieldReference" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter1]>
            <ColumnFilter :title="$t('purchaseOrder.title')" :Filter="FieldName" :getList="PurchaseOrderList"
                @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter2]>
            <ColumnFilter :title="$t('purchaseOrder.company')" :Filter="FieldCompany" :getList="PurchaseOrderList"
                @cancel="FieldCompany = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldCompany" id="FieldCompany" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter3]>
            <ColumnFilter :title="$t('purchaseOrder.supplier')" :Filter="FieldSupplier" :getList="PurchaseOrderList"
                @cancel="FieldSupplier = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldSupplier" id="FieldSupplier" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter4]>
            <ColumnFilter :title="$t('purchaseOrder.consumption')" :Filter="FieldConsumption" :getList="PurchaseOrderList"
                @cancel="FieldConsumption = null, selected = null">
                <template v-slot:input>
                    <v-select :items="selectitems" item-title="name" item-value="value" v-model="selected"
                        :placeholder="$t('ColumnFilter.signsOfComparisons')" variant="outlined"></v-select>
                    <v-text-field v-model="FieldConsumption" id="FieldConsumption" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter5]>
            <ColumnFilter :title="$t('purchaseOrder.expirationDate')" :Filter="FieldExpirationDate" :getList="PurchaseOrderList"
                @cancel="FieldExpirationDate = null">
                <template v-slot:input>
                    <span lang="en-CA" xml:lang="en-CA">
                        <dateField v-model="FieldExpirationDate" />
                    </span>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter6]>
            <ColumnFilter :title="$t('purchaseOrder.status')" :Filter="FieldStatus" :getList="PurchaseOrderList"
                @cancel="FieldStatus = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldStatus" inline hide-details>
                        <v-radio :label="isInitialized" value=0 id="initialized" name="initialized"></v-radio>
                        <v-radio :label="isActive" value=1 id="Active" name="Active"></v-radio>
                        <v-radio :label="isRefused" value=2 id="Refused" name="Refused"></v-radio>
                        <v-radio :label="isCompleted" value=3 id="Completed" name="Completed"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsFilters.Filter7]>
            <ColumnFilter :title="$t('purchaseOrder.creationDate')" :Filter="FieldCreatedDate" :getList="PurchaseOrderList"
                @cancel="FieldCreatedDate = null">
                <template v-slot:input>
                    <span lang="en-CA" xml:lang="en-CA">
                        <dateField v-model="FieldCreatedDate" />
                    </span>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:[SlotsItems.item0]="{ item }">{{ item.reference }}</template>
        <template v-slot:[SlotsItems.item1]="{ item }">{{ item.title }}</template>
        <template v-slot:[SlotsItems.item2]="{ item }">
            <span v-if="item.company">{{ item.company.brandName }}</span>
            <v-chip v-else color="red">
                {{ $t('purchaseOrder.delete') }}
            </v-chip>
        </template>
        <template v-slot:[SlotsItems.item3]="{ item }">
            <span v-if="item.supplier">{{ item.supplier.brandName }}</span>
            <v-chip v-else color="red">
                {{ $t('purchaseOrder.delete') }}
            </v-chip>
        </template>
        <template v-slot:[SlotsItems.item4]="{ item }">
            <span style="float:left;">{{ item.consumption }} {{ item.devise }}</span>
            <span style="float:right;">{{AmountTTC(item)}} {{ item.devise }}</span>
            <v-progress-linear rounded rounded-bar :color="GetProgressColor((item.consumption / AmountTTC(item)) * 100)"
                :model-value="(item.consumption / AmountTTC(item)) * 100" height="7">
            </v-progress-linear>
        </template>
        <template v-slot:[SlotsItems.item5]="{ item }">{{ item.expirationDate.split('T')[0] }}</template>
        <template v-slot:[SlotsItems.item6]="{ item }">
            <StatusButton v-if="item" :title="statut(item)" />
        </template>
        <template v-slot:[SlotsItems.item7]="{ item }">{{ item.created.split('T')[0] }}</template>
        <template v-slot:edit="{ item, index }">
            <EditOrderConfiguration :Id="index" v-bind:idPurchaseOrder="item.idPurchaseOrder" v-bind:getPurchaseOrder="item"
                v-bind:getPurchaseOrderListAfterDelete="PurchaseOrderList" v-bind:SupplierList="SupplierList" />
        </template>
    </gridComponent>
</template>
<script>
import EditOrderConfiguration from "./EditOrderConfiguration.vue";
import StatusButton from "@/genericComponent/StatusButton.vue";
import gridComponent from "@/genericComponent/gridComponent.vue";
import { GetAllPurchaseOrders, GetCompanyFromPurchaseOrders, CheckPermissionByUser, isClient, isSupplier, GetStatusPurchaseOrders } from "@/api/index";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: { EditOrderConfiguration, StatusButton, gridComponent, ColumnFilter, dateField },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            search: null,
            items: {},
            itemsData: {},
            SupplierList: [],
            FieldReference: null,
            FieldName: null,
            FieldCompany: null,
            FieldSupplier: null,
            FieldStatus: null,
            FieldConsumption: null,
            FieldCreatedDate: null,
            FieldExpirationDate: null,
            SortedBy: null,
            SortingType: null,
            IsEqual: false,
            IsGreater: false,
            IsLess: false,
            selected: null,
            HasPermissionValidation: false,
            isClientVar: this.isClient(),
            isSupplierVar: this.isSupplier(),
        };
    },
    created() {
        this.GetStatusPurchaseOrders();
    },
    mounted() {
        this.GetCompanyList();
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.PurchaseOrderList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        GetCompanyList() {
            GetCompanyFromPurchaseOrders("")
                .then((res) => {
                    this.SupplierList = res.data;
                })
        },
        GetProgressColor(A) {
            switch (true) {
                case A === 100:
                    return "#F25C5C";
                case A >= 50:
                    return "#FF8934";
                case A >= 10:
                    return "#3BBAFF";
                default:
                    return "#00CBA0";
            }
        },
        PurchaseOrderList: function (page) {
            if(page){ this.page = page }
            switch (this.selected) {
                case 'Is equal': this.IsEqual = true; this.IsGreater = false; this.IsLess = false; break;
                case 'Is greater then': this.IsEqual = false; this.IsGreater = true; this.IsLess = false; break;
                case 'Is less then': this.IsEqual = false; this.IsGreater = false; this.IsLess = true; break;
                default: this.IsEqual = false; this.IsGreater = false; this.IsLess = false; break;
            }
            CheckPermissionByUser("Optima.PurchaseOrder.Validate").then((response) => {
                this.HasPermissionValidation = response.data
            }).finally(() => {
                GetAllPurchaseOrders(this.page, this.PageSize, this.FieldReference, this.FieldName == null ? this.search : this.FieldName, this.FieldCompany, this.FieldSupplier, this.FieldStatus, this.FieldConsumption, this.FieldExpirationDate, this.FieldCreatedDate, this.SortedBy, this.SortingType, this.IsEqual, this.IsGreater, this.IsLess, this.HasPermissionValidation).then((response) => {
                    this.items = response.data.items;
                    this.itemsData.numberOfPage = response.data.totalPages;
                    this.itemsData.totalItemsCount = response.data.totalCount;
                    this.itemsData.pageNumber = response.data.pageNumber;
                    this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                    this.itemsData.hasNextPage = response.data.hasNextPage
                    this.loader = false;
                });
            })
        },
        isClient() {
            return isClient()
        },
        isSupplier() {
            return isSupplier()
        },
        GetStatusPurchaseOrders() {
            GetStatusPurchaseOrders().then(response => {
                localStorage.setItem(status.Initialized + this.$i18n.availableLocales[0], response.data.Initialized.en);
                localStorage.setItem(status.Initialized + this.$i18n.availableLocales[1], response.data.Initialized.fr);
                localStorage.setItem(status.Active + this.$i18n.availableLocales[0], response.data.Active.en);
                localStorage.setItem(status.Active + this.$i18n.availableLocales[1], response.data.Active.fr);
                localStorage.setItem(status.Refused + this.$i18n.availableLocales[0], response.data.Refused.en);
                localStorage.setItem(status.Refused + this.$i18n.availableLocales[1], response.data.Refused.fr);
                localStorage.setItem(status.Completed + this.$i18n.availableLocales[0], response.data.Completed.en);
                localStorage.setItem(status.Completed + this.$i18n.availableLocales[1], response.data.Completed.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => {
                switch (item.status) {
                    case 0:
                        return localStorage.getItem(status.Initialized + this.$i18n.locale);
                    case 1:
                        return localStorage.getItem(status.Active + this.$i18n.locale);
                    case 2:
                        return localStorage.getItem(status.Refused + this.$i18n.locale);
                    default:
                        return localStorage.getItem(status.Completed + this.$i18n.locale);
                }
            };
        },
        headers() {
            const headers = [
                this.$t('purchaseOrder.reference'),
                this.$t('purchaseOrder.title'),
                this.$t('purchaseOrder.company'),
                this.$t('purchaseOrder.supplier'),
                this.$t('purchaseOrder.consumption'),
                this.$t('purchaseOrder.expirationDate'),
                this.$t('purchaseOrder.status'),
                this.$t('purchaseOrder.creationDate'),
            ];

            const isClient = this.isClientVar;
            const isSupplier = this.isSupplierVar;

            if (isClient && !isSupplier) {
                return headers.slice(0, 2).concat(headers.slice(3));
            }
            if (isSupplier && !isClient) {
                return headers.slice(0, 3).concat(headers.slice(4));
            }

            return headers;
        },
        SlotsItems() {
            const items = {
                item0: 'item',
                item1: 'item',
                item2: 'item',
                item3: 'item',
                item4: 'item',
                item5: 'item',
                item6: 'item',
                item7: 'item',
            }
            if (this.isSupplierVar && !this.isClientVar) {
                delete items.item3;
            }
            if (this.isClientVar && !this.isSupplierVar) {
                delete items.item2;
            }
            let i = 0;
            for (const key in items) {
                items[key] = 'item' + i
                i = i + 1;
            }
            return items;
        },
        SlotsFilters() {
            const filters = {
                Filter0: 'Filter',
                Filter1: 'Filter',
                Filter2: 'Filter',
                Filter3: 'Filter',
                Filter4: 'Filter',
                Filter5: 'Filter',
                Filter6: 'Filter',
                Filter7: 'Filter',
            }
            if (this.isSupplierVar && !this.isClientVar) {
                delete filters.Filter3;
            }
            if (this.isClientVar && !this.isSupplierVar) {
                delete filters.Filter2;
            }
            let i = 0;
            for (const key in filters) {
                filters[key] = 'Filter' + i
                i = i + 1;
            }
            return filters;
        },
        colonneWidth() {
            const headers = ['10%', '20%', '15%', '15%', '20%', '5%', '5%', '5%'];
            const isClient = this.isClientVar;
            const isSupplier = this.isSupplierVar;

            if (isClient && !isSupplier) {
                return headers.slice(0, 2).concat(headers.slice(3));
            }
            if (isSupplier && !isClient) {
                return headers.slice(0, 3).concat(headers.slice(4));
            }

            return headers;
        },
        selectitems() {
            return [
                { 'name': this.$t('ColumnFilter.=='), 'value': 'Is equal' },
                { 'name': this.$t('ColumnFilter.>'), 'value': 'Is greater then' },
                { 'name': this.$t('ColumnFilter.<'), 'value': 'Is less then' }
            ]
        },
        isInitialized() {
            return localStorage.getItem(status.Initialized + this.$i18n.locale);
        },
        isActive() {
            return localStorage.getItem(status.Active + this.$i18n.locale);
        },
        isRefused() {
            return localStorage.getItem(status.Refused + this.$i18n.locale);
        },
        isCompleted() {
            return localStorage.getItem(status.Completed + this.$i18n.locale);
        },
        AmountTTC(){
            return item => {
                const amountTTC = parseFloat(item.quantity) * ((item.threshold * (1 + (item.taxe / 100))))
                let parts = amountTTC.toString().split('.');
                if (parts[1] && parts[1].length>3) {
                    return amountTTC.toFixed(3).toString().replace(/\.?0+$/, '');
                } else {
                    return amountTTC;
                }
            }
        },
    }
};
</script>