<template>
    <gridComponent :loader="loader" :items="items" :itemsData="itemsData" :headers="headers"
        :ColonneWidth="['18.5%', '11.7%', '14.6%', '11.7%', '11.7%']" :GetFunction="RequestForProposalList">
        <template v-slot:Filter0>
            <ColumnFilter :title="$t('requestForProposal.title')" :Filter="FieldName" :getList="RequestForProposalList"
                @cancel="FieldName = null">
                <template v-slot:input>
                    <v-text-field v-model="FieldName" id="FieldName" variant="outlined" maxlength="33"
                        hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter1>
            <ColumnFilter :title="$t('requestForProposal.deadline')" :Filter="FieldDeadline"
                :getList="RequestForProposalList" @cancel="FieldDeadline = null">
                <template v-slot:input>
                    <dateField v-model="FieldDeadline" />
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter2>
            <ColumnFilter :title="$t('requestForProposal.categories')" :Filter="FieldCategory"
                :getList="RequestForProposalList" @cancel="FieldCategory = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldCategory" inline style="margin-top:6px ;">
                        <v-radio :label="$t('requestForProposal.Services')" value="0" id="Services"
                            name="Services"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter3>
            <ColumnFilter :title="$t('requestForProposal.submissions')" :Filter="FieldSubmissions"
                :getList="RequestForProposalList" @cancel="FieldSubmissions = null">
                <template v-slot:input>
                    <v-text-field type="number" max="7" min="0" v-model="FieldSubmissions" id="FieldSubmissions"
                        variant="outlined" maxlength="33" hide-details></v-text-field>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:Filter4>
            <ColumnFilter :title="$t('requestForProposal.status')" :Filter="FieldStatus" :getList="RequestForProposalList"
                @cancel="FieldStatus = null">
                <template v-slot:input>
                    <v-radio-group v-model="FieldStatus" inline style="margin-top:6px ;">
                        <v-radio :label="isOpen" value="0" id="Open" name="Open"></v-radio>
                        <v-radio :label="isClosed" value="1" id="Closed" name="Closed"></v-radio>
                    </v-radio-group>
                </template>
            </ColumnFilter>
        </template>
        <template v-slot:item0="{ item }">{{ item.title }}</template>
        <template v-slot:item1="{ item }">{{ item.deadline.split('T')[0] }}</template>
        <template v-slot:item2="{ item }">{{ item.category }}</template>
        <template v-slot:item3="{ item }">{{ item.submissions }} / 7</template>
        <template v-slot:item4="{ item }">
            <StatusButton :title="statut(item)" />
        </template>
        <template v-slot:edit="{ item, index }">
            <EditRequestConfiguration :Id="index" v-bind:idRequestForProposal="item.id" v-bind:getRequestList="item"
                v-bind:getRequestListAfterDelete="RequestForProposalList" />
        </template>
    </gridComponent>
</template>
<script>
import gridComponent from "@/genericComponent/gridComponent.vue";
import EditRequestConfiguration from "../RequestForProposal/EditRequestConfiguration.vue";
import StatusButton from "@/genericComponent/StatusButton.vue";
import { RequestForProposal, GetStatusProposalRequest } from "@/api/index";
import ColumnFilter from "@/genericComponent/ColumnFilter.vue";
import dateField from "@/genericComponent/date-field.vue";
import { status } from "@/enums/Status";
export default {
    components: { gridComponent, EditRequestConfiguration, StatusButton, ColumnFilter, dateField },
    data() {
        return {
            loader: true,
            page: 1,
            PageSize: 10,
            search: null,
            items: null,
            itemsData: {},
            FieldName: null,
            FieldDeadline: null,
            FieldCategory: null,
            FieldSubmissions: null,
            FieldStatus: null,
            dialogCompose: false,
            SortedBy: null,
            SortingType: null,
        };
    },
    created() {
        this.GetStatusProposalRequest();
    },
    mounted() {
        this.RequestForProposalList()
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
        this.emitter.on('event-test', (event) => {
            this.search = event;
            this.RequestForProposalList();
        })
    },
    unmounted() {
        this.emitter.off('event-test')
        this.emitter.emit('remise-search-at-null');
    },
    methods: {
        RequestForProposalList: function (page) {
            if(page){ this.page = page }
            RequestForProposal(this.page, this.PageSize, this.FieldName == null ? this.search : this.FieldName, this.FieldDeadline, this.FieldCategory, this.FieldSubmissions, this.FieldStatus, this.SortedBy, this.SortingType).then((response) => {
                this.items = response.data.items;
                this.itemsData.numberOfPage = response.data.totalPages;
                this.itemsData.totalItemsCount = response.data.totalCount;
                this.itemsData.pageNumber = response.data.pageNumber;
                this.itemsData.hasPreviousPage = response.data.hasPreviousPage
                this.itemsData.hasNextPage = response.data.hasNextPage
                this.loader = false;
            });
        },
        GetStatusProposalRequest() {
            GetStatusProposalRequest().then(response => {
                localStorage.setItem(status.Open + this.$i18n.availableLocales[0], response.data.Open.en);
                localStorage.setItem(status.Open + this.$i18n.availableLocales[1], response.data.Open.fr);
                localStorage.setItem(status.Closed + this.$i18n.availableLocales[0], response.data.Closed.en);
                localStorage.setItem(status.Closed + this.$i18n.availableLocales[1], response.data.Closed.fr);
            })
        },
    },
    computed: {
        statut() {
            return item => item.status == status.Closed ? localStorage.getItem(status.Closed + this.$i18n.locale) : localStorage.getItem(status.Open + this.$i18n.locale)
        },
        headers() {
            return [this.$t('requestForProposal.title'), this.$t('requestForProposal.deadline'), this.$t('requestForProposal.categories'), this.$t('requestForProposal.submissions'), this.$t('requestForProposal.status')]
        },
        isClosed() {
            return localStorage.getItem(status.Closed + this.$i18n.locale);
        },
        isOpen() {
            return localStorage.getItem(status.Open + this.$i18n.locale);
        },
    }
};
</script>